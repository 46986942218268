import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Box, Button, CardMedia } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import clsx from 'clsx';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Background from '../../assets/logo/background.svg';
import CategaryLogo from '../../assets/Images/category.png'
import Iconify from '../Iconify';


export default function CourseCard(props) {
    
    
    // const { userSubscribedCourses } = useSelector((state) => state);
    const allcourseDetails = useSelector((state) => state);

    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    const classes = useStyles();
    const navigate = useNavigate();
    const [statusfree, setStatusFree] = useState(props.data?.is_free);
    const [status, setStatus] = useState(null);
    const [statusForMainPage, setStatusForMainPage] = useState(null);
    const [isTrial, setIsTrial] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(true);
    const [isPaid, setisPaid] = useState(true);
    const [viewPrice, setViewPrice] = useState(0);
    useEffect(() => {
        if (allcourseDetails?.AllCourseDetails?.length > 0) {
            setIsTrial(true);
            setisPaid(false);
            const obj = allcourseDetails.AllCourseDetails.find((item) => item?.id === props?.data?.id);
            if (obj !== undefined) {
                if (obj.isTrial === false && obj.isSubscribed === false) {
                    setIsSubscribed(false);
                    setisPaid(false);
                } else {
                    setIsTrial(false);
                    setIsSubscribed(false);
                    setisPaid(true)
                }
            }
            else{
                setIsSubscribed(true);
            }        

        }
        else {
            setisPaid(true)
        }
    }, [props]);

    useEffect(() => {        
        setStatus('');
        if (props?.trial && props?.planStatus?.toLowerCase() === 'expired') {
            setStatus('Trial Ended');
        } else if (props?.trial&&props?.is_free !==true) {
            setStatus('Trial');
        }
        else if (props?.is_free) {
            setStatus('Free');
        } else if (props?.subscribed && props?.planStatus?.toLowerCase() === 'expired') {
            setStatus('Subscription Ended');
        } else if (props?.subscribed !== undefined && props?.subscribed !== "undefined") {
            setStatus('Subscribed');
        }
    }, [props]);

    
    useEffect(() => {   
        if (allcourseDetails?.AllCourseDetails?.length > 0) {
            const obj = allcourseDetails.AllCourseDetails.find((item) => item?.id === props?.data?.id);            
           
           
            if (obj!==undefined ) {
                 if (obj.isSubscribed) {
                    setStatusForMainPage('Subscribed');
                }

             }
             else{
                setStatusForMainPage('');
             }
        }     
       
    }, [props]);

    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[2],
                fontSize: 11,
            },
        })
    );

    const splitText = (text, maxWords) => {
        const words = text?.split(' ');
        if (words?.length > maxWords) {
            const firstLine = words.slice(0, maxWords).join(' ');
            const secondLine = words.slice(maxWords).join(' ');
            return [firstLine, secondLine];
        }
        return [text];
    };

    const lines = splitText(props.title, 30);

    React.useMemo(() => {
        const filteredList = props.data?.stripe_pricing_list?.filter(data =>
            allcourseDetails?.currency === 'USD'
                ? data?.related_currency_type_lk === 2
                : data?.related_currency_type_lk === 1
        );
        let displayPrice;

        if (filteredList && filteredList?.length > 0  && allcourseDetails?.currency === 'USD') {
            displayPrice = `$${filteredList[0].value}`;
        } else if (filteredList && filteredList?.length > 0  && allcourseDetails?.currency === 'INR') {
            displayPrice = `₹${filteredList[0].value}`;
        } else if(filteredList && filteredList?.length === 0 ) {
            // displayPrice = `$${props.data?.payable_cost}`;
            displayPrice = 'NAN'
        }
        setViewPrice(displayPrice)

    }, [allcourseDetails])

    return (
        <div>
            <Card
                className="cardhover"
                sx={{
                    // cursor: 'pointer',
                    minHeight: '370px',
                    maxHeight: '370px',
                    display: 'flex',
                    flexDirection: 'column',

                    transition: 'transform 0.3s ease-in-out, max-height 0.3s ease-in-out', // Add transition for max-height
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#00B673',
                        color: 'white',
                        textAlign: 'center',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                    }}
                >
                    <Typography variant="h6" component="div">
                        {props?.type && props?.type.charAt(0).toUpperCase() + props?.type.slice(1).toLowerCase()}
                    </Typography>
                </Box>

                <CardMedia
                    component="img"
                    image={props.image}
                    sx={{
                        height: '160px',
                        width: '100%',
                        objectFit: 'cover',
                        minHeight: '160px',
                        maxHeight: '160px',
                        cursor: props.data?.is_free || props.data?.subscriptionPlanId || props.data?.subscriptionplanid ? 'pointer' : 'default',
                    }}
                    alt="course image"
                    className="card-image"
                    // onClick={() => props.handleCardClick()}

                    onClick={() => {
                        if (props.data?.is_free || (props.data.subscriptionPlanId || props.data.subscriptionplanid !== null)) {
                            props.handleCardClick();
                        }
                    }}
                />

                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '12px' }}>

                    {props?.comingfrom !== 'MyLearning' &&
                    <Box sx={{ minHeight: '30px', display: 'flex', alignItems: 'center' }}>
                        {props.data?.is_free ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Typography variant="subtitle2" color="secondary" component="span" style={{ marginLeft: '2px', fontSize: '12px', marginTop: '4px' }}>Free</Typography>
                                {/* <Typography
                                    variant="body2"
                                    color="black"
                                    width="max-content"
                                    sx={{ display: 'flex'}}
                                >
                                    {props.data?.payable_cost ? props.data?.payable_cost: ""}
                                </Typography> */}
                            </Box>

                        ) : (
                            <>
        {statusForMainPage && props.data ? (
              <LightTooltip
                title={
                  <Box>
                    <Typography variant="body2">
                      Valid from: {moment(props.data?.validFrom).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Valid to: {moment(props.data?.validTo).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Days left: {props.data?.remainingDays} Days
                    </Typography>
                  </Box>
                }
                placement="top-start"
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  width="max-content"
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  {statusForMainPage} &nbsp;
                  <Iconify icon="bi:info-circle" sx={{ color: '#5f5b5b' }} />
                </Typography>
              </LightTooltip>
            ) : (
                <Typography
                variant="body2"
                color="black"
                width="max-content"
                sx={{ display: 'flex' }}
            >
                {viewPrice}
            </Typography>
            )}
                               
                            </>
                        )}
                    </Box>}


                    
{props?.comingfrom === 'MyLearning' &&
<Box sx={{ minHeight: '30px', display: 'flex', alignItems: 'center' }}>
            {status && props.data ? (
              <LightTooltip
                title={
                  <Box>
                    <Typography variant="body2">
                      Valid from: {moment(props.data?.validFrom).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Valid to: {moment(props.data?.validTo).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Days left: {props.data?.remainingDays} Days
                    </Typography>
                  </Box>
                }
                placement="top-start"
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  width="max-content"
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  {status} &nbsp;
                  <Iconify icon="bi:info-circle" sx={{ color: '#5f5b5b' }} />
                </Typography>
              </LightTooltip>
            ) : (
              <Box sx={{ height: '18px' }} />
            )}
          </Box>}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pr: 5 }}>
                        <Typography
                            variant="body2"
                            color="primary"
                            style={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                height: '35px',
                            }}
                            className={classes.coursetitle}
                            gutterBottom
                        >
                            {props?.title}
                        </Typography>
                    </Box>


                    {props.author && (
                        <Typography sx={{ color: '#323030', fontSize: '0.65rem', fontWeight: '600' }}>
                            Author: {props.author.name}
                        </Typography>
                    )}

                    {props.description && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            dangerouslySetInnerHTML={{ __html: props.description }}
                        />
                    )}

                    {/* {props.progress && <Box>{props.progress}</Box>} */}

                    {props?.comingfrom !== 'FreeCourses' && props?.comingfrom !== 'MyLearning' &&
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '6px', marginBottom: "6px", zIndex: '9999' }}>
                            {props.trial && isSubscribed && (
                                <LoadingButton
                                    variant="outlined"
                                    className={classes.trialButton}
                                    onClick={() => {
                                        props.handleTrial(props.subscriptionPlanId, props.data.id, props.data.is_free, props.data.id);
                                    }}
                                    loading={props.data.id === props.isTrialLoading}
                                >
                                    Free Access
                                </LoadingButton>
                            )} 

                            {isSubscribed && !props.is_free ?
                                (<LoadingButton
                                    variant="outlined"
                                    className={classes.subscribeButton}
                                    loading={props?.comingfrom !== 'RecomendedCourses' && props?.subscriptionPlanId === props.isSubscribeLoading }
                                    onClick={() => {
                                        props.handleSubcribe(props.data, props.subscriptionPlanId);
                                    }}
                                >
                                    subscribe
                                </LoadingButton>) :
                                ( (
                                    <LoadingButton
                                        variant="outlined"
                                        className={classes.CourseButton}
                                        // loading={props?.subscriptionPlanId === props.isSubscribeLoading}
                                        onClick={() => {
                                            // getCourseContent(props.item.id,props.subscriptionPlanId);
                                            if (userRole === 'USER_DTC') {
                                                navigate('/auth/course-details', { state: props.data });
                                            } else {
                                                navigate('/app/course-details', { state: props.data });
                                            }
                                        }}
                                    >
                                        Start Course
                                    </LoadingButton>
                                ))
                            }
                        </div>



                    }

                 {props.progress && props?.comingfrom === 'MyLearning' && <Box>{props.progress}</Box>}


                    {props.date && (
                        <Typography gutterBottom color="secondary" variant="body2">
                            {props.date}
                        </Typography>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                            {props.userLicense && (
                                <Typography variant="body2">
                                    User License <span className={classes.enrolledUser}> {props.userLicense}</span>{' '}
                                </Typography>
                            )}
                            {props.userCount && (
                                <Typography variant="body2" sx={{ marginLeft: '12px', width: 'max-content' }}>
                                    {props.userCount}
                                </Typography>
                            )}
                        </Box>

                        {props.status && (
                            <div className={clsx({
                                [classes.active]: props.status === 'Live' || props.status === 'LIVE',
                                [classes.inActive]: props.status === 'ACTIVE',
                                [classes.Expired]: props.status === 'INACTIVE',
                                [classes.inActive]: props.status === 'Draft' || props.status === 'DRAFT',
                            })}>
                                <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '400' }}>
                                    {props.status}
                                </Typography>
                            </div>
                        )}
                    </Box>

                    {props.Enrolled && (
                        <Box sx={{ marginTop: '1rem' }}>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                                <PersonIcon /> &nbsp; Enrolled Users &nbsp; {props.Enrolled}
                            </Typography>
                        </Box>
                    )}

                    {props.enrollDate && (
                        <Typography color="secondary" variant="body2" sx={{ fontSize: '10px' }}>
                            Enrolled on {moment(props.enrollDate).format('LL')}
                        </Typography>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {props.category && props.category?.length > 18 ?
                            <Tooltip title={props.category === 'testing' ? "Category Video" : props.category} arrow>
                                <Typography className={classes.button} color="secondary">
                                    {props.category && props.category === 'testing' ? "Category Video" : props.category}
                                </Typography>
                            </Tooltip>
                            :
                            <Typography className={classes.button} color="secondary">
                                {props.category && props.category === 'testing' ? "Category Video" : props.category}
                            </Typography>}
                        <Box style={{ marginLeft: 1, marginTop: -12 }} className={classes.back}>
                            <img className={classes.logo} src={props?.logo ? props?.logo : CategaryLogo} alt="logo" width={35} height={30} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    trial: {
        color: 'white',
        padding: '0px 6px 0px 6px ',
        background: 'grey',
    },
    coursetitle: {
        overflow: 'hidden',
        // color: 'black',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.down('md')]: {
            overflow: 'auto',
            WebkitLineClamp: 'initial',
            WebkitBoxOrient: 'initial',
        },


    },
    card: {
        border: '0.5px solid #DFDFDF',
        boxShadow: '0px 3px 6px  #0000001A',
        borderRadius: '6px',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
        // cursor: 'pointer',
        marginTop: '16px',
        marginLeft: '10px',
        width: '95%',
        // width: '85%', // Reduced from 90% to 85%
        // height: 'auto', // You can add this if needed
        // maxHeight: '300px', // Add maxHeight to limit overall card height
    },
    date: {
        marginTop: '15px',
    },
    button: {
        backgroundColor: '#F8F5F4',
        padding: '4px 6px',
        borderRadius: '6px',
        width: 'max-content',
        maxWidth: '165px',
        fontSize:'12px',
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    title: {
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: '15px',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    cardTitlenew: {
        fontWeight: 'bold',
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        minHeight: '50px',
        maxHeight: '50px',
    },
    logo: {
        boxShadow: '0px 3px 22px #00000029',
        padding: '4px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        marginTop: '10px',
    },
    description: {
        minHeight: '38px',
        fontSize: '0.8rem !important',
        overflow: 'hidden !important',
        fontFamily: 'Inter',
        textOverflow: 'ellipsis',
        '-o-text-overflow': 'ellipsis',
        '-ms-text-overflow': 'ellipsis',
        '-moz-binding': "url('ellipsis.xml#ellipsis')",
        '-ms-webkit-line-clamp': 2,
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '& span': {
            fontSize: '0.8rem !important',
            color: 'black !important',
            backgroundColor: 'unset !important',
            fontFamily: 'Inter !important',
        },
        '& p': {
            '&:nth-child(1)': {
                display: 'block !important',
            },
            '&:nth-child(even)': {
                display: 'none ',
            },
            '&:nth-child(odd)': {
                display: 'none ',
            },
        },
    },
    enrolledUser: {
        backgroundColor: '#EBFFF8',
        borderRadius: '6px',
        padding: '4px 12px',
        fontSize: '12px',
    },
    active: {
        backgroundColor: 'green',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    inActive: {
        backgroundColor: 'yellow',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    Expired: {
        backgroundColor: '#FF8282',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    trialButton: {
        width: 110,
        borderRadius: '6px',
        fontSize: '12px',
        backgroundColor: 'white',
        '@media (max-width: 1400px)':{
            padding: '6px 3px',
            lineHeight: '1',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        marginRight: '8px',
    },
    // CourseButton: {
    //     width: 110,
    //     color: '#00B673 ! important',
    //     border: '1px solid #00B673 ! important',
    //     borderRadius: '6px',
    //     fontSize: '12px',
    //     // marginLeft: '1rem',
    //     backgroundColor: 'white',
    //     '&:hover': {
    //         backgroundColor: 'white',
    //     },
    // },
    clamptext: {
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        minHeight: '2.4em',
        lineHeight: '1.2em'
        //    min-height: 2.4em;                 
        //    line-height: 1.2em;  
    },

    CourseButton: {
        width: 110,
        color: '#6a6e6b', // initial text color
        border: '1px solid #6a6e6b', // initial border color
        borderRadius: '6px',
        fontSize: '12px',
        backgroundColor: 'white',
        '&:hover': {
            color: '#00B673', // text color on hover
            border: '1px solid #00B673', // border color on hover
            backgroundColor: 'white',
        },
    },
    subscribeButton: {
        width: 110,
        color: '#00B673 ! important',
        border: '1px solid #00B673 ! important',
        borderRadius: '6px',
        fontSize: '12px',
        // marginLeft: '1rem',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));