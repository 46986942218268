import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import katex from "katex";
import "katex/dist/katex.min.css";
import { Avatar, Badge, Grid, FormControl, FormHelperText, Typography, Button, Radio, RadioGroup, Link, Tooltip, InputLabel, IconButton, FormControlLabel, FormGroup, } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import ImageResize from 'quill-image-resize-module-react';
import Checkbox from '@mui/material/Checkbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik, FieldArray } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import AppleIcon from '@mui/icons-material/Apple';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import DialogModal from '../../../components/modal/DialogModal';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import LinearProgressBar from '../../../components/progress/LinearProgressBar';
import WindowsIcon from '../../../assets/logo/windows.svg';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';

// Quill.register('modules/imageResize', ImageResize);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
}));

window.katex = katex;

const modules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ header: [1, 2, 3, false] }],
        ["image", { formula: { customClass: 'qlformula' } }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        matchVisual: false,
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar']
    },
};


const descriptionModules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, false] }],
        [{ color: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const descriptionFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align",
    "code-block",
    "script",
    "clean",
];
const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "formula",
    "color",
    "background",
    "align",
    "code-block",
    "script",
    "clean",
];
// const formats = [
//   'header', 'font', 'list', 'bold', 'italic', 'underline', 'color', 'background', 'align', 'math'
// ];
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const editorPlugin = [
    'a11ychecker',
    'advlist',
    'advcode',
    'advtable',
    'autolink',
    'checklist',
    'export',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'powerpaste',
    'fullscreen',
    'formatpainter',
    'insertdatetime',
    'media',
    'table',
    'help',
    'wordcount',
];

const toolbarStyle =
    'undo redo | casechange blocks | bold italic backcolor | ' +
    'alignleft aligncenter alignright alignjustify | ' +
    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help';

const EditSATQuestions = ({ open, modelClose, title, handleEditMcq, loading, searchedDetails, data,  CallBack }) => {
    console.log(data,'dddddddddddddddddddddddddd');
    
    const [category, setCategory] = useState([]);
    const [keyword, setKeyWord] = useState([]);
    const [categoryValue, setCategoryValue] = useState('');
    const [tag, setTag] = useState('');
    const [details, setDetails] = useState({ level: "", questionType: "English" });
    const [selectedSkills, setSelectedSkills] = useState('');
    const [passage, setPassage] = useState('');
    const [explanation, satExplanation] = useState('');


    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }
    const [subModule, setSubModule] = useState([]);
    const [allSubModules, setAllSubModules] = useState([]);
    const [submitError, setSubmitError] = useState('');
    const [currectError, setCurrectError] = useState('')
    const [loadingnew, setLoadingnew] = useState(false)
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [passageError, setPassageError] = useState('')
    const [levelError, setLevelError] = useState('')
    const [skillIdError, setSkillIdError] = useState('')
    const [questionError, setQuestionError] = useState('')
    const [optionError, setOptionError] = useState('')
    const [correctError, setCorrectError] = useState('')
    const [explanationError, setExplanationError] = useState('')


    const [values, setValues] = useState({
        question: '',
        questionType: 'English',
        mcqOptions: [{ option: '', isCorrect: false }],
    });

    const [mathsValues, setMathsValues] = useState({
        question: '',
        questionType: 'Maths',
        mcqOptions: [{ option: '', isCorrect: false }],
    });


    useEffect(() => {
        if (data && data.options) {
            const optionsData = JSON.parse(data?.options);
            const formattedOptions = optionsData.mcqOptions.map((optionText, index) => ({
                option: optionText,
                isCorrect: optionsData.correctAnswer[index] || false,
            }));

            if (data.question_type === 'English') {
                setValues({
                    question: data.question_text || '',
                    questionType: 'English',
                    mcqOptions: formattedOptions,
                });
                setDetails(prevDetails => ({
                    level: data.level || '',
                    questionType: 'English'
                }));
            } else if (data.question_type === 'Maths') {
                setMathsValues({
                    question: data.question_text || '',
                    questionType: 'Maths',
                    mcqOptions: formattedOptions,
                });
            }
            setSelectedSkills(data.name || '')
            setCategoryValue(data.category?.code || '');
            setTag(data.hashtags || []);
            setPassage(data.passage || '');
            satExplanation(data.justification || '');
            setDetails(prevDetails => ({
                level: data.level || '',
                questionType: data.question_type || 'English',
            }));
        }
    }, [data]);


    const handleChangeMathsQuestion = (field, value) => {
        setMathsValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }

    const handleChangeQuestion = (field, value) => {
        setValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }





    const handleMCQOptionChange = (index, field, value) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions[index][field] = value;
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };

    const handleMCQOptionChangeMaths = (index, field, value) => {
        const newMCQOptions = [...mathsValues.mcqOptions];
        newMCQOptions[index][field] = value;
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };



    const handleAddOption = () => {
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
        }));
    };

    const handleAddOptionMaths = () => {
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
        }));
    };

    const handleRemoveOptionMaths = (index) => {
        const newMCQOptions = [...mathsValues.mcqOptions];
        newMCQOptions.splice(index, 1);
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };

    const handleRemoveOption = (index) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };


    const handleChangeCheck = (event) => {
        setSelectedSkills(event.target.value);
    };



    const classes = useStyles();

    const validation = () => {
        if (!details.level) {
            setLevelError("This field is required")
            return false
        }
        if (!selectedSkills) {
            setSkillIdError("This field is required")
            return false
        }
        if (details?.questionType === 'English'&&!passage) {
            setPassageError("This field is required")
            return false
        }
        if (details?.questionType === 'English'&&!values.question) {
            setQuestionError("This field is required")
            return false
        }
        if (details?.questionType === 'Maths'&&!mathsValues.question) {
            setQuestionError("This field is required")
            return false
        }

        const validOptionEnglish = values.mcqOptions.some(option => option.option.trim() === '');

        if (details?.questionType ==='English'&&validOptionEnglish) {
            setOptionError("This field is required")
            return false
        }

        const validCorrectAnswerEnglish = values.mcqOptions.find(option => option.isCorrect);

        if (details?.questionType === 'English'&&!validCorrectAnswerEnglish) {
            setCorrectError("No correct answer selected")
            return false
        }

        const validOptionMaths = mathsValues.mcqOptions.some(option => option.option.trim() === '');

        if (details?.questionType === 'Maths'&&validOptionMaths) {
            setOptionError("This field is required")
            return false
        }

        const validCorrectAnswerMaths = mathsValues.mcqOptions.find(option => option.isCorrect);

        if (details?.questionType === 'Maths'&&!validCorrectAnswerMaths) {
            setCorrectError("No correct answer selected")
            return false
        }
        if (!explanation) {
            setExplanationError("This field is required")
            return false
        }
        return true
    }

    const CreateQuestion = async () => {
        const validate = validation()
        if (validate) {
            setLoadingnew(true);
            try {
                const keysData = new FormData();
                keysData.append('level', details.level);
                keysData.append('questionType', details.questionType);
                keysData.append('courseCategory', categoryValue);
                keysData.append('cognitive_skills', selectedSkills);
                keysData.append('explanation', explanation);
                if (details.questionType === 'English') {
                    const mcqdata = {
                        question: values.question,
                        mcqOptions: values.mcqOptions.map(option => option.option),
                        correctAnswer: values.mcqOptions.map(option => option.isCorrect),
                    };
                    keysData.append('question', values.question);
                    keysData.append('mcqData', JSON.stringify(mcqdata));
                    keysData.append('passage', passage);

                }
                if (details.questionType === 'Maths') {
                    const mcqdata = {
                        question: mathsValues.question,
                        mcqOptions: mathsValues.mcqOptions.map(option => option.option),
                        correctAnswer: mathsValues.mcqOptions.map(option => option.isCorrect),
                    };
                    keysData.append('question', mathsValues.question);
                    keysData.append('mcqData', JSON.stringify(mcqdata));

                }

                const response = await adminServices.UpdateSATQuestionsBasedOnId(data.id, keysData);
                if (response.ok) {
                    setSnackbarTitle('SAT  Questions Updated successfully');
                    setOpenSnackbar(true);
                    setDetails({ level: "", questionType: "English" });
                    setCategoryValue("")
                    setTag("")
                    setSelectedSkills("")
                    setPassage("")
                    setMathsValues({
                        question: '',
                        questionType: 'Maths',
                        mcqOptions: [{ option: '', isCorrect: false }],
                    });
                    setValues({
                        question: '',
                        questionType: 'English',
                        mcqOptions: [{ option: '', isCorrect: false }],
                    })
                    setLoadingnew(false);
                    CallBack();
                } else {
                    setLoadingnew(false);
                    console.error("Error:", response);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                setLoadingnew(false);
            }
        }
    }
    
    const removeHtmlTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
      };
      

    return (
        <>
            <DialogModal open={open} handleClose={modelClose} title={title}>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}
                        //  error={touched.points && Boolean(errors.points)}
                        >
                            <Autocomplete
                                id="CourseCategory"
                                fullWidth
                                filterSelectedOptions
                                autoComplete
                                options={category}
                                autoHighlight
                                getOptionLabel={(option) => option.description}
                                value={category.find(cat => cat.code === categoryValue) || null}
                                onChange={(e, value) => {
                                    const codeValue = value ? value.code : null;
                                    setCategoryValue(codeValue);
                                }}
                                getOptionSelected={(option, value) => option.code === value}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img loading="lazy" width="20" src={option.categoryImgUrl} alt="i" />
                                        {option.description}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="courseCategory"
                                        // error={Boolean(touched.courseCategory && errors.courseCategory)}
                                        // helperText={touched.courseCategory && errors.courseCategory}
                                        label="Question category"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}
                        // error={touched.points && Boolean(errors.points)}
                        >
                            <Autocomplete
                                id="questionTag"
                                fullWidth
                                filterSelectedOptions
                                autoComplete
                                multiple
                                options={keyword}
                                autoHighlight
                                getOptionLabel={(option) => option.description}
                                value={
                                    tag
                                        ? keyword.filter(cat => tag.includes(cat.code))
                                        : []
                                }
                                onChange={(e, value) => {
                                    const selectedCodes = value ? value.map(item => item.code) : [];
                                    setTag('keyword', selectedCodes);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.description}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="keyword"
                                        // error={Boolean(touched.keyword && errors.keyword)}
                                        // helperText={touched.keyword && errors.keyword}
                                        label="Tag"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                            <Select
                                name="level"
                                labelId="demo-simple-select-standard-label"
                                id="level"
                                label="Level"
                                value={details.level}
                                onChange={(event) => { handleChange(event); setLevelError('') }}
                                displayEmpty
                            >
                                <MenuItem value="easy">Easy</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="complex">Complex</MenuItem>
                            </Select>
                            {levelError && (
                                <FormHelperText error>{levelError}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl}
                        // error={touched.questionType && Boolean(errors.questionType)}
                        >
                            <InputLabel id="demo-simple-select-standard-label">Type*</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="questionType"
                                name="questionType"
                                label="Type"
                                value={details.questionType}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Maths">Maths</MenuItem>
                            </Select>
                            {/* {touched.questionType && errors.questionType && (
                                        <FormHelperText>{errors.questionType}</FormHelperText>
                                    )} */}
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <FormControl fullWidth variant="outlined">

                            <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                Select Level of Objective</Typography>
                            <FormGroup className='FormCheck'>
                                {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                    <FormControlLabel
                                        key={skill}
                                        control={
                                            <Radio
                                                checked={selectedSkills === skill}
                                                onChange={(event) => { handleChangeCheck(event); setSkillIdError('') }}
                                                value={skill}
                                            />
                                        }
                                        label={skill}
                                    />
                                ))}
                            </FormGroup>
                            {skillIdError && (
                                <FormHelperText error>{skillIdError}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    {details?.questionType === 'English' ?
                        <>
                            <Grid item xs={12}>
                                <Typography className={classes.background} gutterBottom variant="subtitle1">
                                    Passage*
                                </Typography>
                                <Box>
                                    <textarea
                                        id="passage"
                                        value={removeHtmlTags(passage)}
                                        onChange={(event) => { setPassage(event.target.value); setPassageError('') }}
                                        placeholder="Enter Passage"
                                        rows="5"
                                        cols="40"
                                        style={{
                                            width: "100%",
                                            height: "150px",
                                            padding: "10px",
                                            fontSize: "16px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            outline: "none",
                                            resize: "both",
                                            transition: "border-color 0.3s",
                                        }}
                                    />
                                    {passageError && (
                                        <FormHelperText error>{passageError}</FormHelperText>
                                    )}
                                </Box>
                            </Grid>


                            {details?.questionType === 'English' &&
                                <>
                                    <div >
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <Typography className={classes.background} gutterBottom variant="subtitle1">
                                                    Create Question*
                                                </Typography>

                                                <ReactQuill
                                                    theme="snow"
                                                    id={`questionText`}
                                                    name="question"
                                                    defaultValue={values.question}
                                                    onChange={(value) => { handleChangeQuestion('question', value); setQuestionError('') }}
                                                    modules={modules}
                                                    formats={formats}
                                                    fullWidth
                                                />
                                                {questionError && (
                                                    <FormHelperText error>{questionError}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>


                                        {values?.mcqOptions?.map((opt, index) => (
                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                        <ReactQuill
                                                            theme="snow"
                                                            id={`optiontext`}
                                                            name={`mcqQuestion`}
                                                            defaultValue={opt.option}
                                                            onChange={(value) => { handleMCQOptionChange(index, 'option', value); setOptionError('') }}
                                                            modules={modules}
                                                            formats={formats}
                                                            placeholder="Option"
                                                            style={{ marginTop: 10, flex: 1 }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            color="error"
                                                            onClick={() => handleRemoveOption(index)}
                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={`mcqOptions.isCorrect`}
                                                                    checked={opt.isCorrect}
                                                                    onChange={() => {handleMCQOptionChange(index, 'isCorrect', !opt.isCorrect); setCorrectError('')}}
                                                                    disabled={!opt.option.trim()}
                                                                />
                                                            }
                                                            label="Correct"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                    {optionError && (
                                        <FormHelperText error>{optionError}</FormHelperText>
                                    )}
                                    {correctError && (
                                        <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                            {correctError}
                                        </div>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                            onClick={() => handleAddOption()}
                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                        >
                                            Add Option
                                        </Button>
                                    </div>
                                    <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                        <Typography variant="subtitle1">Explanation *</Typography>
                                        <ReactQuill
                                            theme="snow"
                                            id="explanation"
                                            name="explanation"
                                            value={explanation}
                                            onChange={(content, delta, source, editor) => {
                                                satExplanation(content); setExplanationError('')
                                            }}
                                        />
                                        {explanationError && (
                                            <FormHelperText error>{explanationError}</FormHelperText>
                                        )}
                                    </Grid>
                                </>
                            }
                        </>
                        :
                        <>
                            <Grid item xs={12}>
                                <FormControl style={{ width: '100%' }} className={classes.formControl}
                                //  error={touched.question && Boolean(errors.question)}
                                >
                                    <Typography className={classes.background} color="primary" gutterBottom variant="subtitle1">
                                        Create Question*
                                    </Typography>
                                    <ReactQuill
                                        theme="snow"
                                        id="questionText"
                                        name="question"
                                        defaultValue={mathsValues.question}
                                        onChange={(value) => { handleChangeMathsQuestion('question', value); setQuestionError('') }}
                                        modules={modules}
                                        formats={formats}
                                        // onBlur={() => setTouched((prev) => ({ ...prev, question: true }))}
                                        fullWidth
                                    />
                                    {questionError && (
                                        <FormHelperText error>{questionError}</FormHelperText>
                                    )}                                </FormControl>
                            </Grid>


                            {mathsValues?.questionType === 'Maths' && (
                                <>
                                    <Grid item xs={12}>
                                        {mathsValues?.mcqOptions.map((opt, index) => (
                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                        <ReactQuill
                                                            theme="snow"
                                                            id={`optiontext${index}`}
                                                            name="mcqQuestion"
                                                            defaultValue={opt.option}
                                                            onChange={(value) => { handleMCQOptionChangeMaths(index, 'option', value); setOptionError('') }}
                                                            modules={modules}
                                                            formats={formats}
                                                            placeholder="Option"
                                                            style={{ marginTop: 10, flex: 1 }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            color="error"
                                                            onClick={() => handleRemoveOptionMaths(index)}
                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={`mcqOptions.${index}.isCorrect`}
                                                                    checked={opt.isCorrect}
                                                                    onChange={() => {handleMCQOptionChangeMaths(index, 'isCorrect', !opt.isCorrect);setCorrectError('')}}
                                                                    disabled={!opt.option.trim()}
                                                                />
                                                            }
                                                            label="Correct"
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </div>
                                        ))}
                                        {optionError && (
                                            <FormHelperText error>{optionError}</FormHelperText>
                                        )}

                                        {submitError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {submitError}
                                            </div>
                                        )}
                                        {correctError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {correctError}
                                            </div>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleAddOptionMaths}
                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                        >
                                            Add Option
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                        <Typography variant="subtitle1">Explanation *</Typography>
                                        <ReactQuill
                                            theme="snow"
                                            id="explanation"
                                            name="explanation"
                                            value={explanation}
                                            onChange={(content, delta, source, editor) => {
                                                satExplanation(content); setExplanationError('')
                                            }}
                                        />
                                        {explanationError && (
                                            <FormHelperText error>{explanationError}</FormHelperText>
                                        )}

                                    </Grid>
                                </>
                            )}
                        </>
                    }


                    <Grid item xs={12}>
                        <LoadingButton
                            id="subMitButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={CreateQuestion}
                            loading={loadingnew}
                            fullWidth
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogModal>
            <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

        </>

    );
};

export default EditSATQuestions;