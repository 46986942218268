
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {
    TextField, Button, Chip, Box, Badge, Avatar, Paper, Typography, InputAdornment, IconButton, FormHelperText, InputLabel, Select, FormControl, Grid, Dialog,
    DialogActions, DialogContent,
    Checkbox,
    ListItemText, CardContent,
    DialogTitle, Alert, Radio, RadioGroup, FormControlLabel, FormGroup, CardActionArea, Card
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import SearchIcon from "@mui/icons-material/Search";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { DropzoneArea } from 'material-ui-dropzone';
import DOMPurify from 'dompurify';
import { makeStyles } from '@mui/styles';
import katex from "katex";
import DialogModal from '../../../components/modal/DialogModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';
import Page from '../../../components/Page'
import PageHeader from '../../../components/PageHeader';
import './styles.css'
import "katex/dist/katex.min.css";

window.katex = katex;

const modules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ header: [1, 2, 3, false] }],
        ["image", { formula: { customClass: 'qlformula' } }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};


const descriptionModules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, false] }],
        [{ color: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const descriptionFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align",
    "code-block",
    "script",
    "clean",
];
const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "formula",
    "color",
    "background",
    "align",
    "code-block",
    "script",
    "clean",
];

const GenericAssessmentAdd = () => {
    const userInfo = useSelector((state) => state.userInfo && state.userInfo);
    const navigate = useNavigate();

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [originalQuestionList, setOriginalQuestionList] = useState([]);
    const [hashtag, setHashtag] = useState([]);
    const [category, setCategory] = useState('');
    const [course, setCourse] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [name, setName] = useState('');
    const [questionid, setQuestionid] = useState('');
    const [questionDetails, setQuestionDetails] = useState('');


    const [complexity, setComplexity] = useState('');
    const [nameError, setNameError] = useState('');

    const [ImageError, setImageError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    const [categoryError, setCategoryError] = useState('');
    const [courseError, setCourseError] = useState('');
    const [ComplexityError, setComplexityError] = useState('');
    const [fields, setFields] = useState(['']);
    const [error, setError] = useState(false);
    const [requirederrors, setrequiredErrors] = useState({
        moduleName: "",
        points: "",
        questionid: "",
        options: "",
        selectedSkills: '',
        optionNumber:''

    });



    const [questionerrors, setQuestionErrors] = useState("")



    const [nodata, setNodata] = useState(true);
    const [categoryList, setCategoryList] = useState([])
    const [courseList, setCourseList] = useState([])


    const [thumbImage, setThumbImage] = useState(null);
    const [thumbPreview, setThumbPreview] = useState(null);
    const [description, setDescription] = useState("");
    const [editorValue, setEditorValue] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const [editData, setEditData] = useState("");
    const [editIndex, setEditIndex] = useState("");
    const [editIndexnew, setEditIndexnew] = useState("");


    const [editDialog, setEditDialog] = useState(false);

    const [moduleName, setModuleName] = useState('');
    const [selectIndex, setSelectIndex] = useState('');

    const [points, setPoints] = useState('');
    const [values, setValues] = useState({
        question: '',
        questionType: 'MCQ',
        mcqOptions: [],
    });

    const [questionWeightage, setQuestionWeightage] = useState({});

    const [open, setOpen] = useState(false);
    const [openBox, setOpenBox] = useState(false);

    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedWeightage, setSelectedWeightage] = useState('');
    const [questions, setQuestions] = useState(['Question 1', 'Question 2', 'Question 3']);
    const [weightages, setWeightages] = useState([10, 20, 30]);

    const [moduleData, setModuleData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageAll, setErrorMessageAll] = useState("");
    const [selectedOption, setSelectedOption] = useState('create');
    const [newQuestion, setNewQuestion] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [filteredQuestions, setFilteredQuestions] = useState(questionList);
    const [selectedSkills, setSelectedSkills] = useState('');
    const [loadingnew, setLoadingnew] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [hasCertificate, setHasCertificate] = useState(false);
    const [Preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState('');
 
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "formula",
        "color",
        "background",
        "align",
        "code-block",
        "script",
        "clean",
    ];



    const handleOpen = () => {
        setOpenBox(true);
    };

    const handleCloseBox = () => {
        setOpenBox(false);
    };
    const ClearError = () => {
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
            options: "",
            selectedSkills: '',
            optionNumber:''
        });
    }
   

    const handleChangeCheck = (event) => {
        setrequiredErrors({
            selectedSkills: ''
        });      
          setSelectedSkills(event.target.value);
    };
    const handleChangeDescription = (value) => {
        const cleanedValue = value
        .replace(/<p><br><\/p>/g, '')
        .replace(/<p><\/p>/g, '') 
        .trim();    
        setDescriptionError("");
        setEditorValue(cleanedValue);
    };

    const handleChangeHighlightedDescription = (index, value) => {
        setErrorMessage("");
        const updatedFields = [...fields];
        updatedFields[index] = value;
        setFields(updatedFields);
    };

    const handleAddField = () => {
        setErrorMessage("");
        const isAnyFieldEmpty = fields.some(field => field.trim() === '');
        if (isAnyFieldEmpty) {
            setError(true);
        } else {
            setError(false);
            if (fields.length < 6) {
                setFields([...fields, '']);
            }
        }
    };

    const handleRemoveField = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };


    const handlePreview = () => {
        setPreview(false)
        setEditIndexnew('')
        setPreviewData('')
    }
    const handlePreviewOpen = (data, index) => {
        setEditIndexnew(index)
        setPreviewData(data)
        setPreview(true)
    }


    const handleDeleteModules = (index) => {
        setModuleData((prev) => prev.filter((_, i) => i !== index));
        setSnackbarTitle('Module Deleted successfully');
        setOpenSnackbar(true);
    };



    const DeleteQuestion = (index) => {
        setPreviewData((prev) => ({
            ...prev,
            questions_list: prev?.questions_list.filter((_, i) => i !== index),
            questiondetails: prev?.questiondetails.filter((_, i) => i !== index)
        }));
    };

    const handleUpdateQuestions = (editIndexnew) => {
        setModuleData((prevModuleData) =>
            prevModuleData.map((item, index) =>
                index === editIndexnew ? { ...item, ...previewData } : item
            )
        );
        setPreview(false);
    };

    const handleSearchClick = (e) => {
        setSearchQuery1(e.target.value)
        const filtered = questionList?.filter((question) =>
            question?.question_text?.toLowerCase().includes(e.target.value)
        );
        setQuestionList(filtered);
    };
    const handlePoints = (e) => {
        // setPoints(e.target.value.replace(/\D/, '')); 
        // ClearError()

        const value = e.target.value.replace(/\D/, '');
        if (value === '0') {
            setrequiredErrors({
                points: "Value cannot be 0",
            })
        } else {
            setrequiredErrors({
                points: "",
            })
        }
        setPoints(value);
    }

    useEffect(() => {
        getCategory()
        setLoading(false);
        getQuestionList(searchQuery);
    }, []);


    useEffect(() => {
        if (category) {
            getCourse(category)
        }
    }, [category])


    const getCourse = async (category) => {
        const result = await adminServices.getCourseBasedOnId(category);
        if (result.ok) {
            setCourseList(result.data)
        }
    }

    // const sanitizeConfig = {
    //     ALLOWED_TAGS: ['b', 'i', 'em', 'a', 'ul', 'ol', 'li'],
    // };
    const sanitizeConfig = {
        ALLOWED_TAGS: ['b', 'i', 'em', 'a', 'ul', 'ol', 'li'],
        
        ALLOWED_ENTITIES: []
    };


    const getQuestionList = async (data) => {
        const result = await adminServices.getQuestionDataMCQNew(data);
        if (result.ok) {
            console.log(result ,result.data, "result.data11");
            
            setQuestionList(result.data);
            setOriginalQuestionList(result.data); 
        }
    };



    const handleFileChange = (file) => {
        setImageError("")
        if (file[0]?.size < 2097152) {
            imageWidthAndHeight(file[0]).then((res) => {
                if (res.width >= 360 && res.height >= 200) {
                    const url = URL.createObjectURL(file[0]);
                    setThumbPreview(url);
                    setThumbImage(file[0]);
                } else {
                    alert("Image dimensions must be at least 360x200px.");
                }
            });
        }
        // else {
        //   alert("File size exceeds the 2MB limit.");
        // }
    };

    const imageWidthAndHeight = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = function () {
                img.onload = function () {
                    resolve({ width: img.width, height: img.height });
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const getCategory = async () => {
        const result = await adminServices.getCategoryLists();
        if (result.ok) {
            setCategoryList(result.data)
        }
    };


    const handleReset = () => {
        setValues({
            question: '',
            questionType: 'MCQ',
            mcqOptions: []
        });
    };
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [currectError, setCurrectError] = useState('');

    const handleChangeQuestion = (field, value) => {
        setValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleMCQOptionChange = (index, field, value) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions[index][field] = value;
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };

    const handleAddOption = () => {
        if (values.mcqOptions.every(option => option.option.trim())) {
            setValues((prevState) => ({
                ...prevState,
                mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
            }));
            setSubmitError('');
        }
    };

    const handleRemoveOption = (index) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };



    const Validation = () => {
        if (!name) {
            setNameError("This field is required")
            return false
        }
        if (!category) {
            setCategoryError("This field is required")
            return false
        }
        // if (!course) {
        //     setCourseError("This field is required")
        //     return false
        // }

        if (!complexity) {
            setComplexityError("This field is required")
            return false
        }

        if (!thumbImage) {
            setImageError("This field is required")
            return false
        }
        if (!editorValue) {
            setDescriptionError("This field is required")
            return false
        }
        if (editorValue?.length > 255) {
            setDescriptionError("Description cannot be more than 255 characters");
            return false;
        }
        if (moduleData?.length === 0) {
            setErrorMessage("Please fill out the question and weightage before adding a new one.");
            return false
        }
        const totalWeightage = moduleData.reduce((acc, module) => acc + parseFloat(module.weightage || 0), 0);

        if (totalWeightage !== 100) {
            setErrorMessage("The total weightage must be exactly 100.");
            return false;
        }
        if (fields && fields?.length < 3) {
            setErrorMessage("Please add highlighted Description must be exactly 3.");
            return false;
        }
        return true
    }
    const QuestionValidationSubmit = () => {

        if (!selectedSkills) {
            setrequiredErrors({
                selectedSkills: "Please Select Level of Objective",
            })
            return false
        }

        if (!values.question) {
            setrequiredErrors({
                questionid: "Please Submit question and Answer!",
            })
            return false
        }
        if (!values.mcqOptions.every(option => option.option)) {
            setrequiredErrors({
                options: "Please fill all the options!",
            })
            return false
        }
        if (values.mcqOptions?.length < 4) {
            setrequiredErrors({
                optionNumber: "  please add at least 4 options ",
            })
            return false
        }
        

        if (!values.mcqOptions.some(option => option.isCorrect)) {
            setrequiredErrors({
                questionid: "Please select at least one correct answer!",
            })
            return false
        }
        return true
    };

    const handleSubmitQuestion = async () => {
        if (submitted) return;

        const valid = QuestionValidationSubmit()

        if (valid) {

            try {
                setLoadingnew(true);

                const keysData = new FormData();
                keysData.append('question', values.question);
                keysData.append('level', complexity);
                keysData.append('questionType', values.questionType);
                keysData.append('courseCategory', category);
                keysData.append('cognitive_skills', selectedSkills);
                const mcqdata = {
                    question: values.question,
                    mcqOptions: values.mcqOptions.map(option => option.option),
                    correctAnswer: values.mcqOptions.map(option => option.isCorrect),
                };
                keysData.append('mcqData', JSON.stringify(mcqdata));

                const response = await adminServices.createQuestions(keysData);

                if (response.ok) {
                    setQuestionid(response.data.id?.id);
                    setQuestionDetails(response.data.id?.question_text)
                    setQuestionErrors("")
                    setSubmitted(true);
                } else {
                    console.error("Error:", response);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                setLoadingnew(false);
            }
        }
    };



    const handleSubmit = async () => {
        const valid = Validation()


        if (valid) {
            setLoading(true);

            const totalSeconds = (time.hours * 3600) + (time.minutes * 60) + time.seconds;
            const selectedQuestions = moduleData && moduleData?.map(module => module.questions_list).flat()

            const formData = new FormData();
            formData.append('name', name);
            formData.append('complexity_level', complexity);
            formData.append('description', editorValue);
            formData.append('time_in_mins', totalSeconds);

            formData.append('category', category);
            formData.append('thumbImage', thumbImage);
            formData.append('modules', JSON.stringify(moduleData));
            formData.append('is_published', false);
            formData.append('userId', userInfo.id);
            formData.append('selectedQuestions', selectedQuestions);
            formData.append('is_free', isFree);
            formData.append('hasCertificate', hasCertificate);
            formData.append("highlight_desc", fields);
            if(course){
                formData.append("course_id", course);
            }
            try {
                const response = await adminServices.CognitiveSkillGA(formData);
                if (response.ok) {
                    setSnackbarTitle('Assignment created successfully');
                    setOpenSnackbar(true);
                    navigate("/app/generalassessment")
                    CloseFunction()
                    setModuleData([])

                    setValues({
                        question: '',
                        questionType: 'MCQ',
                        mcqOptions: [],
                    });
                    setName('')
                    setLoading(false);
                }

            } catch (error) {
                console.log(error);
            }

        }
    };

    const CloseFunction = () => {
        setComplexity("")
        setCategory("")
        setName("")
        setEditorValue("")
        setQuestionList([])
        setSelectedQuestions([])
        setTags([])
        setIsSearched(false);
        setNameError("")
        setCategoryError("")
        setComplexityError("")
        setImageError("")
        setDescriptionError("")
        setDescription("")
        setThumbPreview(null)
        setThumbImage(null)
    }

    const [time, setTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTime((prevTime) => ({
            ...prevTime,
            [name]: value,
        }));
    };

    const hoursArray = Array.from({ length: 24 }, (_, i) => i);
    const minutesArray = Array.from({ length: 60 }, (_, i) => i);
    const secondsArray = Array.from({ length: 60 }, (_, i) => i);



    const handleDialogOpen = () => {
        setSubmitted(false);
        setErrorMessage("")
        if (!category || !complexity || !editorValue) {
            setErrorMessageAll("Please fill out the Above Details.");
        } else {
            setErrorMessageAll("")
            setOpen(true);
        }

    };


    const handleClose = () => {
        setOpen(false);
        setQuestionid('');
        setModuleName('');
        setPoints('');
        setSelectedSkills('')
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
            options: "",
            selectedSkills: ''
        });
        setValues(
            {
                question: '',
                questionType: 'MCQ',
                mcqOptions: [],
            }
        )
    };
    const handleCloseNew = () => {
        setOpenDialog(false);
        setQuestionid('');
        setSelectedSkills('')
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
            options: "",
            selectedSkills: ''
        });
        setValues(
            {
                question: '',
                questionType: 'MCQ',
                mcqOptions: [],
            }
        )
    };
    const handleCloseEdit = () => {
        setEditDialog(false);
        setEditData("")
        setEditIndex("")
    };

    const EditModule = (e) => {
        const { name, value } = e.target;
        setEditData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleModuleUpdateQuestion = () => {
        const updatedModules = [...moduleData];
        updatedModules[editIndex] = editData;
        setModuleData(updatedModules);
        setEditDialog(false);
    };









    const handleAddNewQuestion = (index) => {
        setSubmitted(false);
        setOpenDialog(true);
        setSelectIndex(index)
    }


    const handleEditDetails = (data, index) => {
        setEditData(data)
        setEditIndex(index)
        setEditDialog(true)
    }

    const QuestionValidation = () => {
        const requirederrors = { moduleName: "", points: "", questionid: "",selectedSkills:"" };
        if (!moduleName && !points && !questionid && !selectedSkills) {
            setrequiredErrors({ moduleName: "Name is required!", points: "Weightage is required!", questionid: "Please Submit question and Answer!",selectedSkills: "Please Select Level of Objective"})
        }
        if (!moduleName) {
            requirederrors.moduleName = "Name is required!";
        }
        if (!points) {
            requirederrors.points = "Weightage is required!";
        }
        if (parseInt(points, 10) === '0' || parseInt(points, 10) === 0 || parseInt(points, 10) < 1) {
            requirederrors.points = "Value cannot be 0";
        }
        if (!questionid) {
            requirederrors.questionid = "Please Submit question and Answer!";
            // setQuestionErrors("Please Submit question and Answer!")
            // return false
        }
        if(!selectedSkills){
            requirederrors.selectedSkills =  "Please Select Level of Objective"
        }
        setrequiredErrors(requirederrors);
        return Object.values(requirederrors).every((error) => error === "");
    };

    const CreateQuestion = (e) => {
        const searchQuery = e.target.value?.toLowerCase();
        setQuestionDetails(searchQuery);
        
        if (!searchQuery) {         
            setQuestionList(originalQuestionList);
            setVisible(true);
            return;
        }
        
        const filtered = originalQuestionList?.filter((item) =>
            item.question_text?.toLowerCase().includes(searchQuery)
        );
        
        setQuestionList(filtered || []);
        setVisible(true);
    }

    // const handleSearchChange = (e) => {
    //     // const value = e.target.value;
    //     // setSearchQuery(value);
    //     getQuestionList(e.target.value)
    // };


    const handleModuleSubmitCreate = () => {
        const Validation = QuestionValidation()

        if (Validation) {
            const newFormObject = {
                name: moduleName,
                questions_list: Array.isArray(questionid) ? questionid : [questionid],
                weightage: points,
                questiondetails: Array.isArray(questionDetails) ? questionDetails : [questionDetails],
            };
            setModuleData([...moduleData, newFormObject]);
            setModuleName('');
            setPoints("")
            setQuestionid('')
            setQuestionDetails('')
            setSelectedOption('create')
            setValues({});
            setTimeout(() => {
                setValues({
                    question: '',
                    questionType: 'MCQ',
                    mcqOptions: [],
                });
            }, 0);

            setSelectedSkills("");
            handleClose();
        }
    };
    const handleModuleSubmitCreateQuestion = (selectIndex) => {
        if (questionid) {
            const updatedModuleData = moduleData.map((module, index) => {
                if (index === selectIndex) {
                    return {
                        ...module,
                        questions_list: [...module.questions_list, questionid],
                        questiondetails: [...module.questiondetails, questionDetails],

                    };
                }
                return module;
            });

            setModuleData(updatedModuleData);
            setModuleName('');
            setPoints('');
            setQuestionid('');
            setQuestionDetails('')
            setSelectedOption('create')

            setValues({});

            setTimeout(() => {
                setValues({
                    question: '',
                    questionType: 'MCQ',
                    mcqOptions: [],
                });
            }, 0);

            setSelectedSkills('');
            handleCloseNew();
        }
        else {
            setrequiredErrors({
                questionid: "Please Submit question and Answer!",
            })
            if(!selectedSkills){
                setrequiredErrors({
                    selectedSkills: "Please Select Level of Objective",
                })
            }
             
            // setQuestionErrors("Please Submit question and Answer")
        }
    };

    let buttonText = "Submit Question";
    if (loadingnew) {
        buttonText = "Submitting...";
    } else if (submitted) {
        buttonText = "Submitted";
    }

    const handleChangeOption = (e) => {
        setSelectedOption(e.target.value)
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
            options: "",
            selectedSkills: ''
        });
    }

    const handleFreeChange = (e) => {
        setIsFree(e.target.checked);
        if (e.target.checked) {
            setHasCertificate(false);
        }
    };

    const handleCertificateChange = (e) => {
        setHasCertificate(e.target.checked);
        if (e.target.checked) {
            setIsFree(false);
        }
    };


    const [query, setQuery] = useState('');
    const [visible, setVisible] = useState(true);
    const [selectedOptionnew, setSelectedOptionnew] = useState(null);
    const handleSearchChange = (e) => {
        setQuery(e.target.value);
    };

    const handleSelect = (item) => {
        console.log('wwwwwww',item);
        
        setQuestionid(item.id);
        setQuestionDetails(item.question_text);
        setVisible(false); 
    };

    const filteredData = questionDetails
        ? questionList?.filter((item) => {
            const sanitizedQuestionText = DOMPurify.sanitize(item.question_text, sanitizeConfig)
                .replace(/<[^>]*>/g, '') 
                .replace(/&nbsp;/g, ' ')
                .trim()
                ?.toLowerCase();
            
            return sanitizedQuestionText.includes(questionDetails?.toLowerCase());
        })
        : questionList;
    return (
        <>
            <Page title="Add General Assessment">
                <PageHeader pageTitle="Add General Assessment" submodule="submodule" />
                <Grid container spacing={2} className='GACognitivesection' sx={{ mb: 2, padding: '15px 20px' }}>
                    <Grid item xs={12} sm={4} sx={{ marginBottom: '18px', paddingRight: '18px' }}>
                        <TextField
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            id="addname"
                            label="Assessment Name *"
                            type="search"
                            value={name}
                            onChange={(e) => { setName(e.target.value); setNameError(''); }}
                            sx={{
                                // bgcolor: "#f0f0f0",
                                borderRadius: 1,
                                height: 36,
                                '& .MuiInputBase-input': {
                                    fontSize: 14,
                                    padding: "8px 12px",

                                },
                            }}
                            error={!!nameError}
                        />
                        {nameError && <FormHelperText error>{nameError}</FormHelperText>}
                    </Grid>



                    <Grid item xs={12} sm={4} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <FormControl fullWidth variant="outlined" sx={{
                            // bgcolor: "#f0f0f0", 
                            borderRadius: 1
                        }}>
                            <InputLabel id="complexity-level-label">Difficulty Level *</InputLabel>
                            <Select
                                labelId="complexity-level-label"
                                id="addnamecomplexity"
                                value={complexity || ""}
                                onChange={(e) => { setComplexity(e.target.value); setComplexityError("") }}
                                label="Difficulty Level"
                                error={!!ComplexityError}
                                sx={{
                                    // height: 36,
                                    '& .MuiSelect-select': {
                                        // fontSize: 14,
                                        padding: "8px 12px",

                                    },
                                }}
                            >
                                <MenuItem value="">Select Complexity</MenuItem>
                                <MenuItem value="easy">Easy</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="hard">Hard</MenuItem>
                            </Select>
                            {ComplexityError && <FormHelperText error>{ComplexityError}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <FormControl fullWidth variant="outlined" sx={{
                            //  bgcolor: "#f0f0f0",
                            borderRadius: 1
                        }}>
                            <InputLabel id="category-label">Associated Category *</InputLabel>
                            <Select
                                labelId="category-label"
                                id="category-select"
                                value={category}
                                onChange={(e) => { setCategory(e.target.value); setCategoryError(''); }}
                                label="Category *"
                                error={!!categoryError}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 228,
                                        }
                                    }
                                }}
                                sx={{
                                    height: 36,
                                    '& .MuiSelect-select': {
                                        fontSize: 14,

                                        padding: "8px 12px",
                                    },
                                }}
                            >
                                {categoryList.map((cat, index) => (
                                    <MenuItem key={index} value={cat.id}>{cat.description}</MenuItem>
                                ))}
                            </Select>
                            {categoryError && <FormHelperText error>{categoryError}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <InputLabel sx={{ marginBottom: '5px' }} id="complexity-level-label">Associated Course</InputLabel>
                        <FormControl fullWidth variant="outlined" sx={{
                            //  bgcolor: "#f0f0f0",
                            borderRadius: 1
                        }}>
                            <InputLabel id="category-label">Course</InputLabel>

                            <Select
                                labelId="category-label"
                                id="category-select"
                                value={course}
                                onChange={(e) => { setCourse(e.target.value); setCourseError(''); }}
                                label="Course"
                                disabled={!category}
                                error={!!categoryError}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 228,
                                        }
                                    }
                                }}
                                sx={{
                                    height: 36,
                                    '& .MuiSelect-select': {
                                        fontSize: 14,

                                        padding: "8px 12px",
                                    },
                                }}
                            >
                                {courseList && courseList?.length > 0 && courseList.map((data, index) => (
                                    <MenuItem key={index} value={data.id}>{data.title}</MenuItem>
                                ))}
                            </Select>
                            {courseError && <FormHelperText error>{courseError}</FormHelperText>}
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={8} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <InputLabel id="complexity-level-label">Assessment Time</InputLabel>

                        <Grid container className='AssessmentTime' spacing={2}>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Hours</InputLabel>
                                    <Select
                                        name="hours"
                                        id='hoursIs'
                                        value={time.hours}
                                        onChange={handleChange}
                                        // label="Hours"
                                        className="dropHours"                   
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 158,
                                                }
                                            }
                                        }}
                                    >
                                        {hoursArray.map((hour) => (
                                            <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Minutes</InputLabel>
                                    <Select
                                        name="minutes"
                                        value={time.minutes}
                                         id='minutesIs'
                                        onChange={handleChange}
                                        // label="Minutes"
                                        className="dropHours"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 158,
                                                }
                                            }
                                        }}
                                    >
                                        {minutesArray.map((minute) => (
                                            <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Seconds</InputLabel>
                                    <Select
                                        name="seconds"
                                        id='secondsIs'
                                        value={time.seconds}
                                        onChange={handleChange}
                                        // label="Seconds"
                                        className="dropHours"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 228,
                                                }
                                            }
                                        }}
                                    >
                                        {secondsArray.map((second) => (
                                            <MenuItem key={second} value={second}>{second}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>





                    <Grid className="unique" item xs={12} sm={6} sx={{ marginBottom: '0px', paddingRight: '18px' }}>
                        {thumbPreview === null ? (
                            <FormControl style={{ height: '100%' }}
                                required
                                component="fieldset"
                                color="primary"
                                variant="outlined"
                                fullWidth
                                name="thumbImage"
                            >
                                <Typography variant="subtitle1">Thumb Image* <span style={{
                                    fontSize: '12px',
                                    float: 'inline-end', paddingBottom: '0', marginBottom: '0', position: 'relative', top: '5px'
                                }}>required resolution (360X200)</span></Typography>
                                <DropzoneArea className="dropTextArea"
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    showPreviews={false}
                                    dropzoneText="Drag and Drop Image or Browse File"
                                    showPreviewsInDropzone={false}
                                    maxFileSize={300000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    id="dropzoneArea"
                                    styles={{
                                        height: '100%', minHeight: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                    onChange={handleFileChange}
                                    useChipsForPreview
                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                    showFileNamesInPreview
                                />
                                {ImageError && <FormHelperText error>{ImageError}</FormHelperText>}
                            </FormControl>


                        ) : (
                            <div className={classes.imgPreviewRoot}>
                                <Typography variant="subtitle1">Thumb Image</Typography>
                                <Badge
                                    badgeContent={
                                        <CloseIcon
                                        id="closeIconForThumbImage"
                                            className={classes.badgeAlign}
                                            onClick={() => {
                                                setThumbPreview(null);
                                                setThumbImage(null);
                                            }}
                                        />
                                    }
                                >
                                    <Avatar
                                    id="thumbImagePreviewIcon"
                                        variant="rounded"
                                        src={thumbPreview}
                                        style={{ minHeight: '150px !important' }}
                                        className={thumbPreview !== null && classes.fileImgSIze}
                                    />
                                </Badge>
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <Typography variant="subtitle1">Assessment Description *</Typography>
                        {/* <ReactQuill
                            value={editorValue}
                            modules={modules}
                            formats={formats}
                            onChange={(content) => {
                                if (content.length > 255) {
                                    handleChangeDescription(content.slice(0, 255));
                                } else {
                                    handleChangeDescription(content);
                                }
                            }}
                            onPaste={(e) => {
                                e.preventDefault();
                                const clipboardText = e.clipboardData.getData('text').slice(0, 255);
                                handleChangeDescription(clipboardText);
                            }}
                            style={{ height: '150px', marginBottom: '30px' }}
                        /> */}



                        <ReactQuill
                            theme="snow"
                            id='questionText'
                            name="question"
                            defaultValue={editorValue}
                            onChange={(content) => {
                                if (content.length > 255) {
                                    handleChangeDescription(content.slice(0, 255));
                                } else {
                                    handleChangeDescription(content);
                                }
                            }}
                            onPaste={(e) => {
                                e.preventDefault();
                                const clipboardText = e.clipboardData.getData('text').slice(0, 255);
                                handleChangeDescription(clipboardText);
                            }}
                            // fullWidth
                            style={{ height: '150px', marginBottom: '30px' }}
                        />
                        {/* {descriptionError && <FormHelperText style={{marginTop:20}} error>{descriptionError}</FormHelperText>} */}
                        {descriptionError && <p style={{ marginTop: 50, color: 'red' }}>{descriptionError}</p>}

                    </Grid>


                    <Grid item xs={6} style={{ paddingRight: '15px' }}>
                        <Typography gutterBottom>Highlights Section</Typography>
                        {fields && fields?.length > 0 && fields.map((value, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
                                <TextField
                                    fullWidth
                                    inputProps={{
                                        maxLength: 80,
                                    }}
                                    variant="outlined"
                                    placeholder={`Enter 80 characters only`}
                                    value={value}
                                    id='highlightedSection'
                                    onChange={(e) => handleChangeHighlightedDescription(index, e.target.value)}
                                    error={error && value.trim() === ''}
                                    helperText={error && value.trim() === '' ? 'This field is required' : ''}
                                />
                                {index === fields.length - 1 && fields.length < 3 && (
                                    <Button variant="contained" id="highlightedSection" onClick={handleAddField}>
                                        +
                                    </Button>
                                )}
                                {fields.length > 1 &&
                                    <Button variant="contained" id="highlightedRemoveSection" onClick={() => handleRemoveField(index)}>
                                        -
                                    </Button>}
                            </Box>
                        ))}



                        {
                            // error ? (
                            //     <Typography color="error" sx={{ marginTop: 1, marginLeft: 30 }}>
                            //         Please fill all fields before adding a new one.
                            //     </Typography>
                            // ) :
                            errors?.fields &&
                            <Typography color="error" sx={{ marginTop: 1, marginLeft: 30 }}>
                                {errors?.fields}
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <span style={{ visibility: 'hidden' }}>Empty</span>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <FormControlLabel
                            style={{ marginTop: '30px' }}
                            control={
                                <Checkbox
                                    checked={hasCertificate}
                                    onChange={handleCertificateChange}
                                    value={hasCertificate}
                                    id="hasCertificate"
                                    name="hasCertificate"
                                    color="primary"
                                />
                            }
                            label="Do you provide certificate after clearing this Assessment?"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <FormControlLabel
                            style={{ marginTop: '30px' }}
                            control={
                                <Checkbox
                                    checked={isFree}
                                    onChange={handleFreeChange}
                                    value={isFree}
                                    id="isfree"
                                    name="isfree"
                                    color="primary"
                                />
                            }
                            label="Is this a Free Assessment?"
                        />
                    </Grid>


                    {moduleData && moduleData.length > 0 && moduleData.map((data, index) => (
                        <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }} style={{ paddingLeft: '15px' }}>
                            <Grid item xs={6} style={{ paddingLeft: '15px' }}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {data.name}
                                            </Typography>
                                            <Box display="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }} >
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    {data.weightage}%
                                                </Typography>
                                                <Box display="flex" sx={{ marginLeft: -1, alignItems: 'center' }}>

                                                    <IconButton onClick={() => handleAddNewQuestion(index)} color="error">
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleEditDetails(data, index)} color="error">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton style={{ height: '40px' }} onClick={() => handlePreviewOpen(data, index)} color="error">
                                                        < VisibilityIcon />
                                                    </IconButton>
                                                    {/* <IconButton style={{ height: '40px' }} onClick={() => handleDeleteModules(index)} color="error">
                                                        < DeleteOutlinedIcon />
                                                    </IconButton> */}
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    ))}


                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                        <Button sx={{ marginRight: '15px', paddingBottom: '0px' }} id='Addmodule' variant="contained" color="primary" onClick={handleDialogOpen}>
                            Add Module
                        </Button>


                        <Box sx={{ marginRight: '15px' }}>
                            {/* {selectedQuestions.length > 0 && ( */}
                            <LoadingButton
                                type="submit"
                                id="addassessmentgeneral"
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                // fullWidth

                                loading={loading}
                            >
                                Submit
                            </LoadingButton>
                            {/* )} */}
                        </Box>
                    </Grid>
                    {errorMessage && (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {errorMessage}
                        </Alert>
                    )}
                    {errorMessageAll && (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {errorMessageAll}
                        </Alert>)}
                </Grid>



                <Dialog open={open} onClose={handleClose} fullWidth>
                    <DialogTitle style={{ paddingBottom: '0px' }}>Add Module</DialogTitle>
                    <DialogContent className='GACognitivesection' sx={{ paddingTop: '25px !important' }}>
                        <Box sx={{ marginBottom: '18px' }}>
                            <TextField
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                id="addname"
                                label="Module Name *"
                                type="search"
                                value={moduleName}
                                onChange={(e) => { setModuleName(e.target.value); ClearError() }}
                                sx={{
                                    // bgcolor: "#f0f0f0",
                                    borderRadius: 1,

                                    height: 36,
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: "8px 12px",
                                    },

                                }}
                            />
                            {requirederrors.moduleName && (
                                <FormHelperText error>{requirederrors.moduleName}</FormHelperText>
                            )}
                        </Box>
                        <Box sx={{ marginBottom: '15px' }}>
                            <TextField
                                variant="outlined"
                                inputProps={{ maxLength: 50, inputMode: 'numeric', pattern: '[0-9]*' }}
                                fullWidth
                                id="addweight"
                                label="Module Weight *"
                                type="number"
                                value={points}
                                onChange={handlePoints}
                                sx={{
                                    // marginBottom: '20px',
                                    borderRadius: 1,

                                    height: 36,
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: "8px 12px",
                                    },
                                }}
                            />
                            {requirederrors.points && (
                                <FormHelperText style={{ marginBottom: '5px' }} error>{requirederrors.points}</FormHelperText>
                            )}
                        </Box>
                        {errorMessage && (
                            <Alert severity="error" sx={{ marginBottom: '10px' }}>
                                {errorMessage}
                            </Alert>
                        )}

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={selectedOption}
                                onChange={(e) => handleChangeOption(e)}
                            >

                                <FormControlLabel value="create" id="createRadio" control={<Radio />} sx={{ marginRight: '40px' }} label="Create Question" />
                                <FormControlLabel value="select" id='selectRadio' control={<Radio />} label="Select Question" />
                            </RadioGroup>
                        </FormControl>

                        {selectedOption === 'select' ? (
                            <Box display="flex" flexDirection="column" gap={2}>
                                <Box>
                                    <Grid container spacing={2} alignItems="center">

                                        <Grid item>
                                            <FormControl fullWidth variant="outlined">

                                                <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                                    Select Level of Objective                                               </Typography>
                                                <FormGroup className='FormCheck'>
                                                    {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                        <FormControlLabel
                                                            key={skill}
                                                            id={skill}
                                                            control={
                                                                <Radio
                                                                 id={skill}
                                                                    checked={selectedSkills === skill}
                                                                    onChange={handleChangeCheck}
                                                                    value={skill}
                                                                />
                                                            }
                                                            label={skill}
                                                        />
                                                    ))}
                                                </FormGroup>
                                                {requirederrors.selectedSkills && (
                                                    <FormHelperText error>{requirederrors.selectedSkills}</FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="search-select-container">
                                                <input
                                                    type="text"
                                                    // value={query}
                                                    onChange={CreateQuestion}
                                                    placeholder="Search or Select..."
                                                    aria-label="Search or select an option"
                                                />

                                                {visible && (
                                                    <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                        {filteredData.length > 0 ? (
                                                            filteredData.map((item) => {
                                                                const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();
                                                              
                                                                return (
                                                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                    <li
                                                                        key={item.id}
                                                                        role="option"
                                                                        onClick={() => handleSelect(item)}
                                                                        className="dropdown-item"
                                                                        tabIndex={0}
                                                                        aria-selected={selectedOptionnew?.id === item.id}
                                                                    >
                                                                        {finalText}
                                                                    </li>
                                                                )
                                                            })
                                                        ) : (
                                                            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                            <li className="dropdown-item" role="option">
                                                                No results found
                                                            </li>
                                                        )}
                                                    </ul>
                                                )}

                                                {questionDetails && (
                                                    <div className="selected-option">
                                                        <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig).trim()}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>




                                    </Grid>
                                </Box>

                            </Box>
                        ) : (

                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>

                                        <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                            Select Level of Objective
                                        </Typography>
                                        <FormGroup className='FormCheck'>
                                            {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                <FormControlLabel
                                                    key={skill}
                                                    id={skill}
                                                    control={
                                                        <Radio
                                                           id={skill}
                                                            checked={selectedSkills === skill}
                                                            onChange={handleChangeCheck}
                                                            value={skill}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            ))}
                                        </FormGroup>
                                        {requirederrors.selectedSkills && (
                                            <FormHelperText error>{requirederrors.selectedSkills}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={{ width: '100%' }} className={classes.formControl} error={touched.question && Boolean(errors.question)}>
                                        <Typography className={classes.background} color="primary" gutterBottom variant="subtitle1">
                                            Create Question*
                                        </Typography>
                                        <ReactQuill
                                            theme="snow"
                                            id="questionText"
                                            name="question"
                                            defaultValue={values.question}
                                            onChange={(value) => handleChangeQuestion('question', value)}
                                            modules={modules}
                                            formats={formats}
                                            onBlur={() => setTouched((prev) => ({ ...prev, question: true }))}
                                            fullWidth
                                        />
                                        {touched.question && errors.question && <FormHelperText>{errors.question}</FormHelperText>}
                                    </FormControl>
                                </Grid>

                                {values.questionType === 'MCQ' && (
                                    <Grid item xs={12}>
                                        {values.mcqOptions.map((opt, index) => (
                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                        <ReactQuill
                                                            theme="snow"
                                                            id={`optiontext${index}`}
                                                            name="mcqQuestion"
                                                            defaultValue={opt.option}
                                                            onChange={(value) => handleMCQOptionChange(index, 'option', value)}
                                                            modules={modules}
                                                            formats={formats}
                                                            placeholder="Option"
                                                            style={{ marginTop: 10, flex: 1 }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            color="error"
                                                            onClick={() => handleRemoveOption(index)}
                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={`mcqOptions.${index}.isCorrect`}
                                                                    checked={opt.isCorrect}
                                                                    onChange={() => {handleMCQOptionChange(index, 'isCorrect', !opt.isCorrect);setrequiredErrors({questionid:''})}}
                                                                    disabled={!opt.option.trim()}
                                                                />
                                                            }
                                                            label="Correct"
                                                        />
                                                    </Grid>

                                                    {errors.mcqOptions?.[index]?.option && (
                                                        <div className="error-text" style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                            {errors.mcqOptions[index].option}
                                                        </div>
                                                    )}
                                                </Grid>
                                            </div>
                                        ))}




                                        {submitError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {submitError}
                                            </div>
                                        )}
                                        {currectError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {currectError}
                                            </div>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            id='addOptionsBtn'
                                            onClick={() => {handleAddOption(); setrequiredErrors({questionid:''})}}
                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                        >
                                            Add Option
                                        </Button>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Button  id="moduleSumbitted" onClick={handleSubmitQuestion} type="submit" variant="contained" color="primary"
                                        disabled={loadingnew || submitted}
                                        fullWidth>      
                                        {buttonText}
                                    </Button>
                                    {selectedOption === 'create' && requirederrors.options && (
                                        <FormHelperText error>{requirederrors.options}</FormHelperText>
                                    )}

                                    {selectedOption === 'create' && requirederrors.questionid && (
                                        <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                    )}
                                      {selectedOption === 'create' && requirederrors.optionNumber && (
                                        <FormHelperText error>{requirederrors.optionNumber}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>

                        )}
                    </DialogContent>


                    <DialogActions>
                        <Button id="popupSubmit" onClick={handleModuleSubmitCreate} color="secondary">
                            Submit
                        </Button>
                        <Button id='popupCancel' onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>


                </Dialog>



                <Dialog open={openDialog} onClose={handleCloseNew} fullWidth>
                    <DialogTitle style={{ paddingBottom: '0px' }}>Add Question</DialogTitle>
                    <DialogContent className='GACognitivesection' sx={{ paddingTop: '25px !important' }}>

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={selectedOption}
                                onChange={(e) => handleChangeOption(e)}
                            >

                                <FormControlLabel value="create" control={<Radio />} sx={{ marginRight: '40px' }} label="Create Question" />
                                <FormControlLabel value="select" control={<Radio />} label="Select Question" />
                            </RadioGroup>
                        </FormControl>

                        {selectedOption === 'select' ? <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <FormControl fullWidth>

                                    <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                        Select Level of Objective
                                    </Typography>
                                    <FormGroup className='FormCheck'>
                                        {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                            <FormControlLabel
                                                key={skill}
                                                id={skill}
                                                control={
                                                    <Radio
                                                        checked={selectedSkills === skill}
                                                        onChange={handleChangeCheck}
                                                        value={skill}
                                                        id={skill}
                                                    />
                                                }
                                                label={skill}
                                            />
                                        ))}
                                    </FormGroup>
                                    {requirederrors.selectedSkills && (
                                        <FormHelperText error>{requirederrors.selectedSkills}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                            <div className="search-select-container">
                                                <input
                                                    type="text"
                                                    // value={query}
                                                    onChange={CreateQuestion}
                                                    placeholder="Search or Select..."
                                                    aria-label="Search or select an option"
                                                />

                                                {visible && (
                                                    <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                        {filteredData.length > 0 ? (
                                                            filteredData.map((item) => {
                                                                const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();
                                                              
                                                                return (
                                                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                    <li
                                                                        key={item.id}
                                                                        role="option"
                                                                        onClick={() => handleSelect(item)}
                                                                        className="dropdown-item"
                                                                        tabIndex={0}
                                                                        aria-selected={selectedOptionnew?.id === item.id}
                                                                    >
                                                                        {finalText}
                                                                    </li>
                                                                )
                                                            })
                                                        ) : (
                                                            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                            <li className="dropdown-item" role="option">
                                                                No results found
                                                </li>
                                            )}
                                        </ul>
                                    )}

                                    {questionDetails && (
                                        <div className="selected-option">
                                            <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig).trim()}</p>
                                        </div>
                                    )}

                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="searchable-select-label">Search & Select Questions</InputLabel>
                                                <Select
                                                    labelId="searchable-select-label"
                                                    value={questionid}
                                                    onChange={CreateQuestion}
                                                    label="Select Fruit"
                                                    open={openBox}
                                                    onOpen={handleOpen}
                                                    onClose={handleCloseBox}
                                                    renderValue={(value) => (value || 'Select Questions')}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 300,
                                                                width: 500,
                                                                overflowY: 'auto'
                                                            },
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                >
                                                    {questionList?.length === 0 ? (
                                                        <MenuItem disabled>No options found</MenuItem>
                                                    ) : (
                                                        questionList.map((option, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={option}
                                                                sx={{
                                                                    whiteSpace: 'normal',
                                                                    wordBreak: 'break-word'
                                                                }}
                                                            >
                                                                {DOMPurify.sanitize(option.question_text, sanitizeConfig)}
                                                            </MenuItem>
                                                        ))
                                                    )}
                                                </Select>
                                            </FormControl>
                            </Grid>
                        </Grid>

                            :
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>

                                        <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                            Select Level of Objective
                                        </Typography>
                                        <FormGroup className='FormCheck'>
                                            {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                <FormControlLabel
                                                    key={skill}
                                                    id={skill}
                                                    control={
                                                        <Radio
                                                        id={skill}
                                                            checked={selectedSkills === skill}
                                                            onChange={handleChangeCheck}
                                                            value={skill}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                    {requirederrors.selectedSkills && (
                                        <FormHelperText error>{requirederrors.selectedSkills}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={{ width: '100%' }} className={classes.formControl} error={touched.question && Boolean(errors.question)}>
                                        <Typography className={classes.background} color="primary" gutterBottom variant="subtitle1">
                                            Create Question*
                                        </Typography>
                                        <ReactQuill
                                            theme="snow"
                                            id="questionText"
                                            name="question"
                                            defaultValue={values.question}
                                            onChange={(value) => handleChangeQuestion('question', value)}
                                            modules={modules}
                                            formats={formats}
                                            onBlur={() => setTouched((prev) => ({ ...prev, question: true }))}
                                            fullWidth
                                        />
                                        {touched.question && errors.question && <FormHelperText>{errors.question}</FormHelperText>}
                                    </FormControl>
                                </Grid>

                                {values.questionType === 'MCQ' && (
                                    <Grid item xs={12}>
                                        {values.mcqOptions.map((opt, index) => (
                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                        <ReactQuill
                                                            theme="snow"
                                                            id={`optiontext${index}`}
                                                            name="mcqQuestion"
                                                            defaultValue={opt.option}
                                                            onChange={(value) => handleMCQOptionChange(index, 'option', value)}
                                                            modules={modules}
                                                            formats={formats}
                                                            placeholder="Option"
                                                            style={{ marginTop: 10, flex: 1 }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            color="error"
                                                            onClick={() => handleRemoveOption(index)}
                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                        <FormControlLabel
                                                            control={

                                
                                                                <Checkbox
                                                                    name={`mcqOptions.${index}.isCorrect`}
                                                                    checked={opt.isCorrect}
                                                                    onChange={() => {handleMCQOptionChange(index, 'isCorrect', !opt.isCorrect);setrequiredErrors({questionid:''})}}
                                                                    disabled={!opt.option.trim()}
                                                                />
                                                            }
                                                            label="Correct"
                                                        />
                                                    </Grid>

                                                    {errors.mcqOptions?.[index]?.option && (
                                                        <div className="error-text" style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                            {errors.mcqOptions[index].option}
                                                        </div>
                                                    )}
                                                </Grid>
                                            </div>
                                        ))}

                                        {submitError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {submitError}
                                            </div>
                                        )}
                                        {currectError && (
                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                {currectError}
                                            </div>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            id="addOptionButton"
                                            onClick={() => {handleAddOption(); setrequiredErrors({questionid:''})}}
                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                        >
                                            Add Option
                                        </Button>
                                    </Grid>
                                )}


                                <Grid item xs={12}>
                                    <Button onClick={handleSubmitQuestion} type="submit"
                                        disabled={loadingnew || submitted}
                                        id={buttonText}
                                        variant="contained" color="primary" fullWidth>
                                        {buttonText}
                                    </Button>
                                </Grid>
                                {requirederrors.questionid && (
                                    <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                )}
                                 {selectedOption === 'create' && requirederrors.optionNumber && (
                                        <FormHelperText error>{requirederrors.optionNumber}</FormHelperText>
                                    )}
                            </Grid>}
                    </DialogContent>


                    <DialogActions>
                        <Button id='btnSubmition' onClick={() => handleModuleSubmitCreateQuestion(selectIndex)} color="secondary">
                            Submit
                        </Button>
                        <Button onClick={handleCloseNew} id='btnCancel' color="primary">
                            Cancel
                        </Button>
                    </DialogActions>


                </Dialog>


                <Dialog open={editDialog} onClose={handleCloseEdit} fullWidth>
                    <DialogTitle>Edit Module</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="addname"
                                    label="Module Name"
                                    type="text"
                                    name="name"
                                    value={editData.name || ""}
                                    onChange={EditModule}
                                    sx={{
                                        // bgcolor: "#f0f0f0",
                                        borderRadius: 1,
                                        marginBottom: '24px',
                                        height: 36,
                                        '& .MuiInputBase-input': {
                                            fontSize: 14,
                                            padding: "8px 12px",
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="addweight"
                                    label="Module Weight"
                                    type="text"
                                    name="weightage"
                                    value={editData.weightage || ""}
                                    onChange={EditModule}
                                    sx={{
                                        marginBottom: '20px',
                                        // bgcolor: "#f0f0f0",
                                        borderRadius: 1,
                                        height: 36,
                                        '& .MuiInputBase-input': {
                                            fontSize: 14,
                                            padding: "8px 12px",
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleModuleUpdateQuestion} color="secondary">
                            Update
                        </Button>
                        <Button onClick={handleCloseEdit} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={Preview} onClose={handlePreview} fullWidth>
                    <DialogTitle>Preview2525</DialogTitle>
                    <DialogContent style={{ marginLeft: '18px' }}>
                        <ol type="1">
                            {previewData && previewData?.questiondetails?.length > 0 && previewData?.questiondetails?.map((details, index) => (
                                <li>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
                                        <Typography
                                            variant="para" style={{ lineHeight: '1.2', fontSize: '15px', fontWeight: '400', paddingRight: '15px' }}
                                            dangerouslySetInnerHTML={{ __html: details }}
                                        />
                                        <IconButton onClick={() => DeleteQuestion(index)} color="error">
                                            < DeleteOutlinedIcon style={{ color: "#ff4842" }} />
                                        </IconButton >
                                    </Box>
                                </li>
                            ))}
                        </ol>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handlePreview} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleUpdateQuestions(editIndexnew)} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>



                <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />


            </Page>
        </>
    );

}

const useStyles = makeStyles((theme) => ({
    imgPreviewRoot: {
        borderRadius: '10px',
        padding: '0.8rem;',
    },
    fileImgSIze: {
        width: '100%',
        height: '120px',
        objectFit: 'cover',
        objectPosition: 'center',
        border: '1px solid #fff',
        borderRadius: '5px',
        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
    },
    badgeAlign: {
        boxShadow: '0 2px 8px -5px #ff0000',
        color: '#FF0000',
        fontSize: '1.2rem',
        backgroundColor: '#fff',
        padding: '2px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    deleteLabel: {
        width: 'max-content',
        cursor: 'pointer',
    }
}));
export default GenericAssessmentAdd;
