import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Grid, Typography, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const IntroScreen = ({ screens, activeStep, type, onClickCallBack, videoLoadCallback }) => {
  const classes = useStyles();
  videoLoadCallback(true);
  const horizontal = screens[activeStep]?.horizontalAlign?.code === 'MIDDLE' ? 'center' : 'flex-start';
  const vertical = screens[activeStep]?.verticalAlign?.code === 'CENTER' ? 'center' : 'start';

  const allActionsAreClicks = type?.type === 'simulation' && screens.every(item =>
    (item.type === 'ACTION' && (item.keyType === 'CLICK' || item.keyType === undefined)) ||
    item.type === 'INTRO' ||
    item.type === 'SUCCESS'
  );
  const hasMixedHotKeysAndClick = type?.type === 'simulation' && screens.some(item => item.type === 'ACTION' && (item.keyType === 'INDIVIDUAL_HOT_KEYS' || item.keyType === 'COMBINED_HOT_KEYS' || item.keyType === 'CODE')) &&
    screens.some(item => item.type === 'ACTION' && item.keyType === 'CLICK');
  const allActionsAreKeyStroke = screens.every(item =>
    (item.type === 'ACTION' && (item.keyType === 'INDIVIDUAL_HOT_KEYS' || item.keyType === 'COMBINED_HOT_KEYS' || item.keyType === undefined)) ||
    item.type === 'INTRO' ||
    item.type === 'SUCCESS'
  );
  const allActionsAreCode = type?.type === 'simulation' && screens.every(item =>
    (item.type === 'ACTION' && (item.keyType === 'CODE' || item.keyType === undefined)) ||
    item.type === 'INTRO' ||
    item.type === 'SUCCESS'
  );

  const nextBtnElement = useRef();
  const [sfxPlayed, setsfxPlayed] = useState(false);
  const detectOS = () => {
    let platform;
    if (navigator.userAgentData) {
      platform = navigator.userAgentData.platform.toLowerCase();
    } else {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('win')) {
        platform = 'windows';
      } else if (userAgent.includes('mac')) {
        platform = 'mac';
      } else if (/android/.test(userAgent)) {
        platform = 'android';
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        platform = 'ios';
      } else {
        platform = 'unknown';
      }
    }
    if (platform.includes('mac')) return 'Mac';
    if (platform.includes('win')) return 'Windows';
    return 'Other';
  };

  console.log(screens[activeStep],"screens[activeStep]screens[activeStep]");
  

  const handleKeyDownIntro = (event) => {

    if ((event.key === 'Enter')) {
      introBtnClickHandle("successbutton", event);

    }
    else {
      console.log("outside if condition", event.key, allActionsAreClicks)
    }
    if (event.key === ' ') {
      event.preventDefault();
    }
  };
  useEffect(() => {
    const addKeyListener = () => {
      window.addEventListener('keydown', handleKeyDownIntro);
    };
    const removeKeyListener = () => {
      window.removeEventListener('keydown', handleKeyDownIntro);
    };

    if (allActionsAreClicks === false) {
      addKeyListener();
    } else {
      removeKeyListener();
    }
    return () => {
      removeKeyListener();
    };
  }, [handleKeyDownIntro, allActionsAreClicks]);
  useEffect(() => {
    setTimeout(() => {
      setsfxPlayed(true);
    }, 3000);
  }, []);

  const os = detectOS();
  const keyText = os === 'Mac' ? 'return' : 'enter';


  const introBtnClickHandle = (e, event) => {
    // console.log(' 22222 ');

    onClickCallBack(e, event)
  }

  const screen = screens[activeStep]; 

  const htmlTemplate = JSON.parse(screens[activeStep].html_template);
  const positionGet = htmlTemplate?.position;
  // console.log(positionGet);

  const getAnimationClass = (position) => {
    console.log(position);
    switch (position) {
      case 'right-to-left':
        return 'scroll-text-right';
      case 'left-to-right':
        return 'scroll-text-left';
      case 'pop-up':
        return 'pop-up-animation';
      default:
        return 'delay-effect';

    }
  };

  if (screens && screens[activeStep]) {


    const parsedTemplate = screens[activeStep]?.html_template ? JSON.parse(screens[activeStep].html_template) : null;
    const jsonString = `{"design":{"counters":{"u_column":1,"u_row":1,"u_content_text":1},"body":{},"positionIs":"","position":"left-to-right"}}`;

 
    const parsedData = JSON.parse(jsonString);


    const position = parsedData.design.position;

    // console.log(position);
    
    // console.log('Current screen data:', screens[activeStep].html_template);
    // console.log('Animation type from current screen:', screens[activeStep]?.animationType);
  }



  return (
    <div>
      <Box
        className={`${classes.gridContainer} ${getAnimationClass(positionGet)}`} 
        sx={{
          justifyContent: screens[activeStep]?.horizontalAlign.code === 'RIGHT' ? 'flex-end' : horizontal,
          alignItems: screens[activeStep]?.verticalAlign.code === 'BOTTOM' ? 'end' : vertical,
          margin: 0,
          ...(screens[activeStep]?.backgroundBase64
            ? {                backgroundImage: screens.length > 0  ? `url(${screens[activeStep]?.backgroundBase64})` : '',
          }
            : {
              }),
          }}
      >
        {screens[activeStep]?.html_template ? (
 <div dangerouslySetInnerHTML={{ __html: JSON.parse(screens[activeStep]?.html_template)?.html }} />  ) : (
   <>    
  {(type?.subCategory?.code !== 'PYTHON' && type?.subCategory?.code !== 'SEQUEL') && (screens[activeStep < screens.length ? activeStep : 0].description.trim().replace(/<[^>]*>/g, '').length > 0) && (screens.length > 0)
            && (screens[activeStep < screens.length ? activeStep : 0].description !== '') ? (
            <Box className={screens[activeStep].templates ? classes.introTemplate : classes.introContainer}>
              <div style={{ minHeight: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>

                <Typography
                  sx={{ fontSize: '1.125rem' }}
                  dangerouslySetInnerHTML={{
                    __html:
                      screens.length > 0
                        ? screens[activeStep < screens.length ? activeStep : 0].description
                        : '&nbsp;'
                  }}
                />




              </div>

              {screens[activeStep].type === 'SUCCESS' && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button ref={nextBtnElement} variant="outlined" color="primary" onClick={() => introBtnClickHandle("successbutton")}>
                    {screens[activeStep].buttonLabel || 'okay'}

                  </Button>

                </div>
              )}
            </Box>) :
            (type?.subCategory?.code === 'PYTHON' || type?.subCategory?.code === 'SEQUEL') &&
            (screens[activeStep < screens.length ? activeStep : 0].description.trim().replace(/<[^>]*>/g, '').length > 0) &&
            (screens.length > 0) &&
            //  (activeStep < screens.length) &&
            (screens[activeStep].description !== '') &&
            (screens[activeStep].templates !== null) && (
              <Box
                // className={classes.introContainer}
                className={screens[activeStep].templates ? classes.introTemplate : classes.introContainer}
              >
                <div style={{ minHeight: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography
                    sx={{ fontSize: '1.125rem' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        screens.length > 0
                          ? screens[activeStep < screens.length ? activeStep : 0].description
                          : '&nbsp;'
                    }}
                  />
                </div>
                {screens[activeStep].type === 'SUCCESS' && (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button ref={nextBtnElement} variant="outlined" color="primary" onClick={introBtnClickHandle}>
                      {screens[activeStep].buttonLabel || 'okay'}

                    </Button>

                  </div>
                )}
              </Box>
            )
        }
        </>
 )}





        {type?.type !== 'video' && (
          allActionsAreClicks ? (
            <Button
              ref={nextBtnElement}
              variant="contained"
              className={classes.extroTxts}
              onClick={(event) => { introBtnClickHandle("successbuttonwithclick", event) }}
              sx={{
                color: "orange",
                backgroundColor: "rgba(255, 0, 0, 0)",
                fontSize: '0.675rem',
                position: 'absolute',
                bottom: '-35px',
                visibility: sfxPlayed ? 'visible' : 'hidden'
              }}
            >
              click&nbsp;<span className={classes.highlightedText}>here</span>&nbsp; to continue
            </Button>
          ) : (hasMixedHotKeysAndClick || allActionsAreKeyStroke || allActionsAreCode) && (
            <Button
              ref={nextBtnElement}
              variant="contained"
              onClick={() => introBtnClickHandle("pressbutton")}
              className={classes.extroTxts}
              disabled
              sx={{
                color: "orange",
                backgroundColor: "rgba(255, 0, 0, 0)",
                fontSize: '0.675rem',
                position: 'absolute',
                bottom: '-35px',
                visibility: sfxPlayed ? 'visible' : 'hidden'
              }}
            >
              Press &nbsp;<span className={classes.highlightedText}>{keyText}</span>&nbsp; to continue
            </Button>
          )
        )
        }

        {type?.type === 'video' && screens[activeStep]?.type !== "SUCCESS" && activeStep !== screens?.length - 1 && (
          <Button
            variant="contained"
            className={classes.extroTxts}
            sx={{
              color: "orange",
              backgroundColor: "rgba(255, 0, 0, 0)",
              fontSize: '0.675rem',
              position: 'absolute',
              bottom: '-35px',
              visibility: sfxPlayed ? 'visible' : 'hidden'
            }}
          >
            Press&nbsp;<span className={classes.highlightedText}>Enter</span>&nbsp; to continue
          </Button>
        )
        }
        {type?.type === 'video' && screens[activeStep]?.type === "SUCCESS" && (
          <Button
            variant="contained"
            className={classes.extroTxts}
            sx={{
              color: "orange",
              backgroundColor: "rgba(255, 0, 0, 0)",
              fontSize: '0.675rem',
              position: 'absolute',
              bottom: '-35px',
              visibility: sfxPlayed ? 'visible' : 'hidden'
            }}
          >
            Press&nbsp;<span className={classes.highlightedText}>Enter</span>&nbsp; to continue
          </Button>
        )
        }
      </Box>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({

  backArrow: {
    color: 'black',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(236,121,48)',
    },
  },
  backArrowEnabled: {
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(236,121,48)',
    },
  },
  forwardArrow: {
    color: 'black',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(236,121,48)',
    },
  },
  forwardArrowEnabled: {
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(236,121,48)',
    },
  },

  alertAnimationone: {
    animation: 'alertone 1.5s infinite',
  },
  body: {
    color: '#fff',
    fontFamily: 'Nunito Semibold',
    textAlign: 'center',
  },
  content: {
    display: 'grid'
  },
  gridCell: {
    border: '1px solid transparent',
    cursor: 'pointer',
  },
  selectedButton: {
    background: '#3bbced',
    color: '#fff',
  },
  gridButton: {
    padding: '5px 10px 5px 10px',
    cursor: 'pointer',
    margin: '0 5px',
  },
  gutter: {
    marginBottom: '20px',
  },
  gridContainer: {
    // minHeight: '80vh',   // to match click base screen height due to arrow position get changes each time
    height: '480px',
    width: '100%',
    backgroundSize: '100% 100% ',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    display: 'flex',
    position: 'relative',
  },
  extroTxt: {
    animation: '$pulse 1.5s infinite',
  },
  highlightedText: {
    animation: '$colorChange 1.5s infinite',
    color: 'orange',
    fontWeight: 'bold',
  },
  '@keyframes colorChange': {
    '0%': {
      color: 'orange',
    },
    '50%': {
      color: 'red',
    },
    '100%': {
      color: 'orange',
    },
  },
  introContainer: {
    minWidth: '250px',
    maxWidth: '600px',
    padding: 16,
    paddingBottom: 20,
    borderRadius: 8,
    backgroundColor: '#e7e7e7cf',
    margin: '3rem',
    // boxShadow: `4px 4px 8px 2px #a7a7a7`,
    boxShadow: '0px 2px 6px #00000029',
    border: '2px solid #ddd',
  },
  introTemplate: {
    minWidth: '250px',
    maxWidth: '600px',
    padding: 16,
    paddingBottom: 20,
    borderRadius: 8,
    backgroundColor: 'transparent',
    margin: '3rem',
    // boxShadow: `4px 4px 8px 2px #a7a7a7`,
    boxShadow: '0px 2px 6px transparent',
    border: '2px solid transparent',
  }

}));
export default IntroScreen;