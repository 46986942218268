import React from "react";
import { Grid, Box, Button,Typography } from "@mui/material";

const QuestionView = (props) => {  
  const questions = Array.from({ length: props?.question?.length }, (_, i) => i + 1)
 const handleBack = () =>{
  props.handleBack()
}
  const containerStyle = {
    textAlign: "center",
    background: "#fff",
    padding: "8px 10px !important",
    minHeight: "385px",
    width: "100%",
    minWidth: '250px',
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
  };

  const assessmentStyle = {
    marginTop: "20px",
  };

  

  const buttonStyle = {
    backgroundColor: "#fff",
    color: "black",
    border: "1px solid black",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    padding: '5px 10px',
    marginTop: "20px"
  };

  return (
    <Box sx={containerStyle}>
      <Typography variant="h6" marginBottom={'20px'}>Total Questions</Typography>
      <Grid container spacing={2} justifyContent="center" style={{minHeight: '175px'}}>
    

{questions.map((number, index) => {  
  const question = props.question[index];
  const { answerCorrect } = question;
  let backgroundColor =  index === props?.currentIndex && !props?.spaceview?"#437BFC":"#faf2f4"; 
  let color = 'black'
  if (answerCorrect === true) {
    backgroundColor = "green";
     color = 'white'
  } 
   if (answerCorrect === false) {
    backgroundColor = "red";
    color = 'white'

  }
  

  return (
    <Grid style={{width: '350px'}}
      item
      xs={2.2}
      sm={2.2}
      md={2.2}
      key={number}
    >
      <Box
        sx={{
          backgroundColor:`${backgroundColor}`, 
          color:`${color}`, 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          borderTopLeftRadius: "12px",
          fontSize: "14px",
          fontWeight: "bold",
          // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                 
        }}
      >
        {number}
      </Box>
    </Grid>
  );
})}
      </Grid>
      <div style={assessmentStyle}>
        <Button sx={buttonStyle} onClick={handleBack}>
          Back
        </Button>
      </div>
    </Box>
  );
};

export default QuestionView;
