import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import Page from '../components/Page';
import PagenotFound from '../assets/logo/404errortornwires.svg';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const style = {
  paddingTop: '0px !important',
  background: '#fff',
};
// ----------------------------------------------------------------------

export default function Page404() {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const isLogin = useSelector((state) => state.isLogin);
  
  const handleBackPageNotFound = () => {
    if (!isLogin) {
      navigate('/');
    } else if (isLogin && (userRole === 'SUPER_ADMIN' || userRole === 'CLIENT_ADMIN')) {
      navigate('/app/dashboard');
    } else if (isLogin && (userRole === 'CONTENT_WRITER' || userRole === 'AUTH_USER')) {
      navigate('/app/course');
    }  else if (isLogin && (userRole === 'USER_DTC')){
      navigate('/auth/subscribe');
    }
    else {
      navigate('/auth/my-courses');
    }
  };

  return (
    <Page style={style}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box component="img" src={PagenotFound} mb={1} />

          <Button variant="contained" onClick={() => handleBackPageNotFound()}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
