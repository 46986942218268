import React from "react";
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button, Typography } from "@mui/material";
import TestComplete from '../../../assets/Images/TestComplete.png';

function TimeoutPage() {
  const navigate = useNavigate();

  const handleSummary = () => {
    // Retrieve stored assessment data if needed
    const assessmentAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
    
    // Process the answers or navigate to summary page
    // Add your summary logic here
    
    // Clear stored data after processing
    localStorage.removeItem('assessmentAnswers');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-red-100" style={{ justifyItems: 'center' }}>
      <div className="flex flex-col items-center">
        <div className="flex justify-center mb-4">
          <div className="relative inline-block">
            <img
              style={{ justifySelf: 'center' }}
              src={TestComplete}
              alt="Timeout Icon"
              className="w-16 h-16"
            />
          </div>
        </div>

        <h2 
          className="text-xl font-bold text-blue-600" 
          style={{
            textAlign: 'center',
            marginTop: '5px', 
            fontWeight: '700'
          }}
        >
         Time out, Test Completed !!
        </h2>
        
        <p 
          className="text-gray-600 mt-2" 
          style={{
            textAlign: 'center', 
            marginTop: '10px'
          }}
        >
          You have completed the assessment. Please note that you have exhausted 1 attempt from your quota. 
          To know the answers, please click on the button below!
        </p>
        
        <Button 
          style={{ 
            border: '1px solid', 
            marginTop: '25px' 
          }} 
          className="mt-4 w-full bg-gray-200 text-gray-800 hover:bg-gray-300" 
          onClick={handleSummary}
        >
          View Analysis
        </Button>
      </div>
    </div>
  );
}

export default TimeoutPage;
