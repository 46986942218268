import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Chip,
  Switch 
} from '@mui/material';
import { Form, Formik, FieldArray, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MUIDataTable from 'mui-datatables';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import adminServices from '../../../services/adminServices';
import BasicModal from '../../../components/modal/BasicModel';
import DeleteAlert from '../../../components/modal/DeleteModal';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import SnackBar from '../../../components/snackbar/snackbar';
import subscriptionServices from '../../../services/subscriptionServices';

const SubscriptionPlans = ({ data, getData, dataLoading }) => {
  
  const classes = useStyles();
  const [formType, setFormType] = useState('CREATE');
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('productsPlans'); 
  const [discountType, setDiscountType] = useState([]);
  const [currencyType, setCurrencyType] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [currencyRemain, setCurrencyRemain] = useState(null);
  const [liveCourses, setLiveCourses] = useState([]);
  
  const [liveAssesments, setLiveAssesments] = useState([]);
  const [isCourse, setIsCourse] = useState(true);
  const [assesmentData, setAssesmentData] = useState([]);

  const [formValues, setFormVales] = useState({
    name: '',
    actualCost: '',
    subscriptionPlan: '',
    course: [],
    id: null,
    subscriptionType: '',
    subscriptionTypeValue: '',
    isOnline: '',
    isPaid: '',
    pricing: [{ currencyType: '', actualCost: '', stripePriceId: '' }],
    frequency: 1,
    type:'course'
  });

  let formData;
  const handleSubmitForm = async (values) => {
    console.log('values ',values);
    setFormLoading(true);
    const id = [];
    values.course.map((res) => id.push(res.id));
    try {
      formData = {
        name: values.name,
        subscriptionPlan: values.subscriptionPlan.name,
        subscriptionType: values.subscriptionType.code,
        isOnline: values.isOnline !== '' ? values.isOnline.toLowerCase() : 'false',
        isPaid: values.isPaid.toLowerCase(),
        courseIds: id,
        frequency: 1 
      };
    } catch (error) {
      console.log('error in setting formData : ', error);
    }

    if (values.isPaid === 'TRUE') {
      const data = [...values.pricing];
      values.pricing.map((res, idx) => {
        data[idx].currencyType = res.currencyType?.code;
        return '';
      });
      formData.prices = data;
    }

    try {
      if (formType === 'CREATE') {
        console.log('inside create......................1',formData);
        const response = await subscriptionServices.postSubscriptionPlan(values.subscriptionPlan.id, formData,"course");
        if (response.ok) {
          getData();
          setOpenModal(false);
          setOpenSnackbar(true);
          setSnackbarTitle('Created successfully');
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      } else {
        console.log('inside update subscription',formData)
        const response = await subscriptionServices.putSubscriptionPlan(formValues.id, formData);
        if (response.ok) {
          console.log('inside update',formData);
          getData();
          setOpenModal(false);
          setOpenSnackbar(true);
          setSnackbarTitle('Updated successfully');
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setFormLoading(false);
  };
const handleSubmitAssesment = async (values) => {
    console.log('values ',values);
    setFormLoading(true);
    const id = [];
    values.course.map((res) => id.push(res.id));
    try {
      formData = {
        name: values.name,
        subscriptionPlan: values.subscriptionPlan.name,
        subscriptionType: values.subscriptionType.code,
        isOnline: values.isOnline !== '' ? values.isOnline.toLowerCase() : 'false',
        isPaid: values.isPaid.toLowerCase(),
        courseIds: id,
        frequency: 1 
      };
    } catch (error) {
      console.log('error in setting formData : ', error);
    }

    if (values.isPaid === 'TRUE') {
      const data = [...values.pricing];
      values.pricing.map((res, idx) => {
        data[idx].currencyType = res.currencyType?.code;
        return '';
      });
      formData.prices = data;
    }

    try {
      if (formType === 'CREATE') {
        console.log('inside create......................1',formData);
        const response = await subscriptionServices.postSubscriptionPlan(values.subscriptionPlan.id, formData,'assesment');
        if (response.ok) {
          getData();
          setOpenModal(false);
          setOpenSnackbar(true);
          setSnackbarTitle('Created successfully');
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      } else {
        console.log('inside update subscription',formData)
        const response = await subscriptionServices.putSubscriptionPlan(formValues.id, formData);
        if (response.ok) {
          console.log('inside update',formData);
          getData();
          setOpenModal(false);
          setOpenSnackbar(true);
          setSnackbarTitle('Updated successfully');
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      }      
    } catch (error) {
      console.log(error);
    }

    setFormLoading(false);
  };


  const getAssementList = async () => {
          try {
              const response = await adminServices.getGenericAssessmentDropDown();
              console.log(response,'response');
              if (response.ok) {
                 setAssesmentData(response.data);
              }
          } catch (error) {
              console.log(error);
          }
      };
  const getLookUpDetails = async () => {
    const key = ['currency_type', 'subscription_info', 'course', 'subscriptionType'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      // setDiscountType(result.data.discount_type);
      setCurrencyType(result.data.currency_type);
      setCurrencyRemain(result.data.currency_type);
      setSubscriptionPlans(result.data.subscription_info);
      console.log('subscription_info...',result.data.subscription_info);
      setCourseList(result.data.course);
      console.log('courseValue...',result.data.course);
      const statuses = result.data.course.map(course => course.status === 1);
      console.log('courseStatus...',statuses);
      // filtering live courses for subscription plan drop down
      setLiveCourses(result.data.course.filter(course => course.status === 1));
      setSubscriptionTypes(result.data.subscriptionType);
     
    }
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteAlert(true);
    setSubscriptionId(id);
  };

  const handleDeleteSubscriptionPlan = async (id) => {
    const deleteRes = await subscriptionServices.deleteSubscriptionPlans(subscriptionId);
    if (deleteRes.ok) {
      setOpenSnackbar(true);
      setSnackbarTitle('Subscription plan deleted successfully');
      getData();
    } else if (deleteRes.status === 400) {
      setOpenSnackbar(true);
      setSnackbarTitle(deleteRes.data.message);
    }
    setDeleteAlert(false);
  };

  useEffect(() => {
    getAssementList();
    getLookUpDetails();
  }, [openModal]);

  const hasDuplicates = (array, property) => {
    return array.some((item, index) => {
      return array.findIndex((element, idx) => element[property] === item[property] && idx !== index) !== -1;
    });
  };

  const tableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography>{value}</Typography>

              <Chip label={tableMeta.rowData[8]?.description} size="small" sx={{ marginRight: 1 }} />
              <Chip label={tableMeta.rowData[10]} size="small" variant="outlined" color="primary" />
            </>
          );
        },
      },
    },
    {
      name: 'currencyType',
      label: 'Currency Type',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value?.description}</Typography>;
        },
      },
    },

    {
      name: 'discount',
      label: t('discount'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[4] ? (
            <Typography>
              {value && tableMeta.rowData[4]?.code === 'FLAT'
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: tableMeta.rowData[2]?.code || 'INR',
                  }).format(value)
                : value}
            </Typography>
          ) : null;
        },
      },
    },

    {
      name: 'discountType',
      label: t('discountType'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Typography>{value?.description}</Typography> : null;
        },
      },
    },

    {
      name: 'payableCost',
      label: t('price'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <Typography>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: tableMeta.rowData[2]?.code || 'INR',
              }).format(value)}
            </Typography>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'course',
      label: 'Course',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionInfo',
      label: 'Subscription Info',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionType',
      label: 'subscriptionType',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'isOnline',
      label: t('online'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <FiberManualRecordIcon fontSize="small" color={value ? 'success' : 'info'} />;
        },
      },
    },
    {
      name: 'isPaid',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value) => {
          return <Typography>{value === 'Free' ? 'Free' : 'Paid'}</Typography>;
        },
      },
    },

    {
      name: 'subscription',
      label: 'Subscription',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'courseDetails',
      label: 'Course Name',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'prices',
      label: 'Prices',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: '',
      label: t('action'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>              
              <IconButton
                onClick={() => {
                  setFormVales({
                    name: tableMeta.rowData[1],
                    subscriptionPlan: {
                      id: tableMeta.rowData[7]?.id,
                      name: tableMeta.rowData[7]?.name,
                      related_duration_type_id: tableMeta.rowData[7]?.related_duration_type_id,
                      // Add other properties as needed
                    },
                    course: tableMeta.rowData[12],
                    id: tableMeta.rowData[0],
                    subscriptionType: tableMeta.rowData[8],
                    subscriptionTypeValue: tableMeta.rowData[8],
                    isOnline: tableMeta.rowData[9] ? 'TRUE' : 'FALSE',
                    isPaid: tableMeta.rowData[10].toUpperCase() === 'FREE' ? 'FALSE' : 'TRUE',
                    pricing: tableMeta.rowData[13],
                    type:tableMeta.rowData[12].type
                  });
                  const newData =tableMeta.rowData[12]
                  console.log(newData,'newData');
                  
                  setIsCourse(newData[0].type!=="assessment")

                  setFormType('EDIT');
                  setOpenModal(true);
                }}
              >
                <EditIcon fontSize="small" style={{ color: '#BCBCBC' }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpenDeleteModal(tableMeta.rowData[0]);
                }}
              >
                <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const uniqueCourses = (courses) => {
    const uniqueTitles = new Set();
    return courses.filter((course) => {
      if (uniqueTitles.has(course.title)) {
        return false;
      }
      uniqueTitles.add(course.title);
      return true;
    });
  }
  return (
    <Box>
      <MUIDataTable
        data={data}
        columns={tableColumns}
        options={{
          responsive: 'scroll',
          rowsPerPage: 25,
          rowsPerPageOptions: [25, 50, 75, 100],
          selectableRows: 'none',
          filter: true,
          download: false,
          print: false,
          viewColumns: false,
          textLabels: {
            body: {
              noMatch: dataLoading ? 'Loading...' : 'Sorry, there is no matching data to display',
            },
            pagination: {
              rowsPerPage: t('rowsPerPage')
            }
          },
          customToolbar: (value) => {
            return (
              <IconButton
                variant="contained"
                onClick={() => {
                  setFormType('CREATE');
                  setOpenModal(true);
                  setFormVales({
                    name: '',
                    subscriptionPlan: '',
                    course: [],
                    id: null,
                    subscriptionType: '',
                    subscriptionTypeValue: '',
                    isOnline: '',
                    isPaid: '',
                    pricing: [{ currencyType: '', actualCost: '', stripePriceId: '' }],
                    type:'course'
                  });
                }}
              >
                <Tooltip title="Create Subscription Plan / Pricing">
                  <AddCircleOutlineIcon fontSize="small" className={classes.addIcon} />
                </Tooltip>
              </IconButton>
            );
          },
        }}
      />
 
      <BasicModal
        openModel={openModal}
        maxWidth={'md'}
        title={formType === 'CREATE' ? 'Create Products / Pricing' : 'Edit Products / Pricing'}
        closeModel={() => setOpenModal(false)}
        children={
          <Box mt={'0.6rem'}>
            <Formik
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .trim()
                  .min(3, 'Name contains at least 3 character')
                  .required('Required')
                  .nullable()
                  .trim(),
                isPaid: Yup.string().nullable().required('Required'),
                subscriptionPlan: Yup.string().required('Required').nullable(),
                course: Yup.string().nullable().required('Required'),
                subscriptionType: Yup.string().required('Required').nullable(),

                pricing: Yup.array().when('isPaid', {
                  is: (value) => value === 'TRUE',
                  then: Yup.array()
                    .of(
                      Yup.object().shape({
                        currencyType: Yup.string().required('Required').nullable(),
                        actualCost: Yup.string().required('Required').nullable().trim(),
                        // stripePriceId: Yup.string().required('Required').nullable(),
                        stripePriceId: Yup.string().when('isPaid', {
                          is: (val) => '',
                          then: Yup.string().required('Required').trim(),
                          otherwise: Yup.string().nullable().trim(),
                        }),
                      })
                    )
                    .required('At least one pricing is required'),
                  otherwise: Yup.array().notRequired(),
                }),
              })}
              onSubmit={(values) => {
                if (values.isPaid === 'TRUE' && hasDuplicates(values.pricing, 'currencyType')) {
                  setOpenSnackbar(true);
                  setSnackbarTitle('Duplicate currency are not allowed');
                } else if (
                  values.isPaid === 'TRUE' &&
                  values.subscriptionType?.code === 'B2C' &&
                  !values.pricing?.every((res) => res.stripePriceId.trim() !== '')
                ) {
                  setOpenSnackbar(true);
                  setSnackbarTitle('Strip Id required');
                } else {
                  console.log(isCourse,'isCourse');
                  if(isCourse){
                    handleSubmitForm(values);
                  }
                  else{                                        
                    handleSubmitAssesment(values);
                  }
                }
              }}
            >
   
              {({ errors, handleBlur, handleChange, values, touched, setFieldValue }) => (
                        
                <Form>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                        checked={isCourse}
                        onChange={(e) => setIsCourse(e.target.checked)} 
                        name="isCourse"
                        color="primary"
                        disabled={formType !== 'CREATE'}
                       />
                      }
                      label={isCourse ? "Course" : "Assessment"}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                  
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="outlined-basic"
                        name="name"
                        label="Subscription plan name *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sx={12} sm={12} md={6}>
                      <Autocomplete
                        options={subscriptionTypes}
                        filterSelectedOptions
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.subscriptionType}
                        name="subscriptionType"
                        onChange={(e, value) => {
                          setFieldValue('subscriptionType', value);
                          console.log(value);
                          setFieldValue('subscriptionTypeValue', value.code);
                        }}
                       
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select subscription type *"
                            variant="outlined"
                            fullWidth
                            error={Boolean(touched.subscriptionType && errors.subscriptionType)}
                            helperText={touched.subscriptionType && errors.subscriptionType}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>

                    <Grid item xs={12} sx={12} sm={12} md={6}>
                      <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Do you want to show this subscription plan to user ?
                        </FormLabel>
                        <RadioGroup
                          row
                          name="isOnline"
                          onBlur={handleBlur}
                          error={Boolean(touched.isOnline && errors.isOnline)}
                          helperText={touched.isOnline && errors.isOnline}
                          value={values.isOnline}
                          onChange={(event) => {
                            setFieldValue('isOnline', event.target.value);
                          }}
                        >
                          <FormControlLabel value="TRUE" control={<Radio />} label="Yes" />
                          <FormControlLabel value="FALSE" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={12} sm={12} md={6}>
                      {/* <Typography>is subscription Free or Paid</Typography> */}
                      <FormControl disabled={formType === 'EDIT'}>
                        <FormLabel id="demo">Is subscription Free or Paid*</FormLabel>
                        <RadioGroup
                          row
                          // aria-labelledby="demo-controlled-radio-buttons-group"
                          // name="controlled-radio-buttons-group"
                          // value={value}
                          // onChange={handleChanges}
                          name="isPaid"
                          onBlur={handleBlur}
                          error={Boolean(touched.isPaid && errors.isPaid)}
                          helperText={touched.isPaid && errors.isPaid}
                          value={values.isPaid}
                          onChange={(event) => {
                            setFieldValue('isPaid', event.target.value);
                          }}
                        >
                          <FormControlLabel value={'FALSE'} control={<Radio />} label="Free" />
                          <FormControlLabel value={'TRUE'} control={<Radio />} label="Paid" />
                        </RadioGroup>
                        <Typography variant="subtitle2" color={'red'} sx={{ fontSize: '0.75rem', fontWeight: '400' }}>
                          {touched.isPaid && errors.isPaid}
                        </Typography>
                      </FormControl>
                    </Grid>

                  { isCourse?
               <Grid item xs={12} sx={6} sm={6}>
               <Autocomplete
                 options={liveCourses}
                 autoHighlight
                 multiple
                 getOptionLabel={(option) => option.title || ''}
                 value={uniqueCourses(values.course)}
                 name="course"
                 onChange={(e, value) => {
                   setFieldValue('course', uniqueCourses(value));
                 }}
                 renderOption={(props, option) => (
                   <Box
                     {...props}
                     sx={{
                       display: 'flex',
                       justifyContent: 'start',
                       alignItems: 'start',
                       flexDirection: 'column',
                     }}
                   >
                     <Typography variant="body1" width="100%">
                       {option.title}
                     </Typography>
                     <Typography variant="body1" width="100%" sx={{ fontSize: '12px' }}>
                       Author: {option?.authorName}
                     </Typography>
                   </Box>
                 )}
                 renderInput={(params) => (
                   <TextField
                     {...params}
                     label="Select course *"
                     variant="outlined"
                     fullWidth
                     error={Boolean(touched.course && errors.course)}
                     helperText={touched.course && errors.course}
                   />
                 )}
               />
               <ErrorFocus />
             </Grid>
                    :
                  <Grid item xs={12} sx={6} sm={6}>
                  <Autocomplete
                    options={assesmentData}
                    autoHighlight
                    multiple
                    getOptionLabel={(option) => `${option.title} - ${option?.userDetails?.first_name}`}
                    value={values.course}
                    name="course"
                    onChange={(e, value) => {
                      setFieldValue('course', value);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'start',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant="body1" width="100%">
                          {option.title}
                        </Typography>
                
                        <Typography variant="body1" width="100%" sx={{ fontSize: '12px' }}>
                          Author: {option?.userDetails?.first_name || 'N/A'}
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Assessment *"
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.course && errors.course)}
                        helperText={touched.course && errors.course}
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>
                }

                    <Grid item xs={12} sx={6} sm={6}>
                       <Autocomplete
                            options={subscriptionPlans}
                            filterSelectedOptions
                            autoHighlight
                            getOptionLabel={(option) => option.name || ''}
                            value={values.subscriptionPlan}
                            name="subscriptionPlan"
                            onChange={(e, value) => {
                              setFieldValue('subscriptionPlan', value);
                              console.log('setFieldValue for subscriptionPlan ',value); 
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select billing type *"
                                variant="outlined"
                                fullWidth
                                value={values.subscriptionPlan ? values.subscriptionPlan.name : ''}
                                error={Boolean(touched.subscriptionPlan && errors.subscriptionPlan)}
                                helperText={touched.subscriptionPlan && errors.subscriptionPlan}
                              />
                            )}
                          />

                      <ErrorFocus />
                    </Grid>

                    {values.isPaid === 'TRUE' && (
                      <>
                        <Grid item sm={12} md={12} lg={12}>
                          <FieldArray
                            name="pricing"
                            render={(arrayHelpers) => (
                              <Grid container spacing={2}>
                                {values.pricing?.map((list, index) => (
                                  <>
                                  { console.log(list)}
                                    <Grid item xs={12} sx={6} sm={6} md={3} key={index}>
                                      <Autocomplete
                                        options={currencyType}
                                        filterSelectedOptions
                                        autoHighlight
                                        clearIcon={false}
                                        getOptionLabel={(option) => option.description || ''}
                                        value={getIn(values, `pricing[${index}].currencyType`)}
                                        name={`pricing[${index}].currencyType`}
                                        onChange={async (e, value) => {
                                          if (value) {
                                            await setFieldValue(`pricing[${index}].currencyType`, value);
                                            // if (values.pricing[index].currencyType) {
                                            //   setCurrencyType(
                                            //     currencyType.filter(
                                            //       (res) => res.code !== values.pricing[index].currencyType?.code
                                            //     )
                                            //   );
                                            // } else {
                                            //   setCurrencyType(currencyType.filter((res) => res.code !== value.code));
                                            // }
                                          }
                                        }}
                                        // disabled={formType === 'EDIT' && values.pricing.length  }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Currency type *"
                                            variant="outlined"
                                            fullWidth
                                            // error={Boolean(touched.currencyType && errors.currencyType)}
                                            // helperText={touched.currencyType && errors.currencyType}
                                            error={Boolean(
                                              getIn(errors, `pricing[${index}].currencyType`) &&
                                                getIn(touched, `pricing[${index}].currencyType`)
                                            )}
                                            helperText={getIn(errors, `pricing[${index}].currencyType`)}
                                          />
                                        )}
                                      />
                                      <ErrorFocus />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        type="number"
                                        name={`pricing[${index}].actualCost`}
                                        label="Price *"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={getIn(values, `pricing[${index}].actualCost`)}
                                        variant="outlined"
                                        error={Boolean(
                                          getIn(errors, `pricing[${index}].actualCost`) &&
                                            getIn(touched, `pricing[${index}].actualCost`)
                                        )}
                                        helperText={getIn(errors, `pricing[${index}].actualCost`)}
                                      />
                                      <ErrorFocus />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                      <TextField
                                        fullWidth
                                        type="text"
                                        id="outlined-basic"
                                        name={`pricing[${index}].stripePriceId`}
                                        value={getIn(values, `pricing[${index}].stripePriceId`)}
                                        label="Stripe ID "
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        error={Boolean(
                                          getIn(errors, `pricing[${index}].stripePriceId`) &&
                                            getIn(touched, `pricing[${index}].stripePriceId`)
                                        )}
                                        helperText={getIn(errors, `pricing[${index}].stripePriceId`)}
                                      />
                                      <ErrorFocus />
                                    </Grid>
                                    {formType === 'CREATE' && index !== 0 && (
                                      <Grid item sm={1}>
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setCurrencyType(currencyRemain);
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </>
                                ))}

                                {values.pricing?.length < currencyRemain.length && (
                                  <Grid item sx={12} alignItems="right" width={'100%'}>
                                    {errors.pricing && errors.friends?.length === 0 && (
                                      <Typography>{errors.pricing}</Typography>
                                    )}

                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      sx={{ float: 'right' }}
                                      onClick={() =>
                                        arrayHelpers.push({ currencyType: '', actualCost: '', stripePriceId: '' })
                                      }
                                    >
                                      Add Price
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            fullWidth
                            type="text"
                            id="outlined-basic"
                            name="discount"
                            label="Discount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.discount}
                            variant="outlined"
                            error={Boolean(touched.discount && errors.discount)}
                            helperText={touched.discount && errors.discount}
                          />
                          <ErrorFocus />
                        </Grid>

                        <Grid item xs={12} sx={6} sm={6}>
                          <Autocomplete
                            options={discountType}
                            filterSelectedOptions
                            autoHighlight
                            getOptionLabel={(option) => option.description || ''}
                            value={values.discountType}
                            name="discountType"
                            onChange={(e, value) => {
                              setFieldValue('discountType', value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Discount type"
                                variant="outlined"
                                fullWidth
                                error={Boolean(touched.discountType && errors.discountType)}
                                helperText={touched.discountType && errors.discountType}
                              />
                            )}
                          />
                          <ErrorFocus />
                        </Grid> */}
                      </>
                    )}

                    {/* <Grid item xs="12" sx={{ paddingTop: '4px !important' }}>
                      <FormControl component="fieldset" color="secondary">
                        <FormGroup value={values.isOnline}>
                          <FormControlLabel
                            value={values.isOnline}
                            checked={values.isOnline}
                            color="secondary"
                            control={
                              <Checkbox
                                color="secondary"
                                onChange={(e, value) => {
                                  setFieldValue('isOnline', value);
                                }}
                                name="isOnline"
                              />
                            }
                            label={<Typography variant="body1">Is Online?</Typography>}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs="12" sx={{ paddingTop: '4px !important' }}>
                      <FormControl component="fieldset" color="secondary">
                        <FormGroup value={values.isPaid}>
                          <FormControlLabel
                            value={values.isPaid}
                            checked={values.isPaid}
                            color="secondary"
                            control={
                              <Checkbox
                                color="secondary"
                                onChange={(e, value) => {
                                  setFieldValue('isPaid', value);
                                }}
                                name="isPaid"
                              />
                            }
                            label={<Typography variant="body1">Is Paid?</Typography>}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={12} sm={12} onClick={() => console.log(errors)}>
                      <LoadingButton variant="contained" color="primary" loading={formLoading} type="submit" fullWidth>
                        {formType === 'CREATE' ? 'Create' : 'Update'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        }
      />

      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this subscription plan"
        confirm={handleDeleteSubscriptionPlan}
        close={() => setDeleteAlert(false)}
      />

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addIcon: {
    '&:hover': {
      color: '#fa7343',
    },
  },
}));

export default SubscriptionPlans;
