import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

export default function DraggableDialog(props) {
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(false);
  }, [props.open]);

  const handleConfirm = () => {
    setLoading(true);
    props.confirm();
  };

  const handleCancel = () => {
    props.close();
  };
  return (
    <div>
      <Dialog open={props.open} aria-labelledby="draggable-dialog-title">
        {props.title && (
          <DialogTitle style={{ cursor: 'move', fontSize: '1rem' }} id="draggable-dialog-title">
            {props.title}
          </DialogTitle>
        )}
        {props.subTitle && (
          <DialogContent>
            <DialogContentText>{props.subTitle}</DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button autoFocus onClick={handleCancel} id='cancelbtn'
           sx={{ fontSize: '1rem', color: 'gray' }}>
            Cancel
          </Button>

          <LoadingButton
            sx={{ width: '126px' }}
            onClick={handleConfirm}
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            id='submitbtn'
            loading={loading}
          >
            {props.submitBtnText ? props.submitBtnText : 'Yes! Delete'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
