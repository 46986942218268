import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import brakeTimeImg from "../../../assets/Images/breakTime.gif";



const BreakTime = () => {
  const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <Box classname="breaktimeMain" style={{ paddingTop: '95px', minHeight: '100vh', backgroundColor: '#fff' }} sx={{ flexGrow: 1, maxWidth: '90%', margin: "auto" }}>
      <Box elevation={3} sx={{ padding: 3, borderRadius: 0 }}>
        <Grid container spacing={2}>

          <Grid item xs={12} md={8} style={{ paddingLeft: '55px' }}>
            <Typography variant="h5" fontWeight="bold">
              Practice Test Break
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              You can resume this practice test as soon as you're ready to move on.
              On test day, you'll wait until the clock counts down. Read below to see
              how breaks work on test day.
            </Typography>
            <Box sx={{ my: 2, borderBottom: "1px solid #ccc" }} />

            <Typography variant="h6" fontWeight="bold">
              Take a Break: Do Not Close Your Device
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              After the break, a Resume Testing Now button will appear, and you'll start the next session.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, marginBottom: 1 }}>
              Follow these rules during the break:
            </Typography>
            <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
              <li style={{ marginBottom: "5px" }}>Do not disturb students who are still testing.</li>
              <li style={{ marginBottom: "5px" }}>Do not exit the app or close your laptop.</li>
              <li style={{ marginBottom: "5px" }}>Do not access phones, smartwatches, textbooks, notes, or the internet.</li>
              <li style={{ marginBottom: "5px" }}>Do not eat or drink near any testing device.</li>
              <li style={{ marginBottom: "5px" }}>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} textAlign="center">
            <Box style={{
              '@media (min-width: 900px)': {
                borderLeft: '1px solid #ccc'
              }
            }} sx={{
              borderLeft: {
                xs: 'none',
                md: '1px solid #ccc'
              }
            }}>
              <img
                src={brakeTimeImg}
                alt="Break Time"
                style={{ width: "100%", maxWidth: "180px", margin: 'auto', marginBottom: "10px" }}
              />
              <Box
                style={{
                  height: '110px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: '20px'

                }}
                sx={{
                  backgroundColor: "#437bfc",
                  color: "white",
                  width: '80%',
                  minWidth: '220px',
                  maxWidth: '280px',
                  padding: '10px 0 15px',
                  borderRadius: "8px",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  margin: "auto",
                }}
              >
                <p style={{ fontSize: "18px", marginBottom: '8px', fontWeight: '400' }}>Remaining Break Time</p>
                {formatTime(timeLeft)}
              </Box>
              <Button
                variant="contained"
                color="success"
                style={{
                  width: '80%',
                  minWidth: '220px',
                  maxWidth: '280px',
                  backgroundColor: '#52c28c',
                  color: '#fff',
                  borderRadius: '4px',
                  fontSize: '16px',
                  fontWeight: '400'
                }}
                sx={{ mt: 2, width: "100%" }}
              >
                Resume Test
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BreakTime;
