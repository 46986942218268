// import React, { useState, forwardRef, useEffect } from 'react';
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import settings from '../../settings';

import { closeCookiesAlert } from '../../store/reducer';
// ----------------------------------------------------------------------
const layoutStyle = {
  paddingTop: '20px !important', // 84px
  '@media (min-width:1280px)': {
    paddingTop: '15px !important',
  },
};
// dispalySalasIq.includes(locationPath.pathname);
const WebPage = forwardRef(
  (
    {
      children,
      title = '',
      description = '',
      meta,
      metaUrl = '/',
      style = layoutStyle,
      pageSettings,
      utm,
      prefill,
      ...other
    },
    props,
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const isAcceptCookie = useSelector((state) => state.acceptCookies);
    const navigate = useNavigate();
    const { isBannerVisible } = useSelector((state) => state);
    const locationPath = useLocation();
    const dispalySalasIq = settings.dispalySalasIqUrl;

    const layoutStyleTwo = {
      paddingTop: '84px !important', // 84px
      '@media (min-width:1280px)': {
        paddingTop: '84px !important',
      },
    };

    return (
      <>
        <Helmet>
          <title>{`${title} | keySkillset`}</title>
          <meta name="description" content={`${description}`} />
          {meta}
        </Helmet>

        <Box ref={ref} sx={!dispalySalasIq.includes(locationPath.pathname) ? layoutStyleTwo : style} {...other}>
          {children}
          {isAcceptCookie && (
            <Stack className='cookiesMsg' sx={{position: 'fixed', bottom: '0px', zIndex: '999' }} spacing={2}> 
            <Alert 
                icon={false}
                severity="warning"
                sx={{
                  background: '#00b673',
                  color: '#fff',
                }}
                action={
                  <>
                    <Button
                      sx={{
                        marginRight: '8px',
                        background: '#424242',
                        color: '#fff',
                        '&:hover': {
                          background: '#424242',
                        },
                      }}
                      onClick={() => dispatch(closeCookiesAlert())}
                      size="small"
                    >
                      Agree
                    </Button>
                    <Button
                      size="small"
                      sx={{
                        background: '#424242',
                        color: '#fff',
                        '&:hover': {
                          background: '#424242',
                        },
                      }}
                      style={{width: 'max-content'}}
                      onClick={() => {
                        navigate('/privacy');
                        dispatch(closeCookiesAlert());
                      }}
                    >
                      Privacy policy
                    </Button>
                  </>
                }
              >
                We use cookies to ensure that we give you the best experience on our website. If you continue to use
                this site we will assume that you are happy with it.
              </Alert>
            </Stack>
          )}
        </Box>
      </>
    );
  }
);

WebPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  // meta: PropTypes.node,
};

export default WebPage;
