/* eslint import/newline-after-import: "off" */
import { create } from 'apisauce';
import Cookies from 'js-cookie';
import jwtdecode from 'jwt-decode';
import swal from 'sweetalert';
import { BroadcastChannel } from 'broadcast-channel';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../store/reducer';

const token = Cookies.get('token');
const settings = require('../settings');
// const apiEndpoint = settings.ApiEndPoint;
// changed apiEndpoint to fetch from env
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const apiClient = create({
  baseURL: apiEndpoint,
  timeout: 600000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`,
  },
});

const handleTokenExpire = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const logoutChannel = new BroadcastChannel('logout');

  swal({
    text: 'Session has been expired please re-login. ',
    buttons: {
      confirm: {
        text: 'Okay',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
    closeOnClickOutside: false,
  }).then((res) => {
    console.log(res);

    // logoutChannel.postMessage('Logout');
    // dispatch(logoutSuccess());
    // navigate('/login');
    // loginServices.logout();
  });
};

// The purpose of this function is to check the expiration of the token for each request called only with apiClient
apiClient.addAsyncRequestTransform((request) => async () => {
  const tokenExpire = Cookies.get('tokenExpireTime');
  const isLogIn = Cookies.get('isLogIn');

  // if (isLogIn && token) {
  //   handleTokenExpire();
  // }
  // console.log(  Date.now()  , parseInt(tokenExpire), parseInt(tokenExpire)*1000 )
  if (isLogIn && tokenExpire) {
    if (Date.now() >= parseInt(tokenExpire, 10) * 1000) {
      try {
        const loginResponse = await getNewToken(); // waiting for new token
        if (loginResponse.ok) {
          Cookies.remove('token');
          Cookies.remove('tokenExpireTime');
          const json = await loginResponse.json();
          const decoded = await jwtdecode(json.token);
          // request.headers["Authorization"] = `Bearer ${json.token}`;
          request.headers.Authorization = `Bearer ${json.token}`;
          apiClient.setHeader('Authorization', `Bearer ${json.token}`);
          Cookies.set('token', json.token);
          Cookies.set('tokenExpireTime', decoded.exp);
        }
      } catch (error) {
        console.log('error on getting token from api', error);
      }
    } else {
      // apiClient.setHeaders({ Authorization: token })
    }
  }
});

const getNewToken = () => {
  const refreshToken = Cookies.get('refreshToken');
  return Promise.race([
    fetch(`${apiEndpoint}/authenticate/refreshToken`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ refreshToken }),
    }),
    new Promise((_, reject) =>
      setTimeout(() => {
        reject(new Error('timeout'));
      }, 30000)
    ),
  ]);
};

export default apiClient;
