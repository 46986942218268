import React, { useState } from 'react';
import { Grid, Typography, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import BasicModel from './BasicModel';
import adminServices from '../../services/adminServices';

export const SubscribeModal = (props) => {
  const classes = useStyles();
  const [currencyList, setCurrencyList] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [isWantMoreText, setIsWantMoreText] = useState(true);
  const [buttonLabel, setButtonLabel] = useState('');
  const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);
  const [formvalue, setFormvalue] = useState({
    currency: '',
  });
  const [courseDetails, setCourseDetails] = useState({
    image: '',
    time: '80 hrs',
    description:
      '<p>Excel Efficiency is an LMS course that increases your efficiency in Excel by introducing you to over 70 formulas and functions, over 200 hot keys and shortcuts, plus other tips and tricks. Our Excel Efficiency course includes strategies used by investment banks, consulting companies, and businesses listed on the Fortune 500. This course is specifically built for finance, investment banking, accounting, and consulting careers.</>',
  });

  const getCurrenciesList = async () => {
    try {
      const res = await adminServices.getLookUpData('currency_type');
      if (res.ok) {
        if (res?.data?.currency_type) {
          setCurrencyList(res.data.currency_type);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (openSubscriptionModalDetails !== null) {
      if (openSubscriptionModalDetails?.courseobj) {
        const obj = {};
        obj.image = openSubscriptionModalDetails?.courseobj[0]?.category?.courseImageUrl;
        obj.description = null;
        setCourseDetails(obj);
      } else {
        const obj = {};
        obj.image = openSubscriptionModalDetails?.category?.categoryImgUrl;
        obj.description = openSubscriptionModalDetails?.description;
        setCourseDetails(obj);
      }
    }
    if (
      openSubscriptionModalDetails?.planStatus?.toLowerCase() === 'expired' &&
      openSubscriptionModalDetails?.isSubscribed
    ) {
      setButtonLabel('Renew');
    } else if (!openSubscriptionModalDetails?.isSubscribed) {
      setButtonLabel('Subscribe');
    }
  }, [openSubscriptionModalDetails]);

  React.useEffect(() => {
    if (props.open === true) {
      getCurrenciesList();
      setFormvalue({
        currency: userInfo?.currencyType !== null ? userInfo?.currencyType : '',
      });
    }
  }, [props.open]);

  return (
    <div>
      <BasicModel
        openModel={props.open}
        title={openSubscriptionModalDetails?.subscriptionName}
        closeModel={props.close}
        children={
          <div>
            <div className={classes.subdiv}>
              <img className={classes.logo} src={openSubscriptionModalDetails?.courseDetail && openSubscriptionModalDetails?.courseDetail?.length > 0 && openSubscriptionModalDetails?.courseDetails[0]?.courseImageUrl} alt="exelLogo" />
            </div>
            {courseDetails?.time && (
              <Typography className={classes.hours}>Course Duration:{courseDetails?.time}</Typography>
            )}
            {courseDetails.description && <Typography className={classes.about}>This Course Includes:</Typography>}

            <Typography
              dangerouslySetInnerHTML={{ __html: courseDetails.description }}
              className={isWantMoreText ? classes.para : classes.readLess}
            />
            {courseDetails?.description && courseDetails?.description !== '' && (
              <Typography
                className={classes.readMore}
                color="primary"
                onClick={() => setIsWantMoreText(!isWantMoreText)}
              >
                {isWantMoreText ? 'Read more' : 'Read less'}
              </Typography>
            )}
            {props.open && (
              <Formik
                enableReinitialize
                initialValues={formvalue}
                validationSchema={Yup.object().shape({
                  currency: Yup.string().trim().required('Currency type is Required').label(),
                })}
                onSubmit={(values) => {
                  props.submit(values);
                  // handleSubmitForm(values);
                }}
              >
                {({ errors, handleBlur, setFieldValue, touched, values }) => (
                  <Form>
                    <Grid diaplay="flex" alignItems="center" container spacing={2}>
                      <Grid item xs="12" sm="6" md="6">
                        <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                          Select Currency type
                        </Typography>
                        <ThemeProvider theme={customTheme}>
                          <Autocomplete
                            id="country-select-demo"
                            disableClearable
                            readOnly={userInfo?.currencyType !== null && true}
                            fullWidth
                            options={currencyList}
                            autoHighlight
                            getOptionLabel={(option) => option.code || ''}
                            value={values.currency}
                            onChange={(e, value) => {
                              setFieldValue('currency', value);
                              // setCountryCode(value.code);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="currency"
                                placeholder="Currency"
                                error={Boolean(touched.currency && errors.currency)}
                                helperText={touched.currency && errors.currency}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs="12">
                        <LoadingButton
                          className={classes.button}
                          color="primary"
                          loading={props.loading}
                          size="medium"
                          type="submit"
                          variant="contained"
                          id="impact_clickid"
                        >
                          {buttonLabel}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  subdiv: {
    borderRadius: '20px',
    backgroundColor: '#F4FBF8',
    padding: '3rem',
  },
  hours: {
    color: '#00B673',
    fontSize: '1.2rem ! important',
    fontWeight: '450 ! important',
    marginTop: '1rem ! important',
    fontFamily: 'unset ! important',
  },
  about: {
    fontSize: '1rem ! important',
    fontWeight: 'bold ! important',
    margin: '0.5rem 0rem ! important',
    fontFamily: 'unset ! important',
  },
  logo: {
    height:" 200px",
    display: 'flex',
    textAlign: 'center',
    margin: 'auto',
  },
  typoFontWeight: {
    fontSize: '1rem ! important',
    fontWeight: '450 ! important',
    fontFamily: 'unset ! important',
  },
  para: {
    fontSize: '0.95rem ! important',
    color: '#667085',
    fontFamily: 'unset ! important',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
  },
  readLess: {
    fontSize: '0.95rem ! important',
    color: '#667085',
    fontFamily: 'unset ! important',
    WebkitBoxOrient: 'vertical',
  },
  readMore: {
    width: 'max-content',
    fontSize: '0.95rem ! important',
    fontFamily: 'unset ! important',
    cursor: 'pointer',
    color: '#FE7000 ! important',
    marginBottom: '0.9rem ! important',
  },
  button: {
    backgroundColor: '#FE7000 ! important',
  },
}));

const customTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '3px ! important',
        },
      },
    },
  },
});
