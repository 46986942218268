import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import './index.css';
import DOMPurify from 'dompurify';
import { Button, Typography, Card, CardContent, Grid, Box, Container, Breadcrumbs } from '@mui/material';
import { display } from '@mui/system';
import { getIndividualAssessment, getRecommendedAssessment } from '../../../Redux/Action'
import AssessmentLogo from '../../../assets/Images/AssessmentLogo.jpg'
import helper from '../../../utils/helper';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import { openSnackbar,ComingFrom } from '../../../store/reducer';

const AssessmentCourseDetails = () => {   
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allcourseDetails = useSelector((state) => state);
    const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);
    const [currency, setCurrency] = useState(userInfo?.currencyType ? userInfo?.currencyType.code : 'USD');

    const location = useLocation()
    const [assessmentDetails, setAssessmentDetails] = useState('');
    const [parseArray, setParseArray] = useState('');
    const [assessmentId, setAssessmentId] = useState(location.state?.id?.id ? location.state?.id?.id : location.state?.id);
    const [assessments, setAssessments] = useState([]);
    const [intfrequency, setintfrequency] = React.useState(1);
    const [viewPrice, setViewPrice] = useState(0);


   
console.log(location.state,"location.state");

    React.useMemo(() => {
        const filteredList = assessmentDetails?.assessment_details?.stripe_pricing_list?.filter(data =>
            allcourseDetails?.currency === 'USD'
                ? data?.related_currency_type_lk === 2
                : data?.related_currency_type_lk === 1
        );
        let displayPrice;

        if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'USD') {
            displayPrice = `$${filteredList[0].value}`;
        } else if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'INR') {
            displayPrice = `₹${filteredList[0].value}`;
        } else if (filteredList && filteredList?.length === 0) {
            displayPrice = 'NAN'
        }
        setViewPrice(displayPrice)

    }, [allcourseDetails])

    useEffect(() => {
        dispatch(getIndividualAssessment(assessmentId, allcourseDetails?.userInfo.id))
        dispatch(getRecommendedAssessment(allcourseDetails?.userInfo.id))
    }, [assessmentId])

    useEffect(() => {
        window.history.scrollRestoration = "manual";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setAssessmentDetails(allcourseDetails?.GetAssessmentIndividual)
        const filterData = allcourseDetails?.GetRecomendedAssessment?.filter(item => item.id !== assessmentDetails?.assessment_details?.id) || []
        setAssessments(filterData)
    }, [allcourseDetails])

    const formatTimeTimer = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(1, '0')} Minutes`;

    };
    const handleSwitchAssessment = (id) => {
        setAssessmentId(id)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

       const handleNavigateDetailsMyLearning = () =>{
             dispatch(ComingFrom("MyLearning"))
            navigate("/auth/subscribe")
        }

    const highlightedSection = assessmentDetails?.assessment_details?.highlighted_section;

    useMemo(() => {
        if (highlightedSection !== undefined) {
            const cleanedStr = highlightedSection.replace(/[{}"]/g, '').split(',')
            const formattedOutput = cleanedStr.join('\n');

            const data = formattedOutput.split('\n').map((text) => ({ text }))
            setParseArray(data)
        }
    }, [highlightedSection])

    const handleStart = () => {
        const data = {
            id:assessmentDetails?.assessment_details?.id,
            from:location.state?.from
        }
        navigate("/auth/AssessmentOverview", { state: data})
    }


    const postAssessmentSubscribtionDetails = async (item, planId) => {
        const courseId = item.id;
        let impactClickId = '';
        try {
            impactClickId = await helper.getClickId();
        } catch (error) {
            console.error(error);
            impactClickId = '';
        }

        const payload = {
            userId: userInfo.id,
            courseId,
            planId: item?.subscriptionplanid,
            currencyType: currency,
            frequency: parseInt(intfrequency, 10),
            impactClickId,
            type: 'assessment'
        };

        try {
            const res = await trialAndStripeSubscriptionService.createAssessmentSubscription(JSON.stringify(payload));
            if (res.status === 303) {
                // dispatch(closeSubscriptionModal());
                // setSubscriptionModalLoading(false);
                window.location = res.data.paymentUrl;
            } else if (res?.data?.message) {
                dispatch(openSnackbar(res.data.message));
            } else {
                dispatch(openSnackbar('Failed to Subscribe, Please try again.'));

            }
        } catch (error) {
            console.log(error);
        }

    };

    const handleClickTrialButton = async (planId, enrollmentType, assessmentId) => {
        try {
            const res = await trialAndStripeSubscriptionService.postTrialAssessmentEnrollmentDetails(
             JSON.stringify({ planId, enrollmentType, assessmentId, authUserId: userInfo.id })
            );
            if (res.ok) {
                const data = {
                    id:assessmentDetails?.assessment_details?.id,
                    from:location.state?.from
                }
                navigate("/auth/AssessmentOverview", { state: data})
            // navigate("/auth/AssessmentOverview", { state: assessmentDetails?.assessment_details?.id })
            }
        } catch (error) {
            console.log(error);
        }
    };

    


    return (

        <Box sx={{ width: '92%', margin: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" fontWeight="bold">General Assessment</Typography>
                <Typography variant="body2" color="textSecondary">Assessment Overview</Typography>
            </Box>

            <Box sx={{ marginTop: '60px', }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                        padding: '15px',
                        paddingLeft: '0px',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    separator=">"
                >
                    {location.state?.from === 'GA' ?
                    <Link style={{
                        cursor: 'pointer',
                        color: "#212B36 !important",
                        textDecoration: 'none',
                        "&:hover": {
                            textDecoration: 'underline',
                        }
                    }}
                        underline="hover"
                        color="#212B36"
                        // onClick={handleNavigation}
                        to="/auth/subscribe"
                    >
                        General Assessment
                    </Link>
                    :
                    <button style={{
                        cursor:'pointer',
                         textDecoration: 'none',
                        border: 'none',
     
                        background: 'none',
                        color: '#0000ee',
                        fontSize: '16px',
                        fontWeight: '500'
                     }}
                         // underline="hover"
                         color="#212B36"
                         // to="/auth/AssessmentCourseDetails"
                         onClick={handleNavigateDetailsMyLearning}
                     >
                         My Learning
                     </button>}



                    <Typography color="text.primary">
                        Assessment Overview
                    </Typography>

                </Breadcrumbs>
            </Box>

            <Box sx={{ marginTop: '20px', }}>
                <Card sx={{
                    mx: "auto", boxShadow: 3, borderRadius: '10px',
                    border: '1px solid #dfdede'
                }}>
                    <Grid sx={{ padding: '15px' }} container spacing={2}>
                        <Grid item xs={12} sm={4} sx={{ paddingRight: '15px' }}>
                            <Box
                                component="img"
                                src={assessmentDetails?.assessment_details?.image_name}
                                // src="https://s3-alpha-sig.figma.com/img/0656/9cc3/1739478f82289ef1c25b236830e244e3?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pe8zQWB3DSA4lwK-pjWJIzsvBsrp7n0t9g1~fbnrnkp26hsEgLKwq92N8CR1BNA4qwvokjUo-hlpT3HbZtrraFjNWvLwGIPsuMPCPVfygNlwWNdcpQX3mgAe9YTp2C~w7SUllRbrNK0R1TIVK9sjllitNOydMM4Z8LKmoZIR3AuGj6KwKljFN9Lz~UlpGmL2xaYcpSbVnvHIdzbou0cJCxQbZlBhz-JLElqzA~mgjq33SY5rcscFRNXPC4NSDCuYn4XOJj2Uj44LG5-L1umDc0opJRIatNBXmHDEVkHdpyeO2g9WYrouwFwQMZtTKvRb4guXbeJ~C6bk9ZL31kmY5g__"
                                alt="Assessment"
                                sx={{ width: "100%", height: "220px", borderRadius: 2 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={8} sx={{ padding: 0, paddingRight: '15px' }}>
                            <CardContent sx={{
                                padding: 0, height: '100%', paddingBottom: '0px !important', display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Typography variant="h5" fontWeight="bold">
                                    {assessmentDetails && assessmentDetails?.assessment_details?.title}
                                </Typography>

                                <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessmentDetails && assessmentDetails?.assessment_details?.short_description) }} sx={{ mb: 2, flex: '1 !important' }} />
                                {allcourseDetails?.SubscribedAssessment.map((item) =>item.assessmentDetails[0].id===assessmentDetails?.assessment_details?.id) &&
                                 <Typography >
                                 <span style={{ 
                                     textDecoration: 'line-through', 
                                     marginRight: '5px', 
                                     background: '#ddd', 
                                     padding: '4px 8px', 
                                     borderRadius: '5px' 
                                   }}>
                                   Price: {viewPrice}
                                 </span>
                                 <strong style={{ 
                                     background: '#ddd', 
                                     padding: '4px 8px', 
                                     borderRadius: '5px' 
                                   }}>
                                   Free
                                 </strong>
                               </Typography>
                               }
                                <Box className="cardShowEven">

                                    <Box sx={{ flex: 1 }}>
  
                                        <Typography className={classes.badgeShower} variant="span" sx={{ mt: 1 }}>
                                            No. of Attempts Left: {10 - assessmentDetails?.attempt_count}
                                        </Typography>
                                        <Typography className={classes.badgeShower} variant="span" sx={{ mt: 1 }}>
                                            Time:   {formatTimeTimer(assessmentDetails && assessmentDetails?.assessment_details?.time_in_mins)}
                                        </Typography>

                                        {allcourseDetails?.SubscribedAssessment.map((item) =>item.assessmentDetails[0].id===assessmentDetails?.assessment_details?.id) &&
                                            <Typography className={classes.badgeShower} variant="span" sx={{ mt: 1 }}>
                                                Enrolled
                                            </Typography>}
                                            
                                        {assessmentDetails?.assessment_details?.isPaid !== true &&assessmentDetails?.assessment_details?.is_free===true&&
                                            <Typography className={classes.badgeShower} variant="span" sx={{ mt: 1 }}>
                                                Free
                                            </Typography>}
                                    </Box>
                                 {(allcourseDetails?.SubscribedAssessment.map((item) =>item.assessmentDetails[0].id===assessmentDetails?.assessment_details?.id) )||assessmentDetails?.assessment_details?.isPaid!==true ?
                                    <Button
                                        variant="contained"
                                        className='buyNowbtnOne'
                                        onClick={handleStart}
                                        sx={{
                                            mr: 2
                                        }} >
                                        Go To Test
                                    </Button>
                                    :
                                 <Button
                                        variant="contained"
                                        className='buyNowbtnOne'
                                        onClick={() => { handleClickTrialButton( assessmentDetails?.assessment_details?.subscriptionplanid , true , assessmentDetails?.assessment_details?.id) }}
                                        sx={{
                                        }} >
                                        Enroll Now
                                    </Button>}
                                    {/* <Button
                                        variant="contained"
                                        className='buyNowbtnOne'
                                        onClick={handleStart}
                                        sx={{
                                            mr: 2
                                        }} >
                                        {assessmentDetails?.attempt_count < 10  ? 'Go To Test': 'Enroll Now'}
                                    </Button> */}
                                    {/* Go To Test */}
                                </Box>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>

            <Box xs={12} className="cardShowEvens">
                <Box xs={12} sm={12} md={10} sx={{
                    padding: '15px !important', marginRight: '10px', boxShadow: 3, flex: 2, borderRadius: '10px', border: '1px solid #dfdede', marginTop: 4
                }}>
                    <Box style={{ display: 'flex', marginBottom: 2 }} >
                        {/* <img src={AssessmentLogo} alt="Logo" style={{width: '14%',height: '14%', objectFit: 'contain' }} /> */}
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h4" fontWeight="bold" sx={{ display: 'block', marginBottom: 2 }}>Overview</Typography>

                        </Box>
                    </Box>



                    <table className={classes.tableRow} style={{
                        borderRadius: '5px',
                        width: '100%',
                        border: '1px solid #d7d7d7',
                    }}>
                        <thead className={classes.tableRow}>
                            <tr className={classes.tableRow}>
                                <th className={classes.tableRow}><strong>Number of Attempts:</strong></th>
                                <th className={classes.tableRow}> <strong>{10 - assessmentDetails?.attempt_count}</strong></th>
                            </tr>
                        </thead>
                        <tbody>

                            {assessmentDetails && assessmentDetails?.assessment_details?.course_name !== null ?
                                <tr className={classes.tableRow}>
                                    <td className={classes.tableRow}>Associated Course</td>
                                    <td className={classes.tableRow}>{assessmentDetails && assessmentDetails?.assessment_details?.course_name}</td>
                                </tr> :
                                <tr className={classes.tableRow}>
                                    <td className={classes.tableRow}>Associated Category</td>
                                    <td className={classes.tableRow}>{assessmentDetails && assessmentDetails?.assessment_details?.category_description}</td>
                                </tr>}



                            <tr className={classes.tableRow}>
                                <td className={classes.tableRow}>Mode of Assessment</td>
                                <td className={classes.tableRow}>Online</td>

                            </tr>

                            <tr className={classes.tableRow}>
                                <td className={classes.tableRow}>Difficulty Level</td>
                                <td className={classes.tableRow}>{assessmentDetails && assessmentDetails?.assessment_details?.complexity_level}</td>

                            </tr>
                            <tr className={classes.tableRow}>
                                <td className={classes.tableRow}>Certification</td>
                                <td className={classes.tableRow}>{assessmentDetails && assessmentDetails?.assessment_details?.has_certification !=null || assessmentDetails?.assessment_details?.has_certification === false ? 'No':'Yes'}</td>

                            </tr>
                        </tbody>
                    </table>

                </Box>

                <Box xs={12} sm={12} md={2} sx={{
                    padding: '15px !important', boxShahighlightsdow: 3, borderRadius: '10px', flex: 1,
                    border: '1px solid #dfdede', marginTop: 4, backgroundColor: ' #ebe7ff'
                }}>
                    <Typography variant="h6" fontWeight="bold" style={{ marginBottom: '15px' }}>Highlights</Typography>
                    <ul className="highlights" style={{ paddingLeft: '20px' }}>
                        {parseArray && parseArray?.length && parseArray.map((data) => (
                            <li><Typography variant="body2" color="textSecondary">{data?.text}</Typography></li>
                        ))}

                    </ul>
                </Box>
            </Box>

        {assessments && assessments?.length > 0 &&
            <Card sx={{
                mx: "auto", boxShadow: 3, borderRadius: '10px', marginBottom: '10px',
                border: '1px solid #dfdede', marginTop: 4, padding: "15px"
            }}>
                <Typography variant="h6" fontWeight="bold" style={{ marginBottom: '15px' }}>Related Assessments</Typography>

                <Swiper
                    spaceBetween={10}
                    slidesPerView={4}
                    breakpoints={{
                        1200: {
                            slidesPerView: 4,
                        },
                        1000: {
                            slidesPerView: 4,
                        },
                        600: {
                            slidesPerView: 3,
                        },
                        450: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                    navigation={assessments?.length > 4}
                    pagination={{
                        el: assessments.length > 4 ? `.${classes.swiperPagination}` : "",
                        clickable: assessments.length > 4,
                        bulletClass: 'custom-bullet',
                        bulletActiveClass: 'custom-bullet-active'
                    }}
                    keyboard
                    className="mySwiper"
                >
                    {assessments && assessments?.length > 0 && assessments.map((assessment) => (
                        <SwiperSlide key={assessment.id} >
                            <Box item xs={12} key={assessment.id}>
                                <Card sx={{ padding: 0, border: '1px solid #e7e7e7 !important' }}>
                                    <img
                                        style={{
                                            borderRadius: '15px',
                                            height: '160px',
                                            width: '100%',
                                            objectFit: 'cover',
                                            minHeight: '160px',
                                            maxHeight: '160px',
                                            borderTopRightRadius: '0',
                                            borderTopLeftRadius: '0'
                                         
                                        }}
                                        src={assessment.image_name}
                                        alt="Related Assessment"
                                        width="100%"
                                    />
                                    <CardContent style={{ padding: '10px 15px 10px', display: 'flex', flexDirection: 'column', height: '146px' }}>
                                        <Typography className='titleAssessment' style={{ flex: "4", fontWeight: '600', marginBottom: '5px', lineHeight: '1.1' }} variant="h6">
                                            {assessment.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{ lineHeight: '1.2', color: '#a3a0a0' }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessment.short_description) }}
                                        />
                                        <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '8px' }}>

                                           


                                            {assessmentId !== assessment?.id && <Button
                                                onClick={() => handleSwitchAssessment(assessment.id)}
                                                variant="contained"
                                                sx={{
                                                    background: '#00B673',
                                                    borderRadius: '4px', lineHeight: '1.1',
                                                    color: '#fff', marginRight: '8px', float: 'right',
                                                }}>
                                                View More
                                            </Button>}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </Card>}
        </Box>


    );

};

export default AssessmentCourseDetails;

const useStyles = makeStyles((theme) => ({
    badgeShower: {
        background: '#ddd',
        padding: '4px 8px',
        borderRadius: '5px',
        borderCollapse: 'collapse',
        marginRight: '15px',
        display: 'inline-flex'
    },
    tableRow: {
        border: '1px solid #ddd',
        padding: '10px',
        textAlign: 'left',
        borderCollapse: 'collapse',
        textTransform: 'capitalize'
    },
    assessmentContainer: {
        width: '100%',
        maxWidth: '600px', /* Adjust as needed */
        background: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
    },
}));