import React, { useState, useEffect, useMemo } from 'react';
import { Grid, TextField, Select, MenuItem, FormHelperText, Link, FormControl, Button, LinearProgress, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import InfoIcon from '@mui/icons-material/Info';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import loginServices from '../../services/loginServices';
import preSignedServices from '../../services/preSignedServices';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import adminServices from '../../services/adminServices';
import SnackBar from '../../components/snackbar/snackbar';
import ResetPasswordModal from './ResetPasswordModal';
import BasicPaper from '../../components/cards/BasicPaper';
import { loginSuccess } from '../../store/reducer';
import PhoneNumber from '../../components/PhoneNumber/Index';
import '../../components/PhoneNumber/styles.css';
import ResponsiveDrawer from '../users/course/profilesidebar';
import { getCategoryDetails } from '../../Redux/Action';



const Input = styled('input')({
  display: 'none',
});
const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();



  const userInfo = useSelector((state) => state.userInfo);
  const [userData, setUserData] = useState('');
  
  
  const firstName = userData.first_name;
  const lastName = userData.last_name;
  const initials = `${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`.toUpperCase();

  const id = userInfo.id
  const [editField, setEditField] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isClickedButton, setIsClickedButton] = useState(false);
  const [countries, setCountries] = useState([]);
  const allcourseDetails = useSelector((state) => state);
  
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = React.useState(0);
  const [detail, setDetail] = useState('Personal');
  const [alldata, setAlldata] = React.useState('');
  const [phone, setPhone] = React.useState(userData?.phone ? userData?.phone : '');
  const [genderDetails, setGenderDetails] = React.useState([]);
  const [roleDetails, setRoleDetails] = React.useState([]);
  const [workDetails, setWorkDetails] = React.useState([]);
  const [educationDetails, setEducationDetails] = React.useState([]);
  const [aboutDetails, setAboutDetails] = React.useState([]);
  const [jobDetails, setJobDetails] = React.useState([]);
  const [categoryDetails, setCategoryDetails] = React.useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [carrierDetails, setCarrierDetails] = React.useState([]);
  const [avatarView, setAvatarView] = React.useState('');
  const [showOthersInput, setShowOthersInput] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);  
  const [openResetModel, setOpenResetModel] = useState(false);
  const closeResetModel = () => setOpenResetModel(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [UpdateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState({ firstNameError: '', lastNameError: '' })



  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };



  const getProfile = async () => {
    const res = await adminServices.getUserProfileDetails(id);
    setUserData(res.data[0])
  }
  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    allcourseDetails?.CategoryDetails?.settings?.forEach(item => {
      switch (item.config_name) {

        case 'gender':
          setGenderDetails(item.data)
          break;
        case 'current_role':
          setRoleDetails(item.data);
          break;
        case 'work_experience':
          setWorkDetails(item.data);
          break;
        case 'education':
          setEducationDetails(item.data);
          break;
        case 'job':
          setJobDetails(item.data);
          break;
        case 'hear_about_us':
          setAboutDetails(item.data);
          break;
        case 'career_goal':
          setCarrierDetails(item.data);
          break;

        default:
          break;
      }
    });
  }, [allcourseDetails])

  const handleChangePhone = (e) => {
    setPhone(e)
  }

  const fetchCountries = async (key) => {
    try {
      const response = await adminServices.getLookUpData(key);

      if (response.ok) {
        setCountries(response.data.country);

      } else {
        console.error('Failed to fetch countries');
      }
    } catch (error) {
      console.error('Error fetching countries', error);
    }
  };

  useEffect(() => {
    const key = 'country'
    fetchCountries(key);
  }, []);

  const getLoginUserDetails = async () => {
    const response3 = await loginServices.getUserInfo();
    if (response3.ok) {

      dispatch(loginSuccess(response3.data));
    }
    console.log(response3, 'response3');
  };

  useEffect(() => {
    setIsClickedButton(false);
  }, [editField]);

  const handleSubmitProfileValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('profileImg', values.profileImg);
    formData.append('country', values.country);

    try {
      const response = await adminServices.profileUpdate(formData);
      if (response.ok) {
        if (response.data && response.data.preSignedUrl) {
          await preSignedServices.preSignedUrlUpload(response.data.preSignedUrl, values.profileImg)
        }
        await getLoginUserDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setEditField(false);
        setLoading(false);
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const [initial, setInitial] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    education: "",
    careerGoal: "",
    currentRole: "",
    job: "",
    referral: "",
    workExperience: "",
    Intrest: ""
  })

  useEffect(() => {
    getDetails()
    dispatch(getCategoryDetails())
}, [])

const getDetails = async () => {
    const res = await adminServices.getBasicDetails();
    
    
    if (res.ok) {
        const courseHashTags = res.data.courseHashTags; 
        const uniqueHashTags = Array.from(new Set(courseHashTags.map(item => item.description)))
          .map(description => {
            return courseHashTags.find(item => item.description === description);
          });
        setCategoryDetails(uniqueHashTags);
        
    }
};
  useEffect(() => {
    setInitial({
      firstName: userData.first_name || '',
      lastName: userData.last_name || '',
      email: userData.email || '',
      dateOfBirth: userData.birth_date || '',
      gender: userData.additional_info?.gender || null,
      education: userData.additional_info?.education || null,
      careerGoal: userData.additional_info?.careerGoal || null,
      currentRole: userData.additional_info?.currentRole || null,
      job: userData.additional_info?.job || null,
      referral: userData.additional_info?.referral || null,
      workExperience: userData.additional_info?.workExperience || null,
      Intrest: userData.additional_info?.interests || null,
    })
    setSelectedTags(userData.additional_info?.interests ? userData.additional_info?.interests : []);
    setSelectedDate(userData.birth_date || '');
    setPhone(userData?.phone || '');
    const filterNewInterests = (interests, courseHashTags) => {
      return interests.filter(interest => {
        return !courseHashTags.some(tag => tag.description === interest || tag.code === interest);
      });
    };

    if(userInfo && userInfo?.role === 'USER_DTC'){
      setCategoryDetails([...allcourseDetails?.CategoryDetails?.courseHashTags,...filterNewInterests(userData.additional_info?.interests || [], allcourseDetails?.CategoryDetails?.courseHashTags || [])]);
    }
    else{
      setCategoryDetails([...categoryDetails,...filterNewInterests(userData.additional_info?.interests || [], categoryDetails || [])]);

    }


  }, [userData])



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const handleTagClick = (tagCode) => {
    if (tagCode === 'Others') {
      setShowOthersInput(true);
    } else {
      const isSelected = selectedTags.includes(tagCode);
      if (!isSelected && selectedTags?.length === 5) {
        setSnackbarTitle(' Only 5 items can be selected')
        setSnackbar(true)
        return
      }
      const updatedTags = isSelected
        ? selectedTags.filter(tag => tag !== tagCode)
        : [...selectedTags, tagCode];
      setSelectedTags(updatedTags);
    }
  };

  useMemo(() => {
    const initialData = [initial.firstName, initial.lastName, initial.email, phone, selectedDate, initial.gender, initial.education, initial.job, initial.currentRole, initial.careerGoal, initial.referral, initial.workExperience];
    const filledFieldsCount = initialData.filter(Boolean).length;
    const progressIncrement = filledFieldsCount * 4.16;

    let progress = 25 + progressIncrement;

    if (selectedTags.length > 0) {
      progress += 25;
    }
    setProgress(Math.min(progress, 100));
  }, [initial]);


  const handleSave = (data, phone, selectedDate) => {

    const validateNames = () => {
      if (!data.firstName && !data.lastName) {
        setError({ firstNameError: 'First name is required', lastNameError: 'Last name is required' })
      }
      else if (!data.firstName) {
        setError({ firstNameError: 'First name is required' })
      }
      else if (!data.lastName) {
        setError({ lastNameError: 'Last name is required' })
      }
      else {
        return true
      }

    }

    const validation = validateNames()
    if (validation === true) {
      setAlldata(data);
      const requiredFields = [data.firstName, data.lastName, data.email, phone, selectedDate, data.gender, data.education, data.job, data.currentRole, data.careerGoal, data.referral, data.workExperience];
      const filledFieldsCount = requiredFields.filter(Boolean).length;
      let progressIncrement = filledFieldsCount * 4.16;
      if (selectedTags.length > 0) {
        progressIncrement += 25;
      }
      setProgress(Math.min(100, 25 + progressIncrement));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };


  const handleSaveOld = (data, phone, selectedDate, userData) => {

    setAlldata(data);
    const requiredFields = [data.firstName, data.lastName, data.email, phone, selectedDate, data.gender, data.education, data.job, data.currentRole, data.careerGoal, data.referral, data.workExperience];
    const filledFieldsCount = requiredFields.filter(Boolean).length;
    let progressIncrement = filledFieldsCount * 4.16;
    if (selectedTags.length > 0) {
      progressIncrement += 25;
    }
    setProgress(Math.min(100, 25 + progressIncrement));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };


  const handleUpdateDetails = async (values, phone, selectedTags, selectedDate) => {
    if (selectedTags?.length > 0) {
      setProgress((prev) => (prev <= 75 ? prev + 25 : prev));
    }
    setUpdateLoading(true)
    const dateOfBirth = selectedDate ? new Date(selectedDate).toISOString().split('T')[0] : null;

    setLoading(true);
    const updateData = {
      careerGoal: values.careerGoal,
      currentRole: values.currentRole,
      dateOfBirth,
      education: values.education,
      email: values.email.toLowerCase(),
      firstName: values.firstName,
      gender: values.gender,
      job: values.job,
      lastName: values.lastName,
      phoneNumber: phone,
      referral: values.referral,
      workExperience: values.workExperience,
      Intrest: selectedTags,
      progressbar: progress
    }

    const result = await adminServices.Updateusercreation(detail.userDetails, updateData);

    if (result.ok) {
      setSnackbarTitle("Account Updated Successfully!");
      setSnackbar(true);
      setTimeout(() => {
        navigate("/auth/subscribe");
      }, 800);
      setLoading(false);
    }
  }


  const handlePushDetails = () => {
    if (newTag && !selectedTags.includes(newTag)) {
      const isTagAlreadyInCategoryDetails = categoryDetails.some(item => item.description === newTag);

      if (isTagAlreadyInCategoryDetails) {
        setTimeout(() => { }, 1000)
        setSnackbarTitle(`The tag "${newTag}" is already a default name and cannot be added again.`);
        setSnackbar(true);
        return;
      }
      if (selectedTags?.length === 5) {
        setSnackbarTitle(' Only 5 items can be selected')
        setSnackbar(true)
      } else {
        setSelectedTags([...selectedTags, newTag]);
        setCategoryDetails([...categoryDetails, newTag]);
        setNewTag('');
        setShowOthersInput(false)
      }
    }
  }



  const handleCancel = () => {
    setNewTag('');
    setShowOthersInput(false);
  };

  const handleDetailChange = (newDetail) => {
    setDetail(newDetail);
  };

  const uniqueTagCodes = [
    ...new Set(categoryDetails.map(tag => typeof tag === 'object' ? tag.description : tag))
  ];
  return (
    <Grid container spacing={2} style={{ marginRight: '30px' }}>
      <Grid item xs={12} sm={4} md={3}>
        <ResponsiveDrawer
          onDetailChange={handleDetailChange}
          activeStep={activeStep} />
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <Page title={'Profile'}>

          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '5px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              justifyContent: 'space-between',
              marginLeft: '-265px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '5px',
                width: '120%',
                flexGrow: 1,
              }}
            >
              <Avatar
                alt="User Avatar"
                style={{ marginRight: '10px', backgroundColor: 'black', width: '50px', height: '50px', fontSize: '1.5rem' }}
              >
                {initials || '?'}
              </Avatar>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: '40px' }}>
                  Hi {''}{firstName?.charAt(0).toUpperCase() + firstName?.slice(1)} {lastName?.charAt(0).toUpperCase() + lastName?.slice(1)}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', ml: '40px' }}>
                  Welcome to your personalized learning.
                </Typography>
              </Box>
            </Box>
            <Box style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'start', marginRight: '8px' }}>
              <Typography variant="body2" style={{ color: '#00B818', fontWeight: 'bold', marginBottom: '2px'}}>
              {progress <98?'Complete your profile':'Profile Completed'}
              </Typography>

              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{ width: '160px', height: '6px', borderRadius: '5px', marginRight: '4px' }}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#00B818',
                    },
                  }}
                />
                <Typography variant="body2" style={{ color: '#00B818', fontWeight: 'bold' }}>
                  {Math.round(progress)}%
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: 4 }}>


            <Formik
              initialValues={initial}
              enableReinitialize

            >
              {({ handleChange, values, errors, touched }) => (
                <Form className={classes.formContainer} >

                  <Grid container  >
                    {activeStep === 0 && (
                      <Grid item xs={12} md={12}>
                        <Typography variant="h5" style={{ color: '#e32f29', fontWeight: 'b', marginBottom: '16px' }}>Personal info</Typography>

                        <Box sx={{ marginLeft: '3%' }}>
                          <Grid container spacing={4} justifyContent="flex-end">
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                First Name
                              </Typography>
                              <TextField
                                fullWidth
                                name="firstName"
                                placeholder="First Name"
                                value={values.firstName}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (error.firstNameError) {
                                    setError(prevError => ({ ...prevError, firstNameError: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  if (!values.firstName) {
                                    setError(prevError => ({ ...prevError, firstNameError: 'First name is required' }));
                                  }
                                }}
                                error={Boolean(error.firstNameError)}
                                helperText={error.firstNameError}
                                InputLabelProps={{
                                  sx: {
                                    marginBottom: '8px',
                                  },
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Last Name
                              </Typography>
                              <TextField
                                fullWidth
                                placeholder="Last Name"
                                name="lastName"
                                value={values.lastName}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (error.lastNameError) {
                                    // Clear error when user starts typing
                                    setError(prevError => ({ ...prevError, lastNameError: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  if (!values.lastName) {
                                    // Set error if field is empty on blur
                                    setError(prevError => ({ ...prevError, lastNameError: 'Last name is required' }));
                                  }
                                }}
                                error={Boolean(error.lastNameError)}
                                helperText={error.lastNameError}
                                InputLabelProps={{
                                  sx: {
                                    marginBottom: '8px',
                                  },
                                }}
                              />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Email
                              </Typography>
                              <TextField
                                fullWidth
                                placeholder='Email'
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                disabled
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Phone Number
                              </Typography>
                              <Box sx={{ width: '100%' }}>
                                <PhoneNumber
                                  fullWidth
                                  country={'us'}
                                  name="phoneNumber"
                                  specialLabel={''}
                                  value={phone}
                                  onChange={handleChangePhone}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Date of birth
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker fullWidth
                                  // label="Select Date"
                                  value={selectedDate}
                                  maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                  onChange={handleDateChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="MM/DD/YYYY"
                                      inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                      }}
                                      fullWidth
                                      sx={{
                                        'input::placeholder': {
                                          textTransform: 'uppercase',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: 'black',
                                          },
                                          '&:hover fieldset': {
                                            borderColor: 'black',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>

                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  inputFormat="MM/DD/YYYY"
                                  value={selectedDate}
                                  maxDate={new Date()}
                                  onChange={handleDateChange}
                                  renderInput={(params) => <TextField {...params} fullWidth

                                  />}
                                />
                              </LocalizationProvider> */}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth>
                                <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                  Gender
                                </Typography>
                                <Select
                                  name="gender"
                                  displayEmpty
                                  value={values.gender}
                                  onChange={handleChange}
                                  error={touched.gender && Boolean(errors.gender)}
                                >
                                  {genderDetails && genderDetails?.length > 0 && genderDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>

                          <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item>
                              <Button onClick={() => handleSaveOld(values, phone, selectedDate, userData)} variant="contained" color="primary" type="submit">
                                Save and Continue
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )}


                    {activeStep === 1 && (
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="h5"
                          style={{ color: '#e32f29', fontWeight: 'bold', marginBottom: '20px' }}
                        >
                          Professional Info
                        </Typography>
                        <Box sx={{ marginLeft: '3%' }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Education
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="education"
                                  value={values.education || ""}
                                  onChange={handleChange}
                                  error={touched.education && Boolean(errors.education)}
                                  displayEmpty
                                >

                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Education
                                  </MenuItem>
                                  {educationDetails && educationDetails?.length > 0 && educationDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}

                                </Select>
                                <FormHelperText>{touched.education && errors.education}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Job
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="job"
                                  value={values.job || ""}
                                  onChange={handleChange}
                                  error={touched.job && Boolean(errors.job)}
                                  displayEmpty
                                >
                                  {/* <MenuItem value="" disabled>
                                    <em>Job</em>

                                  </MenuItem> */}
                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Job
                                  </MenuItem>
                                  {jobDetails && jobDetails?.length > 0 && jobDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>{touched.job && errors.job}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Current Role
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="currentRole"
                                  value={values.currentRole || ""}
                                  onChange={handleChange}
                                  error={touched.currentRole && Boolean(errors.currentRole)}
                                  displayEmpty
                                >
                                  {/* <MenuItem value="" disabled>
                                    <em> Current Role</em>
                                  </MenuItem> */}
                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Current Role
                                  </MenuItem>
                                  {roleDetails && roleDetails?.length > 0 && roleDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}

                                </Select>
                                <FormHelperText>{touched.currentRole && errors.currentRole}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Work Experience
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="workExperience"
                                  value={values.workExperience || ""}
                                  onChange={handleChange}
                                  error={touched.workExperience && Boolean(errors.workExperience)}
                                  displayEmpty
                                >
                                  {/* <MenuItem value="" disabled>
                                    <em>Work Experience</em>
                                  </MenuItem> */}
                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Work Experience
                                  </MenuItem>
                                  {workDetails && workDetails?.length > 0 && workDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>

                                  ))}

                                </Select>
                                <FormHelperText>{touched.workExperience && errors.workExperience}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                Career Goal
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="careerGoal"
                                  value={values.careerGoal || ""}
                                  onChange={handleChange}
                                  error={touched.careerGoal && Boolean(errors.careerGoal)}
                                  displayEmpty
                                >
                                  {/* <MenuItem value="" disabled>
                                    <em>Career Goal</em>
                                  </MenuItem> */}
                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Career Goal
                                  </MenuItem>
                                  {carrierDetails && carrierDetails?.length > 0 && carrierDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>{touched.careerGoal && errors.careerGoal}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                How Did You Hear About Us? </Typography>
                              <FormControl fullWidth>
                                <Select
                                  name="referral"
                                  value={values.referral || ""}
                                  onChange={handleChange}
                                  error={touched.referral && Boolean(errors.referral)}
                                  displayEmpty
                                >
                                  {/* <MenuItem value="" disabled>
                                    <em>Referral Source</em>
                                  </MenuItem> */}
                                  <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                    Referral Source
                                  </MenuItem>
                                  {aboutDetails && aboutDetails?.length > 0 && aboutDetails.map((data) => (
                                    <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>{touched.referral && errors.referral}</FormHelperText>
                              </FormControl>
                            </Grid>



                          </Grid>
                          <Grid container spacing={2} style={{ marginTop: '20px' }} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                              <Button onClick={() => handleBack()} variant="outlined" className={classes.backbutton}>
                                Back
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button onClick={() => handleSave(values, phone, selectedDate)} variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
                                Save and Continue
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                    {activeStep === 2 &&
                      <Grid item xs={12} md={12}>
                        <Typography variant="h5" style={{ color: '#e32f29', fontWeight: 'b' }}>Other Details</Typography>

                        <Box sx={{ marginLeft: '2%', marginTop: '30px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 1,
                            }}
                          >
                            {uniqueTagCodes.map((tagCode, index) => {
                              const tag = categoryDetails.find(t => (typeof t === 'object' ? t.description : t) === tagCode);


                              return (
                                <Box
                                  key={index}
                                  className={`${classes.card} ${selectedTags.includes(tagCode) ? classes.selected : ''}`}
                                  onClick={() => handleTagClick(tagCode)}
                                >
                                  <Typography className={classes.tagText}>
                                    {typeof tag === 'string' ? (
                                      <>
                                        {tag.length > 12 ? (
                                          <Tooltip title={tag}>
                                            <span>{tag.slice(0, 12)}...</span>
                                          </Tooltip>
                                        ) : (
                                          tag
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {tag.description && tag.description.length > 12 ? (
                                          <Tooltip title={tag.description}>
                                            <span>{tag.description.slice(0, 12)}...</span>
                                          </Tooltip>
                                        ) : (
                                          tag.description
                                        )}
                                      </>
                                    )}
                                  </Typography>

                                </Box>
                              );
                            })}

                            <Box
                              key="others"
                              className={classes.card}
                              onClick={() => handleTagClick('Others')}
                            >
                              <Typography className={classes.tagText} >
                                Others +
                              </Typography>
                            </Box>
                          </Box>
                          {showOthersInput && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, margin: 2 }}>
                              <TextField
                                variant="outlined"
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                placeholder="Enter your interest"
                              />
                              <IconButton onClick={handleCancel} color="orange">
                                <CloseIcon />
                              </IconButton>
                              <Button onClick={handlePushDetails} variant="contained">
                                Submit
                              </Button>

                            </Box>
                          )}
                          <Grid container spacing={2} style={{ marginTop: '20px' }} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                              <Button onClick={() => handleBack()} variant="outlined" className={classes.backbutton}>
                                Back
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button onClick={() => handleUpdateDetails(values, phone, selectedTags, selectedDate)} variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
                                {UpdateLoading ? <CircularProgress size={24} color="inherit" /> : "Save and Continue"}
                              </Button>
                            </Grid>

                          </Grid>
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Form>
              )}
            </Formik>

          </Box>
          <SnackBar autoHideDuration={5000} open={snackbar} snackbarTitle={snackbarTitle} close={() => setSnackbar(false)} />





          <ResetPasswordModal openModal={openResetModel} closeModel={() => setOpenResetModel(false)} />

          <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
        </Page>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  girdContainer: {
    display: 'flex',
  },
  logo: {
    border: '1px solid lightgrey',
    borderRadius: '10px',
    marginRight: '3rem',
    padding: '1rem',
  },

  name: {
    fontSize: '14px',
    color: '#0C0A09',
    fontWeight: '600',
  },

  changePassword: {
    color: '#FE7000',
    letterSpacing: '0px;',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    },
  },
  backgroundStyle: {
    boxShadow: '0px 3px 30px #0000000A;',
    border: '1px solid #ECECEC',
    borderRadius: '10px;',
    background: '#FFFFFF',
    padding: '1rem',
  },

  btnStyle: {
    color: '#FE7000;',
    fontSize: '1rem',
    fontWeight: '600',
    outline: '1px solid #FE7000',
  },

  fontSizeiconname: {
    letterSpacing: '0.43px;',
    color: '#6D6969;',
    marginLeft: '1rem;',
    fontSize: '0.8rem',
  },
  contanerBackground: {
    backgroundColor: '#FAFAFA',
    height: '100vh',
  },

  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: '400',
  },
  typoFontWeightone: {
    fontSize: '1rem;',
  },
  input: {
    display: 'none',
  },
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
    marginBottom: 16,
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  formContainer: {
    padding: '20px',
    // border: '1px solid #E0E0E0',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  stepIndicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0',
  },
  step: {
    border: '2px solid #ccc',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    fontSize: '18px',
    color: '#333',
  },
  activeStep: {
    borderColor: '#1976D2',
    backgroundColor: '#1976D2',
    color: '#fff',
  },
  stepLabel: {
    textAlign: 'center',
    margin: 0,
    fontWeight: 600,
    fontSize: '14px',
    color: '#333',
  },
  formTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  skipButton: {
    borderColor: '#1976D2',
    color: '#1976D2',
  },
  card: {
    borderRadius: '10px',
    height: '30px',
    cursor: 'pointer',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '20px',
    paddingRight: '20px',
    padding: '8px',
    textAlign: 'left',
    margin: '10px',
    transition: 'background-color 0.3s, box-shadow 0.3s',
    width: '150px',
    // height: '35px'
  },
  tagText: {

    color: 'black',
    fontSize: '12px',
    fontWeight: 'bolder',

  },
  selected: {
    backgroundColor: '#ffb36b',
    color: 'white',
  },
  createButton: {
    borderRadius: '5px',
    marginTop: '20px',
    background: "#D52F37",
    fontSize: "12px",
    width: "200px"
  },
}));

export default Account;
