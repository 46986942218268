import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { Grid, Box, Button, Typography } from "@mui/material";
import "./generalAssessmentStyle.css"
 
 
const QuestionView = (props) => {
  const questions = Array.from({ length: props?.question?.length }, (_, i) => i + 1)
 
 
 
  const handleBack = () => {
    props.handleBack()
  }
  const containerStyle1 = {
    textAlign: "center",
    background: "#fff",
    padding: "8px 10px !important",
    minHeight: "385px",
    width: "100%",
    minWidth: '250px',
    borderRadius: "10px",
    // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
    // boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
  };
  const containerStyle = {
    textAlign: "center",
    background: "#fff",
    padding: "12px 15px !important",
    // minHeight: "255px",
    width: "100%",
    // minWidth: '500px',
    borderRadius: "10px",
    // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  };
 
  const assessmentStyle = {
    marginTop: "10px",
    marginLeft: "14px"
  };
 
 
 
  const buttonStyle = {
    // marginLeft:"200px",
    marginLeft: 'auto',
    display: 'block',
    backgroundColor: "#fff",
    color: "black",
    border: "1px solid black",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    padding: '5px 10px',
    marginTop: "20px"
  };
 
 
 
  return (
    <>
      {props?.questionComponent ? <Box sx={containerStyle} style={{
        display: 'flex',
        flexDirection: 'column',  
        justifyContent: 'space-between', padding: '15px 12px !important'
      }}>
        <Grid container spacing={2} justifyContent="center" style={{ minHeight: '175px' }}>
 
          {
            props.question !== undefined && props.question.map((item, index) => {
              let backgroundColors = index === props?.currentIndex && props?.spaceview === false ? "#769FFE" : "#faf2f4";
              let textColor = 'white';

              if (item?.question?.response_recorded === 'correct') {
                backgroundColors = "#44D485";
                textColor = 'white';
              }
              if (item.question.response_recorded === 'incorrect') {
                backgroundColors = "#F26161";
                textColor = 'white';
              }
              if (item.question.response_recorded === 'Not answered') {
                backgroundColors = "#769FFE";
                textColor = 'white';
              }
 
              return (
                <Grid
                  item
                  xs={1.2}
                  sm={1.2}
                  md={1.2}
                  key={index}
                  style={{ width: '80px' }}
                >
                  <Box className="numberCard"
                    sx={{
                      backgroundColor: backgroundColors,
                      color: textColor,
                      // color: "red", 
                      width: "40px",
                      height: "40px",
                      borderTopLeftRadius: "12px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                    }}
                  >
                    {index + 1}  {/* Assuming 'number' is the property you want to display */}
                  </Box>
                </Grid>
              );
            })
          }
 
        </Grid>
        <div style={{ marginTop: '15px', display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#00C365",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <Typography style={{ lineHeight: 1.1 }} variant="body1">Correct Answer</Typography>
          </div>
 
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#F26161",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <Typography style={{ lineHeight: 1.1 }} variant="body1">Incorrect Answer</Typography>
          </div>
 
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#437BFC",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <Typography style={{ lineHeight: 1.1 }} variant="body1">Not Answered</Typography>
          </div>
        </div>
      </Box>
 
        :
        <Box sx={containerStyle1}>
          <Typography variant="h6" marginBottom={'20px'}>Total Questions</Typography>
          <Box style={{display: 'flex', flexDirection: 'column', justifyContent: "space-between", height: '90%'}}>
          <Grid container spacing={2} justifyContent="center" style={{ minHeight: '175px' }}>
 
            {questions.map((number, index) => {
              const question = props.question[index];
              const { answerCorrect } = question;

              // let backgroundColor = index === props?.currentIndex && props?.spaceview === false ? "#437BFC" : "#faf2f4";

              let backgroundColor = index <= (props?.currentIndex) ? "#437BFC" : "#faf2f4";


              let color = 'black'
              if (answerCorrect === true) {
                backgroundColor = "#437BFC";
                color = 'black'
              }
              if (answerCorrect === false) {
                backgroundColor = "#437BFC";
                color = 'black'
 
              }
 
 
 
              return (
                <Grid style={{ width: '350px' }}
                  item
                  xs={2.2}
                  sm={2.2}
                  md={2.2}
                  key={number}
                >
                  <Box
                    sx={{
                      backgroundColor: `${backgroundColor}`,
                      color: `${color}`,                
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40px",
                      height: "40px",
                      borderTopLeftRadius: "12px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                 
                    }}
                  >
                    {number}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
         
          </Box>
        </Box>}
    </>
  );
};
 
export default QuestionView;