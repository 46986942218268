import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ videoUrl, onClickCallBack, videoLoadCallback, activeStep }) => {
  const playerRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [activeScreen, setActiveScreen] = useState('');
  const currentVideoRef = useRef(null);

  useEffect(() => {
    setActiveScreen(activeStep?.id || '');
  }, [videoUrl]);

  useEffect(() => {
    if (!videoUrl) return;

    const loadGriffithPlayer = () => {
      const Griffith = window.Griffith;
      if (!Griffith) {
        console.error('Griffith library could not be loaded');
        setShowError(true);
        videoLoadCallback(false);
        localStorage.setItem("play", false);
        return;
      }

      try {
        const target = playerRef.current;
        if (!target) {
          console.error('Player target element not found');
          setShowError(true);
          videoLoadCallback(false);
          localStorage.setItem("play", false);
          return;
        }

        const sources = {
          hd: { play_url: videoUrl },
        };

        const newPlayer = Griffith.createPlayer(target);
        newPlayer.render({
          sources,
          autoplay: true,
        });

        const videoElement = target.querySelector('video');
        if (videoElement) {
          videoElement.muted = isMuted;

          videoElement.addEventListener('canplay', () => {
            setIsVideoLoaded(true);
            videoLoadCallback(true);
            localStorage.setItem("play", true);
            currentVideoRef.current = videoUrl;
          });

          videoElement.addEventListener('play', () => {
            if (!isMuted) {
              videoElement.muted = false;
            }
          });

          videoElement.addEventListener('pause', () => {
            videoElement.muted = true;
          });

          // videoElement.addEventListener('ended', onVideoEnd);
          videoElement.addEventListener('ended', () => {
            if (currentVideoRef.current === videoUrl) {
              onClickCallBack(videoUrl);
            }
          });

          videoElement.addEventListener('error', () => {
            console.error('Error while loading video');
            setShowError(true);
            videoLoadCallback(false);
            localStorage.setItem("play", false);
          });
        }
      } catch (error) {
        console.error('Error initializing Griffith player:', error);
        setShowError(true);
        videoLoadCallback(false);
        localStorage.setItem("play", false);
      }
    };

    if (!window.Griffith) {
      const script = document.createElement('script');
      script.src = `https://unpkg.com/griffith-standalone/dist/index.umd.min.js?${Date.now()}`;
      script.async = true;
      script.onload = loadGriffithPlayer;
      script.onerror = (e) => {
        console.error('Script loading error:', e);
        setShowError(true);
        videoLoadCallback(false);
        localStorage.setItem("play", false);
      };
      document.body.appendChild(script);
    } else {
      loadGriffithPlayer();
    }

    return () => {
      // Cleanup function to remove the script if the component unmounts
      const script = document.querySelector(`script[src*="griffith-standalone"]`);
      if (script) {
        script.remove();
      }
    };
  }, [videoUrl, isMuted, videoLoadCallback]);

  useLayoutEffect(() => {
    const videoElement = playerRef.current?.querySelector('video');
    if (videoElement) {
      videoElement.currentTime = 0;
    }
  }, [videoUrl]);

  useEffect(() => {
    if (!isVideoLoaded) {
      videoLoadCallback(false);
      localStorage.setItem("play", false);
    }
  }, [isVideoLoaded]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isVideoLoaded && event.key === 'Enter') {
        event.preventDefault();
        console.warn('Enter key pressed, but action is disabled while loading the video.');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVideoLoaded]);

  // const onVideoEnd = () => {
  //   if (currentVideoRef.current === videoUrl) {
  //     onClickCallBack();
  //   }
  // };

  return (
    <div style={{ position: 'relative', width: '100%', height: '75%' }}>
      {showError ? (
        <div style={{ position: 'absolute', paddingTop: '10%', paddingLeft: '10%', textAlign: 'center' }}>
          <div>Oops! Something went wrong while trying to play the video. Please try again later or switch browser.</div>
        </div>
      ) : (
        <div ref={playerRef} style={{ position: 'relative', width: '100%', height: '500px' }} />
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClickCallBack: PropTypes.func.isRequired,
  videoLoadCallback: PropTypes.func.isRequired,
};

export default VideoPlayer;