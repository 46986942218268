import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Button, LinearProgress, Paper, Breadcrumbs, Link, Tooltip, Card } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';

import { PieChart } from '@mui/x-charts/PieChart'
import Checkbox from '@mui/material/Checkbox';
import { green, red, blue, yellow, orange, purple } from "@mui/material/colors"; // Example for colors
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from "dompurify";
import { height } from '@mui/system';
// import { AlertCircle } from "lucide-react";
// import { motion } from "framer-motion";
import Page from '../../components/Page';
import QuestionList from './QuestionComponent';
// import ProgressBar from './GeneralAssessmentProgresBar';
import Rocket from '../../assets/iqImages/rocket.gif';
import IqStart from '../../assets/iqImages/iqStart.png';
import ImageView from '../../assets/Images/ProgressFrame.png'
import adminServices from '../../services/adminServices';
import LottieLoading from '../../components/LottieLoading';
import Snackbar from '../../components/snackbar/snackbar';
import './generalAssessmentStyle.css';
import Sessiontimeout from '../../assets/Images/Sessiontimeout.png'
import TestComplete from '../../assets/Images/TestComplete.png'
import chartComponent from './ChartComponent'
import {FromGeneralAssessmentView } from '../../store/reducer'


const GeneralAssessment = ({ assessmentId, comingFrom, fromIndex }) => {
  const userid = useSelector((state) => state?.userInfo && state?.userInfo?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get('id');
  // const name = queryParams.get('name');
  // console.log(assessmentId,"assessmentId");

  const id = assessmentId
  const [questions, setQuestions] = useState('');
  const [allDetails, setAllDetails] = useState('');
  const [questionComponent, setQuestionComponent] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textview, setText] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [spaceview, setSpaceView] = useState(true);
  const [seesionout, setSeesionout] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [CloseTime, setCloseTime] = useState('');
  const [allQuestions, setAllQuestions] = useState([]);
  const [myallQuestion, setMyallQuestion] = useState([]);
  const [progress, setProgress] = useState(0);
  const [Clicked, setClicked] = useState(false);
  const [ClickedTimeout, setClickedTimeout] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [attemptCount, setAttemptCount] = useState(1);
  const [attemptAssessment, setAttemptAssessment] = useState('');
  const [attempt, setAttempt] = useState(false);
  const [correctResponse, setCorrectResponse] = useState(0);
  const [inCorrectResponse, setInCorrectResponse] = useState(0);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [startingtime, setStartingTime] = useState('');
  const [answerCount, setAnswerCount] = useState(0);
  const [isSummery, SetIsSummery] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [time, setTime] = useState(0);
  const [ovearallTime, setOvearallTime] = useState(0);
  
  const [timeWith, setTimeWith] = useState(false);
  const [withoutimer, setwithoutimer] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [correctCount, setCorrectCount] = useState(0)
  const [incorrectCount, setIncorrectCount] = useState(0)

  const [correctCountnew, setCorrectCountnew] = useState(0)
  const [incorrectCountnew, setIncorrectCountnew] = useState(0)
  const [notAnsweredCount, setNotAnsweredCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [labels, setLables] = useState([])

  const [correctData, setcorrectData] = useState([])
  const [wrongData, setwrongData] = useState([])
  const [finalSubmit, setFinalSubmit] = useState(false);
  const sanitizedContent = DOMPurify.sanitize(questions?.length > 0 && questions[currentIndex]?.question_text);
  const [latestAttemptCount, setLatestAttemptCount] = useState('');
  



  const handleNavigateNewAssessment = () => {
    const data = {
      id:assessmentId,
      from:'GA'
  }
    navigate(`/auth/AssessmentCourseDetails`,{state:data})
  }
  
  const handleNavigateDetails = () => {    
    const data = {
      id:assessmentId,
      from:'GA'
  }
    navigate("/auth/AssessmentCourseDetails", { state: data });
}

  const getSummaryById = async () => {
    setIsLoading(true);
    const attempt = attemptAssessment || fromIndex

    const result = await adminServices.getSummaryById(userid, id, attempt);
    if (result.ok) {
      setSummaryData(result.data);
    }
    setIsLoading(false)

  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(FromGeneralAssessmentView(false));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const handlePopState = () => {
      dispatch(FromGeneralAssessmentView(false));
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (summaryData?.assessmentDetails !== undefined) {
      setTotalCount(summaryData?.assessmentDetails?.length)
      const correctCount = summaryData?.assessmentDetails?.filter((data) => data.question.response_recorded === "correct")?.length
      setCorrectCount(correctCount)
      const incorrectCount = summaryData?.assessmentDetails?.filter((data) => data.question.response_recorded === "incorrect")?.length
      setIncorrectCount(incorrectCount)
      const notAnsweredCount = summaryData?.assessmentDetails?.filter((data) => data.question.response_recorded === "Not answered")?.length
      setNotAnsweredCount(notAnsweredCount)



      const Apool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 1)
      const Bpool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 2)
      const Cpool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 3)
      const Dpool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 4)
      const Epool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 5)
      const Fpool = summaryData?.assessmentDetails?.filter((val) => val.question.multiplier === 6)
      const apoolcorrect = Apool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const apoolincorrect = Apool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length
      const bpoolcorrect = Bpool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const bpoolincorrect = Bpool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length
      const cpoolcorrect = Cpool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const cpoolincorrect = Cpool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length
      const dpoolcorrect = Dpool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const dpoolincorrect = Dpool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length
      const epoolcorrect = Epool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const epoolincorrect = Epool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length
      const fpoolcorrect = Fpool?.filter((data) => data?.question?.response_recorded === "correct")?.length
      const fpoolincorrect = Fpool?.filter((data) => data?.question?.response_recorded === "incorrect")?.length

      const seriesData = [
        { correct: apoolcorrect, incorrect: apoolincorrect },
        { correct: bpoolcorrect, incorrect: bpoolincorrect },
        { correct: cpoolcorrect, incorrect: cpoolincorrect },
        { correct: dpoolcorrect, incorrect: dpoolincorrect },
        { correct: epoolcorrect, incorrect: epoolincorrect },
        { correct: fpoolcorrect, incorrect: fpoolincorrect }
      ];


      const groupedData = {
        'Knowledge': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 1),
        'Analysis': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 2),
        'Comprehe.': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 3),
        'Synthesis': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 4),
        'Application': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 5),
        'Evaluation': summaryData?.assessmentDetails.filter((val) => val.question.multiplier === 6),
      };

      const xLabels = Object.keys(groupedData);


      const correctData = seriesData.map((d) => d.correct);
      const incorrectData = seriesData.map((d) => d.incorrect);
      setcorrectData(correctData)
      setwrongData(incorrectData)
      setLables(xLabels)
    }
  }, [summaryData?.assessmentDetails]);




  const handleSummary = () => {
    getSummaryById()
    SetIsSummery(true)
    dispatch(FromGeneralAssessmentView(false));
  }




  const handleBack = () => {
    setwithoutimer(false)

    localStorage.setItem('from', 'General Assessment');
    navigate('/auth/subscribe')
  }

  const handleBackLeave = () => {
    // if(attempt === false){
    //   const currentime = getCurrentTime()
    //   handleNextTimeout(currentime);
    // }
    setwithoutimer(false)

    localStorage.setItem('from', 'General Assessment');
    navigate('/auth/subscribe')
  }
  


  const formatTimeTimerHours = (seconds) => {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')} Minutes`;
  };

  const partIs = [
    `The TEST TIME for this test will be limited to "${formatTimeTimerHours(allDetails?.generalAssessment?.time_in_mins)}". The countdown timer will be displayed in the top corner towards your right. When the timer runs to ZERO, the assessment or test will end, after which you cannot reattempt or try again.`,
    `An "Attempt" is considered once you click the "Start Test" button. So, if you are viewing this test instruction screen, ONE attempt has already been deducted from your list of "10" for this assessment.`,
    `You will be ELIGIBLE FOR CERTIFICATION after scoring > 96% in this test/attempt. If you already have an assessment certification, you can score 100% in this assessment or test to unlock various offers & discounts on our platform.`,
    `Please choose one answer from the given options in the assessment. Click on the bubble in the questions to select your answer and click on the "Submit & Next" button to submit your answers and move to the next question.`,
    `You cannot skip any question, and you have to answer all the questions in the test. If the timer runs out, the question you have NOT ANSWERED will stay "WHITE" in color and you will not be rewarded with any marks for that.`,
    `The color code for the questions is given on the right side of the screen. This code applies to the current test screen ONLY and will change in the analysis screen.`

  ]



  // const handleKeyDown = (event) => {
  //   event.preventDefault();
  //   if (event.key === " ") {
  //     PostSpace();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);


  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (comingFrom && comingFrom === 'analysis') {
      getSummaryById()
    }
    setStartingTime(Date.now())
    getAssessmentDetails()
    getAssessmentDetailsCountById()
  }, [])


  useEffect(() => {

    let timer;
    let lastTimestamp = Date.now();
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        lastTimestamp = Date.now();
      } else if (document.visibilityState === "visible") {
        const now = Date.now();
        const elapsed = Math.floor((now - lastTimestamp) / 1000);
        setTime((prevTime) => Math.max(0, prevTime - elapsed));
      }
    };

    if (!spaceview) {
      if (isActive && time > 0) {
        timer = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);
      }

      if (time === 0 && timeWith && !ClickedTimeout) {
        setIsTimeout(true);
        localStorage.removeItem("assessmentData");
        setAllQuestions([]);
        if (!spaceview && finalSubmit===false) {
          handleNextNew()
        }
        clearInterval(timer);
      } else if (!timeWith) {
        timer = setTimeout(() => {
          localStorage.removeItem("assessmentData");
          WithoutTimerFunction();
        }, 1800000);
        // }, 900000);
      }

      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [spaceview, isActive, time, timeWith, ClickedTimeout]);


  const WithoutTimerFunction = () => {
    setwithoutimer(true)
    setIsTimeout(true);
    setMyallQuestion([]);
  }
  

  const startTimer = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resumeTimer = () => {
    setIsPaused(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsPaused(false);
    setTime(allDetails?.generalAssessment?.time_in_mins);
  };

  const StayFunction = () => {
    if (attempt === false && finalSubmit === false) {
      const currentime = getCurrentTime()
      handleNextTimeout(currentime);
      setAttempt(true)
    }
    getAssessmentDetails()

  }



  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} ${mins === 1 ? "Min" : "Mins"} ${secs} ${secs === 1 ? "Second" : "Seconds"}`;
  };

  const formatTimeTimer = (seconds) => {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // console.log(formatTime(Math.floor(summaryData.total_time_taken)),"RTime");

  function CloseTimeCal(time) {
    if (!time) return 0;
    const totalSeconds = (time.hours || 0) * 3600 + (time.minutes || 0) * 60 + (time.seconds || 0);
    return totalSeconds;
  }




  const getAssessmentDetails = async () => {
    setIsLoading(true)
    // const result = await adminServices.getAssessmentDetails(userid, id);
    const result = await adminServices.getStartAssessmentData(id);
    if (result.ok) {
      setAllDetails(result?.data)
      setTime(result?.data?.generalAssessment?.time_in_mins)
      setOvearallTime(result?.data?.generalAssessment?.time_in_mins)
      setTimeWith(typeof result?.data?.generalAssessment?.time_in_mins === 'number' && result?.data?.generalAssessment?.time_in_mins > 0);
      setOvearallTime(result?.data?.generalAssessment?.time_in_mins)
      setQuestions(
        result?.data?.questionDetails
          .sort(() => Math.random() - 0.5)
          .slice(0, 25)
      );
    }
    setIsLoading(false)

  }
  const getAssessmentDetailsCountById = async () => {
    const result = await adminServices.getAssessmentCountById(userid, id);
    setAttemptCount(result.data.count)

  }




  const getAssessmentDetailsBestScore = async () => {
    // setIsLoading(true)
    // const result = await adminServices.getAssessmentDetails(userid, id);
    const result = await adminServices.getStartAssessmentData(id);
    if (result.ok) {
      setAllDetails(result?.data)
    }
    // setIsLoading(false)

  }
  const PostSpace = async () => {

    const starttime = getCurrentTime()
    const data = {
      assessmentId: id,
      starttime: Date.now()
    };
    localStorage.setItem('assessmentData', JSON.stringify(data));
    setStartTime(starttime)
    dispatch(FromGeneralAssessmentView(true));
    
    setSpaceView(false)
    setIsActive(true);
    setIsPaused(false);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const calculateCorrectPercentage = (array, questions) => {
    const totalQuestions = questions?.length;
    if (totalQuestions === 0) return 0;
    const correctResponses = array.filter(item => item.response_recorded === "correct").length;
    setCorrectResponse(correctResponses)
    const incorrectResponses = array.filter(item => item.response_recorded !== "correct").length;
    setInCorrectResponse(incorrectResponses)
    const percentage = (correctResponses / totalQuestions) * 100;
    return percentage;
  };


  function normalizeOptions(options) {
    return options?.map(option => {
      if (typeof option === "string" && option.startsWith("<") && option.endsWith(">")) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = option;
        return tempDiv.textContent || tempDiv.innerText || "";
      }
      return option;
    });
  }

  const handleSubmit = async () => {
    if (selectedOption) {
      const currentQuestion = questions[currentIndex];
      const correctAnswers = currentQuestion.options.correctAnswer;      
      const selectedIndex = currentQuestion.options.mcqOptions.indexOf(selectedOption);
      const isCorrect = correctAnswers[selectedIndex];
      if (questions[currentIndex]) {
        questions[currentIndex] = {
          ...questions[currentIndex],
          answerCorrect: isCorrect
        };
      }
      setQuestions(questions)
      setIsAnswerCorrect(isCorrect);
      setCorrectAnswerIndex(correctAnswers.indexOf(true));
      setButtonClick(true)

      const objData = {
        qn_seq_no: allQuestions?.length + 1,
        response_recorded: isCorrect ? "correct" : "incorrect",
        // related_question_id: currentQuestion.id,
        related_question_id: currentQuestion?.question_id,
        multiplier: currentQuestion.multiplier,
      };
      const correctPercentage = Math.ceil(calculateCorrectPercentage([...allQuestions, objData], questions));
      setProgress(correctPercentage);
      setAllQuestions((prevQuestions) => [...prevQuestions, objData]);
      setMyallQuestion((prevQuestions) => [...prevQuestions, objData]);
    } else {
      alert("Please select any option");
    }
  };

  const calculateTimeDifference = (dateStarted, dateCompleted) => {
    const startTime = new Date(`1970-01-01T${dateStarted}Z`);
    const endTime = new Date(`1970-01-01T${dateCompleted}Z`);
    const differenceInMilliseconds = endTime - startTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const remainingSeconds = differenceInSeconds % 60;
    return { minutes: differenceInMinutes, seconds: remainingSeconds };
  };

  const handleNext = async () => {
    await handleSubmit()
    if (selectedOption) {
      setButtonClick(false);
      resetState();
      if (questions?.length > currentIndex + 1) {
        setCurrentIndex((prev) => prev + 1);
      } else {
        setIsLoading(true)
        const endtime = getCurrentTime()
        setEndTime(endtime)
        const timediff = calculateTimeDifference(startTime, getCurrentTime())
        setCloseTime(timediff)
        const currentQuestion = questions[currentIndex];
        const correctAnswers = currentQuestion.options.correctAnswer;      
        const selectedIndex = currentQuestion.options.mcqOptions.indexOf(selectedOption);
        const isCorrect = correctAnswers[selectedIndex];

        if (questions[currentIndex]) {
          questions[currentIndex] = {
            ...questions[currentIndex],
            answerCorrect: isCorrect
          };
        }
        const objData = {
          qn_seq_no: allQuestions?.length + 1,
          response_recorded: isCorrect ? "correct" : "incorrect",
          related_question_id: currentQuestion?.question_id,
          multiplier: currentQuestion.multiplier,
        };
        const alldata = [...allQuestions, objData]
        if (alldata?.length > 0) {
          const body = {
            assessment_id: id,
            user_id: userid,
            user_result: alldata,
            date_started: startTime,
            date_completed: getCurrentTime(),
            difference_date: timediff,
            current_score: progress,
            attemptCount: parseInt(attemptCount, 10) + 1
          };
          setFinalSubmit(true)
          const result = await adminServices.postAssessmentSubmit(body);
          if (result.ok) {
            if (result?.data && result.data.length > 0) {
              const assessmentId = result.data[0]?.attempted_count;
              if (assessmentId) {
                setAttemptAssessment(assessmentId);
              }
            }
            setIsLoading(false)
            setText(true)
            getAssessmentDetailsBestScore()
            setAttempt(true)
            setClicked(true)
          }
        }
        else {
          setSeesionout(false)
          setSpaceView(true)
          dispatch(FromGeneralAssessmentView(false));
          setOpenSnackbar(true);
          setAllQuestions([])
          setSelectedOption("");
          setSnackbarTitle('Your Assessment is not considered due to Session Timeout!');
        }
      }
    }
  };

  function addSecondsToTime(dateStarted, additionalSeconds) {
    const [hours, minutes, seconds] = dateStarted.split(':').map(Number);
  
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0); 
    date.setSeconds(date.getSeconds() + additionalSeconds);
  
    return date.toTimeString().split(' ')[0]; 
  }

  const handleNextNew = async () => {
    if (allQuestions?.length > 0) {
      setButtonClick(false);
      resetState();
      setIsLoading(true)
      const endtime = getCurrentTime()
      setEndTime(endtime)
      const timediff = calculateTimeDifference(startTime, getCurrentTime())
      
      setCloseTime(timediff)
      questions.forEach((question, index) => {
        const existingQuestion = allQuestions.find(q => q.related_question_id === question.question_id);
        
        if (!existingQuestion) {
            allQuestions.push({
                "qn_seq_no": allQuestions.length + 1, 
                "response_recorded": "Not answered", 
                "related_question_id": question.question_id,
                "multiplier": question.multiplier
            });
        }
    });
    const newTime = addSecondsToTime(startTime, ovearallTime);
      const body = {
        assessment_id: id,
        user_id: userid,
        user_result: allQuestions,
        date_started: startTime,
        date_completed: newTime,
        difference_date: ovearallTime,
        current_score: progress,
        attemptCount: parseInt(attemptCount, 10) + 1
      };
      
      const result = await adminServices.postAssessmentSubmit(body);
 
      if (result.ok) {
        getAssessmentDetailsCountById()
        setLatestAttemptCount(result?.data[0]?.attempted_count)
        if (result?.data && result.data.length > 0) {
          const assessmentId = result.data[0]?.attempted_count;
          if (assessmentId) {
            setAttemptAssessment(assessmentId);
          }
        }
        setIsLoading(false)
        setText(true)
        setOvearallTime(0)
        getAssessmentDetailsBestScore()
        setAttempt(true)
        // setClicked(true)
        setClickedTimeout(true)
      }
    }
    else {
      setSeesionout(false)
      setSpaceView(true);
      dispatch(FromGeneralAssessmentView(true));
      setAttempt(true)
      setText(false)
      setOpenSnackbar(true);
      setAllQuestions([])
      setSelectedOption("");
      setSnackbarTitle('Your Assessment is not considered due to Session Timeout!');
      getAssessmentDetails()
 
    }
  };
 
 
 
 


  const handleNextTimeout = async (endtime) => {
    if (allQuestions?.length > 0) {

      setButtonClick(false);
      resetState();
      // if (questions?.length > currentIndex + 1) {
      //   setCurrentIndex((prev) => prev + 1);
      // } else {
      setIsLoading(true)
      // const endtime = getCurrentTime()
      // setEndTime(endtime)
      const timediff = calculateTimeDifference(startTime, endtime)
      setCloseTime(timediff)
      const body = {
        assessment_id: id,
        user_id: userid,
        user_result: allQuestions,
        date_started: startTime,
        date_completed: endtime,
        difference_date: timediff,
        current_score: progress,
        attemptCount: parseInt(attemptCount, 10) + 1
      };

      const result = await adminServices.postAssessmentSubmit(body);

      if (result.ok) {
        setLatestAttemptCount(result?.data[0]?.attempted_count)
        getAssessmentDetailsBestScore()
        setCorrectResponse(0)
        setSpaceView(false)
        dispatch(FromGeneralAssessmentView(false));
        setAllQuestions([])
        setCorrectAnswerIndex(null)
        setIsAnswerCorrect(null)
        // setProgress(0)
        setText(false)
        getAssessmentDetails();
        setIsActive(true);
        setIsPaused(false);
        setCurrentIndex(0);
        // setStartTime(getCurrentTime())
        setIsLoading(false)
        // setText(true)
        // getAssessmentDetails()
      }


    }
    else {
      setSeesionout(false)
      setSpaceView(true)
      dispatch(FromGeneralAssessmentView(true));
      setOpenSnackbar(true);
      setAllQuestions([])
      setSelectedOption("");
      setSnackbarTitle('Your Assessment is not considered due to Session Timeout!');

    }
  };

  const resetState = () => {
    setSelectedOption("");
    setIsAnswerCorrect(null);
    setCorrectAnswerIndex(null);
  };
  const handleNavigation = () => {
    dispatch(FromGeneralAssessmentView(false));
    navigate('/auth/subscribe')
  };



  // const sortedData = summaryData?.scores?.sort((a, b) => new Date(a.date) - new Date(b.date));
  const sortedData = summaryData?.scores?.sort((a, b) => new Date(b.date) - new Date(a.date));

  const startIndex = currentPage * itemsPerPage;
  const paginatedData = sortedData?.slice(startIndex, startIndex + itemsPerPage);
  const lastScores = paginatedData?.map((item) => item.score) || [];


  const paddedScores = [
    ...lastScores,
    ...Array(itemsPerPage - lastScores.length).fill(null),
  ];

  // const xAxisLabels = [
  //   ...paddedScores.map((_, index) =>
  //     lastScores[index] !== undefined ? `Reattempt${startIndex + index + 1}` : ""
  //   ),
  // ];
  const totalLength = sortedData?.length || 0;

  const xAxisLabels = [
    ...paddedScores.map((_, index) =>
      lastScores[index] !== undefined
        ? `GA${totalLength - (startIndex + index)}`
        : ""
    ),
  ];


  const totalPages = Math.ceil((sortedData?.length || 0) / itemsPerPage);


  const correctPercentage = `${myallQuestion?.filter(q => q.response_recorded === "correct")?.length}`;
  const incorrectPercentage = `${myallQuestion?.filter(q => q.response_recorded === "incorrect")?.length}`;

  const notAnsweredPercentage = questions?.length - (parseInt(correctPercentage, 10) + parseInt(incorrectPercentage, 10));
  // const notAnsweredWidth = `${allQuestions - (correctPercentage + incorrectPercentage)}`;
  const [skipAnimation, setSkipAnimation] = useState(false);

  const mobileAndDesktopOS = summaryData?.skillPercentages || {};

  const pieChartData = Object.entries(mobileAndDesktopOS).map(([key, value]) => ({
    name: key,        // Label for each section
    value: parseFloat(value),  // Value for each section
  }));
  const itemNb = mobileAndDesktopOS.length;

  const slicedData = pieChartData.slice(0, itemNb);


  const radius = 0.6; // Example, adjust as needed
  const valueFormatter = (value) => `${value}%`;

  // Pie chart color mapping
  const colors = [
    '#02b2af', // Knowledge
    '#2e96ff', // Analysis
    '#b800d8', // Comprehension
    '#60009b', // Synthesis
    '#2731c8', // Application
    '#03008d', // Evaluation
  ];


  const formatScientificNotation = (text) => {
    text = text.replace(/(10\^)([-+]?\d+(\.\d+)?)/g, (match, p1, p2) => {
      return `${p1}<sup>${p2}</sup>`; 
    });

    text = text.replace(/([a-zA-Z]+)(\d+)/g, (match, p1, p2) => {
      if (['H', 'O', 'C', 'N'].includes(p1)) {  
        return `${p1}<sub>${p2}</sub>`;
      }
      return match;
    });
  
    return text;
  };
  
  // const formatScientificNotation = (text) => {
  //   // return text.replace(/(10\^)([-+]?\d+(\.\d+)?)/g, (match, p1, p2) => {
  //   //   return `${p1}<sup>${p2}</sup>`; // Convert 10^number to 10<sup>number</sup>
  //   // });

  //   text = text.replace(/(10\^)([-+]?\d+(\.\d+)?)/g, (match, p1, p2) => {
  //     return `${p1}<sup>${p2}</sup>`; 
  //   });
  
  //   text = text.replace(/([a-zA-Z]+)(\d+)/g, (match, p1, p2) => {
  //     return `${p1}<sub>${p2}</sub>`;
  //   });
  
  //   return text;
  // };

  const formatOption = (text) => {
    const sanitizedText = DOMPurify.sanitize(text, {
      ALLOWED_TAGS: ['sup', 'b', 'i', 'u', 'br', 'p', 'span']  
    });
        return formatScientificNotation(sanitizedText);
      };




  return (
    <Page title={'Begin Course'} style={{ marginTop: spaceview ? '28px' : '8px', padding: '0px', paddingTop:  spaceview ? '35px !important' : '70px !important' }}>

      {spaceview && <Box
        sx={{
          marginTop: "20px",
          width: '100%',
          paddingLeft: '50px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '16px',
          display: 'none',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            padding: '15px',
            paddingLeft: '100px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          separator=">"
        >
          {!isSummery && spaceview ? <Link
            underline="hover"
            color="inherit"
            onClick={(e) => { handleNavigation('/auth/subscribe'); }}
            style={{ cursor: 'pointer' }}
          >
            General Assessment
          </Link> :
            <Typography color="text.primary">
              General Assessment
            </Typography>}
          <Typography color="text.primary">
            {allDetails?.generalAssessment?.title ? allDetails?.generalAssessment?.title : 'Condition Functions'}
          </Typography>
        </Breadcrumbs>

        {spaceview === false && textview === false && timeWith === true && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '100px',
              marginTop: '-8px',
            }}
          >
            {/* <Typography className='TimeLeft' style={{ marginRight: "50px", marginTop: '-30px', marginBottom: '5px', color: time <= 59 ? 'red' : 'black' }} variant="body2" >
              Remaining Time: {formatTimeTimer(time)}
            </Typography> */}
          </Box>
        )}
      </Box>}

      {spaceview === false && <Box
        sx={{
          marginTop: "20px",
          width: '100%',
          paddingLeft: '50px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '16px',
          display: 'none',
          flexDirection: 'column',
          // visibility: 'hidden'
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            padding: '15px',
            paddingLeft: '100px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          separator=">"
        >
          {!isSummery && spaceview ? <Link
            underline="hover"
            color="inherit"
            onClick={(e) => { handleNavigation('/auth/subscribe'); }}
            style={{ cursor: 'pointer' }}
          >
            General Assessment
          </Link> :
            <Typography color="text.primary">
              General Assessment
            </Typography>}
          <Typography color="text.primary">
            {allDetails?.generalAssessment?.title ? allDetails?.generalAssessment?.title : 'Condition Functions'}
          </Typography>
        </Breadcrumbs>

        {spaceview === false && textview === false && timeWith === true && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '100px',
              marginTop: '-8px',
            }}
          >
            {/* <Typography className='TimeLeft' style={{ marginRight: "50px", marginTop: '-30px', marginBottom: '5px', color: time <= 59 ? 'red' : 'black' }} variant="body2" >
              Remaining Time: {formatTimeTimer(time)}
            </Typography> */}
          </Box>
        )}
      </Box>}


  

      {isSummery || comingFrom === 'analysis' ?

        <>
             <Breadcrumbs className='Parentcomp'
                             aria-label="breadcrumb"
                             sx={{
                                 padding: '15px',
                                 paddingLeft: '100px',
                                 paddingBottom: '0px',
                                 paddingTop: '0px',
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 marginTop: '35px'
                             }}
                             separator=">"
                         >
                             {/* <Link style={{
                                 cursor: 'pointer',
                                 color: "#212B36 !important",
                                 textDecoration: 'none',
                                 "&:hover": {
                                     textDecoration: 'underline',
                                 }
                             }}
                                 underline="hover"
                                 color="#212B36"
                             
                                 to="/auth/subscribe"
                             >
                                 General Assessment
                             </Link> */}

                             <button style={{
                                cursor:'pointer',
                                 textDecoration: 'none',
                                border: 'none',
             
                                background: 'none',
                                color: '#0000ee',
                                fontSize: '16px',
                                fontWeight: '500'
                             }}
                                 // underline="hover"
                                 color="#212B36"
                                 // to="/auth/AssessmentCourseDetails"
                                 onClick={() => navigate("/auth/subscribe")}
                             >
                                 General Assessment
                             </button>
                         
                            <button style={{
                                cursor:'pointer',
                                 textDecoration: 'none',
                                border: 'none',
             
                                background: 'none',
                                color: '#0000ee',
                                fontSize: '16px',
                                fontWeight: '500'
                             }}
                                 // underline="hover"
                                 color="#212B36"
                                 // to="/auth/AssessmentCourseDetails"
                                 onClick={handleNavigateDetails}
                             >
                                 Assessment Overview
                             </button>
                             <Typography color="text.primary">
                                 Analysis
                             </Typography>
     
                         </Breadcrumbs>
        <Grid container spacing={2} style={{ padding: "20px", width: '92%', margin: 'auto' }}>
          <Grid item xs={12} md={6}>
            <Grid container >
              <Grid item xs={12}>
                <Paper style={{ padding: "10px" }}>
                  <Typography variant="h6" style={{marginBottom: '15px'}}>Your Test Score</Typography>
                  <Grid item className='ScoreBoard CardScoreBoard'>
                    <QuestionList question={summaryData?.assessmentDetails} bestScore={allDetails.generalAssessment?.best_score} firstScore={allDetails.generalAssessment?.first_score} handleBack={handleBack} currentIndex={currentIndex} spaceview={spaceview} textview={textview} questionComponent={questionComponent} />
                  </Grid>
                  <Grid item style={{
                    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
                    marginTop: '40px',
                    minHeight: '135px',
                    borderRadius: '10px',
                    padding: '10px 13px'
                  }}>
                    <Typography variant="h6">Your Overall Score</Typography>
                    <Box>
                      <div className='formateScore' style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                        <Tooltip title={`Correct: ${correctCount} answers`}>
                          <div
                            style={{
                              background: "#44D485",
                              width: `${(correctCount / totalCount) * 100}%`,
                              height: "10px",
                              marginRight: "5px",
                            }}
                          />
                        </Tooltip>

                        <Tooltip title={`Incorrect: ${incorrectCount} answers`}>
                          <div
                            style={{
                              background: "#F26161",
                              width: `${(incorrectCount / totalCount) * 100}%`,
                              height: "10px",
                              marginRight: "5px",
                            }}
                          />
                        </Tooltip>

                        <Tooltip title={`Not Answered: ${notAnsweredCount}`}>
                          <div
                            style={{
                              background: "#769FFE",
                              width: `${(notAnsweredCount / totalCount) * 100}%`,
                              height: "10px",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#00C365",
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          />
                          <Typography variant="body1"><span style={{fontWeight: "600"}}>{correctCount}</span> Correct Answer</Typography>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#F26161",
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          />
                          <Typography variant="body1"><span style={{fontWeight: "600"}}>{incorrectCount}</span> Incorrect Answer</Typography>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#437BFC",
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          />
                          <Typography variant="body1"><span style={{fontWeight: "600"}}>{notAnsweredCount}</span> Not Answered</Typography>
                        </div>
                      </div>
                    </Box>

                  </Grid>
                </Paper>


              </Grid>
              <Grid item spacing={2} style={{ marginTop: "20px", width: "100%" }}>
                <Paper style={{ padding: "20px", height: "100%" }}>
                  <Typography variant="h5">Time Summary</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ padding: "10px", marginTop: '20px' }}>
                      <Paper style={{ padding: "10px", textAlign: "center", backgroundColor: '#437BFC' }}>
                        <Typography variant="subtitle1" color={'white'}> Average Test Time</Typography>
                        <Typography variant="h6" color={'white'}>{formatTime(Math.floor(summaryData?.length !== 0 && summaryData?.assessmentDetails[0]?.time_taken / summaryData?.assessmentDetails?.length))}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "10px", marginTop: '20px' }}>

                      <Paper style={{ padding: "10px", textAlign: "center", backgroundColor: '#437BFC' }}>
                        <Typography variant="subtitle1" color={'white'}>Total Test Time</Typography>
                        {/* <Typography variant="h6" color={'white'}>{formatTime(Math.floor(summaryData.avg_time))}</Typography> */}
                        <Typography variant="h6" color={'white'}>{formatTime(Math.floor(summaryData.length !== 0 && summaryData?.assessmentDetails[0]?.time_taken))}</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper style={{ padding: "20px", height: "100%" }}>
              <Typography variant="h6">Objective Test Report </Typography>

              <div>
                <BarChart
                  height={300}
                  width={600}
                  series={[
                    { data: correctData, label: 'Correct', color: '#93ed93', id: 'correctId', stack: 'total' },
                    { data: wrongData, label: 'Incorrect', color: '#f5918c', id: 'incorrectId', stack: 'total' }]}
                  xAxis={[{ data: labels, scaleType: 'band' }]}
                  borderRadius={7}
                />

              </div>
            </Paper>
          </Grid>
        </Grid>
       </>
        : <>{isLoading ?
          <>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: `calc(100vh - 70px)`,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
              }}
            >
              <LottieLoading loading={isLoading} />
            </div>
          </>
          :
          <>
            <Grid
              container
              spacing={2}
              // width= {Clicked === true || withoutimer === true && attempt === false ? '50%' : "93%"}
              sx={{
                backgroundColor: 'white',
                placeItems: 'flex-start',
                // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                boxShadow: {
                  xs: (Clicked || ClickedTimeout|| withoutimer) && !attempt ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  md: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
                borderRadius: spaceview ? "0px" : "8px",
                border: spaceview ? "1px solid #fe780f" : "0",
                padding: Clicked === true || ClickedTimeout === true || withoutimer === true && attempt === false ? '25px' : "8px",
                paddingRight: '20px',
                paddingBottom: '20px',
                margin: 'auto',
                marginTop: "30px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'stretch',
                width: {
                  xs: (Clicked || ClickedTimeout || withoutimer) && !attempt ? '50%' : '93%',
                  md: (Clicked || ClickedTimeout ||  withoutimer) && !attempt ? '50%' : '93%',
                },
              }}
              // marginTop="10px"
              paddingRight={Clicked === true || ClickedTimeout === true || withoutimer === true && attempt === false ? '25px' : "20px"}
              paddingBottom={Clicked === true || ClickedTimeout === true || withoutimer === true && attempt === false ? '25px' : "20px"}
              placeItems='flex-start'
              className='generalSection'
            >
              { spaceview &&      <Grid item className="GridParent" style={{ padding: '0', borderRadius: '10px', }} xs={2} spacing={2}>
                    <Box style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", height: '100%' }}>
                      <Box textAlign="center" margin={0}>
                        <Typography style={{ fontSize: '18px', color: '#000', fontWeight: "600", margin: '0px' }}>Test Instructions</Typography>
                      </Box>
                      <Box className="sectionTwo">
                        <img src={ImageView} alt="Brain Illustration" />
                      </Box>
                    </Box>

                  </Grid>}
              {spaceview === false && textview === false ?
                ""
                :
                Clicked === false || ClickedTimeout === false && (
                  // <Grid item className="GridParent" style={{ padding: '0', borderRadius: '10px', }} xs={2} spacing={2}>
                  //   <Box style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", height: '100%' }}>
                  //     <Box textAlign="center" margin={0}>
                  //       <Typography style={{ fontSize: '18px', color: '#000', fontWeight: "600", margin: '0px' }}>Test Instructions</Typography>
                  //     </Box>
                  //     <Box className="sectionTwo">
                  //       <img src={ImageView} alt="Brain Illustration" />
                  //     </Box>
                  //   </Box>

                  // </Grid>
                  ""
                  )
              }

{withoutimer === true && attempt === false || ClickedTimeout === true &&
        <div className="flex items-center justify-center min-h-screen bg-red-100" style={{textAlign: "center"}}>

          <div className="flex justify-center mb-4">
            <div className="relative inline-block" style={{justifySelf: 'center'}}>
              <img
                src={Sessiontimeout}
                alt="Timeout Icon"
                className="w-16 h-16"
              />
            </div>
          </div>

          <h2 className="text-xl font-bold text-blue-600">Time Out!</h2>
          <p className="text-gray-600 mt-2">
            Your session time for this assessment is over. We will consider this as ONE attempt. Please click on the button below to go back to the <span className="font-bold">"Test Section"</span>.
          </p>
          <Button onClick={() => handleSummary()} className="mt-4 w-full bg-gray-200 text-gray-800 hover:bg-gray-300" style={{ border: '1px solid', marginTop: '20px'}}>
            View Analysis
          </Button>
        </div>
    
      }


              <Grid item className='pannelcontent' xs={spaceview ? "10" : "9"} style={{ padding: '0', paddingLeft: '12px' }}>
                <Box style={{ flexDirection: 'column', }}>
                  {spaceview &&
                    <Box
                      sx={{
                        border: "2px solid #0da4f5",
                        borderRadius: "8px",
                        padding: "16px",
                        // maxWidth: "800px",
                        backgroundColor: "white",
                        boxShadow: "0px 2px 8px rgba(0,0,0,0.1)"
                      }}
                    >

                      <Grid container spacing={1} sx={{ padding: '15px 20px' }}>
                        <Grid className="instructionPanel" item xs={12} sm={7} sx={{ borderRight: "1px solid rgb(173, 173, 173)" }}>
                          <ol style={{ padding: "16px" }}>
                            {partIs?.map((text, index) => (
                              <li key={index} style={{ marginBottom: "10px", lineHeight: '1.15', fontSize: '15px' }}>
                                {text?.split('"').map((part, idx, arr) =>
                                  idx % 2 === 1 ? <strong key={idx}>"{part}"</strong> : part
                                )}
                              </li>
                            ))}
                          </ol>
                        </Grid>

                        <Grid item sm={5} xs={12} style={{
                          display: 'flex', flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}>
                          <Box sx={{ paddingLeft: "16px !important" }}>
                            <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                              Question Color Code
                            </Typography>

                            {[
                              { number: "01", color: "#fff", title: "Not Answered" },
                              { number: "01", color: "#1976D2", title: "Skipped Question" },
                              { number: "01", color: "#4CAF50", title: "Answered Question" }
                            ]?.map((item, index) => (
                              <Card key={index} sx={{ display: "flex", alignItems: "center", marginBottom: "8px", padding: "8px", boxShadow: "none", border: "1px solid #dfdfdf" }}>
                                <Box
                                  sx={{
                                    width: "37px",
                                    height: "36px",
                                    borderTopLeftRadius: "8px",
                                    backgroundColor: item.color,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: '10px',
                                    color: item.color === "#fff" ? "#000" : "#fff",
                                    border: item.color === "#fff" ? "1px solid #ddd" : "none",
                                    fontWeight: "600"
                                  }}
                                >
                                  {item.number}
                                </Box>
                                <Typography variant="body2" sx={{ marginLeft: "8px", lineHeight: '1.2' }}>
                                  {item.title}
                                </Typography>

                              </Card>
                            ))}

                          </Box>
                          <Box style={{ textAlign: 'end' }}>
                            <Button onClick={handleNavigateNewAssessment} variant="contained" sx={{ marginRight: "8px", backgroundColor: "#1976D2", borderRadius: "4px" }}>
                              Back
                            </Button>
                            <Button onClick={PostSpace} variant="contained" sx={{ backgroundColor: "#1976D2", borderRadius: "4px" }}>
                              Start Test
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>


                    </Box>

                  }


                  {spaceview === false && textview === false &&
                    <>
                      <Typography variant="h6" className='generalHeading' gutterBottom > {allDetails?.generalAssessment?.title}
                      </Typography>
                      {spaceview === false && textview === false && timeWith === true && (

                        <Typography className='TimeLeft' style={{ float: 'right', marginRight: "0px", marginTop: '-30px', marginBottom: '5px', color: time <= 59 ? 'red' : 'black' }} variant="body2" >
                          Remaining Time: {formatTimeTimer(time)}
                        </Typography>

                      )}
                      <Box className="assessmentPanel" style={{
                        marginTop: "20px",
                        marginBottom: "0px",
                        minHeight: '400px',
                        border: '1px solid orange',
                        padding: '20px',
                        boxSizing: 'border-box'
                      }}>
                        <Typography className='questionPanel'
                          variant="h6"
                          dangerouslySetInnerHTML={{
                            __html: `${currentIndex + 1}. ${sanitizedContent}`
                          }}
                        />

                        <FormControl>
                          <RadioGroup value={selectedOption} onChange={buttonClick === false ? handleChange : ""}>
                            {questions &&
                              questions[currentIndex]?.options &&
                              questions[currentIndex]?.options?.mcqOptions?.length > 0 &&
                              questions[currentIndex]?.options?.mcqOptions.map((option, i) => {
                                // const sanitizedOption = DOMPurify.sanitize(option);
                                // const optionView = new DOMParser()
                                //   .parseFromString(sanitizedOption, "text/html")
                                //   .body.textContent || "";

                                  // const sanitizedOption = DOMPurify.sanitize(option);
                                  // const optionView = new DOMParser().parseFromString(sanitizedOption, "text/html").body.textContent || "";

                                  const optionView = formatOption(option);
                                let optionColor = "";
                                if (isAnswerCorrect !== null) {
                                  if (i === correctAnswerIndex) {
                                    optionColor = "#00B818";
                                  } else if (optionView === selectedOption) {
                                    optionColor = "red";
                                  }
                                }
                                return (
                                  <FormControlLabel
                                    key={i}
                                    value={optionView}
                                    control={<Radio />}
                                    // label={optionView}
                                    label={<span dangerouslySetInnerHTML={{ __html: optionView }} />}
                                    style={{ color: optionColor, marginLeft: "20px" }}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </FormControl>

                        <LinearProgress variant="determinate" value={((currentIndex + 1) / allDetails?.questionDetails?.length * 100)} sx={{ marginY: 2 }} />

                        {buttonClick === false &&
                          <Button variant="contained" onClick={() => handleNext()}>
                            Submit and  Next
                          </Button>}

                      </Box></>}


                  {spaceview === false && textview === true && Clicked === true &&
                    <>

                      <div className="flex items-center justify-center min-h-screen bg-red-100" style={{ justifyItems: 'center' }}>

                        <div className="flex justify-center mb-4">
                          <div className="relative inline-block">
                            <img style={{ justifySelf: 'center' }}
                              src={TestComplete}
                              alt="Timeout Icon"
                              className="w-16 h-16"
                            />
                          </div>
                        </div>

                        <h2 className="text-xl font-bold text-blue-600" style={{
                          textAlign: 'center',
                          marginTop: '5px', fontWeight: '700'
                        }}>Test Completed</h2>
                        <p className="text-gray-600 mt-2" style={{
                          textAlign: 'center', marginTop: '10px'
                        }}>
                          You have completed the assessment. Please note that you have exhausted 1 attempt from your quota. To know the answers, please click on the button below!    </p>
                        <Button style={{ border: '1px solid', marginTop: '25px'}} className="mt-4 w-full bg-gray-200 text-gray-800 hover:bg-gray-300" onClick={() => handleSummary()}>
                          View Analysis
                        </Button>
                      </div>
                    </>
                  }



                </Box>
              </Grid>


              {spaceview === false && Clicked === false && ClickedTimeout === false &&
                <Grid item xs={3} className='ScoreBoard ' style={{ display: 'flex', justifyContent: 'center' }}>
                  <QuestionList question={questions} bestScore={allDetails.generalAssessment?.best_score} firstScore={allDetails.generalAssessment?.first_score} handleBack={handleBack} currentIndex={currentIndex} spaceview={spaceview} textview={textview} />
                </Grid>}
            </Grid>
          </>}
        </>}
      <Snackbar open={isopenSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

     
    </Page>
  );
};

export default GeneralAssessment;