import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Link,
  Typography,
  Tooltip,
  Paper,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash.debounce';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

import courseApi from '../../services/users/courseApi';
import Page from '../../components/Page';
import { openSnackbar, openSubscriptionModal, setOpenSubscriptionModalDetails } from '../../store/reducer';
import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
import DeleteAlert from '../../components/modal/DeleteModal';

const UserSubscriptions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [assessmentSubscriptionList, setAssessmentSubscriptionList] = useState([]);

  const [history, setHistory] = useState(null);
  const [isAssessmentView, setIsAssessmentView] = useState(true);    
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getSubscriptionList('');
  }, [isAssessmentView]);

  const getSubscriptionList = async (search) => {
    if (isAssessmentView) {
      const response = await courseApi.getSubscriptionList(search);
      if (response.ok) {
        setLoading(false);
        setSubscriptionList(response.data);
      }
      else {
        setLoading(false);
        console.log('error')
      }
    }
    else {
      const response = await courseApi.getAssessmentSubscriptionList();
      if (response.ok) {
        setAssessmentSubscriptionList(response.data);
      }
      setLoading(false);
    };
  }

  const handleSearchChange = debounce((searchText) => {
    getSubscriptionList(searchText); 
  }, 300);
  

  const handleDelete = async () => {
    putSubscribeDetails(subscriptionId);
  };

  const putSubscribeDetails = async (planId) => {
    // if (item.isPaid) {
    //   dispatch(setOpenSubscriptionModalDetails(item));
    //   dispatch(openSubscriptionModal(planId));
    // } else {
    // setIsSubscribing(planId);
    try {
      const res = await trialAndStripeSubscriptionService.cancelStripeSubscription({ planId });
      if (res.ok) {
        // navigate('/auth/my-courses');
        getSubscriptionList('');
        dispatch(openSnackbar('Course unsubscribed successfully'));
      } else if (res.status === 400) {
        dispatch(openSnackbar(res.data.message));
      } else {
        dispatch(openSnackbar('Failed to unsubscribe, please try again'));
      }
      setBtnLoading(null);
      setDeleteAlert(false);
    } catch (error) {
      console.log(error);
    }
    // setIsSubscribing(null);
    // }
  };

  const handleIQstartVideo = (type) => {
    console.log(type);
    setIsAssessmentView(!isAssessmentView);
  };
  const columns = [
    {
      name: 'id',
      label: 'Client ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionId',
      label: 'Subscription ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {          
          const course = subscriptionList.filter((item) => item.id === tableMeta.rowData[0])[0];          
          return (
            <>
            {tableMeta.rowData[9]&& 'Free'}
              {!tableMeta.rowData[9]&&tableMeta.rowData[5] ? (
                'Trial'
              ) : (
                <>
                  {value?.length > 25 ? (
                    <Tooltip title={value} arrow>
                      <div style={{ maxWidth: 80 }}>
                        <Typography
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          sx={{
                            ':hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          className={clsx({
                            [classes.activeLink]: course.subscriptionDetails?.length > 0,
                          })}
                          onClick={(event) => {
                            setHistory(course);
                            // handleClick();
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          {value}
                        </Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <Typography
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{
                        ':hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      className={clsx({
                        [classes.activeLink]: course.subscriptionDetails?.length > 0,
                      })}
                      onClick={(event) => {
                        setHistory(course);
                        // handleClick();
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      {value}
                    </Typography>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'courseDetails',
      label: 'Course',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const subscriptions =
            value &&
            value.map((item) => {
              return (
                <>
                  <Typography gutterBottom style={{ fontSize: '14px' }}>
                    {`${item.courseName}`}
                  </Typography>
                </>
              );
            });
          return (
            <>
              {/* <div className={classes.div}>
                <div className={classes.subscription}> */}
              {/* {value && value !== null ? (
                    <ThemeProvider theme={customeTheme}>
                      <Tooltip title={subscriptions} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                        <Typography
                          style={{ color: 'white', fontSize: '0.9rem' }}
                        >{`(${value.length}) Enrolled Courses`}</Typography>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <Typography style={{ color: 'white', fontSize: '0.9rem' }}>{`(0) Enrolled Courses`}</Typography>
                  )} */}
              {subscriptions}
              {/* </div>
              </div> */}
            </>
          );
        },
      },
    },
    {
      name: 'validFrom',
      label: 'Valid from',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },
    {
      name: 'validTill',
      label: 'Valid to',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },

    {
      name: 'isTrial',
      label: 'Trial',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'isStudent',
      label: 'Student',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
    
          return (
            <Typography
              className={clsx({
                [classes.active]: value === 'ACTIVE',
                // [classes.expired]: value === 'INACTIVE',
                [classes.expired]: value === 'EXPIRED',
                [classes.cancel]: value === 'CANCELED',
              })}
            >
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: 'subscriptionDetails',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let btnLabel = 'Cancel';

          if (tableMeta.rowData[9]) {
            btnLabel = 'Free ';
          }
            else if (tableMeta.rowData[7] === 'ACTIVE' && tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'EXPIRED' && tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'EXPIRED' && !tableMeta.rowData[5]) {
            btnLabel = 'Renew';
          } else if (tableMeta.rowData[7] === 'CANCELED' && !tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'ACTIVE' && !tableMeta.rowData[5]) {
            btnLabel = 'Cancel';
          }
          else if (tableMeta.rowData[7] === 'ACTIVE' && !tableMeta.rowData[9]) {
            btnLabel = 'Free Course';
          }

          return (
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={tableMeta.rowData[9]}
              sx={{
                padding: '2px 14px',
                fontSize: '14px',
              }}
              onClick={(e) => {
                if (e.currentTarget.disabled) return;
                if (!tableMeta.rowData[5] && tableMeta.rowData[7] === 'ACTIVE') {
                  setSubscriptionId(tableMeta.rowData[0]);
                  setBtnLoading(tableMeta.rowData[1]);
                  setDeleteAlert(true);
                } else {
                  const course = subscriptionList.filter((item) => item.id === tableMeta.rowData[0])[0];

                  dispatch(setOpenSubscriptionModalDetails(course));
                  dispatch(openSubscriptionModal(tableMeta.rowData[0]));
                }
              }}
            >
              {btnLabel}
            </LoadingButton>
          );
        },
      },
    },
    {
      name: 'isFree',
      label: 'isFree',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const assessmentColumns = [
    {
      name: 'id',
      label: 'Client ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionId',
      label: 'Subscription ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const assessment = assessmentSubscriptionList?.filter((item) => item.id === tableMeta.rowData[0])[0];
          return (
            <>
              {tableMeta.rowData[5] ? (
                'Trial'
              ) : (
                <>
                  {value?.length > 25 ? (
                    <Tooltip title={value} arrow>
                      <div style={{ maxWidth: 80 }}>
                        <Typography
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          sx={{
                            ':hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          className={clsx({
                            [classes.activeLink]: assessment.subscriptionDetails?.length > 0,
                          })}
                          onClick={(event) => {
                            setHistory(assessment);
                            // handleClick();
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          {value}
                        </Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <Typography
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{
                        ':hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      className={clsx({
                        [classes.activeLink]: assessment.subscriptionDetails?.length > 0,
                      })}
                      onClick={(event) => {
                        setHistory(assessment);
                        // handleClick();
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      {value}
                    </Typography>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'assessmentDetails',
      label: 'assessment',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const subscriptions =
            value &&
            value.map((item) => {
              return (
                <>
                  <Typography gutterBottom style={{ fontSize: '14px' }}>
                    {`${item.assessmentName}`}
                  </Typography>
                </>
              );
            });
          return (
            <>
              {/* <div className={classes.div}>
                <div className={classes.subscription}> */}
              {/* {value && value !== null ? (
                    <ThemeProvider theme={customeTheme}>
                      <Tooltip title={subscriptions} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                        <Typography
                          style={{ color: 'white', fontSize: '0.9rem' }}
                        >{`(${value.length}) Enrolled assessments`}</Typography>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <Typography style={{ color: 'white', fontSize: '0.9rem' }}>{`(0) Enrolled assessments`}</Typography>
                  )} */}
              {subscriptions}
              {/* </div>
              </div> */}
            </>
          );
        },
      },
    },
    {
      name: 'validFrom',
      label: 'Valid from',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },
    {
      name: 'validTill',
      label: 'Valid to',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },

    {
      name: 'isTrial',
      label: 'Trial',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'isStudent',
      label: 'Student',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              className={clsx({
                [classes.active]: value === 'ACTIVE',
                // [classes.expired]: value === 'INACTIVE',
                [classes.expired]: value === 'EXPIRED',
                [classes.cancel]: value === 'CANCELED',
              })}
            >
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: 'subscriptionDetails',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let btnLabel = 'Cancel';
          
          if (tableMeta.rowData[7] === 'ACTIVE' && tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'EXPIRED' && tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'EXPIRED' && !tableMeta.rowData[5]) {
            btnLabel = 'Renew';
          } else if (tableMeta.rowData[7] === 'CANCELED' && !tableMeta.rowData[5]) {
            btnLabel = 'Subscribe';
          } else if (tableMeta.rowData[7] === 'ACTIVE' && !tableMeta.rowData[5]) {
            btnLabel = 'Cancel';
          }

        
          

          return (
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                padding: '2px 14px',
                fontSize: '14px',
              }}
              // disabled={btnLabel === 'Free'}
              // loading={tableMeta.rowData[1] === btnLoading}
              onClick={() => {
                if (!tableMeta.rowData[5] && tableMeta.rowData[7] === 'ACTIVE') {
                  setSubscriptionId(tableMeta.rowData[0]);
                  setBtnLoading(tableMeta.rowData[1]);
                  setDeleteAlert(true);
                } else {
                  const assessment = subscriptionList.filter((item) => item.id === tableMeta.rowData[0])[0];

                  dispatch(setOpenSubscriptionModalDetails(assessment));
                  dispatch(openSubscriptionModal(tableMeta.rowData[0]));
                }
              }}
            >
              {btnLabel}
            </LoadingButton>
          );
        },
      },
    },
  ];
  const onSearchChange = (searchQuery) => {
    getSubscriptionList(searchQuery);
  };
  return (
    <Page title="Subscriptions">
      <Container maxWidth="xl">
      <Box
      sx={{
        margin:'10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Subscription List
      </Typography>
      <Button
        variant="contained"
        onClick={() => handleIQstartVideo(isAssessmentView ? 'COURSE' : 'ASSESSMENT')}
      >
        {isAssessmentView ? 'View Assessment' : 'View Course'}
      </Button>
    </Box>
       { isAssessmentView?
       <MUIDataTable
          key={JSON.stringify(subscriptionList)}
          data={subscriptionList}
          columns={columns}
          options={{
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 75, 100],
            selectableRows: false,
            filter: false,
            // onSearchChange: (searchText) => getSubscriptionList(searchText),
            onSearchChange: (searchText) => handleSearchChange(searchText),
            
            viewColumns: false,
            download: false,
            print: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no active subscriptions',
              },
            },
          }}
        />
        :
        <MUIDataTable
          data={assessmentSubscriptionList}
          columns={assessmentColumns}
          options={{
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 75, 100],
            selectableRows: false,
            filter: false,
            viewColumns: false,
            download: false,
            print: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no active subscriptions',
              },
            },
          }}
        /> }
      </Container>

      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want cancel this subscription"
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
        submitBtnText="Yes! Cancel"
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box>
          <div className={classes.box}>
            <Typography sx={{ padding: '12px 1rem 0 1rem !important' }} variant="subtitle1">
              Subscriptions History
            </Typography>
            <IconButton onClick={handleClose} className={classes.closeIcon} sx={{ marginRight: '6px' }}>
              <CloseIcon fontSize="small" sx={{ color: 'rgb(254, 112, 0)' }} />
            </IconButton>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl.No</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Discount</TableCell>
                  {/* <TableCell>Price</TableCell> */}
                  <TableCell>Paid Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history?.subscriptionDetails?.map((res, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{moment(res.subscriptionFrom).format('MM-DD-YYYY')}</TableCell>
                    <TableCell>{moment(res.subscriptionTo).format('MM-DD-YYYY')}</TableCell>
                    <TableCell>{res.discount}</TableCell>
                    {/* <TableCell>{res.paidAmount}</TableCell> */}
                    <TableCell>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: res.currencyType,
                      }).format(res.paidAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Menu>
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: '#FE7000',
  },
  tooltip: {
    backgroundColor: '#FE7000',
    color: '#FFFFFF',
  },
  subscription: {
    backgroundColor: '#747474',
    borderRadius: '4px',
    padding: '3px 6px ',
    marginRight: '7px',
    minWidth: '123px',
  },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  active: {
    width: 'max-content',
    // background: '#38d238',
    borderRadius: '14px',
    textTransform: 'lowercase',
    // padding: '2px 14px',
    color: '#38d238',
  },

  expired: {
    width: 'max-content',
    // background: '#f12c2c',
    borderRadius: '14px',
    textTransform: 'lowercase',
    // padding: '2px 14px',
    color: '#f12c2c',
  },
  cancel: {
    width: 'max-content',
    // background: '#f12c2c',
    borderRadius: '14px',
    textTransform: 'lowercase',
    // padding: '2px 14px',
    color: '#f12c2c',
  },
  activeLink: {
    cursor: 'pointer',
    // width: 'max-content',
    color: '#FE7000',
    whiteSpace: 'nowrap',
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closeIcon: {
    marginRight: '10px',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const customeTheme = createTheme({
  components: {
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: '#FFFFFF',
    //       backgroundColor: '#FE7000',
    //     },
    //   },
    // },
  },
});

export default UserSubscriptions;
