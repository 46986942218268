import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography, Modal } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LaunchIcon from '@mui/icons-material/Launch';
import CalculateIcon from '@mui/icons-material/Calculate';
import adminServices from '../../../services/adminServices';
import LottieLoading from '../../../components/LottieLoading';
import TestComplete from '../../../assets/Images/TestComplete.png';
// import breakTime from '../../../assets/Images/break-time.png';

import './index.css'

const App = () => {
  const { userInfo } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [allDetails, setAllDetails] = useState('');
  const [questions, setQuestions] = useState([]);  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [time, setTime] = useState(2604);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [openQuestionList, setOpenQuestionList] = useState(false);
  const [questionTimes, setQuestionTimes] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const [questionType, setQuestionType] = useState('English');
  const [isMarkedAsSaved, setIsMarkedAsSaved] = useState(false);
  const [calculator, setCalculator] = useState(false);
  const [gridLeft, setGridLeft] = useState(6);
  const [gridRight, setGridRight] = useState(6);
  const [parsedOptions, setParsedOptions] = useState([]);
  const [attemptCount, setAttemptCount] = useState(1);
  const [submodule, setSubmodule] = useState(1);
  const [resumeTime, setResumeTime] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); 
  const [correctAnswer, setCorrectAnswer] = useState([]);  
  

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleOpenQuestionList = () => setOpenQuestionList(true);
  const handleCloseQuestionList = () => setOpenQuestionList(false);

  const getAssessmentDetails = async (type) => {
    setIsLoading(true);
    const result = await adminServices.getStartSATAssessment(73,userInfo?.id,type);
    setCurrentIndex(0)
    if (result.ok) {
      setAllDetails(result?.data?.assessmentDetails);
      setTime(result?.data?.assessmentDetails?.time_in_mins);
      setQuestions(
        result?.data?.questions
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAssessmentDetails(questionType);
  }, []);
  const currentQuestion = questions[currentIndex];  


  useEffect(() => {
    let timer;
    if (time > 0 && !isPaused) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    if (time === 0) {
      setIsTimeout(true);
    }
    return () => {
      clearInterval(timer);
    };
  }, [time, isPaused]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if(resumeTime)
  {  if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }}
  }, [timeLeft]);

  const reAttemptTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleNext = () => {
    const endTime = Date.now();
    const timeSpent = (endTime - startTime) / 1000; 
    const currentQuestion = questions[currentIndex];
    setQuestionTimes((prev) => ({
      ...prev,
      [currentIndex]: (prev[currentIndex] || 0) + timeSpent, 
    }));

    setStartTime(Date.now());
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave? Your progress will not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBack = () => {
    if (currentIndex > 0) {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;

      setQuestionTimes((prev) => ({
        ...prev,
        [currentIndex]: (prev[currentIndex] || 0) + timeSpent,
      }));

      setStartTime(Date.now()); 
      setCurrentIndex(currentIndex - 1);
    }
  };


  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const handleOptionSelect = (option,index) => {
    console.log(option,'option');
    
    const persedData = JSON.parse(currentQuestion?.options);
    const correctAnswers = persedData.correctAnswer;
    const isCorrect = correctAnswers[index];
    const stringifiedIsCorrect = JSON.stringify(isCorrect);
    setCorrectAnswer((prev) => ({
      ...prev,
      [currentIndex]: stringifiedIsCorrect, 
    }));
    setSelectedAnswers((prev) => ({
      ...prev,
      [currentIndex]: option,
    }));
  };

  const handleQuestionSelect = (index) => {
    const endTime = Date.now();
    const timeSpent = (endTime - startTime) / 1000;

    setQuestionTimes((prev) => ({
      ...prev,
      [currentIndex]: (prev[currentIndex] || 0) + timeSpent,
    }));

    setStartTime(Date.now()); 
    setCurrentIndex(index);
    handleCloseQuestionList();
  };


  const handleSubmit = async () => {
    setIsLoading(true)
    const totalTimeTaken = Object.values(questionTimes).reduce((acc, time) => acc + time, 0);
    const formattedSelectedAnswers = questions.map((question, index) => ({
      questionId: question.id,
      index,
      selectedAnswer: selectedAnswers[index] || null,
      timeTaken: questionTimes[index] || 0,
      response_recorded:correctAnswer[index] ||null
    }));
    const body = {
      type:questionType,
      assessment_id: allDetails?.id,
      user_id: userInfo?.id,
      user_result: formattedSelectedAnswers,
      date_started: getCurrentTime(),
      date_completed: getCurrentTime(),
      difference_date: totalTimeTaken,
      attemptCount: parseInt(attemptCount, 10) + 1,
      submodules:submodule
    };
    const result = await adminServices.postSATResult(body);
    setSelectedAnswers([]);
    setOpenQuestionList([]);
    setStartTime('');
    setResumeTime(false);
    setCorrectAnswer([]);
    setCurrentIndex(0);
    setIsLoading(false);
    if (result.ok) {
       if (submodule===2)
       {
        setResumeTime(true);
       }
      setSubmodule(2);
      if (result?.data && result.data.length > 0) {
        const assessmentId = result.data[0]?.attempted_count;
        if (assessmentId) {
          console.log(assessmentId, 'assessmentId');
        }
      }
      setIsLoading(false)
    }
    console.log("Selected Answers:", formattedSelectedAnswers);
  };

   

  const handleBookmarkTrue = (index) => {
    setIsMarkedAsSaved(true)
  }

  const handleBookmarkFalse = (index) => {
    setIsMarkedAsSaved(false)
  }
  const openCalculator = () => {
    setCalculator(true)
  }
  const handleGridLeft = () => {
    if(gridLeft ===5 && gridRight===7)
    {
      setGridLeft(6);
      setGridRight(6)
    }
    else{
      setGridLeft(5);
      setGridRight(7)
    }
  
  }
  const handleGridRight = () => {

    if(gridLeft === 7 && gridRight=== 5 )
      {
        setGridLeft(6);
        setGridRight(6)
      }
      else{
        setGridLeft(7);
        setGridRight(5)
      }
  }

  useEffect(() => {
    if (currentQuestion){
      const parsed = JSON.parse(currentQuestion?.options);      
      setParsedOptions(parsed.mcqOptions);
    }
  }, [currentQuestion]);
  

   const handleNextModule = () =>{
    setSubmodule(1)
    setQuestionType('Maths');
    setQuestions([]);
     getAssessmentDetails('Maths');
    setResumeTime(false);
   }
  return (
    <>
    
   { resumeTime?
   <>
    {resumeTime&& questionType === 'English' && <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#d2daff", height: "90vh", padding: 2 }}>
      <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", padding: 2 }}>
          <Typography variant="h6" sx={{ alignSelf: "flex-start", marginBottom: 1 }}>
            Time Remaining: {reAttemptTime(timeLeft)}
          </Typography>
          <Box sx={{ display: "flex",}}>
            <Button variant="contained" color="primary" onClick={handleNextModule}>Resume Test</Button>
          </Box>
        </Box>
        <Box sx={{ flex: 1, padding: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
      
          <Typography variant="h6">Test Instructions</Typography>
          <Typography variant="body1">
            - Read the questions carefully. <br />
            - Do not refresh the page. <br />
            - Submit your answers before the time runs out.
          </Typography>
        </Box>
      </Box>
    </Box>}
    {resumeTime&& questionType === 'Maths' &&
                    <>

                      <div className="flex items-center justify-center min-h-screen bg-red-100" style={{ justifyItems: 'center' }}>

                        <div className="flex justify-center mb-4">
                          <div className="relative inline-block">
                            <img style={{ justifySelf: 'center' }}
                              src={TestComplete}
                              alt="Timeout Icon"
                              className="w-16 h-16"
                            />
                          </div>
                        </div>

                        <h2 className="text-xl font-bold text-blue-600" style={{
                          textAlign: 'center',
                          marginTop: '5px', fontWeight: '700'
                        }}>Test Completed</h2>
                        <p className="text-gray-600 mt-2" style={{
                          textAlign: 'center', marginTop: '10px'
                        }}>
                          You have completed the assessment. Please note that you have exhausted 1 attempt from your quota. To know the answers, please click on the button below!    </p>
                        <Button style={{ border: '1px solid', marginTop: '25px'}} className="mt-4 w-full bg-gray-200 text-gray-800 hover:bg-gray-300" >
                          View Analysis
                        </Button>
                      </div>
                    </>
                  }
    </>
    
    :
    <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#d2daff" }}>
      <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: "column", xs: "column" } }}>
        <Box sx={{ width: { md: '40%', sm: '99vw' } }}>
          <Typography
            sx={{
              fontSize: { xs: '20px', md: '24px' },
              fontWeight: { xs: 600, md: 800 }
            }}
            variant="h6">Section 1: Reading and Writing</Typography>

        </Box>
        <Box sx={{ width: { md: '20%', sm: '99vw', justifyItems: 'center' } }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>{formatTime(time)}</Typography>
          <Typography variant="h6" sx={{padding: '3px 12px', fontWeight: "bold" }}>Module -{submodule}</Typography>

         
        </Box>
        <Box sx={{ visibility: { md: 'hidden', sm: 'visible' }, display: { sm: "none" }, width: { md: '40%', sm: '99vw' } }}>
          <br />
        </Box>
      </Box>
      {questionType === 'English' ?
        <Grid container sx={{ flex: 1, padding: 1 }}>
         {isLoading?
          <Grid>
            <LottieLoading loading={isLoading} />
          </Grid>: 
          <>
          <Grid item sm={12} md={gridLeft} sx={{
            padding: 1, backgroundColor: "#ffffff", borderRight: "5px solid #7d7d7d",
            paddingTop: '50px',
            paddingRight: '50px',
            position: 'relative',
            paddingleft: '50px',
            height: 'Calc(100vh - 148px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
             
              <Typography dangerouslySetInnerHTML={{ __html: currentQuestion?.passage }}
                style={{ userSelect: "none" }}
                onCopy={(e) => e.preventDefault()}
                onContextMenu={(e) => e.preventDefault()} />
             
            <Box sx={{
              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', height: '40px',
              width: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '10px',
              color: '#adadad',
              top: '8px',
              right: '14px',
              position: 'absolute'
            }} onClick={handleGridLeft}>
              <LaunchIcon style={{ scale: '1.1' }} />
            </Box>

          </Grid>
          <Grid item sm={12} md={gridRight} sx={{
            padding: 1, backgroundColor: "#f9f9f9",
            paddingTop: '50px',
            paddingRight: '50px',
            position: 'relative',
            paddingLeft: '50px',
            height: 'Calc(100vh - 148px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
            <Box onClick={handleGridRight} sx={{
              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', height: '40px',
              width: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '10px',
              color: '#adadad',
              top: '8px',
              left: '14px',
              position: 'absolute'
            }}>
              <LaunchIcon style={{ transform: 'rotate(270deg)', scale: '1.1' }} />
            </Box>
            {currentQuestion && (
              <>

                <Box
                  sx={{
                    padding: '4px',
                    backgroundColor: "#f0f0f0",
                    borderRadius: '2px',
                    textAlign: "center",
                    borderBottom: '3px dashed #000000c9'

                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-block",
                        padding: "2px 10px",
                        backgroundColor: "black",
                        borderRadius: "2px",
                        marginBottom: 0,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "white" }}>
                        {currentIndex + 1}
                      </Typography>
                    </Box>

                    {isMarkedAsSaved ? <Button onClick={() => handleBookmarkTrue(currentIndex)} sx={{ minWidth: "auto" }}>
                      Marked As Save <BookmarkIcon color="primary" />
                    </Button> :
                      <Button onClick={() => handleBookmarkFalse(currentIndex)} sx={{ minWidth: "auto" }}>
                        <BookmarkBorderIcon color="primary" /> UnMark
                      </Button>}
                  </Box>
                </Box>
                <Typography style={{    justifySelf: 'start',  userSelect: "none"}}
                  dangerouslySetInnerHTML={{ __html: currentQuestion?.question_text }}
            
                  onCopy={(e) => e.preventDefault()}
                  onContextMenu={(e) => e.preventDefault()}
                />
                {parsedOptions&&parsedOptions?.map((option, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    fullWidth
                    sx={{
                      marginTop: 1,
                      backgroundColor: "white",
                      color: "black",
                      lineHeight: '1.2',
                      justifyContent: 'start',
                      border: selectedAnswers[currentIndex] === option ? "2px solid #4caf50" : "1px solid #ccc",
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                      }
                    }}
                    onClick={() => handleOptionSelect(option,index)}
                  >
                    <span style={{
                      border: '1px solid #aaa',
                      borderRadius: '50%',
                      padding: '7px',
                      height: '12px',
                      width: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '25px',
                    }}>{String.fromCharCode(97 + index)}</span>
                    <span dangerouslySetInnerHTML={{ __html: option }} />

                  </Button>
                ))}
              </>
            )}
          </Grid> </>}
        </Grid> :
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 'Calc(100vh - 150px)', textAlign: "center" }}
        >
        {
          isLoading?
          <Grid>
            <LottieLoading loading={isLoading} />
          </Grid>:
          currentQuestion && (
            <Box
              sx={{
                width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", background: '#fff',
                  marginTop: '10px' },
                padding: 1,
                backgroundColor: "#e9f0fa",
                borderRadius: 1,
                textAlign: "center",
                boxShadow: 2
              }}
            >
              <Box
                  sx={{
                    padding: '4px',
                    backgroundColor: "#f0f0f0",
                    borderRadius: '2px',
                    textAlign: "center",
                    borderBottom: '3px dashed #000000c9'

                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-block",
                        padding: "2px 10px",
                        backgroundColor: "black",
                        borderRadius: "2px",
                        marginBottom: 0,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "white" }}>
                        {currentIndex + 1}
                      </Typography>
                    </Box>

                    {isMarkedAsSaved ? <Button onClick={() => handleBookmarkTrue(currentIndex)} sx={{ minWidth: "auto" }}>
                      Marked As Save <BookmarkIcon color="primary" />
                    </Button> :
                      <Button onClick={() => handleBookmarkFalse(currentIndex)} sx={{ minWidth: "auto" }}>
                        <BookmarkBorderIcon color="primary" /> UnMark
                      </Button>}
                  </Box>
                </Box>
              <Typography
                dangerouslySetInnerHTML={{ __html: currentQuestion?.question_text }}
                sx={{
                  width: "100%",
              
                  textAlign: 'start',
    fontWeight: '400',
    
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: 1,
                  userSelect: 'none'
                }}
                onCopy={(e) => e.preventDefault()}
                onContextMenu={(e) => e.preventDefault()}
              />

              {parsedOptions&&parsedOptions?.map((option, index) => (
                <Button
                  key={index}
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    backgroundColor: "white",
                    color: "black",
                    justifyContent: "start",
                    border: selectedAnswers[currentIndex] === option ? "2px solid #4caf50" : "1px solid #ccc",
                    "&:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                  onClick={() => handleOptionSelect(option,index)}
                >
                  <span style={{
                      border: '1px solid #aaa',
                      borderRadius: '50%',
                      padding: '7px',
                      height: '12px',
                      width: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '25px',
                    }}>{String.fromCharCode(97 + index)}</span>
                    <span dangerouslySetInnerHTML={{  __html: option }} />
                  {/* <span dangerouslySetInnerHTML={{ __html: option }} /> */}
                </Button>
              ))}
            </Box>
          )}
        </Grid>


      }
      <Modal open={openQuestionList} onClose={handleCloseQuestionList}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '12vh',
            right: 'Calc(100vw - 70vw)',
            margin: 'auto',
            width: '550px',
            backgroundColor: 'white',
            padding: '32px',

          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: 20, height: 20, backgroundColor: "#f1f1f1", borderRadius: "4px", marginRight: 1 }} />
              <Typography variant="body2">Not Answered</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: 20, height: 20, backgroundColor: "#4caf50", borderRadius: "4px", marginRight: 1 }} />
              <Typography variant="body2">Answered</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: 20, height: 20, backgroundColor: "#2196F3", borderRadius: "4px", marginRight: 1 }} />
              <Typography variant="body2">Current</Typography>
            </Box>

          </Box>

          <Grid container spacing={2}>
            {questions.map((q, index) => {
              const isSelected = currentIndex === index;
              const isAnswered = Object.prototype.hasOwnProperty.call(selectedAnswers, index);

              let backgroundColor = "#f1f1f1";
              if (isAnswered) backgroundColor = "#4caf50";
              if (isSelected) backgroundColor = "#2196F3";

              return (
                <Grid item xs={2} key={index}>
                  <Box
                    onClick={() => handleQuestionSelect(index)}
                    sx={{
                      backgroundColor,
                      width: "40px",
                            height: "40px",
                         
                            fontSize: "14px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                            borderRadius: '4px',
                            color: '#333',
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                            "&:hover": { backgroundColor: "#1976d2", color: "white" }
                          }}
                  >
                    {index + 1}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <p style={{
            height: '5px',
            display: 'flex',
            justifyContent: 'center',
            margin: '0'
          }}>
            <span className="reviewBtn"
              style={{
                position: 'relative',

              }}><br /></span>
          </p>
        </Box>
      </Modal>


      <Box sx={{ padding: 1, backgroundColor: "#e9f0fa", display: "flex", justifyContent: "space-between" }}>
        <Typography>{userInfo?.name}</Typography>
        <Button variant="contained" style={{ backgroundColor: "black", marginLeft: '40px' }} disabled={isPaused} onClick={handleOpenQuestionList}>
          View All Questions
        </Button>
        <Box sx={{ padding: 1, backgroundColor: "#e9f0fa", display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" onClick={handleBack} disabled={currentIndex === 0 || isPaused} style={{ marginRight: '10px' }}>Back</Button>
          {currentIndex === questions.length - 1 ? (
            <Button variant="contained" color="success" onClick={handleSubmit} disabled={isPaused} >Submit</Button>
          ) : (
            <Button variant="contained" onClick={ () => handleNext()} disabled={isPaused}>Next</Button>
          )}
        </Box>
      </Box>
    </Box>}
    </>
  );
};

export default App;
