import React, { useEffect, useState } from 'react';
import {
    Box, Breadcrumbs, Link, Typography, Grid, Button, IconButton, Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComingFrom, FromGeneralAssessmentView,ComingFromSub } from '../../../store/reducer';
import AnalysisImage from '../../../assets/Images/image.png';
import adminServices from '../../../services/adminServices'
import './index.css';

const getDotColor = (index) => {
    switch (index) {
        case 0:
            return "green";
        case 1:
            return "red";
        default:
            return "gray";
    }
};

const SatAnalysis = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userid = useSelector((state) => state?.userInfo && state?.userInfo?.id);
    const allassessmentdetails = useSelector((state) => state);
    const [expanded, setExpanded] = useState(false);
    const [module, setModule] = useState('');
    const [moduleview, setModuleview] = useState('');
    const [testScore, setTestScore] = useState('testScore');
    const [analysis, setAnalysis] = useState([]);
    const [analysistestScore, setAnalysisTestScore] = useState('');
    const [name, setName] = useState('');
    // console.log(location.state,"locationlocation");
    console.log(analysis, "analysis");




    const data = [
        { label: 'Completed', value: 700 },
        { label: 'Incomplete', value: 200 },
        { label: 'Skipped', value: 100 },
    ];

    const marksTotal = [

        { value: 0, label: '0' },
        { value: 400, label: '400' },
        { value: 800, label: '800' },
        { value: 1200, label: '1200' },
        { value: 1600, label: '1600' },

    ];

    const marks = [

        { value: 0, label: '0' },

        { value: 800, label: '800' },

    ];

    function valuetext(value) {
        return `${value}`;
    }

    function PieCenterLabel({ percentage }) {
        const { width, height, left, top } = useDrawingArea();
        return (
            <StyledText x={left + width / 2} y={top + (height - 100)}>
                <tspan x={left + width / 2} dy="-1em" style={{ fontWeight: '700' }}>
                    {parseFloat(percentage).toFixed(2)}%
                </tspan>
                <tspan x={left + width / 2} dy="1.2em">Correct</tspan>
            </StyledText>
        );
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            dispatch(FromGeneralAssessmentView(false));
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        const handlePopState = () => {
            dispatch(FromGeneralAssessmentView(false));
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 20,
    }));

    useEffect(() => {
        if (module) {
            getAnalysis()
        }
    }, [module, name])

    useEffect(() => {
        if (testScore) {
            getAnalysisTestScore()
        }
    }, [testScore])




    const getAnalysis = async () => {
        const result = await adminServices.getSatAnalysis(location.state?.id, userid, location?.state.fromindex, module, name)
        if (result.ok) {
            setAnalysis(result.data)
        }
    }

    const getAnalysisTestScore = async () => {
        const result = await adminServices.getAnalysisTestScore(location.state?.id, userid, location?.state.fromindex)
        if (result.ok) {
            setAnalysisTestScore(result.data)
        }
    }

    const handleSectionClick = (section) => {
        switch (section) {
            case 'testScore':
                setModule('')
                setModuleview('')
                setName('')
                setTestScore(section)
                break;
            case 'English1':
                setModuleview('English1')
                setModule('English')
                setName('1')
                setTestScore('')
                break;
            case 'English2':
                setModuleview('English2')
                setModule('English')
                setName('2')
                setTestScore('')
                break;
            case 'Maths1':
                setModuleview('Maths1')
                setModule('Maths')
                setName('1')
                setTestScore('')
                break;
            case 'Maths2':
                setModuleview('Maths2')
                setModule('Maths')
                setName('2')
                setTestScore('')
                break;
            default:
                break;
        }
    };

    function calculatePoints(totalQuestions, totalCorrectAnswers, totalPoints) {
        const pointsPerQuestion = totalPoints / totalQuestions;
        const result = totalCorrectAnswers * pointsPerQuestion;
        // console.log(result,"result");
        if (Number.isNaN(result)) {
            return 0;
        }

        return parseInt(result, 10)


    }


    const calculatePercentage = (level) => {
        const totalQuestions = level?.length;
        const correctAnswers = level?.filter(question => question?.userResponse === "correct")?.length;
        const result = (correctAnswers / totalQuestions) * 100;
        if (Number.isNaN(result)) {
            return 0;
        }
        return result
    };

    const calculateCorrectCount = (questions) => {
        return questions.filter(q => q.userResponse === "correct").length;
    };

    const calculateIncorrectCount = (questions) => questions.filter(q => q.userResponse === "incorrect").length;

    const calculateNotAnsweredCount = (questions) => questions.filter(q => q.userResponse === null).length;

    const getDotColor = (label) => {
        switch (label) {
            case "correct":
                return "#4CAF50";
            case "incorrect":
                return "#F44336";
            case null:
                return "#6b6969";
            default:
                return "#ccc";
        }
    };

    const handleNavigateOverview = () => {
        navigate("/auth/SatOverview", {
            state: {
                id: location?.state?.id,
                from: "SAT",
                data: location?.state?.data
            }
        });
    }

    const handleNavigateSat = () => {
           dispatch(ComingFrom("Courses"))
                dispatch(ComingFromSub("SAT/ACT"))
                navigate("/auth/subscribe")
    }
    return (
        <Box>
            <Box sx={{ marginTop: '80px', marginBottom: '15px' }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                        padding: '15px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: '50px'
                    }}
                    separator=">"
                >
                    <button style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        border: 'none',

                        background: 'none',
                        color: '#0000ee',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                        // underline="hover"
                        color="#212B36"
                        // to="/auth/AssessmentCourseDetails"
                        onClick={handleNavigateSat}
                    >
                        SAT Assessment
                    </button>

                    <button
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            border: 'none',
                            background: 'none',
                            color: '#0000ee',
                            fontSize: '16px',
                            fontWeight: '500',
                        }}
                          color="#212B36"
                        onClick={handleNavigateOverview}
                    >
                        SAT Assessment Overview
                    </button>

                  

                    <Typography color="black">SAT Assessment Analysis</Typography>
                </Breadcrumbs>
            </Box>

            <Box className="analysisSection"
                sx={{
                    width: '92%',
                    margin: 'auto',
                    background: 'linear-gradient(to right, #EF76D55E, #EFCEB4)',
                    borderRadius: '8px',
                    padding: '10px 22px 35px !important',

                    marginBottom: '20px'

                }}
            >

                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '20px', marginLeft: '16px' }}>
                    Test Report - Full length diagnostic test
                </Typography>
                <Grid container spacing={1} sx={{ marginLeft: '20px', marginRight: '20px', width: 'Calc(100% - 38px)' }}>
                    {[
                        { text: "Test score", section: 'testScore' },
                        { text: "Section 1, Module 1: Reading and Writing", section: 'English1' },
                        { text: "Section 1, Module 2: Reading and Writing", section: 'English2' },
                        { text: "Section 1, Module 1: Math", section: 'Maths1' },
                        { text: "Section 1, Module 2: Math", section: 'Maths2' }
                    ].map((item, index) => (

                        <Grid item className='sectionTab' xs={12} sm={6} md={2.4} key={index}>
                            <Box
                                onClick={() => handleSectionClick(item.section)}
                                sx={{
                                    backgroundColor: '#fff',
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    border: '1px solid #ccc',
                                    fontWeight: 'bold',
                                    color: '#555',
                                    textAlign: 'center',
                                    wordBreak: 'break-word',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                                style={(moduleview === item.section || testScore === item.section) ? { border: '2px solid rgb(70, 57, 255)' } : { border: '2px solid #f5f5f5' }}
                            >
                                <Typography variant="paragraph" className='sectionTabText' classsx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: (moduleview === item.section || testScore === item.section) ? "#000" : "#555" }}>
                                    {item.text}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {module !== '' && <Box className="module1Section" >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            padding: '20px',
                            marginLeft: '20px',
                            marginRight: '20px',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            marginTop: '20px'
                        }}
                    >
                        {/* <Typography
                            variant="h6"
                            sx={{ margin: '10px 30px 0px', width: '100%', textAlign: 'left' }}
                        >
                            Overall Test Score 
                        </Typography> */}
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                padding: '0px',
                                marginLeft: '10px',
                                marginRight: '10px',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                marginTop: '2px',
                                marginBottom: '8px'
                            }}

                        >
                            <Typography variant="h6" sx={{ margin: '10px 18px 0px', width: '100%', textAlign: 'left' }}>
                                Your performance by Difficulty
                            </Typography>
                            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', padding: '0 20px' }}>


                                <Box
                                    style={{
                                        border: '2px solid #4639ff',
                                        marginBottom: '15px',
                                        height: '170px',
                                        flex: '2',
                                        borderRadius: '8px',
                                        minWidth: '320px'
                                    }}
                                >
                                    <Stack style={{ display: 'flex', flexDirection: 'column' }} direction="row">
                                        <Typography style={{ fontWeight: '600', padding: '10px 0 0 15px' }}>Easy</Typography>
                                        <Box style={{ display: 'flex' }}>
                                            <PieChart
                                                series={[
                                                    {
                                                        startAngle: -90,
                                                        endAngle: 90,
                                                        paddingAngle: 0,
                                                        innerRadius: 60,
                                                        outerRadius: 100,
                                                        data: [
                                                            {
                                                                name: 'Correct',
                                                                value: calculatePercentage(analysis?.questions?.easy) || 0,
                                                                color: '#70bb9c'
                                                            },
                                                            {
                                                                name: 'Incorrect',
                                                                value: (100 - (calculatePercentage(analysis?.questions?.easy) + (analysis?.questions?.easy?.filter(q => q.userResponse === null).length / analysis?.questions?.easy?.length * 100))) || 0,
                                                                color: '#ea4b67'
                                                            },
                                                            {
                                                                name: 'Skipped',
                                                                // value: (analysis?.questions?.easy?.filter(q => q.userResponse === null).length / analysis?.questions?.easy?.length * 100) || 100,
                                                                value: 100 - ((calculatePercentage(analysis?.questions?.easy) || 0) + (100 - ((calculatePercentage(analysis?.questions?.easy) + (analysis?.questions?.easy?.filter(q => q.userResponse === null).length / analysis?.questions?.easy?.length * 100))) || 0)),
                                                                color: '#c8c8c8'
                                                            }
                                                        ],
                                                    },
                                                ]}
                                                margin={{ right: 5 }}
                                                width={250}
                                                height={210}
                                                slotProps={{
                                                    legend: { hidden: true },
                                                }}
                                            >
                                                <PieCenterLabel percentage={calculatePercentage(analysis?.questions?.easy)} />
                                            </PieChart>
                                            <Box style={{ width: '130px', paddingTop: '20px' }}>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#70bb9c', display: 'inline-block', marginRight: '8px' }} /> Correct</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#ea4b67', display: 'inline-block', marginRight: '8px' }} /> Incorrect</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#c8c8c8', display: 'inline-block', marginRight: '8px' }} /> Skipped</Box></Box>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box
                                    style={{
                                        border: '2px solid #4639ff',
                                        marginBottom: '15px',
                                        height: '170px',
                                        flex: 2,
                                        borderRadius: '8px',
                                        minWidth: '350px'
                                    }}
                                >
                                    <Stack style={{ display: 'flex', flexDirection: 'column' }} direction="row">
                                        <Typography style={{ fontWeight: '600', padding: '10px 0 0 15px' }}>Medium</Typography>
                                        <Box style={{ display: 'flex' }}>
                                            <PieChart
                                                series={[
                                                    {
                                                        startAngle: -90,
                                                        endAngle: 90,
                                                        paddingAngle: 0,
                                                        innerRadius: 60,
                                                        outerRadius: 100,
                                                        data: [
                                                            {
                                                                name: 'Correct',
                                                                value: calculatePercentage(analysis?.questions?.medium) || 0,
                                                                color: '#70bb9c'
                                                            },
                                                            {
                                                                name: 'Incorrect',
                                                                value: (100 - (calculatePercentage(analysis?.questions?.medium) + (analysis?.questions?.medium?.filter(q => q.userResponse === null).length / analysis?.questions?.medium?.length * 100))) || 0,
                                                                color: '#ea4b67'
                                                            },
                                                            {
                                                                name: 'Skipped',
                                                                value: 100 - ((calculatePercentage(analysis?.questions?.medium) || 0) + (100 - ((calculatePercentage(analysis?.questions?.medium) + (analysis?.questions?.medium?.filter(q => q.userResponse === null).length / analysis?.questions?.medium?.length * 100))) || 0)),
                                                                // value: (analysis?.questions?.medium?.filter(q => q.userResponse === null).length / analysis?.questions?.medium?.length * 100) || 100,
                                                                color: '#c8c8c8'
                                                            }
                                                        ],
                                                    },
                                                ]}
                                                margin={{ right: 5 }}
                                                width={250}
                                                height={210}
                                                slotProps={{
                                                    legend: { hidden: true },
                                                }}
                                            >
                                                <PieCenterLabel percentage={calculatePercentage(analysis?.questions?.medium)} />
                                            </PieChart>
                                            <Box style={{ width: '130px', paddingTop: '20px' }}>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#70bb9c', display: 'inline-block', marginRight: '8px' }} /> Correct</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#ea4b67', display: 'inline-block', marginRight: '8px' }} /> Incorrect</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#c8c8c8', display: 'inline-block', marginRight: '8px' }} /> Skipped</Box></Box>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box
                                    style={{
                                        border: '2px solid #4639ff',
                                        marginBottom: '15px',
                                        height: '170px',
                                        flex: 2,
                                        borderRadius: '8px',
                                        minWidth: '350px'
                                    }}
                                >
                                    <Stack style={{ display: 'flex', flexDirection: 'column' }} direction="row">
                                        <Typography style={{ fontWeight: '600', padding: '10px 0 0 15px' }}>Complex</Typography>
                                        <Box style={{ display: 'flex' }}>
                                            <PieChart
                                                series={[
                                                    {
                                                        startAngle: -90,
                                                        endAngle: 90,
                                                        paddingAngle: 0,
                                                        innerRadius: 60,
                                                        outerRadius: 100,

                                                        data: [
                                                            {
                                                                name: 'Correct', value: calculatePercentage(analysis?.questions?.complex) || 0,
                                                                color: '#70bb9c'
                                                            },
                                                            {
                                                                name: 'Incorrect', value: (100 - (calculatePercentage(analysis?.questions?.complex) + (analysis?.questions?.complex?.filter(q => q.userResponse === null).length / analysis?.questions?.complex?.length * 100))) || 0,
                                                                color: '#ea4b67'
                                                            },
                                                            {
                                                                name: 'Skipped',
                                                                value: 100 -((calculatePercentage(analysis?.questions?.complex) || 0) + (100 - ((calculatePercentage(analysis?.questions?.complex) + (analysis?.questions?.complex?.filter(q => q.userResponse === null).length / analysis?.questions?.complex?.length * 100))) || 0)),
                                                                // value: (analysis?.questions?.complex?.filter(q => q.userResponse === null).length / analysis?.questions?.complex?.length * 100) || 100,
                                                                color: '#c8c8c8'
                                                            }
                                                        ],
                                                    },
                                                ]}
                                                margin={{ right: 5 }}
                                                width={250}
                                                height={210}
                                                slotProps={{
                                                    legend: { hidden: true },
                                                }}
                                            >
                                                <PieCenterLabel percentage={calculatePercentage(analysis?.questions?.complex)} />
                                            </PieChart>
                                            <Box style={{ width: '130px', paddingTop: '20px' }}>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#70bb9c', display: 'inline-block', marginRight: '8px' }} /> Correct</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#ea4b67', display: 'inline-block', marginRight: '8px' }} /> Incorrect</Box>
                                                <Box><Box style={{ borderRadius: '50%', height: '10px', width: '10px', backgroundColor: '#c8c8c8', display: 'inline-block', marginRight: '8px' }} /> Skipped</Box> </Box>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ padding: 2, margin: "0 20px" }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '20px', marginLeft: '30px' }}>How you did by Difficulty</Typography>
                        {analysis?.assessment_modules?.map((data, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleAccordionChange(`panel${index}`)}
                                sx={{
                                    mb: 2,
                                    // paddingLeft: '8px',
                                    borderRadius: '15px !important',
                                    '&:before': {
                                        display: 'none',
                                    },
                                }}
                            >
                                <AccordionSummary className='iconExplore'
                                    expandIcon={<ExpandMoreIcon style={{
                                        borderRadius: '50%',
                                        border: '1px solid',
                                        width: '30px',
                                        height: '30px',
                                        padding: '5px',
                                        transform: 'rotate(270deg)',
                                        margin: '15px',
                                    }} />}
                                    style={{
                                        borderRadius: expanded === `panel${index}` ? '15px 15px 0 0' : '15px',

                                        flexDirection: 'row-reverse',
                                        '& .MuiAccordionSummary-expandIconWrapper': {
                                            marginRight: '16px',
                                        }
                                    }}
                                >
                                    <Typography variant="body1" sx={{ flexGrow: 1, fontWeight: "bold" }}>
                                        {data.module_name}
                                    </Typography>
                                    <Grid container spacing={2} sx={{ width: "auto", textAlign: "center" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingRight: '16px' }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {calculateCorrectCount(data.matchingQuestions)}
                                            </Typography>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: "50%",
                                                        backgroundColor: 'green',
                                                    }}
                                                />
                                                <Typography variant="caption">correct</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ width: "auto", textAlign: "center" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingRight: '16px' }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {calculateIncorrectCount(data.matchingQuestions)}
                                            </Typography>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: "50%",
                                                        backgroundColor: 'red',
                                                    }}
                                                />
                                                <Typography variant="caption">Incorrect</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ width: "auto", textAlign: "center" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingRight: '16px' }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {calculateNotAnsweredCount(data.matchingQuestions)}
                                            </Typography>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: "50%",
                                                        backgroundColor: '#c8c8c8'

                                                    }}
                                                />
                                                <Typography variant="caption">Not answer</Typography>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} sx={{ width: "auto", paddingLeft: '70px' }}>
                                        {data?.matchingQuestions.map((label, idx) => (
                                            <>
                                                <Box style={{ display: 'flex', width: '100%' }}>
                                                    <span style={{ marginRight: '5px', width: '27px' }}>{idx + 1}.</span>
                                                    <Typography variant="body1" fontWeight="bold" id='QuestionsContent' dangerouslySetInnerHTML={{ __html: label.question_text }} sx={{ alignItems: "center", color: getDotColor(label.userResponse) }} />

                                                </Box>
                                            </>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>

                </Box>}


                {testScore !== '' && <Box className="TestScoreSection" style={{ display: 'block' }}>
                    <Box className="TestScoreViewBoard"
                        sx={{
                            backgroundColor: '#fff',
                            padding: '20px',
                            marginLeft: '20px',
                            marginRight: '20px',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0px',
                            marginTop: '20px'
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ margin: '10px 30px 0px', width: '100%', textAlign: 'left' }}
                        >
                            Overall Test Score
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: { xs: 'center', md: 'end' },
                                // gap: '16px',
                                width: '100%',
                            }}
                        >
                            <Box
                                component="img"
                                src={AnalysisImage}
                                alt="Test Illustration"
                                sx={{
                                    maxWidth: { xs: '250px', md: '315px' },
                                    height: 'auto',
                                    maxHeight: { xs: '180px', md: '240px' },
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                }}
                            />

                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '40px'
                                }}
                            >
                                <Box>


                                    {/* Slider Knob */}
                                    <Box
                                        sx={{
                                            width: '22px',
                                            height: '22px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            border: '2px solid #f57c00',
                                            position: 'absolute',
                                            left: '27%',
                                            top: '-2px',
                                        }}
                                    />
                                </Box>

                                {/* Range Labels */}
                                <Box sx={{ width: '95%' }}>
                                    <Slider
                                        aria-label="Always visible"
                                        value={calculatePoints(analysistestScore?.totalQuestions, analysistestScore?.totalCorrectAnswers, 1600)}
                                        // value={sliderValue}
                                        size="large"
                                        min={0}
                                        max={1600}
                                        step={1}
                                        marks={marksTotal}
                                        valueLabelDisplay="on"

                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box
                        sx={{
                            padding: '20px',
                            marginLeft: '20px',
                            marginRight: '20px',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            marginTop: '20px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                gap: '20px',
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: '#fff',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    flex: 1
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ margin: '30px', width: '100%', textAlign: 'left' }}
                                >
                                    Reading and Writing
                                </Typography>
                                <Box
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginLeft: { xs: 2, md: 5 },
                                        marginRight: { xs: 2, md: 5 },
                                        marginTop: "15px",
                                        width: '100%'
                                    }}
                                >
                                    <Box sx={{ width: '85%' }}>
                                        <Slider
                                            aria-label="Always visible"
                                            // defaultValue={80}
                                            value={calculatePoints(analysistestScore?.sectionWiseResults?.English?.totalQuestions, analysistestScore?.sectionWiseResults?.English?.totalCorrectAnswers, 800)}
                                            size="large"
                                            min={0}
                                            max={800}
                                            step={1}
                                            marks={marks}
                                            valueLabelDisplay="on"

                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                                    <Button className='transit' variant="outlined" sx={{ borderColor: 'blue', color: 'black', backgroundColor: 'white', '&:hover': { backgroundColor: '#f0f0f0' } }}>
                                        Module 1
                                    </Button>
                                    <ArrowRightAltIcon />
                                    <Button className='transit'
                                        variant="outlined"
                                        sx={{
                                            borderColor: 'blue',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0'
                                            }
                                        }}
                                    >
                                        Module 2
                                    </Button>
                                </Box>

                                <Typography
                                    variant="h6"
                                    sx={{ margin: '30px', width: '89%', textAlign: 'left', marginTop: '35px' }}
                                >
                                    Your performance in Module 1 determines the difficulty
                                    level of Module 2</Typography>
                            </Box>

                            <Box
                                sx={{
                                    backgroundColor: '#fff',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    flex: 1
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ margin: '30px', width: '100%', textAlign: 'left' }}
                                >
                                    Mathematics
                                </Typography>

                                <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginLeft: { xs: 0, md: 1 },
                                        marginRight: { xs: 2, md: 5 },
                                        marginTop: "15px",
                                        width: '100%'
                                    }}
                                >
                                    <Box sx={{ width: '85%' }}>
                                        <Slider
                                            aria-label="Always visible"
                                            value={calculatePoints(analysistestScore?.sectionWiseResults?.Maths?.totalQuestions, analysistestScore?.sectionWiseResults?.Maths?.totalCorrectAnswers, 800)}
                                            size="large"
                                            min={0}
                                            max={800}
                                            step={1}
                                            marks={marks}
                                            valueLabelDisplay="on"

                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginTop: '8px'
                                        }}
                                    >
                                        {/* <Typography variant="body2">c</Typography>
                                        <Typography variant="body2">1600</Typography> */}
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px'
                                    }}
                                >
                                    <Button className='transit'
                                        variant="outlined"
                                        sx={{
                                            borderColor: 'blue',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0' // Optional hover effect
                                            }
                                        }}
                                    >
                                        Module 1
                                    </Button>

                                    <ArrowRightAltIcon />

                                    <Button className='transit'
                                        variant="outlined"
                                        sx={{
                                            borderColor: 'blue',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0' // Optional hover effect
                                            }
                                        }}
                                    >
                                        Module 2
                                    </Button>
                                </Box>

                                <Typography
                                    variant="h6"
                                    sx={{ margin: '30px', width: '89%', textAlign: 'left' }}
                                >
                                    Your performance in Module 1 determines the difficulty
                                    level of Module 2</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>}
            </Box>
        </Box>
    );
};

export default SatAnalysis;
