import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const ResponseData = [
  { multiplier: 6, responseRecorded: "incorrect" },
  { multiplier: 3, responseRecorded: "correct" },
  { multiplier: 2, responseRecorded: "incorrect" },
  { multiplier: 4, responseRecorded: "incorrect" },
  { multiplier: 1, responseRecorded: "incorrect" },
  { multiplier: 1, responseRecorded: "correct" },
  { multiplier: 1, responseRecorded: "incorrect" },
  { multiplier: 6, responseRecorded: "incorrect" },
  { multiplier: 4, responseRecorded: "incorrect" },
  { multiplier: 1, responseRecorded: "incorrect" },
  { multiplier: 2, responseRecorded: "correct" },
  { multiplier: 5, responseRecorded: "correct" },
  { multiplier: 2, responseRecorded: "incorrect" }
];

// Aggregate the data for correct and incorrect responses
const correctData = [];
const incorrectData = [];
const xLabels = []; // This will store unique multiplier values for the x-axis

ResponseData.forEach(({ multiplier, responseRecorded }) => {
  if (!xLabels.includes(multiplier)) {
    xLabels.push(multiplier);
  }
  
  if (responseRecorded === 'correct') {
    correctData.push(multiplier);
    incorrectData.push(0);
  } else {
    incorrectData.push(multiplier);
    correctData.push(0);
  }
});

export default function StackedBarChart() {
  return (
    <BarChart
      width={500}
      height={300}
      series={[
        { data: correctData, label: 'Correct', id: 'correctId', stack: 'total' },
        { data: incorrectData, label: 'Incorrect', id: 'incorrectId', stack: 'total' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
  );
}
