import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Box, Button, CardMedia, Grid } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import ShieldIcon from "@mui/icons-material/Shield";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ArrowForwardIosOutlined, MenuBook } from "@mui/icons-material";
import Background from '../../assets/logo/background.svg';


export default function SatCard(props) {

    const topics = [
        { title: "Words in Context" },
        { title: "Cross-Text Connections" },
        { title: "Text Structure and Purpose" },
    ];

    // console.log(props, "props");
    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    const classes = useStyles();
    const navigate = useNavigate();

    // const handleStartSat = () => {
    //     navigate("/auth/SatOverview",{state:props?.data?.id})  
    // }

    const handleStartSat = () => {
        navigate("/auth/SatOverview", {
            state: {
                id: props?.data?.id,
                from: "SAT",
                data: props?.data
            }
        });
    }

    return (
        <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} className={classes.CardView}>
                    <Card
                        sx={{
                            background: "linear-gradient(to bottom, #7761F3, #22223B, #22223B, #22223B)",
                            color: "white",
                            borderRadius: "12px",
                            overflow: "hidden",
                        }}
                    >
                        <CardContent sx={{ p: 0 }}>
                            <Button
                                fullWidth
                                sx={{
                                    // background: "rgba(255, 255, 255, 0.2)",
                                    background: 'linear-gradient(135deg, #9162f7,rgb(125, 64, 255))',
                                    borderRadius: "0",
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    py: 1.5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    gap: 1,
                                }}
                                onClick={handleStartSat}
                            >
                                <Box className={classes.shieldDesign}>  <ShieldIcon fontSize="small" /></Box>
                                Get started <ArrowForwardIosIcon style={{    fontSize: '14px', fontWeight: '800',  paddingTop: '0px'}}/>
                              
                            </Button>
                            <Box className={classes.cardDescription} sx={{ p: 2, textAlign: "center", minHeight: "60px" }}>
                                <Typography variant="body1">{props?.data?.title}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
             </Grid>

            {/* <Card
                className="cardhover"
                sx={{
                    // cursor: 'pointer',
                    minHeight: '280px',
                    maxHeight: '280px',
                    display: 'flex',
                    flexDirection: 'column',
                }} >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "linear-gradient(to right, #7B61FF, #5A4FE5)",
                        padding: "16px",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            padding: "8px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        
                        <MenuBook sx={{ color: "white" }} />
                    </Box>

                    <Typography
                        sx={{
                            color: "white",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handleStartSat}
                        
                    >
                        Get started
                        <ArrowForwardIosOutlined  sx={{ fontSize: "14px", marginLeft: "5px" }} />
                    </Typography>
                </Box>

                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '12px' }}>
                    <Typography>{props?.data?.title}</Typography>
                </CardContent>
            </Card> */}
        </Box>
    );
}
const useStyles = makeStyles((theme) => ({
    trial: {
        color: 'white',
        padding: '0px 6px 0px 6px ',
        background: 'grey',
    },
    CardView: {
        minWidth: '300px',
        maxWidth: '320px'
    },
    cardDescription: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        height: '120px'
    },
    shieldDesign: {
        border: '1px solid #c1c1c1',
        background: '#dddddd69',
        borderRadius: '50%',
        padding: '5px',
        height: '33px',
        width: '33px',
    },
    coursetitle: {
        overflow: 'hidden',
        // color: 'black',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.down('md')]: {
            overflow: 'auto',
            WebkitLineClamp: 'initial',
            WebkitBoxOrient: 'initial',
        },


    },
    card: {
        border: '0.5px solid #DFDFDF',
        boxShadow: '0px 3px 6px  #0000001A',
        borderRadius: '6px',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
        // cursor: 'pointer',
        marginTop: '16px',
        marginLeft: '10px',
        width: '95%',
        // width: '85%', // Reduced from 90% to 85%
        // height: 'auto', // You can add this if needed
        // maxHeight: '300px', // Add maxHeight to limit overall card height
    },
    date: {
        marginTop: '15px',
    },
    button: {
        backgroundColor: '#F8F5F4',
        padding: '4px 6px',
        borderRadius: '6px',
        width: 'max-content',
        maxWidth: '165px',
        fontSize: '12px',
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    title: {
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: '15px',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    cardTitlenew: {
        fontWeight: 'bold',
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        minHeight: '50px',
        maxHeight: '50px',
    },
    logo: {
        boxShadow: '0px 3px 22px #00000029',
        padding: '4px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        marginTop: '10px',
    },
    description: {
        minHeight: '38px',
        fontSize: '0.8rem !important',
        overflow: 'hidden !important',
        fontFamily: 'Inter',
        textOverflow: 'ellipsis',
        '-o-text-overflow': 'ellipsis',
        '-ms-text-overflow': 'ellipsis',
        '-moz-binding': "url('ellipsis.xml#ellipsis')",
        '-ms-webkit-line-clamp': 2,
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '& span': {
            fontSize: '0.8rem !important',
            color: 'black !important',
            backgroundColor: 'unset !important',
            fontFamily: 'Inter !important',
        },
        '& p': {
            '&:nth-child(1)': {
                display: 'block !important',
            },
            '&:nth-child(even)': {
                display: 'none ',
            },
            '&:nth-child(odd)': {
                display: 'none ',
            },
        },
    },
    enrolledUser: {
        backgroundColor: '#EBFFF8',
        borderRadius: '6px',
        padding: '4px 12px',
        fontSize: '12px',
    },
    active: {
        backgroundColor: 'green',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    inActive: {
        backgroundColor: 'yellow',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    Expired: {
        backgroundColor: '#FF8282',
        borderRadius: '6px',
        padding: '4px 12px',
    },
    trialButton: {
        width: 110,
        borderRadius: '6px',
        fontSize: '12px',
        backgroundColor: 'white',
        '@media (max-width: 1400px)': {
            padding: '6px 3px',
            lineHeight: '1',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        marginRight: '8px',
    },
    // CourseButton: {
    //     width: 110,
    //     color: '#00B673 ! important',
    //     border: '1px solid #00B673 ! important',
    //     borderRadius: '6px',
    //     fontSize: '12px',
    //     // marginLeft: '1rem',
    //     backgroundColor: 'white',
    //     '&:hover': {
    //         backgroundColor: 'white',
    //     },
    // },
    clamptext: {
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        minHeight: '2.4em',
        lineHeight: '1.2em'
        //    min-height: 2.4em;                 
        //    line-height: 1.2em;  
    },

    CourseButton: {
        width: 110,
        color: '#6a6e6b', // initial text color
        border: '1px solid #6a6e6b', // initial border color
        borderRadius: '6px',
        fontSize: '12px',
        backgroundColor: 'white',
        '&:hover': {
            color: '#00B673', // text color on hover
            border: '1px solid #00B673', // border color on hover
            backgroundColor: 'white',
        },
    },
    subscribeButton: {
        width: 110,
        color: '#00B673 ! important',
        border: '1px solid #00B673 ! important',
        borderRadius: '6px',
        fontSize: '12px',
        // marginLeft: '1rem',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));