import React from 'react';
import { List, Typography, useTheme, ListItemButton, ListItemIcon, ListItemText,Box } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Iconify from '../../components/Iconify';
import './scrollStyle.css';

const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: '1px solid #BCBCBC',
    padding: 12,
    "&:hover": {
      color: "#fff"
   }
  },
  skillIqTest: {
    padding: '4px 14px',
    borderRadius: '6px',
    margin: 'auto',
    border: '1px solid #FE7000',
    width: 'max-content',
    marginTop: '1rem',
    cursor: 'pointer',
  },        

}));

const TopicList = ({ data, onClickCallBack, onReferenceButtonClick, onVideoClick, selectedIndex, submoduleId, location, subModuleName,status }) => {  
//  console.log(data,"datadata");
 
 
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo?.role);

  const handleListItemClick = (event, index, item) => {
    // console.log(item,"itemitemitemitemitemitem111111");
    
    if (item.actionType?.code === 'VIDEO') {
      
      onVideoClick(item);
    } else if (item?.actionType?.code === 'REFERENCE') {
      onReferenceButtonClick(submoduleId,subModuleName);
    } else {
      console.log('index=>',index,event);
      if(event.key ==='Enter')
      {
        console.log('Inside Enter...')
        // event.preventDefault();
        return
      }
      console.log('before onClickCallBack...')
      onClickCallBack(index,event);
    }
  };

  // const renderIcon = (item) => {
  //   if (item.actionType?.code === 'VIDEO') {
  //     return <VideoLibraryIcon sx={{ color: theme.palette.primary.main }} />;
  //   } 
  //    if (item.actionType?.code === 'REFERENCE') {
  //     return <Iconify icon="ant-design:book-outlined" width={18} height={18} sx={{ color: 'blue' }} />;
  //   } {
  //     const iconColor = selectedIndex === item.id ? theme.palette.primary.main : theme.palette.secondary.main;
  //     const iconType = item.isCompleted ? 'ant-design:check-circle-outlined' : 'akar-icons:circle';
  //     return <Iconify icon={iconType} width={18} height={18} sx={{ color: iconColor }} />;
  //   }
  // };

  const renderIcon = (item) => {
    // return <VideoLibraryIcon sx={{ color: theme.palette.primary.main }} />;
    if (item === "SkillSet IQ") {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.warning.main}`,
            color: theme.palette.warning.main,
            fontSize: '0.75rem',
          }}
        >
          <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
            {'Q'}
          </Typography>
        </Box>

      );
    }
    if(item !== "SkillSet IQ"){
      
      return <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.warning.main}`,
          color: theme.palette.warning.main,
          fontSize: '0.75rem',
        }}
      >
        <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
   
          {item?.type ? item.type.charAt(0).toUpperCase() :"S"}

        </Typography>
      </Box>
    
    }
};


const handleNavigateSkill = () => {
  const keyType = { actionType: data[0]?.actionType?.code === 'CODE' ? 'CODE' : 'HOTKEYS' };
  const path = ['AUTH_USER', 'SUPER_ADMIN', 'CONTENT_WRITER'].includes(userRole) ?
    `/app/skilliq-test?subModuleId=${submoduleId}` :
    `/auth/skilliq-test?subModuleId=${submoduleId}`;

  navigate(path, { state: { ...location.state, ...keyType ,subModuleName} });
}

  return (
    <>
      <List
        sx={{
          width: '100%',
          marginTop: 0,
          bgcolor: 'background.paper',
          paddingTop: '0',
          maxHeight: 'calc(100vh - 322px)',
          overflow: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollSnapType: 'y proximity',
          scrollBehavior: 'smooth',
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {data && data?.length > 0 && data.map((item, index) => {
          const isSelected = selectedIndex === item.id;
          const isCompleted = item.isCompleted;

          return (
            <ListItemButton
              className={classes.listItem}
              selected={isSelected}
              onClick={status ? (event) => handleListItemClick(event, index, item) : undefined}
              key={`topic-${index}`}
              sx={{
                color: isCompleted ? theme.palette.secondary.main : theme.palette.grey[500],
                scrollSnapAlign: isSelected ? 'start' : 'none',
                scrollMargin: isSelected ? '100px' : '0px',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.grey[200],
                },
                '&.Mui-selected:hover': {       
                  color: "#FE7000",        
                  backgroundColor: theme.palette.secondary.lighter,             
                },
                '&:hover': {
                  backgroundColor: theme.palette.secondary.lighter,
                },
              }}
            >
              <ListItemIcon>
                {renderIcon(item)}
              </ListItemIcon>
              <ListItemText primary={item.courseSubmoduleTopics} />
            </ListItemButton>
          );
        })}

<ListItemButton
              className={classes.listItem}
              // selected={isSelected}
              onClick ={status?() => handleNavigateSkill():undefined}

              // onClick={() => {
              //   const keyType = { actionType: data[0]?.actionType?.code === 'CODE' ? 'CODE' : 'HOTKEYS' };
              //   const path = ['AUTH_USER', 'SUPER_ADMIN', 'CONTENT_WRITER'].includes(userRole) ?
              //     `/app/skilliq-test?subModuleId=${submoduleId}` :
              //     `/auth/skilliq-test?subModuleId=${submoduleId}`;
      
              //   navigate(path, { state: { ...location.state, ...keyType ,subModuleName} });
              // }}
              // key={`topic-${index}`}
              sx={{
                color: theme.palette.grey[500],
                scrollSnapAlign:'start' ,
                scrollMargin:'100px',
                '&.Mui-selected': {
                  color: theme.palette.grey[500],
                  backgroundColor: theme.palette.grey[200],
                },
                '&.Mui-selected:hover': {
                  backgroundColor: theme.palette.secondary.lighter,
                },
                '&:hover': {
                  backgroundColor: theme.palette.secondary.lighter,
                },
              }}
            >
              <ListItemIcon>
                {renderIcon('SkillSet IQ')}
              </ListItemIcon>
              <ListItemText primary={'SkillSet IQ'} />
            </ListItemButton>
      </List>



      {/* <Typography
        className={classes.skillIqTest}
        variant="subtitle1"
        onClick={() => {
          const keyType = { actionType: data[0]?.actionType?.code === 'CODE' ? 'CODE' : 'HOTKEYS' };
          const path = ['AUTH_USER', 'SUPER_ADMIN', 'CONTENT_WRITER'].includes(userRole) ?
            `/app/skilliq-test?subModuleId=${submoduleId}` :
            `/auth/skilliq-test?subModuleId=${submoduleId}`;

          navigate(path, { state: { ...location.state, ...keyType } });
        }}
        sx={{ textDecoration: 'none !important' }}
        color="primary"
      >
        SkillSet IQ
      </Typography> */}
    </>
  );
};

export default TopicList;
