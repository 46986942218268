import React, { useState, useEffect } from "react";
import {
    Grid, Typography, Container, Button, IconButton, TextField,
    InputAdornment, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TablePagination, Switch
} from '@mui/material';
import moment from 'moment'
import DOMPurify from "dompurify";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Page from '../../../components/Page'
import PageHeader from '../../../components/PageHeader';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';
import CreateSATQuestionModel from './CreateSatQuestions';
import EditQuestionModule from "./EditSATQuestions";

import adminServices from '../../../services/adminServices';

const SatAssessment = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openCreateSat, setOpenCreateSat] = useState(false);
    const [openEditMcq, setOpenEditMcq] = useState(false);
    const [newData,setNewData] = React.useState('');
    const [searchedDetails, setSearchedDetails] = useState('');
    const [satDetails, setSatDetails] = useState([]);
    const [total, setTotal] = useState('');
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [deleteModalTitle, setDeleteModalTitle] = useState('');
    const [deleteId, setDeleteId] = useState('')
    const [deleteType, setDeleteType] = useState('')
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getSATDetails()
    }, [page, rowsPerPage, searchedDetails])


    const getSATDetails = async () => {
        const result = await adminServices.getSATDetails(page, rowsPerPage, searchedDetails);
        if (result.ok) {
             const reverseOrderQuestionList = result.data.questions?.reverse()
            setSatDetails(reverseOrderQuestionList)
            setTotal(parseInt(result.data.total,10)) 
        }

    }


    const handleEdit = (values)=>{
        setNewData(values)
         setOpenEditMcq(true)
      }
    const handleCreateSAT = () => {
        setOpenCreateSat(true)
    }

      const handleEditCallBack = () =>{
        setOpenEditMcq(false)
      }
    const CallBackRemove = () => {
        setOpenCreateSat(false)
        getSATDetails()
    }
    const CallBackEditRemove = () => {
        setOpenEditMcq(false)
        getSATDetails()
    }
    

    const handleDelete = (data) => {
        setDeleteAlert(true);
        setDeleteId(data.id);
        setDeleteType(data?.question_type);
        setDeleteModalTitle('Are you sure you want delete this Sat Question?');
    }

    const handleDeleteSat = async () => {
        try {
            const response = await adminServices.deleteSATDetails(deleteId,deleteType);
            if (response.ok) {
                setSnackbarTitle('Sat Question deleted successfully');
                setOpenSnackbar(true);
                getSATDetails();
                setDeleteAlert(false)
                setDeleteId('')
                setDeleteType('')
                setDeleteModalTitle("");
            }
        } catch (error) {
            console.log(error);
        }
    }
const handleSearch = (searchedDetails) => {
    setPage(0);
    setSearchedDetails(searchedDetails);
   
}

    return (
        <Page title="SAT Assessment Questions">
            <PageHeader pageTitle="SAT Assessment Questions" submodule="submodule" />
            <>
                <Grid container spacing={2}>
                    <Grid align="end" item xs={12}>
                        <TextField
                            variant="outlined"
                            placeholder="Search Name"
                            id="searchassessment"
                            size="small"
                            sx={{ width: '250px', height: '40px' }}
                            value={searchedDetails}
                            onChange={(e) => handleSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon id={`editsat`} />
                                    </InputAdornment>
                                ),
                                sx: { height: '100%' },
                            }}
                        />
                        <Button
                            style={{ marginLeft: '15px', height: '40px' }}
                            id="createassessmentButtongeneral"
                            variant="contained"
                            color="primary"
                            onClick={handleCreateSAT}
                        >
                            Create SAT
                        </Button>
                    </Grid>
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table sx={{ marginLeft: '15px', width: '98%' }}>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', paddingLeft: '8px !important' }}>Name</TableCell> */}
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>author</TableCell>

                                    {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Live</TableCell> */}
                                    {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Questions</TableCell> */}
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Level</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Question</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Date</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {loading && (
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                )} */}

                                {satDetails && satDetails?.length > 0 && (
                                    satDetails?.map((row) =>{
                                        
                                        return(
                                        <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                            {/* <TableCell sx={{ padding: '10px' }}>{row?.assessment?.title}</TableCell> */}
                                            {/* <TableCell sx={{ marginLeft: '40px' }}>{row?.question_type}</TableCell> */}


                                            {/* <TableCell sx={{ marginLeft: '40px' }}>
                                                <Switch
                                                    checked={row?.assessment.is_published === true}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase': {
                                                            color: row?.assessment.is_published ? 'green' : 'default',
                                                        },
                                                        '& .Mui-checked': {
                                                            color: 'green',
                                                        },
                                                        '& .Mui-Track': {
                                                            backgroundColor: row?.assessment.is_published ? 'green' : 'default',
                                                        },
                                                    }}
                                                    // onChange={() => {
                                                    //     const newValue = !row?.assessment.is_published;
                                                    //     handleToggleButton(row.assessment.id, newValue);
                                                    // }}
                                                />
                                            </TableCell> */}

                                         <TableCell dangerouslySetInnerHTML={{ __html: row?.question_type }} sx={{ marginLeft: '40px' }}/>
                                         <TableCell  sx={{ marginLeft: '40px' }}>{row?.created_by?.firstname}</TableCell>

                                            <TableCell sx={{ marginLeft: '40px' }}>{row?.level.charAt(0).toUpperCase() + row?.level.slice(1).toLowerCase()}</TableCell>

                                            
                                            <TableCell dangerouslySetInnerHTML={{ __html: row?.question_text }} />
                                            <TableCell>
                                                {moment(row.created_date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                <IconButton
                                                    id={`editsat${row.id}`}
                                                    onClick={() => handleEdit(row)}
                                                    color="primary">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton id={`deletesat${row.id}`}
                                                    onClick={() => handleDelete(row)}
                                                    color="primary">
                                                    <DeleteIcon />
                                                </IconButton>
                                             
                                            </TableCell>
                                        </TableRow>
                                    )})
                                )
                                    // : (
                                    //     <TableRow>
                                    //         <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                    //             {!loading && openCreateSat && openCreateSat?.length === 0 && "No Data Found"}
                                    //         </TableCell>
                                    //     </TableRow>
                                    // )
                                }
                            </TableBody>




                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        sx={{ marginTop: 2 }}
                    />
                    <DeleteAlert
                        open={deleteAlert}
                        title={deleteModalTitle}
                        confirm={handleDeleteSat}
                        close={() => setDeleteAlert(false)}
                    />
                    <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
                    <CreateSATQuestionModel
                        open={openCreateSat}
                        modelClose={() => setOpenCreateSat(!openCreateSat)}
                        title="Create Sat"
                        // handleCreateMcq={handleSubmitForm}
                        loading={loading}
                        // searchedDetails={searchedDetails}
                        CallBack={CallBackRemove}
                    />
                     <EditQuestionModule
                        open={openEditMcq}
                        modelClose={() => setOpenEditMcq(!openEditMcq)}
                        title="Edit SAT"
                        handleEditMcq={handleEditCallBack}
                        loading={loading}
                        searchedDetails={searchedDetails}
                        data ={newData}
                        CallBack={CallBackEditRemove}
                    />
                </Grid>
            </>
        </Page>
    )
}

export default SatAssessment;