
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {
    TextField, Button, Chip, Box, Badge, Avatar, Paper, Typography, InputAdornment, IconButton, FormHelperText, InputLabel, Select, FormControl, Grid, Dialog,
    DialogActions, DialogContent,
    Checkbox,
    ListItemText, CardContent,
    DialogTitle, Alert, Radio, RadioGroup, FormControlLabel, FormGroup, CardActionArea, Card
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import SearchIcon from "@mui/icons-material/Search";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { DropzoneArea } from 'material-ui-dropzone';
import DOMPurify from 'dompurify';
import { makeStyles } from '@mui/styles';
import katex from "katex";
import DialogModal from '../../../components/modal/DialogModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';
import Page from '../../../components/Page'
import PageHeader from '../../../components/PageHeader';
import './styles.css'
import "katex/dist/katex.min.css";

window.katex = katex;

const modules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ header: [1, 2, 3, false] }],
        ["image", { formula: { customClass: 'qlformula' } }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};


const descriptionModules = {
    toolbar: [
        ["bold", "italic", "underline"], // toggled buttons
        // ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, false] }],
        [{ color: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const descriptionFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align",
    "code-block",
    "script",
    "clean",
];
const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "formula",
    "color",
    "background",
    "align",
    "code-block",
    "script",
    "clean",
];

const SatTest = () => {
    const userInfo = useSelector((state) => state.userInfo && state.userInfo);
    const navigate = useNavigate();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [hashtag, setHashtag] = useState([]);
    const [category, setCategory] = useState('');
    const [course, setCourse] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [name, setName] = useState('');
    const [questionid, setQuestionid] = useState('');
    const [questionDetails, setQuestionDetails] = useState('');
    const [complexity, setComplexity] = useState('');
    const [nameError, setNameError] = useState('');
    const [ImageError, setImageError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [courseError, setCourseError] = useState('');
    const [ComplexityError, setComplexityError] = useState('');
    const [fields, setFields] = useState(['']);
    const [error, setError] = useState(false);
    const [requirederrors, setrequiredErrors] = useState({
        moduleName: "",
        points: "",
        questionid: "",
    });
    const [questionerrors, setQuestionErrors] = useState("")
    const [nodata, setNodata] = useState(true);
    const [categoryList, setCategoryList] = useState([])
    const [courseList, setCourseList] = useState([])
    const [thumbImage, setThumbImage] = useState(null);
    const [thumbPreview, setThumbPreview] = useState(null);
    const [description, setDescription] = useState("");
    const [editorValue, setEditorValue] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [OpenDialogNew, setOpenDialogNew] = useState(false);
    const [visible, setVisible] = useState(true);
    const [editData, setEditData] = useState("");
    const [editIndex, setEditIndex] = useState("");
    const [editIndexnew, setEditIndexnew] = useState("");
    const [shortdescription, setShortDescription] = useState('');
    const [editDialog, setEditDialog] = useState(false);
    const [query, setQuery] = useState('');
    const [moduleName, setModuleName] = useState('');
    const [selectIndex, setSelectIndex] = useState('');
    const [passage, setPassage] = useState('');
    const [explanation, satExplanation] = useState('');
    const [selectedOptionnew, setSelectedOptionnew] = useState(null);

    const [points, setPoints] = useState('');
    const [values, setValues] = useState({
        question: '',
        questionType: 'MCQ',
        mcqOptions: [{ option: '', isCorrect: false }],
    });


    const [satValues, setSatValues] = useState({
        question: '',
        questionType: 'English',
        mcqOptions: [{ option: '', isCorrect: false }],
    });




    const [mathsValues, setMathsValues] = useState({
        question: '',
        questionType: 'Maths',
        mcqOptions: [{ option: '', isCorrect: false }],
    });
    console.log(mathsValues,'mathsValues');

    const [moduleError, setModuleError] = useState({
        moduleName: "",
        weightage: "",
        questionid: "",
        level: '',
        Objective: '',

    });

    const [questionError, setQuestionError] = useState({
        passage: "",
        question: "",
        option: "",
        Objective: '',
        explanation: ''


    });


    const [questionWeightage, setQuestionWeightage] = useState({});

    const [open, setOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedWeightage, setSelectedWeightage] = useState('');
    const [questions, setQuestions] = useState(['Question 1', 'Question 2', 'Question 3']);
    const [weightages, setWeightages] = useState([10, 20, 30]);

    const [moduleData, setModuleData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageAll, setErrorMessageAll] = useState("");
    const [selectedOption, setSelectedOption] = useState('create');
    const [newQuestion, setNewQuestion] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSkills, setSelectedSkills] = useState('');
    const [loadingnew, setLoadingnew] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [hasCertificate, setHasCertificate] = useState(false);
    const [Preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState('');

    const [details, setDetails] = useState({ level: "", questionType: "English" });


    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "formula",
        "color",
        "background",
        "align",
        "code-block",
        "script",
        "clean",
    ];



    const ClearError = () => {
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
        });
    }




    const handleChangeCheck = (event) => {
        setQuestionError(
            {
                Objective: ''
            }
        )
        setSelectedSkills(event.target.value);
    };
    const handleChangeDescription = (value) => {
        const cleanedValue = value
        .replace(/<p><br><\/p>/g, '')
        .replace(/<p><\/p>/g, '') 
        .trim();    
        setDescriptionError("");
        setEditorValue(cleanedValue);
    };

    const handleChangeHighlightedDescription = (index, value) => {
        setErrorMessage("");
        const updatedFields = [...fields];
        updatedFields[index] = value;
        setFields(updatedFields);
    };

    const handleAddField = () => {
        setErrorMessage("");
        const isAnyFieldEmpty = fields.some(field => field.trim() === '');
        if (isAnyFieldEmpty) {
            setError(true);
        } else {
            setError(false);
            if (fields.length < 6) {
                setFields([...fields, '']);
            }
        }
    };

    const handlePreview = () => {
        setPreview(false)
        setEditIndexnew('')
        setPreviewData('')
    }
    const handlePreviewOpen = (data, index) => {
        setEditIndexnew(index)
        setPreviewData(data)
        setPreview(true)
    }
    const DeleteQuestion = (index) => {
        setPreviewData((prev) => ({
            ...prev,
            questions_list: prev?.questions_list.filter((_, i) => i !== index),
            questiondetails: prev?.questiondetails.filter((_, i) => i !== index)
        }));
    };

    const handleUpdateQuestions = (editIndexnew) => {
        setModuleData((prevModuleData) =>
            prevModuleData.map((item, index) =>
                index === editIndexnew ? { ...item, ...previewData } : item
            )
        );
        setPreview(false);
    };
    const handlePoints = (e) => {
        // setPoints(e.target.value.replace(/\D/, '')); 
        // ClearError()

        const value = e.target.value.replace(/\D/, '');
        if (value === '0') {
            setrequiredErrors({
                points: "Value cannot be 0",
            })
        } else {
            setrequiredErrors({
                points: "",
            })
        }
        setPoints(value);
    }

    useEffect(() => {
        getCategory()
        setLoading(false);
        getQuestionList(details.questionType);
    }, [details.questionType]);


    useEffect(() => {
        if (category) {
            getCourse(category)
        }
    }, [category])


    const getCourse = async (category) => {
        const result = await adminServices.getCourseBasedOnId(category);
        if (result.ok) {
            setCourseList(result.data)
        }
    }

    const sanitizeConfig = {
        ALLOWED_TAGS: ['b', 'i', 'em', 'a', 'ul', 'ol', 'li'],
    };


    const getQuestionList = async (data) => {
        const result = await adminServices.getSatQuestion(data)
        setQuestionList(result.data)

        // const result = await adminServices.getQuestionDataMCQ('+ex');
        if (result.ok) {
            if (result.data?.length === 0) {
                setNodata(true)
            }
            else {
                setNodata(false)
            }
        }
    };


    const handleChangeMathsQuestion = (field, value) => {
        setQuestionError({
            Objective: '',
            question: ''
        });
        setMathsValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }

    const handleMCQOptionChangeMaths = (index, field, value) => {
        setQuestionError({ option: '', })

        const newMCQOptions = [...mathsValues.mcqOptions];
        newMCQOptions[index][field] = value;
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };


    const handleMCQOptionChangeEnglish = (index, field, value) => {
        const newMCQOptions = [...satValues.mcqOptions];
        newMCQOptions[index][field] = value;
        setQuestionError({ option: '', })
        setSatValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));

        // setSatValues((prevState) => {
        //     const newQuestions = [...prevState.questions];
        //     newQuestions[questionIndex].mcqOptions[optionIndex] = {
        //         ...newQuestions[questionIndex].mcqOptions[optionIndex],
        //         [field]: value,
        //     };
        //     return { ...prevState, questions: newQuestions };
        // });

    };

    const handleRemoveOptionMaths = (index) => {
        const newMCQOptions = [...mathsValues.mcqOptions];
        newMCQOptions.splice(index, 1);
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };


    const handleRemoveOptionEnglish = (index) => {
        const newMCQOptions = [...satValues.mcqOptions];
        newMCQOptions.splice(index, 1);
        setSatValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };



    // const handleRemoveOptionEnglish = (questionIndex, optionIndex) => {
    //     setSatValues((prevState) => {
    //         const newQuestions = [...prevState.questions];
    //         newQuestions[questionIndex].mcqOptions.splice(optionIndex, 1);
    //         return { ...prevState, questions: newQuestions };
    //     });
    // };

    const handleAddOptionMaths = () => {
        setMathsValues((prevState) => ({
            ...prevState,
            mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
        }));
    };


    const handleFileChange = (file) => {
        setImageError("")
        if (file[0]?.size < 2097152) {
            imageWidthAndHeight(file[0]).then((res) => {
                if (res.width >= 360 && res.height >= 200) {
                    const url = URL.createObjectURL(file[0]);
                    setThumbPreview(url);
                    setThumbImage(file[0]);
                } else {
                    alert("Image dimensions must be at least 360x200px.");
                }
            });
        }
        // else {
        //   alert("File size exceeds the 2MB limit.");
        // }
    };

    const imageWidthAndHeight = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = function () {
                img.onload = function () {
                    resolve({ width: img.width, height: img.height });
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const getCategory = async () => {
        const result = await adminServices.getCategoryLists();
        if (result.ok) {
            setCategoryList(result.data)
        }
    };


    const handleReset = () => {
        setValues({
            question: '',
            questionType: 'MCQ',
            mcqOptions: [{ option: '', isCorrect: false }]
        });
    };
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [currectError, setCurrectError] = useState('');

    const handleChangeQuestion = (field, value) => {
        setValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeQuestionEnglish = (field, value) => {

        setQuestionError({
            Objective: ''
        });
        setSatValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
        // setSatValues((prevState) => {
        //     const newQuestions = [...prevState.questions];
        //     newQuestions[index] = {
        //         ...newQuestions[index],
        //         [field]: value,
        //     };
        //     return { ...prevState, questions: newQuestions };
        // });
    };

    const handleMCQOptionChange = (index, field, value) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions[index][field] = value;
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };

    const handleAddOption = () => {
        if (values.mcqOptions.every(option => option.option.trim())) {
            setValues((prevState) => ({
                ...prevState,
                mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
            }));
            setSubmitError('');
        }
    };

    const handleAddOptionEnglish = () => {
        setSatValues((prevState) => ({
            ...prevState,
            mcqOptions: [...prevState.mcqOptions, { option: '', isCorrect: false }],
        }));
    };




    const handleRemoveOption = (index) => {
        const newMCQOptions = [...values.mcqOptions];
        newMCQOptions.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            mcqOptions: newMCQOptions,
        }));
    };



    const Validation = () => {

        if (!name) {
            setNameError("This field is required")
            return false
        }

        if (!complexity) {
            setComplexityError("This field is required")
            return false
        }

        if (!thumbImage) {
            setImageError("This field is required")
            return false
        }
        if (!editorValue) {
            setDescriptionError("This field is required")
            return false
        }
        if (editorValue?.length > 255) {
            setDescriptionError("Description cannot be more than 255 characters");
            return false;
        }

        if (moduleData?.length === 0) {
            setErrorMessage("Please fill out the question and weightage before adding a new one.");
            return false
        }
        const totalWeightage = moduleData.reduce((acc, module) => acc + parseFloat(module.weightage || 0), 0);

        if (totalWeightage !== 200) {
            setErrorMessage("The total weightage must be exactly 200.");
            return false;
        }
        // if (fields && fields?.length < 3) {
        //     setErrorMessage("Please add highlighted Description must be exactly 3.");
        //     return false;
        // }
        return true
    }

    const QuestionValidationSubmit = () => {
        if (!values.question) {
            // setQuestionErrors("Question and options are required!")
            setrequiredErrors({
                questionid: "Please Submit question and Answer!",
            })
            return false
        }
        return true
    };




    const handleSubmit = async () => {
        const valid = Validation()


        if (valid) {
            setLoading(true);

            const totalSeconds = (time.hours * 3600) + (time.minutes * 60) + time.seconds;
            const selectedQuestions = moduleData && moduleData?.map(module => module.questions_list).flat()

            const formData = new FormData();
            formData.append('name', name);
            formData.append('complexity_level', complexity);
            formData.append('description', editorValue);
            // formData.append('time_in_mins', totalSeconds);
            formData.append('time_in_mins', 7920);
            formData.append('thumbImage', thumbImage);
            formData.append('modules', JSON.stringify(moduleData));
            formData.append('is_published', false);
            formData.append('userId', userInfo.id);
            formData.append('selectedQuestions', selectedQuestions);
            formData.append('is_free', isFree);
            formData.append('hasCertificate', hasCertificate);
            formData.append("short_description", shortdescription);

            try {
                const response = await adminServices.SatCreation(formData);
                if (response.ok) {
                    setSnackbarTitle('SAT created successfully');
                    setOpenSnackbar(true);
                    navigate("/app/question-book")
                    CloseFunction()
                    setModuleData([])

                    setValues({
                        question: '',
                        questionType: 'MCQ',
                        mcqOptions: [{ option: '', isCorrect: false }],
                    });
                    setName('')
                    setLoading(false);
                }

            } catch (error) {
                console.log(error);
            }

        }
    };

    const CloseFunction = () => {
        setComplexity("")
        setCategory("")
        setName("")
        setEditorValue("")
        setQuestionList([])
        setSelectedQuestions([])
        setTags([])
        setIsSearched(false);
        setNameError("")
        setCategoryError("")
        setComplexityError("")
        setImageError("")
        setDescriptionError("")
        setDescription("")
        setThumbPreview(null)
        setThumbImage(null)
        setQuestionError({
            passage: "",
            question: "",
            option: "",
            Objective: '',
            explanation: ''
    
    
        });
        setrequiredErrors({
            questionid: "",
        })
    }

    const [time, setTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTime((prevTime) => ({
            ...prevTime,
            [name]: value,
        }));
    };

    const hoursArray = Array.from({ length: 24 }, (_, i) => i);
    const minutesArray = Array.from({ length: 60 }, (_, i) => i);
    const secondsArray = Array.from({ length: 60 }, (_, i) => i);



    const handleDialogOpen = (data) => {
        setErrorMessageAll("")
        if (!name || !complexity || !editorValue) {
            setErrorMessageAll("Please fill out the Above Details.");
        } else {
            setDetails(prevDetails => ({
                ...prevDetails,
                questionType: data
            }));
            setOpen(true);
        }

    };









    const handleClose = () => {
        setModuleName('');
        setPoints("")
        setSubmitted(false);
        setQuestionid('')
        setQuestionDetails('')
        setVisible(true);
        setSelectedOption('create')
        setDetails({ level: "", questionType: "English" });
        setSelectedSkills("")
        setPassage("")
        setValues({});
        satExplanation('');
        setSatValues({});
        setMathsValues({});
        setTimeout(() => {
            setValues({
                question: '',
                questionType: 'MCQ',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setSatValues({
                question: '',
                questionType: 'English',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setMathsValues({
                question: '',
                questionType: 'Maths',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
        }, 0);
        setSelectedSkills("");
        setOpen(false);
        setQuestionError({
            passage: "",
            question: "",
            option: "",
            Objective: '',
            explanation: ''
    
    
        });
        setrequiredErrors({
            questionid: "",
        })
    };
    const handleCloseNew = () => {
        setModuleName('');
        setPoints("")
        setSubmitted(false);
        setQuestionid('')
        setQuestionDetails('')
        setVisible(true);
        setSelectedOption('create')
        setDetails({ level: "", questionType: "English" });
        setSelectedSkills("")
        setPassage("")
        setValues({});
        satExplanation('');
        setSatValues({});
        setMathsValues({});
        setTimeout(() => {
            setValues({
                question: '',
                questionType: 'MCQ',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setSatValues({
                question: '',
                questionType: 'English',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setMathsValues({
                question: '',
                questionType: 'Maths',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
        }, 0);
        setSelectedSkills("");
        setOpenDialog(false);
        setOpenDialogNew(false);
        setrequiredErrors({
            questionid: "",
        })
    };
    const handleCloseMaths = () => {
        setModuleName('');
        setSubmitted(false);
        setPoints("")
        setQuestionid('')
        setQuestionDetails('')
        setVisible(true);
        setSelectedOption('create')
        setDetails({ level: "", questionType: "English" });
        setSelectedSkills("")
        setPassage("")
        setValues({});
        satExplanation('');
        setSatValues({});
        setTimeout(() => {
            setValues({
                question: '',
                questionType: 'MCQ',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setSatValues({
                question: '',
                questionType: 'English',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
            setMathsValues({
                question: '',
                questionType: 'Maths',
                mcqOptions: [{ option: '', isCorrect: false }],
            });
        }, 0);
        setSelectedSkills("");
        setOpenDialog(false);
        setOpenDialogNew(false);
        setQuestionError({
            passage: "",
            question: "",
            option: "",
            Objective: '',
            explanation: ''
        });
        setrequiredErrors({
            questionid: "",
        })
    };


    const handleCloseEdit = () => {
        setEditDialog(false);
        setEditData("")
        setEditIndex("")
    };

    const EditModule = (e) => {
        const { name, value } = e.target;
        setEditData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleModuleUpdateQuestion = () => {
        const updatedModules = [...moduleData];
        updatedModules[editIndex] = editData;
        setModuleData(updatedModules);
        setEditDialog(false);
    };









    const handleAddNewQuestion = (index, data) => {

        if (data?.type === 'English') {
            // setSubmitted(false);
            setOpenDialog(true);
            setSelectIndex(index)
            setDetails(prevDetails => ({
                ...prevDetails,
                questionType: 'English'
            }));
        } else {
            // setSubmitted(false);
            setOpenDialogNew(true);
            setSelectIndex(index)
            setDetails(prevDetails => ({
                ...prevDetails,
                questionType: 'Maths'
            }));
        }

    }


    const handleEditDetails = (data, index) => {
        setEditData(data)
        setEditIndex(index)
        setEditDialog(true)
    }

    const QuestionValidation = () => {
        const requirederrors = { moduleName: "", points: "", questionid: "" };
        if (!moduleName && !points && !questionid && !selectedSkills) {
            setrequiredErrors({ moduleName: "Name is required!", points: "Weightage is required!", questionid: "Please Submit question and Answer!" })
            setQuestionError({ Objective: "Please Select Level of Objective" });
        }
        if (!selectedSkills) {
            setQuestionError({ Objective: "Please Select Level of Objective" });
            return false;
        }
        if (!moduleName) {
            requirederrors.moduleName = "Name is required!";
        }
        if (!points) {
            requirederrors.points = "Weightage is required!";
        }
        if (parseInt(points, 10) === '0' || parseInt(points, 10) === 0 || parseInt(points, 10) < 1) {
            requirederrors.points = "Value cannot be 0";
        }
        if (!questionid) {
            requirederrors.questionid = "Please Submit question and Answer!";
            // setQuestionErrors("Please Submit question and Answer!")
            // return false
        }
        setrequiredErrors(requirederrors);
        return Object.values(requirederrors).every((error) => error === "");
    };

    const CreateQuestion = (e) => {
        
        setQuestionid(e.target.value.id)
        setQuestionDetails(e.target.value)
        setVisible(true);
    }


    const handleQuestionSubmit = () => {
        if (details?.questionType === 'English') {
            if (
                !passage &&
                !selectedSkills &&
                (!satValues?.question?.trim() || !satValues?.mcqOptions?.length ||
                    satValues.mcqOptions.some(option => !option.option.trim()) ||
                    !satValues.mcqOptions.some(option => option.isCorrect === true))
            ) {
                setQuestionError({
                    passage: "This field is required",
                    question: "This field is required",
                    Objective: "Please Select Level of Objective",
                    explanation: "This field is required"
                });
                return false;
            }

            if (!passage) {
                setQuestionError({ passage: "This field is required" });
                return false;
            }

            if (!satValues?.question?.trim()) {
                setQuestionError({ question: "This field is required" });
                return false;
            }

            if (!selectedSkills) {
                setQuestionError({ Objective: "Please Select Level of Objective" });
                return false;
            }

            if (!satValues?.mcqOptions?.length || satValues.mcqOptions.some(option => !option.option.trim())) {
                setQuestionError({ option: "Each option must be filled" });
                return false;
            }

            if (satValues.mcqOptions.some(option => option.option.trim()) && !satValues.mcqOptions.some(option => option.isCorrect === true)) {
                setQuestionError({ correctAnswer: "At least one option must be selected as correct" });
                return false;
            }
            if (!explanation) {
                setQuestionError({ explanation: "This field is required" });
                return false;
            }
            return true;
        }

        if (details?.questionType === 'Maths') {
            if (
                !selectedSkills &&
                (!mathsValues?.question?.trim())
            ) {
                setQuestionError({
                    question: "This field is required",

                    Objective: "Please Select Level of Objective",
                });
                return false;
            }

            if (!mathsValues?.question?.trim()) {
                setQuestionError({ question: "This field is required" });
                return false;
            }

            if (!selectedSkills) {
                setQuestionError({ Objective: "Please Select Level of Objective" });
                return false;
            }

            if (!mathsValues?.mcqOptions?.length || mathsValues.mcqOptions.some(option => !option.option.trim())) {
                setQuestionError({ option: "Each option must be filled" });
                return false;
            }

            if (mathsValues.mcqOptions.some(option => option.option.trim()) && !mathsValues.mcqOptions.some(option => option.isCorrect === true)) {
                setQuestionError({ correctAnswer: "At least one option must be selected as correct" });
                return false;
            }
            if (!explanation) {
                setQuestionError({ explanation: "This field is required" });
                return false;
            }

            return true;
        }
    };






    const SubmitQuestion = async () => {
        const questionTest = handleQuestionSubmit()
        if (questionTest === true) {
            setLoadingnew(true);
            try {
                const keysData = new FormData();
                keysData.append('level', details.level);
                keysData.append('questionType', details.questionType);
                keysData.append('cognitive_skills', selectedSkills);
                if (details.questionType === 'English') {
                    const mcqdata = {
                        question: satValues.question,
                        mcqOptions: satValues.mcqOptions.map(option => option.option),
                        correctAnswer: satValues.mcqOptions.map(option => option.isCorrect),
                    };
                    keysData.append('question_text', satValues.question);
                    keysData.append('mcqData', JSON.stringify(mcqdata));
                    keysData.append('passage', passage);
                    keysData.append('explanation', explanation);
                }
                if (details.questionType === 'Maths') {
                    const mcqdata = {
                        question: mathsValues.question,
                        mcqOptions: mathsValues.mcqOptions.map(option => option.option),
                        correctAnswer: mathsValues.mcqOptions.map(option => option.isCorrect),
                    };
                    keysData.append('question_text', mathsValues.question);
                    keysData.append('mcqData', JSON.stringify(mcqdata));
                    keysData.append('explanation', explanation);
                }
                const response = await adminServices.createSatQuestion(keysData);
                if (response.ok) {
                    setSubmitted(true);
                    setSnackbarTitle('Sat Questions created successfully');
                    setQuestionid(response.data.id?.id);
                    setQuestionDetails(response.data.id?.question_text)
                    setOpenSnackbar(true);
                    setLoadingnew(false);
                } else {
                    setLoadingnew(false);
                    console.error("Error:", response);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                setSelectedOption('create')
                setLoadingnew(false);
               

            }
        }

    }



    const handleModuleSubmitCreate = () => {
        const Validation = QuestionValidation()
        if (Validation) {
            const newFormObject = {
                name: moduleName,
                questions_list: Array.isArray(questionid) ? questionid : [questionid],
                weightage: points,
                type: details.questionType,
                questiondetails: Array.isArray(questionDetails) ? questionDetails : [questionDetails],
            };
            setModuleData([...moduleData, newFormObject]);
            setModuleName('');
            setPoints("")
            setQuestionid('');
            setSubmitted(false);
            setVisible(true);
            setQuestionDetails('')
            setSelectedOption('create')
            setDetails({ level: "", questionType: "English" });
            setSelectedSkills("")
            setPassage("");
            setMathsValues({
                question: '',
                questionType: 'Maths',
                mcqOptions: [{ option: '', isCorrect: false }],
            }); setTimeout(() => {
                setValues({
                    question: '',
                    questionType: 'MCQ',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });
                setSatValues({
                    question: '',
                    questionType: 'English',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });
                setMathsValues({
                    question: '',
                    questionType: 'Maths',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });
            }, 0);

            setSelectedSkills("");
            handleClose();
        }
    };
    const handleModuleSubmitCreateQuestion = (selectIndex) => {
        if (questionid && selectedSkills) {
            const updatedModuleData = moduleData.map((module, index) => {
                if (index === selectIndex) {
                    return {
                        ...module,
                        questions_list: [...module.questions_list, questionid],
                        questiondetails: [...module.questiondetails, questionDetails],

                    };
                }
                return module;
            });

            setModuleData(updatedModuleData);
            setModuleName('');
            setPoints('');
            setSubmitted(false);
            setQuestionid('');
            setVisible(true);
            setQuestionDetails('')
            setSelectedOption('create')
            setValues({});
            satExplanation('');
            setSatValues({})
            setMathsValues({})
            setTimeout(() => {
                setValues({
                    question: '',
                    questionType: 'MCQ',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });

                setSatValues({
                    question: '',
                    questionType: 'English',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });
                setMathsValues({
                    question: '',
                    questionType: 'Maths',
                    mcqOptions: [{ option: '', isCorrect: false }],
                });
            }, 0);

            setSelectedSkills('');
            handleCloseNew();
           
        }
        else {
            setrequiredErrors({
                questionid: "Please Submit question and Answer!",
            })

            if (!selectedSkills) {
                setQuestionError({ Objective: "Please Select Level of Objective" });
            }

            // setQuestionErrors("Please Submit question and Answer")
        }
    };

    let buttonText = "Submit Question";
    if (loadingnew) {
        buttonText = "Submitting...";
    } else if (submitted) {
        buttonText = "Submitted";
    }

    const handleChangeOption = (e) => {
        setSelectedOption(e.target.value)
        setrequiredErrors({
            moduleName: "",
            points: "",
            questionid: "",
        });
    }

    const handleFreeChange = (e) => {
        setIsFree(e.target.checked);
        if (e.target.checked) {
            setHasCertificate(false);
        }
    };

    const handleCertificateChange = (e) => {
        setHasCertificate(e.target.checked);
        if (e.target.checked) {
            setIsFree(false);
        }
    };
    const filteredData = questionDetails
        ? questionList?.filter((item) => {
            const sanitizedQuestionText = DOMPurify.sanitize(item.question_text, sanitizeConfig)
                .replace(/<[^>]*>/g, '')
                .replace(/&nbsp;/g, ' ')
                .trim()
                .toLowerCase();

            return sanitizedQuestionText.includes(questionDetails.toLowerCase());
        })
        : questionList;

        

    const handleSelect = (item) => {
        setQuestionid(item.id);
        setQuestionDetails(item.question_text);
        setVisible(false);
    };

    return (
        <>
            <Page title="Add Sat Assessment">
                <PageHeader pageTitle="Add Sat Assessment" submodule="submodule" />
                <Grid container spacing={2} className='GACognitivesection' sx={{ mb: 2, padding: '15px 20px' }}>
                    <Grid item xs={12} sm={3} sx={{ marginBottom: '18px', paddingRight: '18px' }}>
                        <TextField
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            id="addname"
                            label="Sat Name *"
                            type="search"
                            value={name}
                            onChange={(e) => { setName(e.target.value); setNameError(''); }}
                            sx={{
                                // bgcolor: "#f0f0f0",
                                borderRadius: 1,
                                height: 36,
                                '& .MuiInputBase-input': {
                                    fontSize: 14,
                                    padding: "8px 12px",

                                },
                            }}
                            error={!!nameError}
                        />
                        {nameError && <FormHelperText error>{nameError}</FormHelperText>}
                    </Grid>



                    <Grid item xs={12} sm={3} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <FormControl fullWidth variant="outlined" sx={{
                            // bgcolor: "#f0f0f0", 
                            borderRadius: 1
                        }}>
                            <InputLabel id="complexity-level-label">Difficulty Level *</InputLabel>
                            <Select
                                labelId="complexity-level-label"
                                id="addnamecomplexity"
                                value={complexity || ""}
                                onChange={(e) => { setComplexity(e.target.value); setComplexityError("") }}
                                label="Difficulty Level"
                                error={!!ComplexityError}
                                sx={{
                                    // height: 36,
                                    '& .MuiSelect-select': {
                                        // fontSize: 14,
                                        padding: "8px 12px",

                                    },
                                }}
                            >
                                <MenuItem value="">Select Complexity</MenuItem>
                                <MenuItem value="easy">Easy</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="hard">Hard</MenuItem>
                            </Select>
                            {ComplexityError && <FormHelperText error>{ComplexityError}</FormHelperText>}
                        </FormControl>

                    </Grid>


                    <Grid item xs={12} sm={6} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <InputLabel id="complexity-level-label">Sat Time</InputLabel>

                        <Grid container className='AssessmentTime' spacing={2}>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Hours</InputLabel>
                                    <Select
                                        name="hours"
                                        value={time.hours}
                                        disabled
                                        onChange={handleChange}
                                        // label="Hours"
                                        className="dropHours"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 158,
                                                }
                                            }
                                        }}
                                    >
                                        {hoursArray.map((hour) => (
                                            <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Minutes</InputLabel>
                                    <Select
                                        name="minutes"
                                        value={time.minutes}
                                        onChange={handleChange}
                                        disabled
                                        // label="Minutes"
                                        className="dropHours"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 158,
                                                }
                                            }
                                        }}
                                    >
                                        {minutesArray.map((minute) => (
                                            <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Seconds</InputLabel>
                                    <Select
                                        name="seconds"
                                        value={time.seconds}
                                        onChange={handleChange}
                                        disabled
                                        // label="Seconds"
                                        className="dropHours"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 228,
                                                }
                                            }
                                        }}
                                    >
                                        {secondsArray.map((second) => (
                                            <MenuItem key={second} value={second}>{second}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>





                    <Grid className="unique" item xs={12} sm={6} sx={{ marginBottom: '0px', paddingRight: '18px' }}>
                        {thumbPreview === null ? (
                            <FormControl style={{ height: '100%' }}
                                required
                                component="fieldset"
                                color="primary"
                                variant="outlined"
                                fullWidth
                                name="thumbImage"
                            >
                                <Typography variant="subtitle1">Thumb Image* <span style={{
                                    fontSize: '12px',
                                    float: 'inline-end', paddingBottom: '0', marginBottom: '0', position: 'relative', top: '5px'
                                }}>required resolution (360X200)</span></Typography>
                                <DropzoneArea className="dropTextArea"
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    showPreviews={false}
                                    dropzoneText="Drag and Drop Image or Browse File"
                                    showPreviewsInDropzone={false}
                                    maxFileSize={300000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    styles={{
                                        height: '100%', minHeight: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                    onChange={handleFileChange}
                                    useChipsForPreview
                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                    showFileNamesInPreview
                                />
                                {ImageError && <FormHelperText error>{ImageError}</FormHelperText>}
                            </FormControl>


                        ) : (
                            <div className={classes.imgPreviewRoot}>
                                <Typography variant="subtitle1">Thumb Image</Typography>
                                <Badge
                                    badgeContent={
                                        <CloseIcon id='ThumbPreview'
                                            className={classes.badgeAlign}
                                            onClick={() => {
                                                setThumbPreview(null);
                                                setThumbImage(null);
                                            }}
                                        />
                                    }
                                >
                                    <Avatar
                                        variant="rounded"
                                        src={thumbPreview}
                                        style={{ minHeight: '150px !important' }}
                                        className={thumbPreview !== null && classes.fileImgSIze}
                                    />
                                </Badge>
                            </div>
                        )}
                    </Grid>


                    <Grid item xs={12} sm={6} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                        <Typography variant="subtitle1">Sat Description *</Typography>
                        <ReactQuill
                            theme="snow"
                            id='questionText'
                            name="question"
                            defaultValue={editorValue}
                            onChange={(content) => {
                                if (content.length > 255) {
                                    handleChangeDescription(content.slice(0, 255));
                                } else {
                                    handleChangeDescription(content);
                                }
                            }}
                            onPaste={(e) => {
                                e.preventDefault();
                                const clipboardText = e.clipboardData.getData('text').slice(0, 255);
                                handleChangeDescription(clipboardText);
                            }}
                            // fullWidth
                            style={{ height: '150px', marginBottom: '30px' }}
                        />
                        {descriptionError && <FormHelperText error>{descriptionError}</FormHelperText>}

                    </Grid>

                    <Grid item xs={6}>
                        <Typography className={classes.background} gutterBottom variant="subtitle1">
                            Short Description
                        </Typography>
                        <Box>
                            <textarea
                                id="shortdescription"
                                value={shortdescription}
                                onChange={(event) => setShortDescription(event.target.value)}
                                placeholder="Enter shortdescription"
                                rows="5"
                                cols="40"
                                maxLength={250}
                                style={{
                                    width: "100%",
                                    height: "150px",
                                    padding: "10px",
                                    fontSize: "16px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    outline: "none",
                                    resize: "both",
                                    transition: "border-color 0.3s",
                                }}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6} >
                        <span style={{ visibility: 'hidden' }}>Empty</span>
                    </Grid>




                    {moduleData && moduleData.length > 0 && moduleData.map((data, index) => (
                        <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }} style={{ paddingLeft: '15px' }}>
                            <Grid item xs={6} style={{ paddingLeft: '15px' }}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {data.name}
                                            </Typography>
                                            <Box display="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }} >
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    {data.weightage}%
                                                </Typography>
                                                <Box display="flex" sx={{ marginLeft: -1, alignItems: 'center' }}>

                                                    <IconButton id="AddCircleIconBtn" onClick={() => handleAddNewQuestion(index, data)} color="error">
                                                        <AddCircleIcon id="AddCircleIcon" />
                                                    </IconButton>
                                                    <IconButton id="EditIconBtn" onClick={() => handleEditDetails(data, index)} color="error">
                                                        <EditIcon id="EditIcon" />
                                                    </IconButton>
                                                    <IconButton id='VisibilityIconBtn' style={{ height: '40px' }} onClick={() => handlePreviewOpen(data, index)} color="error">
                                                        < VisibilityIcon id="VisibilityIcon" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    ))}


                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                        <Button id='AddEnglish' sx={{ marginRight: '15px', paddingBottom: '0px' }} variant="contained" color="primary" onClick={() => handleDialogOpen("English")}>
                            Add English Module
                        </Button>
                        <Button id='AddMaths' sx={{ marginRight: '15px', paddingBottom: '0px' }} variant="contained" color="primary" onClick={() => handleDialogOpen("Maths")}>
                            Add Maths Module
                        </Button>

                        <Box sx={{ marginRight: '15px' }}>
                            <LoadingButton
                                type="submit"
                                id="addassessmentgeneral"
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"

                                loading={loading}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {errorMessage && (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {errorMessage}
                        </Alert>
                    )}
                    {errorMessageAll && (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {errorMessageAll}
                        </Alert>)}
                </Grid>



                <Dialog open={open} onClose={handleClose} fullWidth>
                    <DialogTitle style={{ paddingBottom: '0px' }}>Add {details?.questionType === 'English' ? 'English Module' : 'Maths Module'}</DialogTitle>
                    <DialogContent className='GACognitivesection' sx={{ paddingTop: '25px !important' }}>
                        <Box sx={{ marginBottom: '18px' }}>
                            <TextField
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                id="addname"
                                label="Module Name *"
                                type="search"
                                value={moduleName}
                                onChange={(e) => { setModuleName(e.target.value); ClearError() }}
                                sx={{
                                    borderRadius: 1,

                                    height: 36,
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: "8px 12px",
                                    },

                                }}
                            />
                            {requirederrors.moduleName && (
                                <FormHelperText error>{requirederrors.moduleName}</FormHelperText>
                            )}
                        </Box>
                        <Box sx={{ marginBottom: '15px' }}>
                            <TextField
                                variant="outlined"
                                inputProps={{ maxLength: 50, inputMode: 'numeric', pattern: '[0-9]*' }}
                                fullWidth
                                id="addweight"
                                label="Module Weight *"
                                type="number"
                                value={points}
                                onChange={handlePoints}
                                sx={{
                                    // marginBottom: '20px',
                                    borderRadius: 1,

                                    height: 36,
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: "8px 12px",
                                    },
                                }}
                            />
                            {requirederrors.points && (
                                <FormHelperText style={{ marginBottom: '5px' }} error>{requirederrors.points}</FormHelperText>
                            )}
                        </Box>
                        {errorMessage && (
                            <Alert severity="error" sx={{ marginBottom: '10px' }}>
                                {errorMessage}
                            </Alert>
                        )}

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={selectedOption}
                                 readOnly={submitted}
                                onChange={(e) => handleChangeOption(e)}
                            >

                                <FormControlLabel id='createRadioQue' value="create" control={<Radio />} sx={{ marginRight: '40px' }} label="Create Question" />
                                <FormControlLabel id='selectRadioQue' value="select" control={<Radio />} label="Select Question" />
                            </RadioGroup>
                        </FormControl>

                        {selectedOption === 'select' ? (
                            <Box display="flex" flexDirection="column" gap={2}>
                                <Box style={{ marginTop: "10px" }}>
                                    <Grid container spacing={2}>


                                        <Grid item xs={6}>
                                            <FormControl style={{ display: "block", width: "100%" }} className={classes.formControl}
                                            // error={touched.questionType && Boolean(errors.questionType)}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                                                <Select name="level" labelId="demo-simple-select-standard-label"
                                                   disabled={submitted}
                                                    style={{ width: "100%" }}
                                                    id="level"
                                                    label="Level"
                                                    value={details.level}
                                                    onChange={(e) => setDetails(prevDetails => ({
                                                        ...prevDetails,
                                                        level: e.target.value
                                                    }))}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="easy">Easy</MenuItem>
                                                    <MenuItem value="medium">Medium</MenuItem>
                                                    <MenuItem value="complex">Complex</MenuItem>
                                                </Select>

                                            </FormControl>
                                        </Grid>


                                        <Grid item>
                                            <FormControl fullWidth variant="outlined">

                                                <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                                    Select Level of Objective</Typography>
                                                <FormGroup className='FormCheck'>
                                                    {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                        <FormControlLabel
                                                           readOnly={submitted}
                                                            key={skill}
                                                            id={`radio${skill}Get`}
                                                            control={
                                                                <Radio
                                                                 disabled={submitted}
                                                                    id={`radioVal${skill}`}
                                                                    checked={selectedSkills === skill}
                                                                    onChange={handleChangeCheck}
                                                                    value={skill}
                                                                />
                                                            }
                                                            label={skill}
                                                        />
                                                    ))}
                                                </FormGroup>
                                                {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                            </FormControl>
                                        </Grid>

                                        {details?.questionType === 'English' ?
                                            <>
                                                <div style={{ paddingLeft: "15px", width: "100%" }}>

                                                    <Grid item xs={12}>
                                                        <div className="search-select-container">
                                                            <input
                                                                type="text"
                                                                // value={query}
                                                                 readOnly={submitted}
                                                                onChange={CreateQuestion}
                                                                placeholder="Search or Select..."
                                                                aria-label="Search or select an option"
                                                            />

                                                            {visible && (
                                                                <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                                    {filteredData && filteredData?.length > 0 ? (
                                                                        filteredData?.map((item) => {
                                                                            const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                            const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();

                                                                            return (
                                                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                                <li
                                                                                    key={item.id}
                                                                                    role="option"
                                                                                    onClick={() => handleSelect(item)}
                                                                                    className="dropdown-item"
                                                                                    tabIndex={0}
                                                                                    aria-selected={selectedOptionnew?.id === item.id}
                                                                                >
                                                                                    {finalText}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                                        <li className="dropdown-item" role="option">
                                                                            No results found
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}

                                                            {questionDetails && (
                                                                <div className="selected-option">
                                                                    <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig)
                                                                        .replace(/<[^>]*>/g, '')
                                                                        .replace(/&nbsp;/g, ' ')
                                                                        .trim()
                                                                        .toLowerCase()}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                   
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ paddingLeft: "15px", width: "100%" }}>
                                                    
                                                      <Grid item xs={12}>
                                                        <div className="search-select-container">
                                                            <input
                                                                type="text"
                                                                // value={query}
                                                                onChange={CreateQuestion}
                                                                placeholder="Search or Select..."
                                                                aria-label="Search or select an option"
                                                            />

                                                            {visible && (
                                                                <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                                    {filteredData && filteredData?.length > 0 ? (
                                                                        filteredData?.map((item) => {
                                                                            const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                            const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();

                                                                            return (
                                                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                                <li
                                                                                    key={item.id}
                                                                                    role="option"
                                                                                    onClick={() => handleSelect(item)}
                                                                                    className="dropdown-item"
                                                                                    tabIndex={0}
                                                                                    aria-selected={selectedOptionnew?.id === item.id}
                                                                                >
                                                                                    {finalText}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                                        <li className="dropdown-item" role="option">
                                                                            No results found
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}

                                                            {questionDetails && (
                                                                <div className="selected-option">
                                                                    <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig)
                                                                        .replace(/<[^>]*>/g, '')
                                                                        .replace(/&nbsp;/g, ' ')
                                                                        .trim()
                                                                        .toLowerCase()}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </div>


                                            </>
                                        }

                                        {selectedOption === 'create' &&
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    id="subMitButton"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={CreateQuestion}
                                                    loading={loadingnew}
                                                    fullWidth
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </Grid>}
                                    </Grid>
                                </Box>

                            </Box>
                        ) : (

                            <Box display="flex" flexDirection="column" style={{ marginTop: "10px" }} gap={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} fullWidth >
                                        <FormControl className={classes.formControl}
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                                            <Select
                                            disabled={submitted}
                                                name="level"
                                                labelId="demo-simple-select-standard-label"
                                                id="level"
                                                label="Level"
                                                value={details.level}
                                                onChange={(e) => setDetails(prevDetails => ({
                                                    ...prevDetails,
                                                    level: e.target.value
                                                }))}
                                                displayEmpty
                                            >
                                                <MenuItem value="easy">Easy</MenuItem>
                                                <MenuItem value="medium">Medium</MenuItem>
                                                <MenuItem value="complex">Complex</MenuItem>
                                            </Select>
                                          
                                        </FormControl>
                                    </Grid>


                                    <Grid item>
                                        <FormControl fullWidth variant="outlined">

                                            <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                                Select Level of Objective</Typography>
                                            <FormGroup className='FormCheck'>
                                                {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                    <FormControlLabel
                                                        key={skill}
                                                        id={`radioIs${skill}`}
                                                        control={
                                                            <Radio
                                                            disabled={submitted}
                                                                id={`valRadio${skill}`}
                                                                checked={selectedSkills === skill}
                                                                onChange={handleChangeCheck}
                                                                value={skill}
                                                            />
                                                        }
                                                        label={skill}
                                                    />
                                                ))}
                                            </FormGroup>
                                            {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                        </FormControl>
                                    </Grid>

                                    {details?.questionType === 'English' ?
                                        <>
                                            <Grid item xs={12}>
                                               
                                                <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                                    <Typography variant="subtitle1">Passage *</Typography>
                                                    <ReactQuill
                                                     readOnly={submitted}
                                                        theme="snow"
                                                        id="questionText"
                                                        name="question"
                                                        defaultValue={passage}
                                                        onChange={(content, delta, source, editor) => {
                                                            setPassage(content);
                                                            setQuestionError({
                                                                question: ''
                                                            });
                                                        }}
                                                    />

                                                    {questionError && questionError.passage && <FormHelperText error  >{questionError?.passage}</FormHelperText >}



                                                </Grid>
                                            </Grid>


                                            {details?.questionType === 'English' &&
                                                <>
                                                    <div style={{ paddingLeft: "15px" }}>
                                                        <Grid item xs={12}>
                                                            <FormControl className={classes.formControl}>
                                                                <Typography className={classes.background} gutterBottom variant="subtitle1">
                                                                    Create Question*
                                                                </Typography>

                                                                <ReactQuill
                                                                  readOnly={submitted}
                                                                    theme="snow"
                                                                    id={`questionText`}
                                                                    name="question"
                                                                    defaultValue={satValues.question}
                                                                    onChange={(value) => handleChangeQuestionEnglish('question', value)}
                                                                    modules={modules}
                                                                    formats={formats}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                            {questionError && questionError.question && <FormHelperText error >{questionError?.question}</FormHelperText>}
                                                        </Grid>


                                                        {satValues?.mcqOptions?.map((opt, index) => (
                                                            // index > 0 && ( 
                                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                                        <ReactQuill
                                                                          readOnly={submitted}
                                                                            theme="snow"
                                                                            id={`optiontext`}
                                                                            name={`mcqQuestion`}
                                                                            defaultValue={opt.option}
                                                                            onChange={(value) => handleMCQOptionChangeEnglish(index, 'option', value)}
                                                                            modules={modules}
                                                                            formats={formats}
                                                                            placeholder="Option"
                                                                            style={{ marginTop: 10, flex: 1 }}
                                                                        />
                                                                        <IconButton
                                                                        disabled={submitted}
                                                                            id='removeOption'
                                                                            aria-label="delete"
                                                                            color="error"
                                                                            onClick={() => handleRemoveOptionEnglish(index)}
                                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                                        >
                                                                            <ClearIcon fontSize="small" id="deleteIcon" />
                                                                        </IconButton>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`mcqOptionsisCorrect`}
                                                                                    checked={opt.isCorrect}
                                                                                    onChange={() => handleMCQOptionChangeEnglish(index, 'isCorrect', !opt.isCorrect)}
                                                                                    disabled={!opt.option.trim()||submitted}
                                                                                />
                                                                            }
                                                                            label="Correct"
                                                                        />


                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            // )
                                                        ))}
                                                        {questionError && questionError.option && <FormHelperText error >{questionError?.option}</FormHelperText>}
                                                        {questionError && questionError.correctAnswer && <FormHelperText error >{questionError?.correctAnswer}</FormHelperText>}

                                                        <Button
                                                        disabled={submitted}
                                                            variant="contained"
                                                            color="primary"
                                                            id='Addoptions'
                                                            onClick={handleAddOptionEnglish}
                                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                                        >
                                                            Add Option
                                                        </Button>
                                                        {requirederrors.questionid && (
                                                            <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                                        )}

                                                    </div>

                                                    <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                                        <Typography variant="subtitle1">Explanation *</Typography>
                                                        <ReactQuill
                                                          readOnly={submitted}
                                                            theme="snow"
                                                            id="explanation"
                                                            name="explanation"
                                                            modules={modules}
                                                            formats={formats}
                                                            defaultValue={explanation}
                                                            onChange={(content, delta, source, editor) => {
                                                                satExplanation(content);
                                                                setQuestionError({
                                                                    explanation: ''
                                                                });
                                                            }}
                                                        />
                                                        {questionError && questionError.explanation && <FormHelperText error  >{questionError?.explanation}</FormHelperText >}
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl style={{ width: '100%' }} className={classes.formControl}
                                                >
                                                    <Typography className={classes.background} color="primary" gutterBottom variant="subtitle1">
                                                        Create Question*
                                                    </Typography>


                                                    <ReactQuill
                                                      readOnly={submitted}
                                                        theme="snow"
                                                        id="questionText"
                                                        name="question"
                                                        defaultValue={mathsValues.question}
                                                        onChange={(value) => handleChangeMathsQuestion('question', value)}
                                                        modules={modules}
                                                        formats={formats}
                                                        fullWidth
                                                    />
                                                    {questionError && questionError.question && <FormHelperText error>{questionError?.question}</FormHelperText >}

                                                </FormControl>
                                            </Grid>


                                            {mathsValues?.questionType === 'Maths' && (
                                                <>
                                                    <Grid item xs={12}>
                                                        {mathsValues?.mcqOptions.map((opt, index) => (
                                                            <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                                    <ReactQuill
                                                                      readOnly={submitted}
                                                                    theme="snow"
                                                                    id={`optiontext`}
                                                                    name={`mcqQuestion`}
                                                                    defaultValue={opt.option}
                                                                    onChange={(value) => handleMCQOptionChangeMaths(index, 'option', value)}
                                                                    modules={modules}
                                                                    formats={formats}
                                                                    placeholder="Option"
                                                                    style={{ marginTop: 10, flex: 1 }}
                                                                />
                                                                        <IconButton
                                                                        disabled={submitted}
                                                                          readOnly={submitted}
                                                                            aria-label="delete"
                                                                            color="error"
                                                                            onClick={() => handleRemoveOptionMaths(index)}
                                                                            style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                                        >
                                                                            <ClearIcon fontSize="small" id="ClearIcon" />
                                                                        </IconButton>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`mcqOptions.${index}.isCorrect`}
                                                                                    checked={opt.isCorrect}
                                                                                    onChange={() => handleMCQOptionChangeMaths(index, 'isCorrect', !opt.isCorrect)}
                                                                                    disabled={!opt.option.trim()||submitted}
                                                                                />
                                                                            }
                                                                            label="Correct"
                                                                        />
                                                                    </Grid>


                                                                </Grid>
                                                            </div>
                                                        ))}

                                                        {submitError && (
                                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                                {submitError}
                                                            </div>
                                                        )}
                                                        {currectError && (
                                                            <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                                {currectError}
                                                            </div>
                                                        )}
                                                        {questionError && questionError.option && <FormHelperText error >{questionError?.option}</FormHelperText>}
                                                        {questionError && questionError.correctAnswer && <FormHelperText error >{questionError?.correctAnswer}</FormHelperText>}

                                                        <Button
                                                        disabled={submitted}
                                                            variant="contained"
                                                            id='valOptions'
                                                            color="primary"
                                                            onClick={handleAddOptionMaths}
                                                            style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                                        >
                                                            Add Option
                                                        </Button>
                                                        {requirederrors.questionid && (
                                                            <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                                        <Typography variant="subtitle1">Explanation *</Typography>
                                                        <ReactQuill
                                                          readOnly={submitted}
                                                            theme="snow"
                                                            id="explanation"
                                                            name="explanation"
                                                            modules={modules}
                                                            formats={formats}
                                                            defaultValue={explanation}
                                                            onChange={(content, delta, source, editor) => {
                                                                satExplanation(content);
                                                                setQuestionError({
                                                                    explanation: ''
                                                                });
                                                            }}
                                                        />
                                                        {questionError && questionError.explanation && <FormHelperText error  >{questionError?.explanation}</FormHelperText >}
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    }
                                        {submitted ? <Grid item xs={12}>
                                            <LoadingButton
                                                id="subMitButton"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled
                                                loading={loadingnew}
                                                fullWidth
                                            >
                                                submitted
                                            </LoadingButton>
                                        </Grid> :
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    id="subMitButton"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    // onClick={CreateQuestion}
                                                    onClick={SubmitQuestion}
                                                    loading={loadingnew}
                                                    fullWidth
                                                >
                                                    Submit Question
                                                </LoadingButton>
                                            </Grid>}
                                    </Grid>
                            </Box>

                        )}
                    </DialogContent>


                    <DialogActions>
                        <Button id='modulepopSubmits' onClick={handleModuleSubmitCreate} color="secondary">
                            Submit
                        </Button>
                        <Button id='modulepopCancel' onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>


                </Dialog>






                <Dialog open={openDialog} onClose={handleCloseNew} fullWidth>
                    <DialogTitle style={{ paddingBottom: '0px' }}>Add English Question</DialogTitle>
                    <DialogContent className='GACognitivesection' sx={{ paddingTop: '25px !important' }}>

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={selectedOption}
                                onChange={(e) => handleChangeOption(e)}
                            >

                                <FormControlLabel id='createRadioIs' value="create" control={<Radio />} sx={{ marginRight: '40px' }} label="Create Question" />
                                <FormControlLabel id='selectRadioIs' value="select" control={<Radio />} label="Select Question" />
                            </RadioGroup>
                        </FormControl>

                        {selectedOption === 'select' ? <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <FormControl fullWidth>

                                    <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                        Select Level of Objective
                                    </Typography>
                                    <FormGroup className='FormCheck'>
                                        {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                            <FormControlLabel
                                            disabled={submitted}
                                                key={skill}
                                                id={skill}
                                                control={
                                                    <Radio
                                                        id={`radioIsget${skill}`}
                                                        checked={selectedSkills === skill}
                                                        onChange={handleChangeCheck}
                                                        value={skill}
                                                    />
                                                }
                                                label={skill}
                                            />
                                        ))}
                                    </FormGroup>
                                    {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                </FormControl>
                            </Grid>




                           
                              <Grid item xs={12}>
                                                        <div className="search-select-container">
                                                            <input
                                                              readOnly={submitted}
                                                                type="text"
                                                                // value={query}
                                                                onChange={CreateQuestion}
                                                                placeholder="Search or Select..."
                                                                aria-label="Search or select an option"
                                                            />

                                                            {visible && (
                                                                <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                                    {filteredData && filteredData?.length > 0 ? (
                                                                        filteredData?.map((item) => {
                                                                            const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                            const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();

                                                                            return (
                                                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                                <li
                                                                                    key={item.id}
                                                                                    role="option"
                                                                                    onClick={() => handleSelect(item)}
                                                                                    className="dropdown-item"
                                                                                    tabIndex={0}
                                                                                    aria-selected={selectedOptionnew?.id === item.id}
                                                                                >
                                                                                    {finalText}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                                        <li className="dropdown-item" role="option">
                                                                            No results found
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}

                                                            {questionDetails && (
                                                                <div className="selected-option">
                                                                    <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig)
                                                                        .replace(/<[^>]*>/g, '')
                                                                        .replace(/&nbsp;/g, ' ')
                                                                        .trim()
                                                                        .toLowerCase()}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>
                        </Grid>

                            :
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>

                                        <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                            Select Level of Objective
                                        </Typography>
                                        <FormGroup className='FormCheck'>
                                            {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                <FormControlLabel
                                                disabled={submitted}
                                                    key={skill}
                                                    id={`Label${skill}`}
                                                    control={
                                                        <Radio
                                                         readOnly={submitted}
                                                            id={skill}
                                                            checked={selectedSkills === skill}
                                                            onChange={handleChangeCheck}
                                                            value={skill}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            ))}
                                        </FormGroup>
                                        {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} fullWidth>
                                    <FormControl className={classes.formControl} style={{ width: "100%" }}
                                    // error={touched.questionType && Boolean(errors.questionType)}
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                                        <Select
                                        disabled={submitted}
                                            name="level"
                                            labelId="demo-simple-select-standard-label"
                                            id="level"
                                            label="Level"
                                            value={details.level}
                                            onChange={(e) => setDetails(prevDetails => ({
                                                ...prevDetails,
                                                level: e.target.value
                                            }))}
                                            displayEmpty
                                        >
                                            <MenuItem value="easy">Easy</MenuItem>
                                            <MenuItem value="medium">Medium</MenuItem>
                                            <MenuItem value="complex">Complex</MenuItem>
                                        </Select>
                                        {/* {touched.level && errors.level && (
                                    <FormHelperText>{errors.level}</FormHelperText>
                                )} */}
                                    </FormControl>
                                </Grid>

                                <>

                                    <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                        <Typography variant="subtitle1">Passage *</Typography>
                                        <ReactQuill
                                          readOnly={submitted}
                                            theme="snow"
                                            id='questionText'
                                            name="question"
                                            defaultValue={passage}
                                            onChange={(content, delta, source, editor) => {
                                                setPassage(content);
                                                setQuestionError({
                                                    question: ''
                                                });
                                            }}
                                        />
                                        <FormHelperText error>{questionError?.passage}</FormHelperText>
                                    </Grid>

                                    {details?.questionType === 'English' &&
                                        <>
                                            <div >
                                                <Grid item xs={12}>
                                                    <FormControl className={classes.formControl}>
                                                        <Typography className={classes.background} gutterBottom variant="subtitle1">
                                                            Create Question*
                                                        </Typography>

                                                        <ReactQuill
                                                          readOnly={submitted}
                                                            theme="snow"
                                                            id={`questionText`}
                                                            name="question"
                                                            defaultValue={satValues.question}
                                                            onChange={(value) => handleChangeQuestionEnglish('question', value)}
                                                            modules={modules}
                                                            formats={formats}
                                                            fullWidth
                                                        />
                                                        {questionError && questionError.question && <FormHelperText error>{questionError?.question}</FormHelperText>}

                                                    </FormControl>
                                                </Grid>


                                                {satValues?.mcqOptions?.map((opt, index) => (
                                                    <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                                <ReactQuill
                                                                  readOnly={submitted}
                                                                    theme="snow"
                                                                    id={`optiontext`}
                                                                    name={`mcqQuestion`}
                                                                    defaultValue={opt.option}
                                                                    onChange={(value) => handleMCQOptionChangeEnglish(index, 'option', value)}
                                                                    modules={modules}
                                                                    formats={formats}
                                                                    placeholder="Option"
                                                                    style={{ marginTop: 10, flex: 1 }}
                                                                />
                                                                <IconButton
                                                                  readOnly={submitted}
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    onClick={() => handleRemoveOptionEnglish(index)}
                                                                    style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                                >
                                                                    <ClearIcon fontSize="small" id="ClearsIcon" />
                                                                </IconButton>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                        
                                                                            name={`mcqOptionsisCorrect`}
                                                                            checked={opt.isCorrect}
                                                                            onChange={() => handleMCQOptionChangeEnglish(index, 'isCorrect', !opt.isCorrect)}
                                                                            disabled={!opt.option.trim()||submitted}
                                                                            />
                                                                    }
                                                                    label="Correct"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                                {questionError && questionError.option && <FormHelperText error >{questionError?.option}</FormHelperText>}
                                                {questionError && questionError.correctAnswer && <FormHelperText error >{questionError?.correctAnswer}</FormHelperText>}

                                                <Button id='AddoptionsIn'
                                                disabled={submitted}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAddOptionEnglish}
                                                    style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                                >
                                                    Add Option
                                                </Button>
                                                {requirederrors.questionid && (
                                                    <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                                )}
                                            </div>
                                            <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                                <Typography variant="subtitle1">Explanation *</Typography>
                                                <ReactQuill
                                                  readOnly={submitted}
                                                    theme="snow"
                                                    id="explanation"
                                                    name="explanation"
                                                    modules={modules}
                                                    formats={formats}
                                                    defaultValue={explanation}
                                                    onChange={(content, delta, source, editor) => {
                                                        satExplanation(content);
                                                        setQuestionError({
                                                            explanation: ''
                                                        });
                                                    }}
                                                />
                                                {questionError && questionError.explanation && <FormHelperText error  >{questionError?.explanation}</FormHelperText >}
                                            </Grid>
                                        </>
                                    }
                                </>


                                <Grid item xs={12}>
                                    <Button onClick={SubmitQuestion} id={buttonText} type="submit"
                                        disabled={loadingnew || submitted}
                                        variant="contained" color="primary" fullWidth>
                                        {buttonText}
                                    </Button>
                                </Grid>
                                {requirederrors.questionid && (
                                    <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                )}
                            </Grid>}
                    </DialogContent>


                    <DialogActions>
                        <Button id='btnSubmited' onClick={() => handleModuleSubmitCreateQuestion(selectIndex)} color="secondary">
                            Submit
                        </Button>
                        <Button id='btnCanceled' onClick={handleCloseNew} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>


                </Dialog>


                <Dialog open={OpenDialogNew} onClose={handleCloseMaths} fullWidth>
                    <DialogTitle style={{ paddingBottom: '0px' }}>Add Maths Question</DialogTitle>
                    <DialogContent className='GACognitivesection' sx={{ paddingTop: '25px !important' }}>

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={selectedOption}
                                onChange={(e) => handleChangeOption(e)}
                                id='valFieldSetRadio'
                            >

                                <FormControlLabel id='createRadioQueOpted' value="create" control={<Radio />} sx={{ marginRight: '40px' }} label="Create Question" />
                                <FormControlLabel id='SelectedRadioOpt' value="select" control={<Radio />} label="Select Question" />
                            </RadioGroup>
                        </FormControl>

                        {selectedOption === 'select' ?
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>

                                        <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                            Select Level of Objective
                                        </Typography>
                                        <FormGroup className='FormCheck'>
                                            {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                <FormControlLabel
                                                    key={skill}
                                                    id={`Label${skill}`}
                                                    control={
                                                        <Radio
                                                        disabled={submitted}
                                                         readOnly={submitted}
                                                            id={`radioIs${skill}`}
                                                            checked={selectedSkills === skill}
                                                            onChange={handleChangeCheck}
                                                            value={skill}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            ))}
                                        </FormGroup>
                                        {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                                        <div className="search-select-container">
                                                            <input
                                                              readOnly={submitted}
                                                                type="text"
                                                                // value={query}
                                                                onChange={CreateQuestion}
                                                                placeholder="Search or Select..."
                                                                aria-label="Search or select an option"
                                                            />

                                                            {visible && (
                                                                <ul className="dropdown" role="listbox" aria-expanded={query.length > 0}>
                                                                    {filteredData && filteredData?.length > 0 ? (
                                                                        filteredData?.map((item) => {
                                                                            const sanitizedText = DOMPurify.sanitize(item.question_text, sanitizeConfig).replace(/<[^>]*>/g, '').trim();
                                                                            const finalText = sanitizedText.replace(/&nbsp;/g, ' ').trim();

                                                                            return (
                                                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                                <li
                                                                                    key={item.id}
                                                                                    role="option"
                                                                                    onClick={() => handleSelect(item)}
                                                                                    className="dropdown-item"
                                                                                    tabIndex={0}
                                                                                    aria-selected={selectedOptionnew?.id === item.id}
                                                                                >
                                                                                    {finalText}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                                                        <li className="dropdown-item" role="option">
                                                                            No results found
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}

                                                            {questionDetails && (
                                                                <div className="selected-option">
                                                                    <p>Selected: {DOMPurify.sanitize(questionDetails, sanitizeConfig)
                                                                        .replace(/<[^>]*>/g, '')
                                                                        .replace(/&nbsp;/g, ' ')
                                                                        .trim()
                                                                        .toLowerCase()}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>

                            </Grid>

                            :
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>

                                        <Typography style={{ marginBottom: '0px' }} color="primary" className={classes.background} gutterBottom variant="subtitle1">
                                            Select Level of Objective
                                        </Typography>
                                        <FormGroup className='FormCheck'>
                                            {['Knowledge', 'Comprehension', 'Application', 'Analysis', 'Synthesis', 'Evaluation'].map((skill) => (
                                                <FormControlLabel
                                                disabled={submitted}
                                                    key={skill}
                                                    id={`valRadio${skill}`}
                                                    control={
                                                        <Radio
                                                            id={`radio${skill}`}
                                                            checked={selectedSkills === skill}
                                                            onChange={handleChangeCheck}
                                                            value={skill}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            ))}
                                        </FormGroup>
                                        {questionError && questionError.Objective && <FormHelperText error  >{questionError?.Objective}</FormHelperText >}

                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} fullWidth>
                                    <FormControl className={classes.formControl} style={{ width: "100%" }}
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                                        <Select
                                        disabled={submitted}
                                            name="level"
                                            labelId="demo-simple-select-standard-label"
                                            id="level"
                                            label="Level"
                                            value={details.level}
                                            onChange={(e) => setDetails(prevDetails => ({
                                                ...prevDetails,
                                                level: e.target.value
                                            }))}
                                            displayEmpty
                                        >
                                            <MenuItem value="easy">Easy</MenuItem>
                                            <MenuItem value="medium">Medium</MenuItem>
                                            <MenuItem value="complex">Complex</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <>
                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '100%' }} className={classes.formControl}
                                        //  error={touched.question && Boolean(errors.question)}
                                        >
                                            <Typography className={classes.background} color="primary" gutterBottom variant="subtitle1">
                                                Create Question*
                                            </Typography>
                                            <ReactQuill
                                             readOnly={submitted}
                                                theme="snow"
                                                id="questionText"
                                                name="question"
                                                defaultValue={mathsValues.question}                                                onChange={(value) => handleChangeMathsQuestion('question', value)}
                                                modules={modules}
                                                formats={formats}
                                                // onBlur={() => setTouched((prev) => ({ ...prev, question: true }))}
                                                fullWidth
                                            />
                                            {questionError && questionError.question && <FormHelperText error>{questionError?.question}</FormHelperText>}

                                            {/* {touched.question && errors.question && <FormHelperText>{errors.question}</FormHelperText>} */}
                                        </FormControl>
                                    </Grid>


                                    {mathsValues?.questionType === 'Maths' && (
                                        <>
                                            <Grid item xs={12}>
                                                {mathsValues?.mcqOptions.map((opt, index) => (
                                                    <div key={index} style={{ position: 'relative', marginTop: '10px' }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', marginLeft: 40 }}>
                                                            <ReactQuill
                                                              readOnly={submitted}
                                                                    theme="snow"
                                                                    id={`optiontext`}
                                                                    name={`mcqQuestion`}
                                                                    defaultValue={opt.option}
                                                                    onChange={(value) => handleMCQOptionChangeMaths(index, 'option', value)}
                                                                    modules={modules}
                                                                    formats={formats}
                                                                    placeholder="Option"
                                                                    style={{ marginTop: 10, flex: 1 }}
                                                                />
                                                                <IconButton
                                                                disabled={submitted}
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    id='IconBtnClear'
                                                                    onClick={() => handleRemoveOptionMaths(index)}
                                                                    style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                                >
                                                                    <ClearIcon fontSize="small" />
                                                                </IconButton>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            name={`mcqOptions.${index}.isCorrect`}
                                                                            checked={opt.isCorrect}
                                                                            onChange={() => handleMCQOptionChangeMaths(index, 'isCorrect', !opt.isCorrect)}
                                                                            disabled={!opt.option.trim()||submitted}
                                                                            />
                                                                    }
                                                                    label="Correct"
                                                                />
                                                            </Grid>


                                                        </Grid>
                                                    </div>
                                                ))}

                                                {submitError && (
                                                    <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                        {submitError}
                                                    </div>
                                                )}
                                                {currectError && (
                                                    <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                                        {currectError}
                                                    </div>
                                                )}

                                                {questionError && questionError.option && <FormHelperText error >{questionError?.option}</FormHelperText>}
                                                {questionError && questionError.correctAnswer && <FormHelperText error >{questionError?.correctAnswer}</FormHelperText>}

                                                <Button
                                                disabled={submitted}
                                                    id='handleOption'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAddOptionMaths}
                                                    style={{ width: '120px', backgroundColor: 'rgb(63, 186, 150)', marginTop: '10px', borderRadius: '6px' }}
                                                >
                                                    Add Option
                                                </Button>
                                                {requirederrors.questionid && (
                                                    <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{ marginBottom: '15px', paddingRight: '18px' }}>
                                                <Typography variant="subtitle1">Explanation *</Typography>
                                                <ReactQuill
                                                  readOnly={submitted}
                                                    theme="snow"
                                                    id="explanation"
                                                    name="explanation"
                                                    modules={modules}
                                                    formats={formats}
                                                    defaultValue={explanation}
                                                    onChange={(content, delta, source, editor) => {
                                                        satExplanation(content);
                                                        setQuestionError({
                                                            explanation: ''
                                                        });
                                                    }}
                                                />
                                                {questionError && questionError.explanation && <FormHelperText error  >{questionError?.explanation}</FormHelperText >}
                                            </Grid>
                                        </>
                                    )}
                                </>

                                <Grid item xs={12}>
                                    <Button onClick={SubmitQuestion} type="submit"
                                        id='handleSubmited'
                                        disabled={loadingnew || submitted}
                                        variant="contained" color="primary" fullWidth>
                                        {buttonText}
                                    </Button>
                                </Grid>
                                {requirederrors.questionid && (
                                    <FormHelperText error>{requirederrors.questionid}</FormHelperText>
                                )}
                            </Grid>}
                    </DialogContent>


                    <DialogActions>
                        <Button id='handleSubmit' onClick={() => handleModuleSubmitCreateQuestion(selectIndex)} color="secondary">
                            Submit
                        </Button>
                        <Button id='handleCancel' onClick={handleCloseMaths} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>


                </Dialog>



                <Dialog open={editDialog} onClose={handleCloseEdit} fullWidth>
                    <DialogTitle>Edit Module</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                  readOnly={submitted}
                                    variant="outlined"
                                    fullWidth
                                    id="addname"
                                    label="Module Name"
                                    type="text"
                                    name="name"
                                    value={editData.name || ""}
                                    onChange={EditModule}
                                    sx={{
                                        // bgcolor: "#f0f0f0",
                                        borderRadius: 1,
                                        marginBottom: '24px',
                                        height: 36,
                                        '& .MuiInputBase-input': {
                                            fontSize: 14,
                                            padding: "8px 12px",
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                  readOnly={submitted}
                                    variant="outlined"
                                    fullWidth
                                    id="addweight"
                                    label="Module Weight"
                                    type="text"
                                    name="weightage"
                                    value={editData.weightage || ""}
                                    onChange={EditModule}
                                    sx={{
                                        marginBottom: '20px',
                                        // bgcolor: "#f0f0f0",
                                        borderRadius: 1,
                                        height: 36,
                                        '& .MuiInputBase-input': {
                                            fontSize: 14,
                                            padding: "8px 12px",
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleModuleUpdateQuestion} color="secondary">
                            Update
                        </Button>
                        <Button onClick={handleCloseEdit} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={Preview} onClose={handlePreview} fullWidth>
                    <DialogTitle>Preview</DialogTitle>
                    <DialogContent style={{ marginLeft: '18px' }}>
                        <ol type="1">
                            {previewData && previewData?.questiondetails?.length > 0 && previewData?.questiondetails?.map((details, index) => (
                                <li>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
                                        <Typography
                                            variant="para" style={{ lineHeight: '1.2', fontSize: '15px', fontWeight: '400', paddingRight: '15px' }}
                                            dangerouslySetInnerHTML={{ __html: details }}
                                        />
                                        <IconButton onClick={() => DeleteQuestion(index)} color="error">
                                            < DeleteOutlinedIcon id={`DeleteIcon${index}`} style={{ color: "#ff4842" }} />
                                        </IconButton >
                                    </Box>
                                </li>
                            ))}
                        </ol>
                    </DialogContent>

                    <DialogActions>
                        <Button id='valCancel' onClick={handlePreview} color="primary">
                            Cancel
                        </Button>
                        <Button id='updateVal' onClick={() => handleUpdateQuestions(editIndexnew)} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>



                <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />


            </Page>
        </>
    );

}

const useStyles = makeStyles((theme) => ({
    imgPreviewRoot: {
        borderRadius: '10px',
        padding: '0.8rem;',
    },
    fileImgSIze: {
        width: '100%',
        height: '120px',
        objectFit: 'cover',
        objectPosition: 'center',
        border: '1px solid #fff',
        borderRadius: '5px',
        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
    },
    badgeAlign: {
        boxShadow: '0 2px 8px -5px #ff0000',
        color: '#FF0000',
        fontSize: '1.2rem',
        backgroundColor: '#fff',
        padding: '2px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    deleteLabel: {
        width: 'max-content',
        cursor: 'pointer',
    }
}));
export default SatTest;