import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography, Card, CardContent, Grid, Box, Container, Breadcrumbs,Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link,useNavigate } from 'react-router-dom';
import './index.css';
import { getIndividualResult } from '../../../Redux/Action'
import AssessmentSimulation from '../../generalAssessment/GeneralAssessmentMain';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import {ComingFrom } from '../../../store/reducer';
import AssessmentOverviewImage from '../../../assets/Images/Overview.png'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AssessmentOverview = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
   const navigate = useNavigate();
    const allassessmentdetails = useSelector((state) => state);
    const userid = useSelector((state) => state.userInfo && state.userInfo.id);
    const [value, setValue] = useState(0);
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [view, setView] = useState(allassessmentdetails?.GetAssessmentIndividual)
    const [assessmentView, setAssessmentView] = useState(false);
    const [comingFrom, setComingFrom] = useState('');
    const [fromIndex, setFromIndex] = useState(1);


    useEffect(() => {
        dispatch(getIndividualResult(allassessmentdetails && allassessmentdetails?.userInfo?.id, location.state.id))
    }, [location.state.id])


    useEffect(() => {
        setAssessmentDetails(allassessmentdetails?.GetAssessmentResult)
    }, [allassessmentdetails])

    const handleChange = (event, newValue) => {
        setValue(newValue);
      if(newValue === 2){
        // alert("55", assessmentDetails?.length, "1155")
      }
    };

    const handleNavigate = (id) => {
        setFromIndex(id + 1)
        setComingFrom('analysis')
        setAssessmentView(true)
        // navigate(`/auth/generalassessment?id=${location.state}`,{state:"ComingfromAnalysis"})
    }

    const handleClickTrialButton = async (planId, enrollmentType, assessmentId) => {
        
        // try {
        //     const res = await trialAndStripeSubscriptionService.postTrialAssessmentEnrollmentDetails(
        //         JSON.stringify({ planId, enrollmentType, assessmentId, authUserId: userid })
        //     );
        //     if (res.ok) {
        //         console.log('responec succes', res);

        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    };

    const handleStartTest = () => {
        handleClickTrialButton(view?.assessment_details?.subscriptionplanid, true, view?.assessment_details?.id)
        setComingFrom('start')
        setAssessmentView(true)
    }


    const assessments = [
        { id: 1, title: "Attempt 1", name: `${view && view?.assessment_details?.title}1` },
        { id: 2, title: "Attempt 2", name: `${view && view?.assessment_details?.title}2` },
        { id: 3, title: "Attempt 3", name: `${view && view?.assessment_details?.title}3` },
        { id: 4, title: "Attempt 4", name: `${view && view?.assessment_details?.title}4` },
        { id: 5, title: "Attempt 5", name: `${view && view?.assessment_details?.title}5` },
        { id: 6, title: "Attempt 6", name: `${view && view?.assessment_details?.title}6` },
        { id: 7, title: "Attempt 7", name: `${view && view?.assessment_details?.title}7` },
        { id: 8, title: "Attempt 8", name: `${view && view?.assessment_details?.title}8` },
        { id: 9, title: "Attempt 9", name: `${view && view?.assessment_details?.title}9` },
        { id: 10, title: "Attempt 10", name: `${view && view?.assessment_details?.title}10` },
    ];


    const handleNavigateDetails = () => {  
        const data = {
            id:location.state?.id?.id ? location.state?.id?.id:location.state?.id,
             from:location.state?.from
        }      
        navigate("/auth/AssessmentCourseDetails", { state: data});
    }

    const handleNavigateDetailsMyLearning = () =>{
         dispatch(ComingFrom("MyLearning"))
        navigate("/auth/subscribe")
    }

    return (
        assessmentView === false ?
            <Box sx={{ width: '92%', margin: 'auto', marginTop: '20px' }}>
                <Box sx={{ marginTop: '100px', marginBottom: "15px" }}>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        sx={{
                            padding: '15px',
                            paddingLeft: '0px',
                            paddingBottom: '0px',
                            paddingTop: '0px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        separator=">"
                    >
                    {location.state?.from !== 'MyLearning'?
                        <Link style={{
                            cursor: 'pointer',
                            color: "#212B36 !important",
                            textDecoration: 'none',
                            "&:hover": {
                                textDecoration: 'underline',
                            }
                        }}
                            underline="hover"
                            color="#212B36"
                        
                            to="/auth/subscribe"
                        >
                        General Assessment
                        </Link>
                        :
                        <button style={{
                            cursor:'pointer',
                             textDecoration: 'none',
                            border: 'none',
         
                            background: 'none',
                            color: '#0000ee',
                            fontSize: '16px',
                            fontWeight: '500'
                         }}
                             // underline="hover"
                             color="#212B36"
                             // to="/auth/AssessmentCourseDetails"
                             onClick={handleNavigateDetailsMyLearning}
                         >
                             My Learning
                         </button>
                         }
                     
                       <button style={{
                           cursor:'pointer',
                            textDecoration: 'none',
                           border: 'none',
        
                           background: 'none',
                           color: '#0000ee',
                           fontSize: '16px',
                           fontWeight: '500'
                        }}
                            // underline="hover"
                            color="#212B36"
                            // to="/auth/AssessmentCourseDetails"
                            onClick={handleNavigateDetails}
                        >
                            Assessment Overview
                        </button>
                        <Typography color="text.primary">
                            Test Section
                        </Typography>

                    </Breadcrumbs>
                </Box>

                <Box className={classes.greetingCard}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', }}>
                        <Box >
                            <Typography variant="h5" style={{ marginBottom: '10px', fontWeight: "900", color: '#000' }} ><strong>Welcome, {allassessmentdetails && allassessmentdetails?.userInfo?.firstName}</strong></Typography>
                            <Typography variant="paragraph" fontWeight="500" style={{ fontSize: '17px', fontWeight: "500", color: '#000' }}>Ready to start your day with Assessment?</Typography>
                        </Box>
                        <Box style={{ width: "150px", position: 'relative', height: "75px" }}>
                            <img style={{
                                scale: '2.1',
                                position: 'absolute',
                                width: "148px",
                                bottom: '8px',
                                transform: 'scaleX(1)',
                            }} src={AssessmentOverviewImage} alt="imgCard" />
                        </Box>
                    </Box>
                </Box>
                <Typography variant="h5" style={{ marginBottom: '14px', fontWeight: "900", marginTop: '18px', paddingLeft: '18px' }} ><strong>{view && view?.assessment_details?.title}
                </strong></Typography>
                <Stack direction="row" spacing={2} style={{ paddingLeft: '18px' }}>
                    <Item style={{ lineHeight: 1.1, padding: '4px 8px' }}>No of Attempts left: {assessmentDetails?.length && assessmentDetails !== null ? 10 - assessmentDetails?.length : 10}</Item>
                    <Item style={{ lineHeight: 1.1, padding: '4px 8px' }}>{view && view?.assessment_details?.title}</Item>
                    <Item style={{ lineHeight: 1.1, padding: '4px 8px' }}>{view && view?.assessment_details?.complexity_level?.charAt(0).toUpperCase() + view?.assessment_details?.complexity_level?.slice(1)}</Item>
                </Stack>
                <Box sx={{ width: '100%', typography: 'body1', marginTop: '24px' }}>
                    <Card sx={{
                        mx: "auto", boxShadow: 3, borderRadius: '10px',
                        border: '1px solid #dfdede'
                    }}>
                        <Box className="121" sx={{ width: '100%' }}>
                            <Box className="122" sx={{ borderBottom: 1, borderColor: 'divider', height: "40px" }}>
                                <Tabs value={value} onChange={handleChange} className="tabBasic" aria-label="basic tabs example"
                                    sx={{
                                        "& .MuiTabs-indicator": {
                                            backgroundColor: "#ff0004",
                                        },
                                        "& .MuiTab-root": {
                                            color: "#424242 !important",
                                        },
                                        "& .Mui-selected": {
                                            color: "#424242 !important",
                                        },
                                    }}
                                >
                                    <Tab label="All" {...a11yProps(0)} />
                                    <Tab label="Not Started" {...a11yProps(1)} />
                                    {/* <Tab label="In-Progress" {...a11yProps(2)} /> */}
                                    <Tab label="Completed" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0} style={{ padding: '10px 10px 20px' }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: '10px 10px 20px' }}>
                                    {assessments && assessments?.length > 0 && assessments?.map((assessment, index) => {
                                        let borderColor = '';
                                        let Color = '';
                                        if (assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails[index]?.user_result &&
                                            assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "correct" && item.response_recorded !== "incorrect")?.length === assessmentDetails[index]?.user_result?.length) {
                                            borderColor = '#40C057'
                                            Color = '#EAFBEA'
                                        }
                                        else if (assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails[index]?.user_result &&
                                            assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "incorrect" || "correct")?.length === assessmentDetails[index]?.user_result?.length) {
                                            borderColor = '#FA5252'
                                            Color = '#FCEBEA'
                                        }
                                        else {
                                            borderColor = '#dddada';
                                            Color = '#f5f5f5';

                                        }
                                        const firstEmptyAssessmentIndex = assessments.findIndex((_, i) => {
                                            const results = assessmentDetails?.[i]?.user_result || [];
                                            return results.length === 0;
                                        });
                                        return (
                                            <Box
                                                key={assessment.id}
                                                sx={{

                                                    borderRadius: "4px",
                                                    width: '99%',
                                                    padding: '1px 0px 0px !important',
                                                    margin: '5px auto 0px !important',
                                                    minHeight: '63px',
                                                    // backgroundColor: assessment.color,
                                                    backgroundColor: Color,
                                                    borderBottom: `3px solid ${borderColor}`,
                                                    flexWrap: "wrap"
                                                }}>
                                                <Box sx={{
                                                    // borderBottom: `3px solid ${borderColor}`,

                                                    display: "flex",
                                                    justifyContent: "space-between", padding: '8px 15px !important', alignItems: "center",
                                                }}>
                                                    <Box sx={{ flex: 1 }}>
                                                        <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
                                                            {assessment.title}: <span style={{ fontWeight: "normal" }}>{assessment.name}</span>
                                                        </Box>
                                                        {assessmentDetails && assessmentDetails[index]?.user_result ?
                                                            <Box sx={{ marginTop: "5px", fontSize: "14px" }}>{assessmentDetails && assessmentDetails[index]?.user_result && assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "correct")?.length}/ {assessmentDetails && assessmentDetails[index]?.user_result?.length}</Box>
                                                            : <Box sx={{ marginTop: "5px", fontSize: "14px" }}>{assessmentDetails?.[0]?.user_result?.length} Questions</Box>}
                                                    </Box>
                                                    {assessmentDetails && assessmentDetails[index]?.user_result && assessmentDetails[index]?.user_result?.length > 0 ?
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleNavigate(index)}
                                                            sx={{
                                                                borderColor: "#3B82F6",
                                                                borderRadius: '4px !important',
                                                                color: "#3B82F6",
                                                                padding: '4px 18px !important',
                                                                "&:hover": {
                                                                    backgroundColor: "#3B82F6",
                                                                    color: "#fff",
                                                                    borderColor: '#3B82F6'
                                                                },
                                                            }}>
                                                            Analysis
                                                        </Button>
                                                        :
                                                    <Tooltip title={firstEmptyAssessmentIndex === index ? "Click to start the test" : "To unlock this test, please attend the previous test!"}>
                                                        <span>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={handleStartTest}
                                                            disabled={firstEmptyAssessmentIndex!==index}
                                                            sx={{
                                                                borderColor: "#3B82F6",
                                                                borderRadius: '4px !important',
                                                                color: "#3B82F6",
                                                                padding: '4px 18px !important',
                                                                "&:hover": {
                                                                    backgroundColor: "#3B82F6",
                                                                    color: "#fff",
                                                                    borderColor: '#3B82F6'
                                                                },
                                                            }}>
                                                            Start
                                                        </Button> 
                                                        </span>

                                                        </Tooltip>}

                                                </Box>
                                            </Box>
                                        )

                                    })}

                                </Box>
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={1} style={{ padding: '10px 10px 20px' }}>
                                {assessments && assessments.length > 0 && assessments.map((assessment, index) => {
                                    const userResults = assessmentDetails?.[index]?.user_result || [];
                                    const firstEmptyAssessmentIndex = assessments.findIndex((_, i) => {
                                        const results = assessmentDetails?.[i]?.user_result || [];
                                        return results.length === 0;
                                    });
                                    return userResults.length === 0 ? (
                                        <Box
                                            key={assessment.id}
                                            sx={{
                                                borderRadius: "4px",
                                                width: '99%',
                                                padding: '1px 0px 0px !important',
                                                margin: '5px auto 15px !important',
                                                backgroundColor: '#f5f5f5',
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderBottom: `3px solid #dddada`,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: '8px 15px !important',
                                                    margin: '5px 0 15px !important',
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box sx={{ flex: 1 }}>
                                                    <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
                                                        {assessment.title}: <span style={{ fontWeight: "normal" }}>{assessment.name}</span>
                                                    </Box>
                                                    <Box sx={{ marginTop: "5px", fontSize: "14px" }}>
                                                        {assessmentDetails?.[0]?.user_result?.length} Questions
                                                    </Box>
                                                </Box>

                                                <Tooltip title={firstEmptyAssessmentIndex === index ? "Click to start the test" : "To unlock this test, please attend the previous test!"}>
                                                <span>
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleStartTest}
                                                    disabled={firstEmptyAssessmentIndex!==index}
                                                    sx={{
                                                        borderColor: "#3B82F6",
                                                        borderRadius: '4px',
                                                        color: "#3B82F6",
                                                        padding: '4px 18px',
                                                        "&:hover": {
                                                            backgroundColor: "#3B82F6",
                                                            color: "#fff",
                                                            borderColor: '#3B82F6',
                                                        },
                                                    }}
                                                >
                                                    Start
                                                </Button>
                                                </span>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ) : null;
                                })}
                            </CustomTabPanel>


                            <CustomTabPanel value={value} index={2} style={{ padding: '10px 10px 20px' }}>
                                {assessments && assessments?.length > 0 && assessments?.map((assessment, index) => {
                                    const userResults = assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails?.[index]?.user_result || [];                                    
                                    const correctResponses = userResults?.filter(item => item.response_recorded === "incorrect")?.length === 0;


                                    let borderColor = '';
                                    let Color = '';

                                    if (assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails[index]?.user_result &&
                                        assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "correct" && item.response_recorded !== "incorrect")?.length === assessmentDetails[index]?.user_result?.length) {
                                        borderColor = '#40C057'
                                        Color = '#EAFBEA'
                                    }
                                    else if (assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails[index]?.user_result &&
                                        assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "incorrect" || "correct")?.length === assessmentDetails[index]?.user_result?.length) {
                                        borderColor = '#FA5252'
                                        Color = '#FCEBEA'
                                    }
                                    else {
                                        borderColor = '#dddada';
                                        Color = '#f5f5f5';

                                    }

                                    return (
                                        userResults?.length > 0 && (
                                            <>
                                                <Box
                                                    key={assessment.id}
                                                    sx={{

                                                        borderRadius: "4px",
                                                        width: '99%',
                                                        minHeight: '63px',
                                                        padding: '1px 0px 0px !important',
                                                        margin: '5px auto 15px !important',
                                                        backgroundColor: Color,
                                                        flexWrap: "wrap"
                                                    }}>
                                                    <Box sx={{
                                                        borderBottom: `3px solid${borderColor}`,
                                                        display: "flex",
                                                        justifyContent: "space-between", padding: '8px 15px !important', alignItems: "center",
                                                    }}>
                                                        <Box sx={{ flex: 1 }}>
                                                            <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
                                                                {assessment.title}: <span style={{ fontWeight: "normal" }}>{assessment.name}</span>
                                                            </Box>
                                                            {assessmentDetails && assessmentDetails[index]?.user_result &&
                                                                <Box sx={{ marginTop: "5px", fontSize: "14px" }}>{assessmentDetails && assessmentDetails[index]?.user_result && assessmentDetails[index]?.user_result?.filter(item => item.response_recorded === "correct")?.length} / {assessmentDetails && assessmentDetails[index]?.user_result?.length}</Box>}
                                                        </Box>


                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleNavigate(index)}
                                                            sx={{
                                                                borderColor: "#3B82F6",
                                                                borderRadius: '4px !important',
                                                                color: "#3B82F6",
                                                                padding: '4px 18px !important',
                                                                "&:hover": {
                                                                    backgroundColor: "#3B82F6",
                                                                    color: "#fff",
                                                                    borderColor: '#3B82F6'
                                                                },
                                                            }}>
                                                            Analysis
                                                        </Button>


                                                    </Box>
                                                </Box>
                                            </>
                                        )


                                    )
                                })}
                                
                                   {assessmentDetails === null &&
                                            <Box style={{ height: '200px' }}><Typography style={{
                                                textAlign: 'center', fontWeight: '500', paddingTop: '20px'
                                            }}>No assessments completed</Typography></Box>
                                        }
                            </CustomTabPanel>
                        </Box>

                    </Card>
                </Box>
            </Box>
            :
            <AssessmentSimulation assessmentId={location.state?.id} comingFrom={comingFrom} fromIndex={fromIndex} />
    )
}
export default AssessmentOverview;


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#002b50',
    borderRadius: '4px',
    padding: '2px 10px',
    textAlign: 'center',
    color: "#fff",
    fontSize: '14px'
}));

const useStyles = makeStyles((theme) => ({
    greetingCard: {
        width: '100%',
        backgroundColor: '#d2daff',
        borderRadius: '18px'

    }

}));
