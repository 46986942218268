import React, { useState, useEffect } from "react";
import {
    Grid, Typography, Container, Button, IconButton, TextField,
    InputAdornment, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TablePagination, Switch
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import lottie from 'lottie-web';
import Page from '../../../components/Page'
import PageHeader from '../../../components/PageHeader';
import AddGeneralAssessment from "./AddGACognitiveSkill";
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';
import DeleteAlert from '../../../components/modal/DeleteModal';
import AssessmentSimulation from './GeneralAssessmentSimulation';

const Assessment = () => {
    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    const navigate = useNavigate()
    const [searchedDetails, setSearchedDetails] = useState('')
    const [openAssessment, setOpenAssessment] = useState(true);
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [paginationCount, setPaginationCount] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [deleteModalTitle, setDeleteModalTitle] = useState('');
    const [deleteId, setDeleteId] = useState('')
    const [simulationStart, setSimulationStart] = useState(false);
    const [simulationData, setSimulationData] = useState('');

    useEffect(() => {
        getCognitiveSkillGA()
    }, [searchedDetails, page, rowsPerPage])

    const getCognitiveSkillGA = async () => {
        // setLoading(true);
        const result = await adminServices.getCognitiveSkillGA(searchedDetails, page, rowsPerPage);
        if (result.ok) {
            console.log(result.data,"result.data get Cognitive");
            
            setLoading(false);
            setPaginationCount(result.data.pagination)
            setAssessmentDetails(result.data.assessments)
        }
        // setLoading(false);
    }

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCreateAssessment = () => {
        navigate("/app/AddGeneralAssessment")
        // setOpenAssessment(false);
    }
    const handleCloseAssessment = () => {
        setOpenAssessment(false);
    }

    const handleBack = () => {
        setOpenAssessment(true);
        getCognitiveSkillGA()
    }

    const handleBackSimulation = () =>{
        setSimulationStart(false)
     }

    const handleDelete = (data) => {
        setDeleteAlert(true);
        setDeleteId(data.assessment.id);
        setDeleteModalTitle('Are you sure you want delete this assessment?');
    }

    const handleEdit = (data) => {
        navigate("/app/EditGACognitiveskill",{state:data?.assessment?.id})
    }

    const handleToggleButton = async (id, value) => {
        try {
            const resulte = await adminServices.UpdateGeneralAssesmentStatus(id, value);
            if (resulte.ok) {
                setSnackbarTitle('Assignment status updated successfully');
                setOpenSnackbar(true);
                getCognitiveSkillGA();

            }
        } catch (error) {
            console.log(error, 'error');
        }

    };

    const handleSimulation= (data) =>{
        setSimulationStart(true)
        setSimulationData(data)
     }


        const handleDeleteGeneralAssessment = async () => {
            try {
                const response = await adminServices.deleteGeneralAssessment(deleteId);
                if (response.ok) {
                    setSnackbarTitle('Assignment deleted successfully');
                    setOpenSnackbar(true);
                    getCognitiveSkillGA();
                    setDeleteAlert(false)
                    setDeleteId('')
                    setDeleteModalTitle("");
                }
            } catch (error) {
                console.log(error);
            }
        }
    
    return (
        <>
        {!simulationStart? 
            <>
        <Page title="General Assessment">
            <PageHeader pageTitle="General Assessment" submodule="submodule" />
            <>
               <Grid container spacing={2}>
                    <Grid align="end" item xs={12}>
                        <TextField
                            variant="outlined"
                            placeholder="Search Name"
                            id="searchassessment"
                            size="small"
                            sx={{ width: '250px', height: '40px' }}
                            value={searchedDetails}
                            onChange={(e) => setSearchedDetails(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon id="searchAssessmentIconbtn"/>
                                    </InputAdornment>
                                ),
                                sx: { height: '100%' },
                            }}
                        />
                        <Button
                            style={{ marginLeft: '15px', height: '40px' }}
                            id="createassessmentButtongeneral"
                            variant="contained"
                            color="primary"
                            onClick={handleCreateAssessment}
                        >
                            Create Assessment
                        </Button>
                    </Grid>
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table sx={{ marginLeft: '15px', width: '98%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', paddingLeft: '8px !important' }}>Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Author</TableCell>
                                    {userRole === "SUPER_ADMIN" &&
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Live</TableCell>}
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Questions</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Level</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Category</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Date</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                )}

                                {!loading && assessmentDetails && assessmentDetails?.length > 0 ? (
                                    assessmentDetails?.map((row) => (
                                      
                                        
                                        <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                            <TableCell sx={{ padding: '10px' }}>{row?.assessment?.title}</TableCell>
                                            <TableCell sx={{ marginLeft: '40px' }}>{row?.assessment?.author_first_name}</TableCell>
                                           
                                           
                                            {userRole === "SUPER_ADMIN" &&<TableCell sx={{ marginLeft: '40px' }}>
                                                <Switch id={`switchLive${row?.assessment?.id}`}
                                                    checked={row?.assessment.is_published === true}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase': {
                                                            color: row?.assessment.is_published ? 'green' : 'default',
                                                        },
                                                        '& .Mui-checked': {
                                                            color: 'green',
                                                        },
                                                        '& .Mui-Track': {
                                                            backgroundColor: row?.assessment.is_published ? 'green' : 'default',
                                                        },
                                                    }}
                                                    onChange={() => {
                                                        const newValue = !row?.assessment.is_published;
                                                        handleToggleButton(row.assessment.id, newValue);
                                                    }}
                                                />
                                            </TableCell>}

                                            <TableCell sx={{ marginLeft: '40px' }}>{row?.assessment?.questions_list?.length}</TableCell>
                                            <TableCell sx={{ marginLeft: '40px' }}>{row?.assessment?.complexity_level.charAt(0).toUpperCase() + row?.assessment?.complexity_level.slice(1).toLowerCase()}</TableCell>
                                            
                                            <TableCell>{row?.assessment?.category_name}</TableCell>

                                            <TableCell>
                                                {moment(row?.assessment?.created_date_time).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                <IconButton
                                                 disabled={row?.assessment.is_published === true}
                                                id="editassessmentgeneral"
                                                    onClick={() => handleEdit(row)} 
                                                    color="primary">
                                                    <EditIcon id="editassessmentgeneralIcon"      />
                                                </IconButton>
                                                <IconButton id="deleteassessmentgeneral"
                                                    onClick={() => handleDelete(row)} 
                                                    color="primary">
                                                    <DeleteIcon id="deleteassessmentgeneralIcon"/>
                                                </IconButton>
                                                <IconButton id="deleteassessmentgeneralPreview"
                                                    onClick={() => handleSimulation(row)}
                                                    color="primary">
                                                    <PlayCircleFilledWhiteIcon id="deleteassessmentgeneralPreviewIcon"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                            {!loading && assessmentDetails && assessmentDetails?.length === 0 && "No Data Found"}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>




                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={paginationCount?.total_count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        sx={{ marginTop: 2 }}
                    />
                    <DeleteAlert
                        open={deleteAlert}
                        title={deleteModalTitle}
                        confirm={handleDeleteGeneralAssessment}
                        close={() => setDeleteAlert(false)}
                    />
                    <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

                </Grid>
            </>
        </Page>
        </>
        :
        <AssessmentSimulation  data={simulationData} handleBack={handleBackSimulation}/>}
        </>
    );
};

export default Assessment;