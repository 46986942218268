import React, { useState, useEffect,useRef, useLayoutEffect, useMemo } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel, Modal, FormControl, Button, LinearProgress, Paper, Breadcrumbs, Link, Tooltip, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from "dompurify";
import LaunchIcon from '@mui/icons-material/Launch';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import CoffeeIcon from "@mui/icons-material/Coffee";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import InsightsIcon from "@mui/icons-material/Insights";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Page from '../../components/Page';
import ImageView from '../../assets/Images/ProgressFrame.png'
import adminServices from '../../services/adminServices';
import LottieLoading from '../../components/LottieLoading';
import Snackbar from '../../components/snackbar/snackbar';
import Sessiontimeout from '../../assets/Images/Sessiontimeout.png'
import TestComplete from '../../assets/Images/TestComplete.png'
import chartComponent from './ChartComponent'
import { FromGeneralAssessmentView } from '../../store/reducer'
import brakeTimeImg from "../../assets/Images/breakTime.gif";
import './index.css'

const SatAssessment = ({ assessmentId, comingFrom, fromIndex,details,onCallBack,resumeData,resumeLength }) => {
  // console.log(resumeData?.questions,"resumeData");
 // console.log(resumeLength,"resumeLength");
  
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [allDetails, setAllDetails] = useState('');
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
   const navigate = useNavigate();
  const [isTimeout, setIsTimeout] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [openQuestionList, setOpenQuestionList] = useState(false);
  const [questionTimes, setQuestionTimes] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const [questionType, setQuestionType] = useState('English');
  const [time, setTime] = useState(0);
  const [isMarkedAsSaved, setIsMarkedAsSaved] = useState(false);
  const [calculator, setCalculator] = useState(false);
  const [gridLeft, setGridLeft] = useState(6);
  const [gridRight, setGridRight] = useState(6);
  const [parsedOptions, setParsedOptions] = useState([]);
  const [attemptCount, setAttemptCount] = useState(1);
  const [submodule, setSubmodule] = useState(1);
  const [testView, setTestView] = useState(false);
  const [resumeTime, setResumeTime] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600);
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [showModuleComplete, setShowModuleComplete] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [markedForReview, setMarkedForReview] = useState({});
  const [confirmSumbitMsg , setConfirmSubmitMsg ] = useState(false);
  const [breakTimeLeft, setBreakTimeLeft] = useState(600);
  const [openDrop, setOpendrop] = useState(false);
  const [lastVisibilityChange, setLastVisibilityChange] = useState(Date.now());
  const [overlayZIndex, setOverlayZIndex] = useState('none');
  const [questionListAnchorEl, setQuestionListAnchorEl] = useState(null);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

    useEffect(() => {
      const handleBeforeUnload = () => {
        dispatch(FromGeneralAssessmentView(false));
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      const handlePopState = () => {
        dispatch(FromGeneralAssessmentView(false));
      };
  
      window.addEventListener("popstate", handlePopState);
  
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("popstate", handlePopState);
      };
    }, []);
  
   
    const handlePostUserResult = async () =>{
      setOpenConfirmDialog(false);
      setIsLoading(true)
    const totalTimeTaken = Object.values(questionTimes).reduce((acc, time) => acc + time, 0);
    const formattedSelectedAnswers = questions && questions?.length > 0 && questions.map((question, index) => ({
      questionId: question.id,
      index,
      selectedAnswer: selectedAnswers[index] || null,
      timeTaken: questionTimes[index] || 0,
      response_recorded: correctAnswer[index] || null,
      multiplier: question?.cognitive_skill_id
    }));
    const body = {
      type: questionType,
      assessment_id: allDetails?.id,
      user_id: userInfo?.id,
      user_result: formattedSelectedAnswers,
      date_started: getCurrentTime(),
      date_completed: getCurrentTime(),
      difference_date: totalTimeTaken,
      // attemptCount: parseInt(attemptCount, 10) + 1,

      attemptCount:fromIndex,
      submodules: submodule,
      section: questionType
    };
    const result = await adminServices.postSATResult(body);
    setMarkedForReview({});  
    if (result.ok) {
        if(questionType === 'Maths'){
        setQuestions(result?.data?.questions?.slice(0,22))
       } 
       else{
        setQuestions(result?.data?.questions?.slice(0,27))
       }
       if (submodule === 1) {
        setShowModuleComplete(true);
        }
      setTimeout(() => {
        setShowModuleComplete(false);
        setSelectedAnswers([]);
        setOpenQuestionList([]);
        setTime(questionType === 'English' ? 1920 : 2100);
        setStartTime('');
        setResumeTime(false);
        setCorrectAnswer([]);
        setCurrentIndex(0);
        setIsLoading(false);
        if (submodule === 2) {
          setResumeTime(true);
        }
        setSubmodule(2);
        setIsLoading(false);
      }, 3800);
          }
   }

  const onStartTest = () => {
    setTestView(true)
    setTime(questionType === 'English' ? 1920 : 2100)
    dispatch(FromGeneralAssessmentView(true));
  }
  const onBack = () => {
    onCallBack()
  }
  


  const handleOpenQuestionList = (event) => {
    setQuestionListAnchorEl(event.currentTarget);
    setOverlayZIndex('block');
  };

  const handleCloseQuestionList = () => {
    setQuestionListAnchorEl(null);
    setOverlayZIndex('none');
  };

  const getAssessmentDetails = async (type) => {
    setIsLoading(true);
    const result = await adminServices.getStartSATAssessment(assessmentId, userInfo?.id, type);
    setCurrentIndex(0)
    if (result.ok) {
      setAllDetails(result?.data?.assessmentDetails);
      // setTime(result?.data?.assessmentDetails?.time_in_mins);
      if (type === 'Maths') {
        setQuestions(result?.data?.questions?.slice(0, 22))
      }
      else {
        setQuestions(result?.data?.questions?.slice(0, 27))
      }
    }
    setIsLoading(false);
  };

  const getAssessmentDetailsMaths = async () => {
    setIsLoading(true);
    const result = await adminServices.getStartSATAssessment(assessmentId, userInfo?.id, 'Maths');
    setCurrentIndex(0)
    if (result.ok) {
      setAllDetails(result?.data?.assessmentDetails);
      // setTime(result?.data?.assessmentDetails?.time_in_mins);
        setQuestions(result?.data?.questions?.slice(0, 22))
    }
    setIsLoading(false);
  };
 
  useEffect(() => {
    if(resumeLength === 0){
        getAssessmentDetails(questionType);
    }
    else if(resumeLength > 0){
        setTime(1920)
        if (resumeLength === 1 ) {
          setQuestionType('English');
          setSubmodule(resumeLength === 1  ? 2 : 1);
          // setTime(resumeLength === 1 || resumeLength === 2 ? 1920 : 2100);
          const filteredQuestions = resumeData?.questions?.filter(item => item != null)
          setQuestions(filteredQuestions?.slice(0, 25));
        }
       
        else {
          setQuestionType('Maths');
          setSubmodule(resumeLength === 3 ? 2 : 1);
          getAssessmentDetailsMaths();
        }

    }
   
  }, [resumeLength]);
  const currentQuestion = questions && questions[currentIndex];
  
  const getAccurateElapsed = () => {
    const now = Date.now();
    let elapsed = 0;
   
    if (backgroundStartRef.current) {
      elapsed += backgroundStartRef.current - lastUpdateRef.current;
      elapsed += now - backgroundStartRef.current;
      backgroundStartRef.current = null;
    } else {
      elapsed = now - lastUpdateRef.current;
    }
   
    lastUpdateRef.current = now;
    return Math.floor(elapsed / 1000);
  };
 
  const lastUpdateRef = useRef(Date.now());
  const backgroundStartRef = useRef(null);
  const timerRef = useRef(null);
 
  const updateTimer = () => {
    const elapsedSeconds = getAccurateElapsed();
    setTime(prev => {
      const newTime = prev - elapsedSeconds;
     
      if (newTime <= 0) {
        if (!isPaused && testView) {
          setIsTimeout(true);
          handlePostUserResult();
        }
        return 0;
      }
      return newTime;
    });
  };
 
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(timerRef.current);
        backgroundStartRef.current = Date.now();
      } else {
        updateTimer();
        startTimer();
      }
    };
 
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isPaused, testView]);
 
  const startTimer = () => {
    if (!isPaused && time > 0) {
      timerRef.current = setInterval(updateTimer, 1000);
    }
  };
 
  useEffect(() => {
    lastUpdateRef.current = Date.now();
    startTimer();
   
    return () => {
      clearInterval(timerRef.current);
    };
  }, [isPaused, time]);
 

  const handleNavigateAnalysis = () => {
    navigate(`/auth/SATAnalysis?id=${assessmentId}`,{
      state: {
          id:assessmentId,
          comingFrom: 'ComingfromAnalysis',
          fromindex:fromIndex,
          data:details
        }
      })
      dispatch(FromGeneralAssessmentView(false));
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formatTimeTimer = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formatTimeTimerDisplay = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, '0')} Hours${minutes.toString().padStart(2, '0')} Minutes`;
  };

  useEffect(() => {
    if (resumeTime) {
      if (timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(timer);
      }
      if (timeLeft === 0) {
        setTimeout(() => {
          setShowModuleComplete(false);
          handleNextModule(); 
        }, [100]);
      }
    }
  }, [timeLeft]);

  const reAttemptTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleNext = () => {
    const endTime = Date.now();
    const timeSpent = (endTime - startTime) / 1000;
    const currentQuestion = questions[currentIndex];
    setQuestionTimes((prev) => ({
      ...prev,
      [currentIndex]: (prev[currentIndex] || 0) + timeSpent,
    }));

    setStartTime(Date.now());
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave? Your progress will not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBack = () => {
    if (currentIndex > 0) {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;

      setQuestionTimes((prev) => ({
        ...prev,
        [currentIndex]: (prev[currentIndex] || 0) + timeSpent,
      }));

      setStartTime(Date.now());
      setCurrentIndex(currentIndex - 1);
    }
  };


  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const handleOptionSelect = (option, index) => {

    const persedData = JSON.parse(currentQuestion?.options);
    const correctAnswers = persedData.correctAnswer;
    const isCorrect = correctAnswers[index];
    const stringifiedIsCorrect = JSON.stringify(isCorrect);

    setCorrectAnswer((prev) => ({
      ...prev,
      [currentIndex]: stringifiedIsCorrect === "false" || stringifiedIsCorrect === false ? "incorrect" : "correct",
    }));
    setSelectedAnswers((prev) => ({
      ...prev,
      [currentIndex]: option,
    }));
  };

  const handleQuestionSelect = (index) => {
    const endTime = Date.now();
    const timeSpent = (endTime - startTime) / 1000;

    setQuestionTimes((prev) => ({
      ...prev,
      [currentIndex]: (prev[currentIndex] || 0) + timeSpent,
    }));

    setStartTime(Date.now());
    setCurrentIndex(index);
    handleCloseQuestionList();
  };



  const handleSubmit = async () => { 
      setOpenConfirmDialog(true);
   
  };



  const handleBookmarkTrue = (index) => {
    setMarkedForReview((prevState) => ({
      ...prevState,
      [index]: true, 
    }));
  };
  
  const handleBookmarkFalse = (index) => {
    setMarkedForReview((prevState) => ({
      ...prevState,
      [index]: false, 
    }));
  };

  const openCalculator = () => {
    setCalculator(true)
  }
  const handleGridLeft = () => {
    if (gridLeft === 5 && gridRight === 7) {
      setGridLeft(6);
      setGridRight(6)
    }
    else {
      setGridLeft(5);
      setGridRight(7)
    }

  }
  const handleGridRight = () => {

    if (gridLeft === 7 && gridRight === 5) {
      setGridLeft(6);
      setGridRight(6)
    }
    else {
      setGridLeft(7);
      setGridRight(5)
    }
  }

  useEffect(() => {
    if (currentQuestion) {
      const parsed = JSON.parse(currentQuestion?.options);
      setParsedOptions(parsed.mcqOptions);
    }
  }, [currentQuestion]);


  useEffect(() => {
    let breakTimer;
    if (resumeTime && testView === true && questionType === 'English') {
      if (breakTimeLeft > 0) {
        breakTimer = setInterval(() => {
          setBreakTimeLeft(prev => {
            if (prev <= 1) {
              clearInterval(breakTimer);
              // Reset main timer and move to next module
              setTime(2100); // Reset to 37 minutes for Math section
              handleNextModule();
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
      return () => clearInterval(breakTimer);
    }
  }, [resumeTime, testView, questionType]);

  const handleNextModule = () => {
    setSubmodule(1);
    setQuestionType('Maths');
    setQuestions([]);
    getAssessmentDetails('Maths');
    setResumeTime(false);
    setBreakTimeLeft(600);
    setTime(2100)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOverlayZIndex('block');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOverlayZIndex('none');
  };

  // console.log(questions,"questions");

  return (
    <Page title={'Begin Course'} style={{ marginTop: '8px', padding: '0px', paddingTop: '70px !important' }}>
      <Box className="overLay" style={{
        opacity: '1', transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)', position: 'fixed',
        justifyContent: 'center', right: '0', bottom: '0', top: '0', left: '0', 
        display: overlayZIndex,
        backgroundColor: 'rgb(12 12 12 / 35%)', zIndex: '1101',    width: '100vw',
        height: '100vh'
      }} />
      {testView === false &&
        <Box className="practiceTimeInstructions" sx={{ mx: "auto", p: 2, textAlign: "center" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            {allDetails && allDetails?.title}
          </Typography>

          <Card variant="outlined" sx={{ textAlign: "left" }}>
            <CardContent>
              <Box style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <HourglassTopIcon className='iconDesign' />
                <Typography style={{ display: 'inline' }} variant="h6" className='topicHeading' fontWeight="bold">Timing</Typography>
              </Box>
              <Typography style={{ paddingLeft: '50px' }} variant="body2">
                The <strong>{formatTimeTimerDisplay(allDetails && allDetails?.time_in_mins)}</strong> is timed. But you can pause the test at any moment.
                The test time is fixed for ‘Reading and Writing’ section, that is <strong>64 minutes</strong> considering both modules.
                The test time is fixed for ‘Math’ section, that is <strong>70 minutes</strong> considering both modules.
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                <CoffeeIcon className='iconDesign' />
                <Typography style={{ display: 'inline' }} variant="h6" className='topicHeading' fontWeight="bold">Break time</Typography>
              </Box>
              <Typography style={{ paddingLeft: '50px' }} variant="body2">
                You will have <strong>10 minutes</strong> of break time after the two modules of ‘Reading and Writing’ section are completed.
                The instructions to follow during this break time can be found on that screen.
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                <InsightsIcon className='iconDesign' />
                <Typography style={{ display: 'inline' }} variant="h6" className='topicHeading' fontWeight="bold">Scores and Analytics</Typography>
              </Box>
              <Typography style={{ paddingLeft: '50px' }} variant="body2">
                Your score is calculated once you complete both the ‘Reading and Writing’ and ‘Math’ sections.
                The analytics of your performance in each module and the overall test will be displayed at the end of the test.
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                <PsychologyIcon className='iconDesign' />
                <Typography style={{ display: 'inline' }} variant="h6" className='topicHeading' fontWeight="bold">Make much of your practice</Typography>
              </Box>
              <Typography style={{ paddingLeft: '50px' }} variant="body2">
                Please consider this practice SAT test as an actual assessment that you take in your Bluebook application.
                We suggest you create an environment that supports the actual test environment so that you are mentally prepared.
              </Typography>
            </CardContent>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="contained" style={{ backgroundColor: '#497ffc', color: '#fff', borderRadius: '6px' }}
            onClick={onBack}
            >
              Back
            </Button>
            <Button variant="contained" style={{ backgroundColor: '#497ffc', color: '#fff', borderRadius: '6px' }}
              onClick={onStartTest}
            >
              Start Test
            </Button>
          </Box>
        </Box>}


      {resumeTime && testView === true &&
        <>
          {resumeTime && questionType === 'English' &&
            //  <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#d2daff", height: "90vh", padding: 2 }}>
            //   <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
            //     <Box sx={{ flex: 1, display: "flex", flexDirection: "column", padding: 2 }}>
            //       <Typography variant="h6" sx={{ alignSelf: "flex-start", marginBottom: 1 }}>
            //         Time Remaining: {reAttemptTime(timeLeft)}
            //       </Typography>
            //       <Box sx={{ display: "flex", }}>
            //         <Button variant="contained" color="primary" onClick={handleNextModule}>Resume Test</Button>
            //       </Box>
            //     </Box>
            //     <Box sx={{ flex: 1, padding: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

            //       <Typography variant="h6">Test Instructions</Typography>
            //       <Typography variant="body1">
            //         - Read the questions carefully. <br />
            //         - Do not refresh the page. <br />
            //         - Submit your answers before the time runs out.
            //       </Typography>
            //     </Box>
            //   </Box>
            // </Box>
            <Box classname="breaktimeMain" style={{ paddingTop: '45px', minHeight: '80vh', backgroundColor: '#fff' }} sx={{ flexGrow: 1, maxWidth: '90%', margin: "auto" }}>
              <Box elevation={3} sx={{ padding: 3, borderRadius: 0 }}>
                <Grid container spacing={2}>

                  <Grid item xs={12} md={8} style={{ paddingLeft: '55px' }}>
                    <Typography variant="h5" fontWeight="bold">
                      Practice Test Break
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      You can resume this practice test as soon as you're ready to move on.
                      On test day, you'll wait until the clock counts down. Read below to see
                      how breaks work on test day.
                    </Typography>
                    <Box sx={{ my: 2, borderBottom: "1px solid #ccc" }} />

                    <Typography variant="h6" fontWeight="bold">
                      Take a Break: Do Not Close Your Device
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      After the break, a Resume Testing Now button will appear, and you'll start the next session.
                    </Typography>

                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, marginBottom: 1 }}>
                      Follow these rules during the break:
                    </Typography>
                    <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
                      <li style={{ marginBottom: "5px" }}>Do not disturb students who are still testing.</li>
                      <li style={{ marginBottom: "5px" }}>Do not exit the app or close your laptop.</li>
                      <li style={{ marginBottom: "5px" }}>Do not access phones, smartwatches, textbooks, notes, or the internet.</li>
                      <li style={{ marginBottom: "5px" }}>Do not eat or drink near any testing device.</li>
                      <li style={{ marginBottom: "5px" }}>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} textAlign="center">
                    <Box style={{
                      '@media (min-width: 900px)': {
                        borderLeft: '1px solid #ccc'
                      }
                    }} sx={{
                      borderLeft: {
                        xs: 'none',
                        md: '1px solid #ccc'
                      }
                    }}>
                      <img
                        src={brakeTimeImg}
                        alt="Break Time"
                        style={{ width: "100%", maxWidth: "180px", margin: 'auto', marginBottom: "10px" }}
                      />
                      <Box
                        style={{
                          height: '110px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginTop: '20px'

                        }}
                        sx={{
                          backgroundColor: "#437bfc",
                          color: "white",
                          width: '80%',
                          minWidth: '220px',
                          maxWidth: '280px',
                          padding: '10px 0 15px',
                          borderRadius: "8px",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          margin: "auto",
                        }}
                      >
                        <p style={{ fontSize: "18px", marginBottom: '8px', fontWeight: '400' }}>Remaining Break Time</p>
                        {formatTime(breakTimeLeft)}
                      </Box>
                      <Button
                        onClick={handleNextModule}
                        variant="contained"
                        color="success"
                        style={{
                          width: '80%',
                          minWidth: '220px',
                          maxWidth: '280px',
                          backgroundColor: '#52c28c',
                          color: '#fff',
                          borderRadius: '4px',
                          fontSize: '16px',
                          fontWeight: '400'
                        }}
                        sx={{ mt: 2, width: "100%" }}
                      >
                        Resume Test
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
          {resumeTime && questionType === 'Maths' &&
            <>
            <div style={{    marginTop: '35px'}}>
              <div className="flex items-center justify-center min-h-screen bg-red-100 testStatus"  style={{ boxShadow: 'rgb(41 102 247) 0px 2px 4px 0px, rgb(41 102 247) 0px 2px 16px 0px',   background: '#fff', justifyItems: 'center',margin: 'auto' }} >
                <div className="flex justify-center mb-4">
                  <div className="relative inline-block">
                    <img style={{ justifySelf: 'center' }}
                      src={TestComplete}
                      alt="Timeout Icon"
                      className="w-16 h-16"
                    />
                  </div>
                </div>

                <h2 className="text-xl font-bold text-blue-600" style={{
                  textAlign: 'center',
                  marginTop: '5px', fontWeight: '700', color: "#6c98fd !important"
                }}>Test Completed</h2>
                <p className="text-gray-600 mt-2" style={{
                  textAlign: 'center', marginTop: '10px'
                }}>
                  You have completed the assessment. Please note that you have exhausted 1 attempt from your quota. To know the answers, please click on the button below!    </p>
                <Button onClick={handleNavigateAnalysis} style={{  marginTop: '25px',
                      border: '1px solid #8f8f8f',borderRadius: '4px', color: '#333',
                      "&:hover": { 
                        backgroundColor: '#d3d3d3 !important' 
                      } 
                 }} className="mt-4 w-full bg-gray-200 text-gray-800 " >
                  View Analysis
                </Button>
              </div>
              </div>
            </>
          }
        </>}
      {!resumeTime && testView === true &&
        <>
        <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#d2daff" }}>
          {(isLoading) ? <br /> :
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: "column", xs: "column" }, maxHeight: '60px' }}>
              <Box sx={{ width: { md: '40%', sm: '99vw' } }}>
              {questionType === 'English' ? (
                  <>
                    <Typography
                      sx={{
                        padding: '10px 0px 0px 40px',
                        fontSize: { xs: '20px', md: '22px' },
                        fontWeight: { xs: 600, md: 700 },
                        paddingTop: '0px'
                      }}
                      variant="h6"
                    >
                      Section 1: Reading and Writing
                    </Typography>
{/*                  
                      <Typography variant="text" onClick={() => setOpendrop(true)} sx={{ textTransform: "none", fontSize: "17px" ,
                        color: '#000',  fontWeight: '500',    paddingLeft: '40px' }}>
          Directions 22 <KeyboardArrowDownIcon style={{  top: '6px', position: 'relative', color: '#4a4a4a', fontSize: '24px'}}/>

        </Typography> */}
                      <Dialog 
                        open={openDrop} 
                        onClose={() => setOpendrop(false)} 
                        fullWidth 
                        maxWidth="sm"
                        className='dialogWithArrow'
                        PaperProps={{
                          sx: {
                            zIndex: 1105,
                            position: 'absolute',
                            left: '1vh',
                            top: '160px',
                            m: 0
                          },
                    
                        }}
                      >            
             
                        <DialogContent>
                          <Typography>
                            The essay gives you an opportunity to show how effectively you can read and comprehend a passage and write an essay analyzing the passage.
                            In your essay, you should demonstrate that you have read the passage carefully, present a clear and logical analysis, and use language precisely.
                          </Typography>
                          <Button 
                            onClick={() => setOpendrop(false)} 
                            variant="contained" 
                            sx={{  background: "#ff9800", color: "#fff" , borderRadius: "5px",
                              float: 'right',
                              marginTop: '20px',

                              "&:hover": { 
                                  background: "#d3d3d3", 
                                  color: "#444" 
                                }
                            }}
                          >
                            Close
                          </Button>
                        </DialogContent>
                      </Dialog>
                   
                  </>
                )  :
                  <Typography
                    sx={{
                      padding: '0px 0px 0px 40px',
                      fontSize: { xs: '20px', md: '22px' },
                      fontWeight: { xs: 600, md: 700 },
                      paddingTop: '0px'
                    }}
                    variant="h6">Section 2: Math</Typography>}

                    
<Typography variant="text"       onClick={handleClick} sx={{ textTransform: "none", fontSize: "17px" , float: 'left',
top: '-7px', position: 'relative', color: '#000',  fontWeight: '500',    paddingLeft: '40px' }}>
          Directions<KeyboardArrowDownIcon style={{  top: '6px', position: 'relative', color: '#4a4a4a', fontSize: '24px'}}/>

        </Typography>
           <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2, maxWidth: '550px' }}>
                The essay gives you an opportunity to show how effectively you can read and comprehend a passage and write an essay analyzing the passage.
                In your essay, you should demonstrate that you have read the passage carefully, present a clear and logical analysis, and use language precisely.
              </Typography>
            </Popover>
        
                      {/* <Dialog 
                        open={openDrop} 
                        onClose={() => setOpendrop(false)} 
                        fullWidth 
                        maxWidth="sm"
                        className='dialogWithArrow'
                        PaperProps={{
                          sx: {
                            position: 'absolute',
                            left: '1vh',
                            top: '160px',
                            m: 0
                          },
                    
                        }}
                      >            
             
                        <DialogContent>
                          <Typography>
                            The essay gives you an opportunity to show how effectively you can read and comprehend a passage and write an essay analyzing the passage.
                            In your essay, you should demonstrate that you have read the passage carefully, present a clear and logical analysis, and use language precisely.
                          </Typography>
                          <Button 
                            onClick={() => setOpendrop(false)} 
                            variant="contained" 
                            sx={{  background: "#ff9800", color: "#fff" , borderRadius: "5px",
                              float: 'right',
                              marginTop: '20px',

                              "&:hover": { 
                                  background: "#d3d3d3", 
                                  color: "#444" 
                                }
                            }}
                          >
                            Close
                          </Button>
                        </DialogContent>
                      </Dialog> */}

                </Box>
                <Box sx={{ maxHeight: { md: '60px', sm: '70px' }, width: { md: '20%', sm: '99vw', justifyItems: 'center' } }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", position: 'relative', top: '5px' }}>{formatTimeTimer(time)}</Typography>
                  <Typography variant="h6" sx={{ padding: '3px 12px', fontWeight: "bold" }}>Module - {submodule}</Typography>


                </Box>
                <Box sx={{ visibility: { md: 'hidden', sm: 'visible' }, display: { sm: "none" }, width: { md: '40%', sm: '99vw' } }}>
                  <br />
                </Box>
              </Box>}
            {questionType === 'English' ?
              <Grid container sx={{ flex: 1, padding: '0px 8px' }}>
                {isLoading ?
                  <Grid sx={{
                    padding: 1, backgroundColor: "#ffffff",
                    paddingTop: '200px',
                    width: '100%',
                    paddingRight: '0px',
                    marginTop: '60px',
                    paddingleft: '0px',
                    height: 'Calc(100vh - 225px)',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}>         
                    
                       <LottieLoading loading={isLoading} />
                    {showModuleComplete && (
                      <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                        padding: '20px',
                        textAlign: 'center'
                      }}
                    >
                      <Typography variant="h4" sx={{ color: '#909bd7', marginBottom: 2, fontWeight: '500' }}>
                        This Module Is Over
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: 2,  fontWeight: '500'  }}>
                        All your work has been saved
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: 1,  fontWeight: '500'  }}>
                        You'll move on automatically in just a moment.
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'red' }}>
                        Do not refresh this page or quit the app.
                      </Typography>
                    </Box>
                    )}
                  </Grid> :
                  <>
                    <Grid item sm={12} md={gridLeft} sx={{
                      padding: 1, backgroundColor: "#ffffff", borderRight: "5px solid #7d7d7d",
                      paddingTop: '35px',
                      paddingRight: '50px',
                      paddingLeft: '35px',
                      position: 'relative',
                      paddingleft: '50px',
                      height: 'Calc(100vh - 200px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}>

                      <Typography dangerouslySetInnerHTML={{ __html: currentQuestion?.passage }}
                        style={{ userSelect: "none" }}
                        onCopy={(e) => e.preventDefault()}
                        onContextMenu={(e) => e.preventDefault()} />

                      <Box sx={{
                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginBottom: '10px',
                        color: '#adadad',
                        top: '8px',
                        right: '14px',
                        position: 'absolute'
                      }} onClick={handleGridLeft}>
                        <LaunchIcon style={{ scale: '1.1' }} />
                      </Box>

                    </Grid>
                    <Grid item sm={12} md={gridRight} sx={{
                      padding: 1, backgroundColor: "#f9f9f9",
                      paddingTop: '8px',
                      paddingRight: '39px',
                      position: 'relative',
                      paddingLeft: '0px',
                      height: 'Calc(100vh - 200px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                      display: 'flex',
                    }}>
                      <Box onClick={handleGridRight} sx={{
                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', height: '40px',
                        minWidth: '40px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginBottom: '10px',
                        color: '#adadad',
                        top: '0px',
                        left: '14px',
                        position: 'sticky'
                      }}>
                        <LaunchIcon style={{ transform: 'rotate(270deg)', scale: '1.1' }} />
                      </Box>
                      {currentQuestion && (
                        <Box className="assessmentQuestionPanel" sx={{ paddingLeft: "25px !important", paddingTop: "25px !important", flex: '2' }}>

                          <Box
                            sx={{
                              padding: '4px',
                              backgroundColor: "#f0f0f0",
                              borderRadius: '2px',
                              textAlign: "center",
                              borderBottom: '3px dashed #000000c9',
                              paddingLeft: { sx: 0, md: "50px" }
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "inline-block",
                                  padding: "4px 10px !important",
                                  backgroundColor: "black",
                                  borderRadius: "2px",
                                  marginBottom: 0,
                                }}
                              >
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "white" }}>
                                  {currentIndex + 1}
                                </Typography>
                              </Box>

                            <Button
                              onClick={() => {
                                if (markedForReview[currentIndex]) {
                                  handleBookmarkFalse(currentIndex);
                                } else {
                                  handleBookmarkTrue(currentIndex);
                                }
                              }}
                              className="MarkBtn"
                              sx={{
                                minWidth: "auto",
                                color: '#1976d2',
                              }}
                            >
                              {markedForReview[currentIndex] ? (
                                <BookmarkIcon color="#1976d2" />
                              ) : (
                                <BookmarkBorderIcon color="#1976d2" />
                              )}
                              {markedForReview[currentIndex] ? 'Marked for Review' : 'Mark for Review'}
                            </Button>
                            </Box>
                          </Box>
                          <Typography style={{ justifySelf: 'start', userSelect: "none", margin: "10px 0" }}
                            dangerouslySetInnerHTML={{ __html: currentQuestion?.question_text }}

                            onCopy={(e) => e.preventDefault()}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                          {parsedOptions && parsedOptions?.map((option, index) => (
                            <Button
                              key={index}
                              variant="contained"
                              fullWidth
                              sx={{
                                marginTop: 1,
                                fontWeight: "400",
                                backgroundColor: "white",
                                color: "black",
                                lineHeight: '1.2',
                                justifyContent: 'start',
                                borderRadius: '4px',
                                padding: '17px 20px',
                                border: selectedAnswers[currentIndex] === option ? "2px solid #4caf50" : "1px solid #ccc",
                                "&:hover": {
                                  backgroundColor: "#f1f1f1",
                                }
                              }}
                              onClick={() => handleOptionSelect(option, index)}
                            >
                              <span style={{
                                border: '1px solid #aaa',
                                borderRadius: '50%',
                                fontWeight: '400',
                                padding: '7px',
                                height: '23px',
                                width: '23px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '15px',
                              }}>{String.fromCharCode(97 + index)}</span>
                              <span dangerouslySetInnerHTML={{ __html: option }} />

                            </Button>
                          ))}
                        </Box>
                      )}
                    </Grid> </>}
              </Grid> :
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: questionType === 'English' ? 'Calc(100vh - 208px)' : 'Calc(100vh - 200px)', textAlign: "center", alignItems: 'baseline' }}
              >
                {
                  isLoading ?
                    <Grid sx={{
                      padding: 1, backgroundColor: "#ffffff",
                      paddingTop: '200px',
                      width: '100%',
                      paddingRight: '0px',
                      marginTop: '60px',
                      paddingleft: '0px',
                      height: 'Calc(100vh - 180px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}>
                      <LottieLoading loading={isLoading} />
                      {showModuleComplete && (
                      <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                        padding: '20px',
                        textAlign: 'center'
                      }}
                    >
                      <Typography variant="h4" sx={{ color: '#909bd7', marginBottom: 2, fontWeight: '500' }}>
                        This Module Is Over
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: 2,  fontWeight: '500'  }}>
                        All your work has been saved
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: 1,  fontWeight: '500'  }}>
                        You'll move on automatically in just a moment.
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'red' }}>
                        Do not refresh this page or quit the app.
                      </Typography>
                    </Box>
                    )}
                    </Grid> :
                    currentQuestion && (
                      <Box
                        sx={{
                          width: {
                            xs: "90%", sm: "80%", md: "70%", lg: "68%", xl: "85%"
                          },
                          marginTop: '0px',
                          backgroundColor: "#e9f0fa",
                          borderRadius: 1,
                          textAlign: "center",
                          boxShadow: 2,
                          maxHeight: {
                            xs: '370px',
                            sm: 'calc(70vh - 100px)'
                          },
                          overflowY: 'auto',
                          '@media (max-height: 1000px)': {
                            maxHeight: '95%',
                            overflowY: 'auto'
                          },
                          '@media (min-height: 1000px)': {
                            maxHeight: '100%'
                          }
                        }}
                        style={{ padding: '25px', }}
                      >
                        <Box
                          sx={{
                            padding: '4px',
                            backgroundColor: "#f0f0f0",
                            borderRadius: '2px',
                            textAlign: "center",
                            borderBottom: '3px dashed #000000c9'

                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "inline-block",
                                padding: "4px 10px !important",
                                backgroundColor: "black",
                                borderRadius: "2px",
                                marginBottom: 0,
                              }}
                            >
                              <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "white" }}>
                                {currentIndex + 1}
                              </Typography>
                            </Box>

                            <Button
                              onClick={() => {
                                if (markedForReview[currentIndex]) {
                                  handleBookmarkFalse(currentIndex);
                                } else {
                                  handleBookmarkTrue(currentIndex);
                                }
                              }}
                              className="MarkBtn"
                              sx={{
                                minWidth: "auto",
                                color: '#1976d2',
                              }}
                            >
                              {markedForReview[currentIndex] ? (
                                <BookmarkIcon color="#1976d2" />
                              ) : (
                                <BookmarkBorderIcon color="#1976d2" />
                              )}
                              {markedForReview[currentIndex] ? 'Marked for Review' : 'Mark for Review'}
                            </Button>
                          </Box>
                        </Box>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: currentQuestion?.question_text }}
                          sx={{
                            marginTop: '5px',
                            width: "100%",
                            textAlign: 'start',
                            fontWeight: '400',
                            fontSize: { xs: "16px", md: "18px" },
                            marginBottom: 1,
                            userSelect: 'none'
                          }}
                          onCopy={(e) => e.preventDefault()}
                          onContextMenu={(e) => e.preventDefault()}
                        />

                        {parsedOptions && parsedOptions?.map((option, index) => (
                          <Button
                            key={index}
                            variant="contained"
                            fullWidth
                            sx={{
                              marginTop: 1,
                              backgroundColor: "white",
                              color: "black",
                              justifyContent: "start",
                              border: selectedAnswers[currentIndex] === option ? "2px solid #4caf50" : "1px solid #ccc",
                              "&:hover": {
                                backgroundColor: "#f1f1f1",
                              },
                              fontSize: { xs: "12px", md: "14px" },
                            }}
                            style={{
                              padding: '12px 20px',
                              borderRadius: '4px', fontWeight: '400'
                            }}
                            onClick={() => handleOptionSelect(option, index)}
                          >
                            <span style={{
                              border: '1px solid #aaa',
                              borderRadius: '50%',
                              padding: '7px',
                              height: '30px',
                              width: '32px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: '12px',
                            }}>{String.fromCharCode(97 + index)}</span>
                            <span dangerouslySetInnerHTML={{ __html: option }} />
                          </Button>
                        ))}
                      </Box>
                    )}
              </Grid>


            }

<Popover
              open={Boolean(questionListAnchorEl)}
              anchorEl={questionListAnchorEl}
              onClose={handleCloseQuestionList}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{
                sx: {
                  width: '550px',
                  padding: '32px',
                  paddingTop: '20px',
                  overflow: 'hidden'
                }
              }}
            >
              
                 <Box sx={{ display: "flex", flexWrap: 'wrap', justifyContent: "space-between", marginBottom: 2, borderBottom: '2px solid #b1b1b1', alignItems: 'baseline', paddingBottom: '12px !important', }}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#B3B3B3", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Not Answered</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#4caf50", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Answered</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#2196F3", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Current</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      backgroundColor: '#f1f1f1',
                      width: "20px",
                      height: "20px",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: '4px',
                      color: '#FFFFFF',
                      position: "relative",
                      marginRight: 1
                    }}
                  >
                    <BookmarkIcon
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        fontSize: "16px",
                        color: "#FE780F",
                        transform: "translate(30%, -25%)",
                      }}
                    />
                  </Box>
                  <Typography variant="body2">Marked for Review</Typography>
                </Box>
              </Box>

              <Grid container spacing={2}>
              {questions && questions?.length > 0 && questions.map((q, index) => {
                  const isSelected = currentIndex === index;
                  const isAnswered = Object.prototype.hasOwnProperty.call(selectedAnswers, index);
                  const isMarkedForReview = markedForReview[index];

                  let backgroundColor = "#B3B3B3";
                  if (isAnswered) backgroundColor = "#4caf50";
                  if (isSelected) backgroundColor = "#2196F3";

                  return (
                    <Grid item xs={2} key={index}>
                      <Box
                        onClick={() => handleQuestionSelect(index)}
                        sx={{
                          backgroundColor,
                          width: "40px",
                          height: "40px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: '4px',
                          color: '#FFFFFF',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                          position: "relative",
                          "&:hover": { backgroundColor: "#1976d2", color: "white" }
                        }}
                      >
                        {isMarkedForReview && (
                          <BookmarkIcon
                            sx={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              fontSize: "26px",
                              color: "#FE780F",
                              transform: "translate(30%, -25%)",
                            }}
                          />
                        )}
                        {index + 1}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <p style={{
                height: '5px',
                display: 'flex',
                justifyContent: 'center',
                margin: '0'
              }}>
                <span className="reviewBtn"
                  style={{
                    position: 'relative',

                  }}><br /></span>
              </p>
       
            </Popover>

            <Box sx={{ backgroundColor: "#e9f0fa", display: "flex", justifyContent: "space-between", height: '60px', padding: '10px 5px !important' }}>
              <Typography>{userInfo?.name}</Typography>
              <Button variant="contained" style={{ backgroundColor: "#434040",borderRadius: '4px', marginLeft: '40px' }} disabled={isPaused} onClick={handleOpenQuestionList}>
                View all questions
              </Button>
              <Box sx={{ padding: 1, backgroundColor: "#e9f0fa", display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={handleBack}
                  disabled={currentIndex === 0 || isPaused}
                  style={{
                    marginRight: '10px', background: (currentIndex === 0 || isPaused) ? '#d3d3d3' : '#1976d2',
                    borderRadius: '4px', color: "#fff"
                  }} >
                  Back
                </Button>
                {currentIndex === questions?.length - 1 ? (
                  <Button variant="contained" style={{ background: '#1976d2', borderRadius: '4px', color: "#fff" }} onClick={handleSubmit} disabled={isPaused} >Submit</Button>
                ) : (
                  <Button variant="contained" style={{ background: '#1976d2', borderRadius: '4px', color: "#fff" }} onClick={() => handleNext()} disabled={isPaused}>Next</Button>
                )}
              </Box>
            </Box>
          </Box>
        </>
      }
    
      <Snackbar open={isopenSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <DialogTitle id="alert-dialog-title" style={{ paddingBottom: '0px !important' }} sx={{pb: 0}} textAlign={'center'}>
          {"Do you really want to submit"}
          <Typography variant="body2" style={{ textAlign: 'center' , marginBottom: '10px', fontWeight: '500', fontSize: '17px'}}>
              {questionType === 'English' ? 'Section 1: Reading and Writing' : 'Section 2: Math'}, Module - {submodule} ?
            </Typography>
        </DialogTitle>
        <DialogContent style={{borderTop: '1px solid rgb(163, 163, 163)', borderBottom: '1px solid rgb(163, 163, 163)',  overflow: 'hidden'}}>

          <Box
            sx={{
              margin: 'auto',
              width: '550px',
              backgroundColor: 'white',
              padding: '20px !important',
              paddingTop: '15px !important',
              overflow: 'hidden'

            }}
          >

            {/* <Typography variant="body2" style={{ textAlign: 'center', marginBottom: '5px', fontSize: '16px' }}><b>Module - {submodule}</b></Typography> */}

            <Box sx={{ display: "flex", flexWrap: 'wrap', justifyContent: "space-between", marginBottom: 2, borderBottom: '2px solid #b1b1b1', alignItems: 'baseline', paddingBottom: '12px !important', }}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#B3B3B3", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Not Answered</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#4caf50", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Answered</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: 20, height: 20, backgroundColor: "#2196F3", borderRadius: "4px", marginRight: 1 }} />
                  <Typography variant="body2">Current</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      backgroundColor: '#f1f1f1',
                      width: "20px",
                      height: "20px",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: '4px',
                      color: '#FFFFFF',
                      position: "relative",
                      marginRight: 1
                    }}
                  >
                    <BookmarkIcon
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        fontSize: "16px",
                        color: "#FE780F",
                        transform: "translate(30%, -25%)",
                      }}
                    />
                  </Box>
                  <Typography variant="body2">Marked for Review</Typography>
                </Box>
              </Box>

              <Grid container spacing={2}>
              {questions && questions?.length > 0 && questions.map((q, index) => {
                  const isSelected = currentIndex === index;
                  const isAnswered = Object.prototype.hasOwnProperty.call(selectedAnswers, index);
                  const isMarkedForReview = markedForReview[index];

                  let backgroundColor = "#B3B3B3";
                  if (isAnswered) backgroundColor = "#4caf50";
                  if (isSelected) backgroundColor = "#2196F3";

                  return (
                    <Grid item xs={2} key={index}>
                      <Box
                       
                        sx={{
                          backgroundColor,
                          width: "40px",
                          height: "40px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: '4px',
                          color: '#FFFFFF',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                          position: "relative",
                          "&:hover": { backgroundColor: "#1976d2", color: "white" }
                        }}
                      >
                        {isMarkedForReview && (
                          <BookmarkIcon
                            sx={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              fontSize: "26px",
                              color: "#FE780F",
                              transform: "translate(30%, -25%)",
                            }}
                          />
                        )}
                        {index + 1}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <p style={{
                height: '5px',
                display: 'flex',
                justifyContent: 'center',
                margin: '0'
              }}>
                <span className="reviewBtn"
                  style={{
                    position: 'relative',

                  }}><br /></span>
              </p>
            </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{display:'flex',justifyContent:'space-between',flex:2,padding:'0 20px',marginBottom:'10px'}}>
          <Button 
           variant="outlined"
            onClick={() => setOpenConfirmDialog(false)} 
            color="primary"
          >
            Back
          </Button>
          <Button 
            variant="outlined"
            onClick={() => handlePostUserResult()} 
            color="primary"
          >
            Submit
          </Button>
          </Box>
          
         
        </DialogActions>
      </Dialog>

    </Page>
  );
};

export default SatAssessment;
